import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as librariesActions from '../../actions/libraries.actions';
import Table from '../../components/Table';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import ModalNewGrade from '../../components/Grades/Modals/ModalNewGrade';
import ModalEditGrade from '../../components/Grades/Modals/ModalEditGrade';
import ModalDeleteGrade from '../../components/Grades/Modals/ModalDeleteGrade';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { checkPolicies } from '../../utils/policies.utils';
import { DELETE_GRADE, UPDATE_GRADE } from '../../constants/policies.constants';
import AccessChecker from '../../components/AccessChecker';
import PoliciesContext from '../../PoliciesContext';
import { GradeInfoType } from '../../types/libraries';
import { getCompanyPositionsFilter } from '../../actions/filters.actions';
import RefreshButton from '../../components/RefreshButton';

function Grades({
  getGradesList,
  createNewGrade,
  editGrade,
  deleteGrade,
  gradesError,
  isLoading,
  tableData,
  getCompanyPositionsFilters,
  companyPositionList,
  resetGradesError,
  resetErrors,
  resetState,
}: ConnectedProps<typeof connector>) {
  const policies = useContext(PoliciesContext);

  const [modalNewGradeIsOpen, setModalNewGradeIsOpen] = useState(false);
  const [modalEditGradeIsOpen, setModalEditGradeIsOpen] = useState(false);
  const [modalDeleteGradeIsOpen, setModalDeleteGradeIsOpen] = useState(false);
  const [gradeClicked, setGradeClicked] = useState<GradeInfoType>({
    name: '',
    id: '',
    level: 0,
    positions: [],
    positionIds: [],
  });

  const intl = useIntl();

  useEffect(() => {
    getGradesList();
    getCompanyPositionsFilters();
    return () => {
      resetState();
    };
  }, []);

  const openNewGradeModal = useCallback(() => {
    setModalNewGradeIsOpen(true);
  }, []);

  const closeNewGradeModal = useCallback(() => {
    setModalNewGradeIsOpen(false);
    resetGradesError();
  }, []);

  const openEditGradeModal = useCallback(() => {
    setModalEditGradeIsOpen(true);
  }, []);

  const closeEditGradeModal = useCallback(() => {
    setModalEditGradeIsOpen(false);
    resetGradesError();
  }, []);

  const openDeleteGradeModal = useCallback(() => {
    setModalDeleteGradeIsOpen(true);
  }, []);

  const closeDeleteGradeModal = useCallback(() => {
    setModalDeleteGradeIsOpen(false);
  }, []);

  const setGradeCallback = useCallback(() => {
    setGradeClicked({
      name: '',
      id: '',
      level: 0,
      positions: [],
      positionIds: [],
    });
  }, []);

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.nameColumn),
        modifier: (row: GradeInfoType) => row.name,
      },
      {
        name: intl.formatMessage(messages.positionsLabel),
        modifier: (row: GradeInfoType) => row.positions?.map(({ displayName }) => displayName).join(', '),
      },
    ],
    [],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: GradeInfoType) => {
          setGradeClicked(row);
          openEditGradeModal();
        },
        verifiablePolicies: [UPDATE_GRADE],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: GradeInfoType) => {
          setGradeClicked(row);
          openDeleteGradeModal();
        },
        verifiablePolicies: [DELETE_GRADE],
      },
    ],
    [],
  );

  const submitTable = useCallback(
    (data: Record<string, unknown>) => {
      const grade = tableData[data.oldIndex as number];
      grade.level = data.newIndex;
      editGrade(grade.id, {
        data: {
          level: grade.level,
          name: grade.name,
          id: grade.id,
          positions: grade.positions,
          positionIds: grade.positionIds,
        },
        callback: () => null,
        params: { forceUpdate: true },
      });
    },
    [tableData],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom no-border">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[UPDATE_GRADE]}>
                  <Button externalClass={'button--with-icon'} onClick={openNewGradeModal}>
                    <Icon iconName={'plus'} externalClass={'button__icon'} />
                    <span className="button__text">
                      <FormattedMessage {...messages.newButton} />
                    </span>
                  </Button>
                </AccessChecker>
                <RefreshButton onRefresh={() => getGradesList()} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <Table
            externalClass={'table'}
            tableColumns={tableColumns}
            tableData={tableData || []}
            loading={isLoading.getGrades}
            error={gradesError}
            tableActions={tableActions}
            onDragSort={submitTable}
            sortable={checkPolicies([UPDATE_GRADE], policies)}
          />
        </div>
      </div>
      {modalNewGradeIsOpen && (
        <ModalNewGrade
          isOpen
          onCloseRequest={closeNewGradeModal}
          createNewGrade={createNewGrade}
          gradeError={gradesError}
          isLoading={isLoading.createGrade}
          companyPositions={companyPositionList}
          resetErrors={resetErrors}
        />
      )}
      {modalEditGradeIsOpen && (
        <ModalEditGrade
          isOpen
          onCloseRequest={closeEditGradeModal}
          editGrade={editGrade}
          gradeData={gradeClicked}
          gradeError={gradesError}
          isLoading={isLoading.editGrade}
          companyPositions={companyPositionList}
          resetErrors={resetErrors}
        />
      )}
      {modalDeleteGradeIsOpen && (
        <ModalDeleteGrade
          isOpen
          onCloseRequest={closeDeleteGradeModal}
          onDeleteRequest={(data: Record<string, unknown>) => {
            deleteGrade({
              ...data,
              setGradeCallback,
            });
          }}
          isLoading={isLoading.deleteGrade}
          gradeError={gradesError}
          gradeData={gradeClicked}
          resetErrors={resetErrors}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ libraries, filters }: RootState) => ({
  tableData: libraries.gradesTableData?.content,
  gradesError: libraries.errors.gradeError,
  isLoading: libraries.loading,
  companyPositionList: filters.companyPositionsFilter.positions,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getGradesList: () => dispatch(librariesActions.getGradesList()),
  deleteGrade: (data: Record<string, unknown>) => dispatch(librariesActions.deleteGrade(data)),
  createNewGrade: (data: Record<string, unknown>) => dispatch(librariesActions.createNewGrade(data)),
  editGrade: (id: string, data: Record<string, unknown>) => dispatch(librariesActions.editGrade({ ...data, id })),
  getCompanyPositionsFilters: () => dispatch(getCompanyPositionsFilter()),
  resetGradesError: () => dispatch(librariesActions.resetErrors()),
  resetErrors: () => dispatch(librariesActions.resetErrors()),
  resetState: () => dispatch(librariesActions.resetState()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Grades);
