import React, { useCallback, useMemo, useState } from 'react';
import { OptionTypeBase } from 'react-select';
import { useIntl } from 'react-intl';
import messages from '../messages';
import Filter from '../../Filter';
import { UserInfo } from '../../../enums/users.enum';
import { FilterParamsName, FilterTypes } from '../../../constants/filters.constants';
import { useFiltersListValue } from '../../../utils/hooks.utils';
import { EmployeesTimesheetReportParams } from '../../../enums/params/reports.params';
import { employeesTimesheetUnsavedParams, ReportUsersStatuses } from '../../../constants/reports.constants';
import FilterClearButton from '../../FilterClearButton';
import FiltersControl from '../../FiltersControl';
import { SavedFilter, SavedFiltersDataType } from '../../../enums/filters.enum';
import RefreshButton from '../../RefreshButton';

type EmployeesIssueTypesFilterProps = {
  filters: any;
  values: EmployeesTimesheetReportParams;
  setEmployeesTimesheetReportParams: (data: Partial<EmployeesTimesheetReportParams>) => void;
  handleMultiParamsChange: (name: string) => (data: OptionTypeBase) => void;
  handleUsersParamsChange: (name: string) => (data: OptionTypeBase) => void;
  handleUsersStatuses: (data: OptionTypeBase | OptionTypeBase[]) => void;
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) => void;
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) => void;
  deleteSavedFilter: (data: { id: string; callback: () => void }) => void;
  savedFiltersData: SavedFiltersDataType;
  authUserId: string;
  handleFiltersControlChange: (value: SavedFilter) => void;
  handleClear: () => void;
  resetSavedFilterErrors: () => void;
  showClearButton: boolean;
};

const EmployeesTimesheetFilter = ({
  filters,
  values,
  handleMultiParamsChange,
  handleUsersParamsChange,
  handleUsersStatuses,
  setEmployeesTimesheetReportParams,
  createNewSavedFilter,
  editSavedFilter,
  deleteSavedFilter,
  savedFiltersData,
  authUserId,
  handleFiltersControlChange,
  handleClear,
  resetSavedFilterErrors,
  showClearButton,
}: EmployeesIssueTypesFilterProps) => {
  const intl = useIntl();

  const [resetCurrentFilter, setResetCurrentFilter] = useState(false);

  const officesOptions = useMemo(
    () =>
      filters.offices?.map((office: Record<string, any>) => ({
        label: office.name,
        value: office.id,
      })),
    [filters.offices],
  );

  const departmentsOptions = useMemo(
    () =>
      filters.departments?.map((department: Record<string, any>) => ({
        label: department.displayName,
        value: department.id,
      })),
    [filters.departments],
  );

  const employeesOptions = useMemo(
    () =>
      filters.users.map((user: UserInfo) => ({
        label: user.fullName,
        value: user,
      })),
    [filters.users],
  );

  const jiraProjectsOptions = useMemo(
    () => filters.jiraProjectsList?.map(({ id, name }: { id: string; name: string }) => ({ value: id, label: name })),
    [filters.jiraProjectsList],
  );

  const usersStatusesOptions = useMemo(
    () => [
      {
        label: intl.formatMessage(messages.activeLabel),
        value: ReportUsersStatuses.ACTIVE,
      },
      {
        label: intl.formatMessage(messages.inactiveLabel),
        value: ReportUsersStatuses.INACITVE,
      },
    ],
    [],
  );

  const officesValues = useFiltersListValue(officesOptions, values.offices);

  const departmentsValues = useFiltersListValue(departmentsOptions, values.departmentIds);

  const employeeValues = useFiltersListValue(employeesOptions, values.portalUserIds);

  const jiraProjectsValues = useFiltersListValue(jiraProjectsOptions, values.jiraProjectIds);

  const statusesValues = useMemo(
    () =>
      usersStatusesOptions.filter(el => {
        switch (el.value) {
          case ReportUsersStatuses.ACTIVE: {
            return values.isAllowActive;
          }
          case ReportUsersStatuses.INACITVE: {
            return values.isAllowPassive;
          }
        }
      }),
    [values.isAllowActive, values.isAllowPassive],
  );

  const onClear = useCallback(() => {
    setResetCurrentFilter(true);
    handleClear();
  }, [values]);

  const setResettFilterFlag = useCallback(() => {
    setResetCurrentFilter(false);
  }, []);

  return (
    <>
      <Filter
        isMulti
        label={intl.formatMessage(messages.projectsLabel)}
        options={jiraProjectsOptions}
        handleChange={handleMultiParamsChange(FilterParamsName.JIRA_PROJECT_IDS)}
        value={jiraProjectsValues}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        label={intl.formatMessage(messages.officesLabel)}
        options={officesOptions}
        value={officesValues}
        handleChange={handleMultiParamsChange(FilterParamsName.OFFICES)}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        label={intl.formatMessage(messages.departmentsLabel)}
        options={departmentsOptions}
        value={departmentsValues}
        handleChange={handleMultiParamsChange(FilterParamsName.DEPARTMENT_IDS)}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        isUsersFilter
        label={intl.formatMessage(messages.employeesLabel)}
        options={employeesOptions}
        value={employeeValues}
        handleChange={handleUsersParamsChange(FilterParamsName.PORTAL_USER_IDS)}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        label={intl.formatMessage(messages.statusLabel)}
        options={usersStatusesOptions}
        defaultValue={[usersStatusesOptions[0]]}
        handleChange={handleUsersStatuses}
        value={statusesValues}
        externalClass="filters__select"
      />
      {showClearButton && <FilterClearButton onClear={onClear} />}
      <FiltersControl
        handleSaveFilter={createNewSavedFilter}
        handleUpdateFilter={editSavedFilter}
        handleDeleteFilter={deleteSavedFilter}
        savedFiltersData={savedFiltersData}
        authUserId={authUserId}
        filterType={FilterTypes.MEMBERS_TIMESHEET_REPORT_FILTER}
        handleChange={handleFiltersControlChange}
        params={values}
        resetSavedFilterErrors={resetSavedFilterErrors}
        unsavedParams={employeesTimesheetUnsavedParams}
        resetCurrentFilter={resetCurrentFilter}
        setResettFilterFlag={setResettFilterFlag}
      />
      <RefreshButton onRefresh={() => setEmployeesTimesheetReportParams(values)} />
    </>
  );
};

export default React.memo(EmployeesTimesheetFilter);
