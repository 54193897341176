import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { EventTypeInfo } from '../../enums/calendar.enum';
import * as calendarActions from '../../actions/calendar.actions';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import Table from '../../components/Table';
import ModalNewEventType from '../../components/EventTypes/Modals/ModalNewEventType';
import ModalEditEventType from '../../components/EventTypes/Modals/ModalEditEventType';
import ModalDeleteEventType from '../../components/EventTypes/Modals/ModalDeleteEventType';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import TableUserAvatar from '../../components/TableUserAvatar';
import { EventTypesParams } from '../../enums/params/calendar.params';
import AccessChecker from '../../components/AccessChecker';
import { DELETE_EVENT_TYPE, UPDATE_EVENT_TYPE } from '../../constants/policies.constants';
import { getOfficesFilter, getUsersFilter } from '../../actions/filters.actions';
import { convertDateTimeToUtc } from '../../enums/common.enum';
import RefreshButton from '../../components/RefreshButton';

function EventTypes({
  userList,
  officeList,
  currentUserInfo,
  tableData,
  sortParams,
  eventTypeError,
  isLoading,
  eventType,
  getEventTypesList,
  getOfficesFilter,
  getUsersFilter,
  deleteEventType,
  createNewEventType,
  editEventType,
  setEventTypesParams,
  getEventTypeById,
  resetErrors,
  resetState,
  resetEventType,
}: ConnectedProps<typeof connector>) {
  const intl = useIntl();

  const [modalNewEventTypeIsOpen, setModalNewEventTypeIsOpen] = useState(false);
  const [modalEditEventTypeIsOpen, setModalEditEventTypeIsOpen] = useState(false);
  const [modalDeleteEventTypeIsOpen, setModalDeleteEventTypeIsOpen] = useState(false);
  const [eventTypeClicked, setEventTypeClicked] = useState(new EventTypeInfo());

  useEffect(() => {
    getEventTypesList();
    getOfficesFilter();
    getUsersFilter();
    return () => {
      resetState();
    };
  }, []);

  const openNewEventTypeModal = useCallback(() => {
    setModalNewEventTypeIsOpen(true);
  }, []);

  const closeNewEventTypeModal = useCallback(() => {
    setModalNewEventTypeIsOpen(false);
  }, []);

  const openEditEventTypeModal = useCallback(() => {
    setModalEditEventTypeIsOpen(true);
  }, []);

  const closeEditEventTypeModal = useCallback(() => {
    setModalEditEventTypeIsOpen(false);
    resetEventType();
  }, []);

  const openDeleteEventTypeModal = useCallback(() => {
    setModalDeleteEventTypeIsOpen(true);
  }, []);

  const closeDeleteEventTypeModal = useCallback(() => {
    setModalDeleteEventTypeIsOpen(false);
    resetEventType();
  }, []);

  const handleSort = useCallback((sortBy, direction) => setEventTypesParams({ sortBy, direction }), []);

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.nameColumn),
        sortName: 'name',
        modifier: (row: EventTypeInfo) => (
          <div className={'table__data-wrapper'}>
            <div className={'table__data-no-image'} style={{ backgroundColor: row.color }} />
            <span className={'table_button'}>{row.name}</span>
          </div>
        ),
      },
      {
        name: intl.formatMessage(messages.editorColumn),
        sortName: 'lastEditor.secondName',
        modifier: (row: EventTypeInfo) => <span>{`${row.lastEditor.firstName} ${row.lastEditor.secondName}`}</span>,
      },
      {
        name: intl.formatMessage(messages.modifiedTitle),
        sortName: 'lastModifiedDate',
        modifier: (row: EventTypeInfo) => <span>{convertDateTimeToUtc(row.lastModifiedDate)}</span>,
      },
      {
        name: intl.formatMessage(messages.eventsTitle),
        modifier: (row: EventTypeInfo) => <span>{row.eventsQuantity}</span>,
      },
      {
        name: intl.formatMessage(messages.whoCanManageTitle),
        modifier: (row: EventTypeInfo) => (
          <>
            {row.limitedManagerPersonEnabled ? (
              <TableUserAvatar users={row.limitedManagerPersons} fileSize={48} selectedTableUser />
            ) : (
              <div className="table__data-wrapper all-responders-wrapper">
                <Icon iconName={'users'} />
                <FormattedMessage {...messages.allLabel} />
              </div>
            )}
          </>
        ),
      },
    ],
    [],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: EventTypeInfo) => {
          setEventTypeClicked(row);
          openEditEventTypeModal();
        },
        verifiablePolicies: [UPDATE_EVENT_TYPE],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: EventTypeInfo) => {
          setEventTypeClicked(row);
          openDeleteEventTypeModal();
        },
        verifiablePolicies: [DELETE_EVENT_TYPE],
      },
    ],
    [],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom no-border">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[UPDATE_EVENT_TYPE]}>
                  <Button externalClass={'button--with-icon'} onClick={openNewEventTypeModal}>
                    <Icon iconName={'plus'} externalClass={'button__icon'} />
                    <span className="button__text">
                      <FormattedMessage {...messages.newButton} />
                    </span>
                  </Button>
                </AccessChecker>
                <RefreshButton onRefresh={() => setEventTypesParams(sortParams)} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <Table
            externalClass={'table--offices'}
            tableColumns={tableColumns}
            tableData={tableData || []}
            params={sortParams}
            tableActions={tableActions}
            onSort={handleSort}
            error={eventTypeError}
            loading={isLoading.getEventTypes}
          />
        </div>
      </div>
      {modalNewEventTypeIsOpen && (
        <ModalNewEventType
          isOpen
          currentUserInfo={currentUserInfo}
          onCloseRequest={closeNewEventTypeModal}
          createNewEventType={createNewEventType}
          eventTypeError={eventTypeError}
          isLoading={isLoading.createEventType}
          offices={officeList}
          users={userList}
          resetErrors={resetErrors}
        />
      )}
      {modalEditEventTypeIsOpen && (
        <ModalEditEventType
          currentUserInfo={currentUserInfo}
          isOpen
          onCloseRequest={closeEditEventTypeModal}
          eventTypeData={eventTypeClicked}
          eventType={eventType}
          editEventType={editEventType}
          eventTypeError={eventTypeError}
          isLoading={isLoading.editEventType}
          offices={officeList}
          users={userList}
          getEventTypeById={getEventTypeById}
          resetErrors={resetErrors}
        />
      )}
      {modalDeleteEventTypeIsOpen && (
        <ModalDeleteEventType
          isOpen
          onCloseRequest={closeDeleteEventTypeModal}
          onDeleteRequest={(data: any) => {
            deleteEventType({
              ...data,
              setEventTypeCallback: () => {
                setEventTypeClicked(new EventTypeInfo());
              },
            });
          }}
          eventTypeData={eventTypeClicked}
          eventTypeError={eventTypeError}
          isLoading={isLoading.deleteEventType}
          resetErrors={resetErrors}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ auth, calendar, filters }: RootState) => ({
  officeList: filters.officesFilter.offices,
  userList: filters.usersFilter.users,
  currentUserInfo: auth.currentUserInfo,
  sortParams: calendar.eventTypesParams,
  tableData: calendar.eventTypesTableData?.content,
  eventTypeError: calendar.errors.eventTypeError,
  isLoading: calendar.loading,
  eventType: calendar.eventType,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getOfficesFilter: () => dispatch(getOfficesFilter()),
  getEventTypesList: () => dispatch(calendarActions.getEventTypesList()),
  deleteEventType: (data: any) => dispatch(calendarActions.deleteEventType(data)),
  createNewEventType: (data: any) => dispatch(calendarActions.createNewEventType(data)),
  editEventType: (id: string, data?: any, callback?: () => void, makeRequest = false) =>
    dispatch(calendarActions.editEventType({ data, id, callback, makeRequest })),
  setEventTypesParams: (params: Partial<EventTypesParams>) => dispatch(calendarActions.setEventTypesParams(params)),
  getUsersFilter: () => dispatch(getUsersFilter()),
  getEventTypeById: (id: string) => dispatch(calendarActions.getEventTypeById(id)),
  resetErrors: () => dispatch(calendarActions.resetCalendarErrors()),
  resetState: () => dispatch(calendarActions.resetState()),
  resetEventType: () => dispatch(calendarActions.resetEventType()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(EventTypes);
