import {
  Client,
  FinancePlan,
  InvoiceCloneClass,
  OfficeBalanceClass,
  PlannedExpenseClass,
  PutDashboardSetting,
  Transaction,
  TransactionInitialValue,
} from '../enums/finance/finance.enum';
import * as paramsTypes from '../enums/params/finance.params';
import * as cashflowTypes from '../enums/params/cashflow.params';
import * as financeTypes from '../types/finance';
import request from '../utils/request.utils';
import { QueryParamsType } from './../utils/params.utils';
import { CancelToken } from 'axios';

export function getIncomeTypesList(params: QueryParamsType<paramsTypes.IncomeTypesParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/incomeTypes',
    params,
  };
  return request(options);
}

export function getIncomeTypesFullList(params: QueryParamsType<paramsTypes.IncomeTypesParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/incomeTypes/fullInfo',
    params,
  };
  return request(options);
}

export function createIncomeType(data: financeTypes.IncomeType) {
  const options: RequestConfig = {
    method: 'post',
    url: '/incomeTypes',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function editIncomeType(data: financeTypes.IncomeType) {
  const options: RequestConfig = {
    method: 'put',
    url: `/incomeTypes/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteIncomeType(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/incomeTypes/${id}`,
  };
  return request(options);
}

export function getCurrenciesList(params: QueryParamsType<paramsTypes.CurrenciesParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/currencies',
    params,
  };
  return request(options);
}

export function createCurrency(data: financeTypes.CurrencyType) {
  const options: RequestConfig = {
    method: 'post',
    url: '/currencies',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function editCurrency(data: financeTypes.CurrencyType) {
  const options: RequestConfig = {
    method: 'put',
    url: `/currencies/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteCurrency(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/currencies/${id}`,
  };
  return request(options);
}

export function getSuppliersList(params: QueryParamsType<paramsTypes.SuppliersParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/suppliers',
    params,
  };
  return request(options);
}

export function createSupplier(data: financeTypes.SupplierType) {
  const options: RequestConfig = {
    method: 'post',
    url: '/suppliers',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function editSupplier(data: financeTypes.SupplierType) {
  const options: RequestConfig = {
    method: 'put',
    url: `/suppliers/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteSupplier(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/suppliers/${id}`,
  };
  return request(options);
}

export function getProjectTypesList(params: QueryParamsType<paramsTypes.ProjectTypesParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/projectTypes',
    params,
  };
  return request(options);
}

export function createProjectType(data: financeTypes.ProjectType) {
  const options: RequestConfig = {
    method: 'post',
    url: '/projectTypes',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function editProjectType(data: financeTypes.ProjectType) {
  const options: RequestConfig = {
    method: 'put',
    url: `/projectTypes/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteProjectType(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/projectTypes/${id}`,
  };
  return request(options);
}

export function getExpenseTypesList(params: QueryParamsType<paramsTypes.ExpenseTypesParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/expenseTypes',
    params,
  };
  return request(options);
}

export function getExpenseTypesFullList(params: QueryParamsType<paramsTypes.ExpenseTypesParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/expenseTypes/fullInfo',
    params,
  };
  return request(options);
}

export function createExpenseType(data: financeTypes.ExpenseType) {
  const options: RequestConfig = {
    method: 'post',
    url: '/expenseTypes',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function editExpenseType(data: financeTypes.ExpenseType) {
  const options: RequestConfig = {
    method: 'put',
    url: `/expenseTypes/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteExpenseType(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/expenseTypes/${id}`,
  };
  return request(options);
}

export function getClientsList(params: QueryParamsType<paramsTypes.ClientsParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/clients',
    params,
  };
  return request(options);
}

export function createClient(data: Client) {
  const options: RequestConfig = {
    method: 'post',
    url: '/clients',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function editClient(data: Client) {
  const options: RequestConfig = {
    method: 'put',
    url: `/clients/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteClient(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/clients/${id}`,
  };
  return request(options);
}

export function changeClientStatus(id: string, isActive: string) {
  const options: RequestConfig = {
    method: 'patch',
    url: `/clients/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: isActive,
  };
  return request(options);
}

export function getSalariesList(params: paramsTypes.SalariesParams) {
  const options: RequestConfig = {
    method: 'get',
    url: '/salaries/report',
    params,
  };
  return request(options);
}

export function getSalary(id: string) {
  const options: RequestConfig = {
    method: 'get',
    url: `/salaries/${id}`,
  };
  return request(options);
}

export function editSalary(data: financeTypes.SalaryType) {
  const options: RequestConfig = {
    method: 'put',
    url: `/salaries`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteSalary(data: { id: string; deleteFutureSalaries: boolean }) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/salaries/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: `${data.deleteFutureSalaries}`,
  };
  return request(options);
}

export function getInvoices(params: QueryParamsType<cashflowTypes.InvoicesParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/invoices',
    params,
  };
  return request(options);
}

export function getInvoice(id: string) {
  const options: RequestConfig = {
    method: 'get',
    url: `/invoices/${id}`,
  };
  return request(options);
}

export function createInvoice(data: any) {
  const options: RequestConfig = {
    method: 'post',
    url: `/invoices`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function editInvoice(data: any, id: string | undefined) {
  const options: RequestConfig = {
    method: 'put',
    url: `/invoices/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function uploadInvoice(data: any, id: string | undefined) {
  const options: RequestConfig = {
    method: 'post',
    url: `/invoices/${id}/file`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function generateTaskList(id: string, cancelToken: CancelToken) {
  const options: RequestConfig = {
    method: 'get',
    url: `/invoices/${id}/invoiceTasksHoursList/export/stream`,
    responseType: 'blob',
    cancelToken,
  };
  return options;
}

export function downloadInvoice(id: string, cancelToken: CancelToken) {
  const options: RequestConfig = {
    method: 'get',
    url: `/invoices/${id}/file`,
    responseType: 'blob',
    cancelToken,
  };
  return options;
}

export function deleteInvoiceFile(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/invoices/${id}/file`,
  };
  return request(options);
}

export function deleteInvoice(id: string, deleteAllSubsequent: boolean) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/invoices/${id}?deleteAllSubsequent=${deleteAllSubsequent}`,
  };
  return request(options);
}

export function cloneInvoice(data: InvoiceCloneClass) {
  const options: RequestConfig = {
    method: 'post',
    url: `/invoices/${data.id}/clone`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function getProjectsList(params: QueryParamsType<paramsTypes.FinanceProjectsParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/financeProjects',
    params: params,
  };
  return request(options);
}

export function createProject(data: financeTypes.ProjectType, forceUpdate?: boolean) {
  const options: RequestConfig = {
    method: 'post',
    url: '/financeProjects',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    params: { forceUpdate: forceUpdate },
  };
  return request(options);
}

export function editProject(data: financeTypes.ProjectType, forceUpdate?: boolean) {
  const options: RequestConfig = {
    method: 'put',
    url: `/financeProjects/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    params: { forceUpdate: forceUpdate },
  };
  return request(options);
}

export function deleteProject(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/financeProjects/${id}`,
  };
  return request(options);
}

export function changeProjectStatus(id: string, isActive: string) {
  const options: RequestConfig = {
    method: 'patch',
    url: `/financeProjects/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: isActive,
  };
  return request(options);
}

export function getProfitLossList(params: paramsTypes.OfficesBalanceParams) {
  const options: RequestConfig = {
    method: 'get',
    url: '/profitLossReport',
    params: params,
  };
  return request(options);
}

export function getProfitLossRevenueClientList() {
  const options: RequestConfig = {
    method: 'get',
    url: '/profitLossReportSetting/revenueFromClient',
  };
  return request(options);
}

export function putProfitLossRevenueClient(data: cashflowTypes.ProfitLossRevenueClient) {
  const options: RequestConfig = {
    method: 'put',
    url: '/profitLossReportSetting/revenueFromClient',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function getProfitLossOperatingIncome() {
  const options: RequestConfig = {
    method: 'get',
    url: '/profitLossReportSetting/operatingIncome',
  };
  return request(options);
}

export function putProfitLossOperatingIncome(name: string) {
  const options: RequestConfig = {
    method: 'put',
    url: '/profitLossReportSetting/operatingIncome',
    headers: {
      'Content-Type': 'application/json',
    },
    data: { name },
  };
  return request(options);
}

export function getProfitLossNetIncome() {
  const options: RequestConfig = {
    method: 'get',
    url: '/profitLossReportSetting/netIncome',
  };
  return request(options);
}

export function putProfitLossNetIncome(name: string) {
  const options: RequestConfig = {
    method: 'put',
    url: '/profitLossReportSetting/netIncome',
    headers: {
      'Content-Type': 'application/json',
    },
    data: { name },
  };
  return request(options);
}

export function getProfitLossGrossProfit() {
  const options: RequestConfig = {
    method: 'get',
    url: '/profitLossReportSetting/grossProfit',
  };
  return request(options);
}

export function putProfitLossGrossProfit(name: string) {
  const options: RequestConfig = {
    method: 'put',
    url: '/profitLossReportSetting/grossProfit',
    headers: {
      'Content-Type': 'application/json',
    },
    data: { name },
  };
  return request(options);
}

export function getProfitLossRevenuesExpense() {
  const options: RequestConfig = {
    method: 'get',
    url: '/profitLossReportSetting/totalRevenuesExpense',
  };
  return request(options);
}

export function putProfitLossRevenuesExpense(name: string) {
  const options: RequestConfig = {
    method: 'put',
    url: '/profitLossReportSetting/totalRevenuesExpense',
    headers: {
      'Content-Type': 'application/json',
    },
    data: { name },
  };
  return request(options);
}

export function getProfitLossTotalGoods() {
  const options: RequestConfig = {
    method: 'get',
    url: '/profitLossReportSetting/totalCostOfGoods',
  };
  return request(options);
}

export function putProfitLossTotalGoods(name: string) {
  const options: RequestConfig = {
    method: 'put',
    url: '/profitLossReportSetting/totalCostOfGoods',
    headers: {
      'Content-Type': 'application/json',
    },
    data: { name },
  };
  return request(options);
}

export function getProfitLossFinancialRevenue() {
  const options: RequestConfig = {
    method: 'get',
    url: '/profitLossReportSetting/financialRevenue',
  };
  return request(options);
}

export function putProfitLossFinancialRevenue(data: cashflowTypes.CashFlowSettingClass) {
  const options: RequestConfig = {
    method: 'put',
    url: '/profitLossReportSetting/financialRevenue',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function getProfitLossCostOfProject(blockType: 'COST_OF_GOODS' | 'COST_OF_PRODUCTS') {
  const options: RequestConfig = {
    method: 'get',
    url: `/profitLossReportSetting/costOfProject/${blockType}`,
  };
  return request(options);
}

export function putProfitLossCostOfProject(
  blockType: 'COST_OF_GOODS' | 'COST_OF_PRODUCTS',
  data: cashflowTypes.ProfitLossCostProjectClass,
) {
  const options: RequestConfig = {
    method: 'put',
    url: `/profitLossReportSetting/costOfProject/${blockType}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function getProfitLossFinancialExpense(blockType: 'OPERATING_EXPENSES' | 'OTHER_FINANCIAL_EXPENSES') {
  const options: RequestConfig = {
    method: 'get',
    url: `/profitLossReportSetting/financialExpense/${blockType}`,
  };
  return request(options);
}

export function putProfitLossFinancialExpense(
  blockType: 'OPERATING_EXPENSES' | 'OTHER_FINANCIAL_EXPENSES',
  data: cashflowTypes.ProfitLossFinancialExpense,
) {
  const options: RequestConfig = {
    method: 'put',
    url: `/profitLossReportSetting/financialExpense/${blockType}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function getDashboard(id: string, params: paramsTypes.OfficesBalanceParams) {
  const options: RequestConfig = {
    method: 'get',
    url: `/dashboard/${id}`,
    params: params,
  };
  return request(options);
}

export function getDashboardIncome(groupPositionNumber: number, params: paramsTypes.OfficesBalanceParams) {
  const options: RequestConfig = {
    method: 'get',
    url: `/dashboard/topIncome`,
    params: {
      groupPositionNumber,
      ...params,
    },
  };
  return request(options);
}

export function getDashboardExpense(groupPositionNumber: number, params: paramsTypes.OfficesBalanceParams) {
  const options: RequestConfig = {
    method: 'get',
    url: `/dashboard/topExpense`,
    params: {
      groupPositionNumber,
      ...params,
    },
  };
  return request(options);
}

export function getRevenueAndExpense() {
  const options: RequestConfig = {
    method: 'get',
    url: `/dashboardSetting/incomeTypeBlockSetting/REVENUE_AND_EXPENSE`,
  };
  return request(options);
}

export function getExpensesStructure() {
  const options: RequestConfig = {
    method: 'get',
    url: `/dashboardSetting/commonBlockSetting/EXPENSES_STRUCTURE`,
  };
  return request(options);
}

export function putRevenueAndExpense(data: PutDashboardSetting) {
  const options: RequestConfig = {
    method: 'put',
    url: `/dashboardSetting/incomeTypeBlockSetting/REVENUE_AND_EXPENSE`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function putExpensesStructure(data: financeTypes.ExpensesStructure) {
  const options: RequestConfig = {
    method: 'put',
    url: `/dashboardSetting/commonBlockSetting/EXPENSES_STRUCTURE`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function getDashboardPlannedExpenses(blockType: 'PLANNED_EXPENSES_BY_OFFICE' | 'FAVORITE_PLANNED_EXPENSE') {
  const options: RequestConfig = {
    method: 'get',
    url: `/dashboardSetting/expenseTypeBlockSetting/${blockType}`,
  };
  return request(options);
}

export function putDashboardPlannedExpenses(
  blockType: 'PLANNED_EXPENSES_BY_OFFICE' | 'FAVORITE_PLANNED_EXPENSE',
  data: PutDashboardSetting,
) {
  const options: RequestConfig = {
    method: 'put',
    url: `/dashboardSetting/expenseTypeBlockSetting/${blockType}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function getDashboardTopSetting(userId: string) {
  const options: RequestConfig = {
    method: 'get',
    url: `/dashboardSetting/blockTopSetting/${userId}/`,
  };
  return request(options);
}

export function putDashboardTopSetting(userId: string, data: financeTypes.DashboardTop) {
  const options: RequestConfig = {
    method: 'put',
    url: `/dashboardSetting/blockTopSetting/${userId}/`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function getDashboardIncomeTopSetting() {
  const options: RequestConfig = {
    method: 'get',
    url: `/dashboardSetting/blockIncomeTopSetting`,
  };
  return request(options);
}

export function putDashboardIncomeTopSetting(data: financeTypes.EditDashboardTopSetting[]) {
  const options: RequestConfig = {
    method: 'put',
    url: `/dashboardSetting/blockIncomeTopSetting`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function getDashboardExpenseTopSetting() {
  const options: RequestConfig = {
    method: 'get',
    url: `/dashboardSetting/blockExpenseTopSetting`,
  };
  return request(options);
}

export function putDashboardExpenseTopSetting(data: financeTypes.EditDashboardTopSetting[]) {
  const options: RequestConfig = {
    method: 'put',
    url: `/dashboardSetting/blockExpenseTopSetting`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function getDashboardClientSetting(
  blockType: 'PLANNED_AND_ACTUAL_REVENUE' | 'CUSTOMER_INVOICE' | 'REVENUE_BY_CUSTOMER',
) {
  const options: RequestConfig = {
    method: 'get',
    url: `/dashboardSetting/clientBlockSetting/${blockType}`,
  };
  return request(options);
}

export function putDashboardClientSetting(
  blockType: 'PLANNED_AND_ACTUAL_REVENUE' | 'CUSTOMER_INVOICE' | 'REVENUE_BY_CUSTOMER',
  data: PutDashboardSetting,
) {
  const options: RequestConfig = {
    method: 'put',
    url: `/dashboardSetting/clientBlockSetting/${blockType}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function getDashboardSetting(userId: string) {
  const options: RequestConfig = {
    method: 'get',
    url: `/dashboardSetting/blockSetting/${userId}/`,
  };
  return request(options);
}

export function putDashboardSetting(userId: string, data: any) {
  const options: RequestConfig = {
    method: 'put',
    url: `/dashboardSetting/blockSetting/${userId}/`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function getCashFlowReportList(params: cashflowTypes.CashFlowReportParams) {
  const options: RequestConfig = {
    method: 'get',
    url: '/cashFlow',
    params: params,
  };
  return request(options);
}

export function getCashFlowReportExpenseSetting(blockType: 'FIRST_EXPENSE_BLOCK' | 'SECOND_EXPENSE_BLOCK') {
  const options: RequestConfig = {
    method: 'get',
    url: `/cashFlowSetting/expense/${blockType}`,
  };
  return request(options);
}

export function putCashFlowReportExpenseSetting(
  blockType: 'FIRST_EXPENSE_BLOCK' | 'SECOND_EXPENSE_BLOCK',
  data: cashflowTypes.CashFlowSettingClass,
) {
  const options: RequestConfig = {
    method: 'put',
    url: `/cashFlowSetting/expense/${blockType}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function getCashFlowReportTotalSetting() {
  const options: RequestConfig = {
    method: 'get',
    url: `/cashFlowSetting/total`,
  };
  return request(options);
}

export function putCashFlowReportTotalSetting(data: string) {
  const options: RequestConfig = {
    method: 'put',
    url: `/cashFlowSetting/total`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function getCashFlowReportBalanceSetting() {
  const options: RequestConfig = {
    method: 'get',
    url: `/cashFlowSetting/balance`,
  };
  return request(options);
}

export function putCashFlowReportBalanceSetting(data: string) {
  const options: RequestConfig = {
    method: 'put',
    url: `/cashFlowSetting/balance`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function getCashFlowReportIncomeSetting(blockType: 'FIRST_INCOME_BLOCK' | 'SECOND_INCOME_BLOCK') {
  const options: RequestConfig = {
    method: 'get',
    url: `/cashFlowSetting/income/${blockType}`,
  };
  return request(options);
}

export function putCashFlowReportIncomeSetting(
  blockType: 'FIRST_INCOME_BLOCK' | 'SECOND_INCOME_BLOCK',
  data: cashflowTypes.CashFlowSettingClass,
) {
  const options: RequestConfig = {
    method: 'put',
    url: `/cashFlowSetting/income/${blockType}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function getOfficesBalanceList(params: paramsTypes.OfficesBalanceParams) {
  const options: RequestConfig = {
    method: 'get',
    url: '/officeBalance',
    params: params,
  };
  return request(options);
}

export function editOfficeBalance(data: OfficeBalanceClass) {
  const options: RequestConfig = {
    method: 'put',
    url: '/officeBalance',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function getTransactionsList(params: QueryParamsType<paramsTypes.TransactionsParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/transactions',
    params: params,
  };
  return request(options);
}

export function getTransactionsTotal(params: QueryParamsType<Partial<paramsTypes.TransactionsParams>>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/transactions/total',
    params: params,
  };
  return request(options);
}

export function createTransactions(data: Transaction) {
  const options: RequestConfig = {
    method: 'post',
    url: '/transactions',
    data,
  };
  return request(options);
}

export function batchCreateTransactions(data: { transactions: TransactionInitialValue[] }) {
  const options: RequestConfig = {
    method: 'post',
    url: '/transactions/batch',
    data,
  };
  return request(options);
}

export function editTransaction(data: Transaction) {
  const options: RequestConfig = {
    method: 'put',
    url: `/transactions/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deleteTransaction(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/transactions/${id}`,
  };
  return request(options);
}

export function getPlans(params: QueryParamsType<paramsTypes.FinancePlanParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: '/financePlan',
    params: params,
  };
  return request(options);
}

export function getCurrentPlan(id: string) {
  const options: RequestConfig = {
    method: 'get',
    url: `/financePlan/${id}`,
  };
  return request(options);
}

export function createPlan(data: FinancePlan) {
  const options: RequestConfig = {
    method: 'post',
    url: '/financePlan',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function editPlan(data: FinancePlan) {
  const options: RequestConfig = {
    method: 'put',
    url: `/financePlan/${data.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function clonePlan(data: FinancePlan) {
  const options: RequestConfig = {
    method: 'post',
    url: `/financePlan/${data.id}/clone`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function checkClonePlan(id: string, plannedMonth: string) {
  const options: RequestConfig = {
    method: 'get',
    url: `/financePlan/${id}/clone`,
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      plannedMonth,
    },
  };
  return request(options);
}

export function getDatesOfExistingPlans(params: financeTypes.DatesOfExistingPlansParams) {
  const options: RequestConfig = {
    method: 'get',
    url: '/financePlan/exists',
    params,
  };
  return request(options);
}

export function deletePlan(id: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/financePlan/${id}`,
  };
  return request(options);
}

export function getPlanEmployees(id: string, params: QueryParamsType<paramsTypes.FinancePlanEmployeesParams>) {
  const options: RequestConfig = {
    method: 'get',
    url: `/financePlan/${id}/employees`,
    params: params,
  };
  return request(options);
}

export function getEmployeeHours(id: string, month: string) {
  const options: RequestConfig = {
    method: 'get',
    url: `/financePlan/users/${id}/userPlansLoad`,
    params: {
      month,
    },
  };
  return request(options);
}

export function createPlanEmployee(id: string, data: financeTypes.EmployeeHoursApiType) {
  const options: RequestConfig = {
    method: 'post',
    url: `/financePlan/${id}/employees/loadChanges`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function editPlanEmployee(id: string, data: financeTypes.EmployeeHoursApiType) {
  const options: RequestConfig = {
    method: 'put',
    url: `/financePlan/${id}/employees/${data.userId}/loadChanges`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function editPlanEmployeeFinalize(id: string) {
  const options: RequestConfig = {
    method: 'put',
    url: `/financePlan/${id}/employees/finalize`,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return request(options);
}

export function syncPlanWithJira(id: string) {
  const options: RequestConfig = {
    method: 'put',
    url: `/financePlan/${id}/employees/syncWithJira`,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return request(options);
}

export function deletePlanEmployee(planId: string, employeeId: string) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/financePlan/${planId}/employees/${employeeId}`,
  };
  return request(options);
}

export function getIncomesExpensesReport(params: paramsTypes.IncomesExpensesReportParams) {
  const options: RequestConfig = {
    method: 'get',
    url: '/incomesExpensesReports',
    params: params,
  };
  return request(options);
}

export function importResources(financePlanId: string, month: string, projectGroupId: string) {
  const options: RequestConfig = {
    method: 'post',
    url: `/financePlan/${financePlanId}/import`,
    data: {
      month,
      projectGroupId,
    },
  };
  return request(options);
}

export function checkImportResources(
  id: string,
  params: {
    month: string;
    projectGroupId: string;
  },
) {
  const options: RequestConfig = {
    method: 'post',
    url: `/financePlan/${id}/calculateDifference`,
    params,
  };
  return request(options);
}

export function getPlannedExpense(params: paramsTypes.PlannedExpenseParams) {
  const options: RequestConfig = {
    method: 'get',
    url: '/plannedExpenses/report',
    params: params,
  };
  return request(options);
}

export function editPlannedExpense(data: PlannedExpenseClass) {
  const options: RequestConfig = {
    method: 'put',
    url: '/plannedExpenses',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };
  return request(options);
}

export function deletePlannedExpense(id: PlannedExpenseClass, deleteFutureExpenses: boolean) {
  const options: RequestConfig = {
    method: 'delete',
    url: `/plannedExpenses/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: `${deleteFutureExpenses}`,
  };
  return request(options);
}
