import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as librariesActions from '../../actions/libraries.actions';
import Icon from '../../components/Icon';
import ModalEditSpecializationCompetencies from '../../components/SpecializationsCompetencies/Modals/ModalEditSpecializationCompetencies';
import * as competenciesActions from '../../actions/competencies.actions';
import { CompetenceType } from '../Competencies/Competencies';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { UPDATE_SPECIALIZATION_COMPETENCE } from '../../constants/policies.constants';
import HierarchicalTable from '../../components/HierarchicalTable';
import Dropdown from '../../components/Dropdown';
import { useTableData } from '../../utils/hooks.utils';
import { getTableCell } from '../../utils/table.utils';
import { DepthLevels } from '../../constants/tables.constants';
import PoliciesContext from '../../PoliciesContext';
import { checkPolicies } from '../../utils/policies.utils';
import { getCompetenciesFilter } from '../../actions/filters.actions';
import RefreshButton from '../../components/RefreshButton';

export type SpecializationDataType = {
  name: string;
  id: string;
  competencies: Array<CompetenceType>;
  competenceIds: Array<string>;
};

function SpecializationsCompetencies({
  getSpecializationsList,
  specializationsError,
  isLoading,
  tableData,
  competenciesList,
  editSpecialization,
  getCompetenciesFilters,
  resetErrors,
  resetState,
}: ConnectedProps<typeof connector>) {
  const policies = useContext(PoliciesContext);

  const [modalEditSpecializationIsOpen, setModalEditSpecializationIsOpen] = useState(false);
  const [clickedSpecialization, setClickedSpecialization] = useState<any>({});

  const intl = useIntl();

  useEffect(() => {
    getSpecializationsList();
    getCompetenciesFilters();
    return () => {
      resetState();
    };
  }, []);
  const openEditSpecCompModal = useCallback(() => {
    setModalEditSpecializationIsOpen(true);
  }, []);

  const closeEditSpecCompModal = useCallback(() => {
    setClickedSpecialization({});
    setModalEditSpecializationIsOpen(false);
  }, []);

  const tableRowActions: any = useMemo(
    () =>
      checkPolicies([UPDATE_SPECIALIZATION_COMPETENCE], policies)
        ? [
            {
              label: (
                <>
                  <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
                  <FormattedMessage {...messages.editButton} />
                </>
              ),
              itemClassName: 'dropdown__list-item__button',
              handler: (row: SpecializationDataType) => {
                setClickedSpecialization(row);
                openEditSpecCompModal();
              },
            },
          ]
        : [],
    [policies],
  );

  const tableColumns = useMemo(
    () => [
      {
        Header: intl.formatMessage(messages.nameTitle),
        accessor: 'name',
      },
      {
        id: 'length',
        Header: intl.formatMessage(messages.competenciesTitle),
        Cell: ({ row }: any) => {
          const data = row.original?.competencies;
          return data ? data.length : null;
        },
      },
      ...(tableRowActions.length
        ? [
            {
              Header: '',
              id: 'dropdown',
              Cell: ({ row }: any) =>
                getTableCell(row, [
                  {
                    depth: DepthLevels.FIRST,
                    content: (row: SpecializationDataType, index: number) => (
                      <Dropdown
                        dropdownClass="dropdown--no-bg"
                        dropdownToggle={<Icon iconName="dots" externalClass="dropdown__button-main-icon" />}
                        dropdownList={tableRowActions}
                        dropdownInfo={row}
                        dropdownIndex={index}
                        stopPropagation
                      />
                    ),
                  },
                ]),
            },
          ]
        : []),
    ],
    [tableRowActions.length],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed half-content">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom no-border">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <RefreshButton onRefresh={() => getSpecializationsList()} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content half-content">
        <div className="page__wrapper ">
          <HierarchicalTable
            tableColumns={tableColumns}
            loading={isLoading.getSpecializations}
            error={specializationsError}
            tableData={useTableData(tableData, ['competencies'])}
          />
        </div>
      </div>
      {modalEditSpecializationIsOpen && (
        <ModalEditSpecializationCompetencies
          isOpen
          competencies={competenciesList}
          specializationData={clickedSpecialization}
          specializationError={specializationsError}
          isLoading={isLoading.editSpecializationCompetencies}
          onSubmit={editSpecialization}
          onCloseRequest={closeEditSpecCompModal}
          resetErrors={resetErrors}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ libraries, filters }: RootState) => ({
  tableData: libraries.specializationsTableData?.content,
  specializationsError: libraries.errors.specializationError,
  isLoading: libraries.loading,
  competenciesList: filters.competenciesFilter.competencies,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getSpecializationsList: () => dispatch(librariesActions.getSpecializationsList()),
  editSpecialization: (data: Record<string, unknown>) =>
    dispatch(librariesActions.editSpecializationCompetencies(data)),
  getCompetenciesFilters: () => dispatch(getCompetenciesFilter()),
  resetErrors: () => dispatch(librariesActions.resetErrors()),
  resetState: () => dispatch(competenciesActions.resetState()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(SpecializationsCompetencies);
