import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as resumesActions from '../../../actions/resumes.actions';
import * as filtersActions from '../../../actions/filters.actions';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import Filter from '../../Filter';
import { find, get, isEqual } from 'lodash-es';
import Select from '../../Select';
import Search from '../../Search';
import Button from '../../Button';
import DropdownForm from '../../DropdownForm';
import Icon from '../../Icon';
import { useFormik } from 'formik';
import Input from '../../Input';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';
import { EXPERIENCE_FILTER_SCHEMA } from '../../../enums/resumes.enum';
import { ResumesParams } from '../../../enums/params/resumes.params';
import { CompanyPositionInfoType, DepartmentsInfoType, ProjectInfoType } from '../../../types/libraries';
import { OfficeInfo } from '../../../enums/libraries.enum';
import { UserInfo } from '../../../enums/users.enum';
import {
  resetParamsChange,
  useFiltersListValue,
  useParamsChange,
  useUsersParamsChange,
} from '../../../utils/hooks.utils';
import { FilterParamsName, FilterTypes } from '../../../constants/filters.constants';
import FilterClearButton from '../../FilterClearButton';
import FiltersControl from '../../FiltersControl';
import { SavedFilterParams } from '../../../enums/params/filters.params';
import { SavedFilter } from '../../../enums/filters.enum';
import { convertSavedFieldsToParams, getSavedFilterParams, getSavedFilters } from '../../../utils/filters.utils';
import SortSelect from '../../SortSelect';
import { SortParams } from '../../../enums/params.enum';
import { scrollToError } from '../../../utils';
import RefreshButton from '../../RefreshButton';

function ResumesManagementFilter({
  officesFilter,
  departmentsFilter,
  usersFilter,
  companyPositionList,
  projectList,
  values,
  setResumesParams,
  technicalSkillList,
  setSavedFiltersParams,
  createNewSavedFilter,
  savedFiltersData,
  editSavedFilter,
  deleteSavedFilter,
  resetSavedFilterErrors,
  authUserId,
}: ConnectedProps<typeof connector>) {
  const [isOpenTechnicalSkillsFilter, setIsOpenTechnicalSkillsFilter] = useState(false);
  const [isOpenExperienceFilter, setIsOpenExperienceFilter] = useState(false);
  const [resetCurrentFilter, setResetCurrentFilter] = useState(false);
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    const currentSavedFilter = getSavedFilterParams(getSavedFilters(), FilterTypes.RESUMES_FILTER);

    setResumesParams(
      currentSavedFilter
        ? new ResumesParams(currentSavedFilter)
        : {
            departmentIds: departmentsFilter.value,
            officeIds: officesFilter.value,
            employeeIds: usersFilter.value,
          },
    );
    setSavedFiltersParams({ filterType: FilterTypes.RESUMES_FILTER });
  }, []);

  const handleMultiParamsChange = useParamsChange(setResumesParams, dispatch);

  const handleSearchChange = useCallback((value: string) => {
    setResumesParams({ summary: value });
  }, []);

  const handleUsersParamsChange = useUsersParamsChange(setResumesParams, dispatch);

  const departmentsOptions = useMemo(
    () =>
      departmentsFilter.departments.map(({ id, displayName }: DepartmentsInfoType) => ({
        label: displayName,
        value: id,
      })),
    [departmentsFilter.departments],
  );

  const officesOptions = useMemo(
    () => officesFilter.offices.map(({ id, name }: OfficeInfo) => ({ label: name, value: id })),
    [officesFilter.offices],
  );

  const usersOptions = useMemo(
    () => usersFilter.users.map((user: UserInfo) => ({ label: user.fullName, value: user })),
    [usersFilter.users],
  );

  const companyPositionsOptions = useMemo(
    () =>
      companyPositionList?.map(({ id, displayName }: CompanyPositionInfoType) => ({
        label: displayName,
        value: id,
      })) || [],
    [companyPositionList],
  );

  const projectsOptions = useMemo(
    () => projectList?.map(({ id, name }: ProjectInfoType) => ({ label: name, value: id })) || [],
    [projectList],
  );

  const statusesOptions = useMemo(
    () => [
      {
        value: true,
        label: intl.formatMessage(messages.approvedLabel),
      },
      {
        value: false,
        label: intl.formatMessage(messages.notApprovedLabel),
      },
    ],
    [],
  );

  const departmentsValues = useFiltersListValue(departmentsOptions, values.departmentIds);

  const oficesValues = useFiltersListValue(officesOptions, values.officeIds);

  const usersValues = useFiltersListValue(usersOptions, values.employeeIds);

  const statusesValues = useFiltersListValue(statusesOptions, values.approvedStatus ? values.approvedStatus : []);

  const projectsValues = useFiltersListValue(projectsOptions, values.projectIds);

  const companyPositionsValues = useFiltersListValue(companyPositionsOptions, values.positionIds);

  const skillOptions = (groupId: string) =>
    find(technicalSkillList, el => el.id == groupId)?.skills.map(({ id, skill }: any) => ({ label: skill, value: id }));

  const {
    values: technicalSkillsValues,
    handleSubmit: handleSubmitTechnicalSkills,
    setFieldValue: setTechnicalSkillsFieldValue,
  } = useFormik<{
    skillGroups: {
      skillGroupId: string;
      skillGroupName: string;
      skills: { id: string; skill: string }[];
    }[];
  }>({
    initialValues: { skillGroups: values.technicalSkills || [] },
    validationSchema: '',
    onSubmit: data => {
      setResumesParams({ technicalSkills: data.skillGroups.filter(item => item.skills.length) });
      closeTechnicalSkillsFilter();
    },
  });

  const skillGroupsOptions = useMemo(
    () =>
      technicalSkillList
        ?.filter(
          (item: { id: string }) =>
            !find(technicalSkillsValues.skillGroups, skillGroup => skillGroup.skillGroupId === item.id),
        )
        .map(({ id, name }: any) => ({ label: name, value: id })),
    [technicalSkillList, technicalSkillsValues],
  );

  const onChooseSkillGroupe = (data: any) => {
    setTechnicalSkillsFieldValue('skillGroups', [
      ...technicalSkillsValues.skillGroups,
      {
        skillGroupId: data.value,
        skillGroupName: data.label,
        skills: [],
      },
    ]);
  };

  const removeSkillsGroup = (index: number) => {
    technicalSkillsValues.skillGroups.splice(index, 1);
    setTechnicalSkillsFieldValue('skillGroups', technicalSkillsValues.skillGroups);
  };

  const handleChangeSkills = useCallback(
    (data, index) => {
      setTechnicalSkillsFieldValue(
        `skillGroups[${index}].skills`,
        data.map((item: { value: number; label: string }) => ({ id: item.value, skill: item.label })),
      );
    },
    [technicalSkillsValues],
  );

  const clearTechnicalSkillsFormic = () => {
    setTechnicalSkillsFieldValue('skillGroups', []);
  };

  const {
    values: experienceValues,
    setFieldValue: setExperienceFieldValue,
    handleChange: handleExperienceChange,
    handleSubmit: handleSubmitExperience,
    errors: experienceErrors,
    touched: experienceTouched,
  } = useFormik({
    initialValues: {
      experienceFrom: values.experienceFrom,
      experienceTo: values.experienceTo,
    },
    enableReinitialize: true,
    validate: scrollToError,
    validationSchema: EXPERIENCE_FILTER_SCHEMA,
    onSubmit: data => {
      setResumesParams({ experienceFrom: data.experienceFrom, experienceTo: data.experienceTo });
      closeExperienceFilter();
    },
  });

  const hasExperienceError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(experienceErrors, fieldName) && get(experienceTouched, fieldName));
    },
    [experienceErrors, experienceTouched],
  );

  const clearExperienceFormic = () => {
    setExperienceFieldValue('experienceFrom', null);
    setExperienceFieldValue('experienceTo', null);
  };

  const closeTechnicalSkillsFilter = useCallback(() => {
    setIsOpenTechnicalSkillsFilter(false);
  }, []);

  const openTechnicalSkillsFilter = useCallback(() => {
    setIsOpenTechnicalSkillsFilter(true);
    setTechnicalSkillsFieldValue('skillGroups', [...(values.technicalSkills || [])]);
  }, [values.technicalSkills]);

  const closeExperienceFilter = useCallback(() => {
    setIsOpenExperienceFilter(false);
  }, []);

  const openExperienceFilter = useCallback(() => {
    setIsOpenExperienceFilter(true);
  }, []);

  const handleFiltersControlChange = useCallback(value => {
    setResumesParams(new ResumesParams(convertSavedFieldsToParams(value.fields)));
  }, []);

  const handleClear = useCallback(() => {
    setResumesParams(new ResumesParams());

    resetParamsChange(
      [FilterParamsName.OFFICE_IDS, FilterParamsName.DEPARTMENT_IDS, FilterParamsName.EMPLOYEE_IDS],
      dispatch,
    );
  }, []);

  const handleSort = useCallback((sortBy, direction) => setResumesParams({ sortBy, direction }), []);

  const showClearButton = useMemo(() => !isEqual(values, new ResumesParams()), [values]);

  const onClear = useCallback(() => {
    setResetCurrentFilter(true);
    handleClear();
  }, []);

  const setResettFilterFlag = useCallback(() => {
    setResetCurrentFilter(false);
  }, []);

  return (
    <>
      <div className="page__panel-bottom resumes_filter_block">
        <div className="page__panel-bottom__wrapper--people margin_bottom">
          <div className="page__panel-bottom__wrapper--left">
            <Search
              placeholder="Summary"
              onChange={e => {
                handleSearchChange(e.target.value);
              }}
            />
            <SortSelect
              sortOptions={[
                { label: intl.formatMessage(messages.memberLabel), value: 'employee' },
                { label: intl.formatMessage(messages.positionLabel), value: 'position' },
                { label: intl.formatMessage(messages.experienceLabel), value: 'experience' },
                { label: intl.formatMessage(messages.lastUpdateColumn), value: 'lastUpdate' },
                { label: intl.formatMessage(messages.updaterColumn), value: 'updater' },
                { label: intl.formatMessage(messages.statusLabel), value: 'approvedStatus' },
              ]}
              params={new SortParams('', { sortBy: values.sortBy, direction: values.direction })}
              onSort={handleSort}
            />
            <Filter
              isMulti
              label={intl.formatMessage(messages.officesLabel)}
              options={officesOptions}
              value={oficesValues}
              externalClass="filters__select"
              handleChange={e => {
                handleMultiParamsChange(FilterParamsName.OFFICE_IDS)(e);
              }}
            />
            <Filter
              isMulti
              label={intl.formatMessage(messages.departmentsLabel)}
              options={departmentsOptions}
              value={departmentsValues}
              externalClass="filters__select"
              handleChange={e => {
                handleMultiParamsChange(FilterParamsName.DEPARTMENT_IDS)(e);
              }}
            />
            <Filter
              isMulti
              isUsersFilter
              label={intl.formatMessage(messages.employeesLabel)}
              options={usersOptions}
              value={usersValues}
              externalClass="filters__select"
              handleChange={e => {
                handleUsersParamsChange(FilterParamsName.EMPLOYEE_IDS)(e);
              }}
            />
            <Filter
              isMulti
              label={intl.formatMessage(messages.positionsLabel)}
              options={companyPositionsOptions}
              value={companyPositionsValues}
              externalClass="filters__select"
              handleChange={e => {
                handleMultiParamsChange('positionIds')(e);
              }}
            />
            {/* <div className="page__panel__filters--right"> */}
            {/* TODO add options when filters are implemented */}
            {/* <Select
              label={intl.formatMessage(messages.filtersLabel)}
              options={[]}
              handleChange={() => null}
              externalClass="filters__select"
              iconName="filter"
            /> 
           </div> */}

            <DropdownForm
              placement="bottom"
              side="left"
              dropdownToggle={
                <>
                  <FormattedMessage {...messages.technicalSkillsLabel} />:
                  <Icon iconName="filter-arrow" />
                </>
              }
              dropdownToggleClass="form__btn-add-group"
              isOpen={isOpenTechnicalSkillsFilter}
              onOpenRequest={openTechnicalSkillsFilter}
              dropdownClass={'technical_skills_filter'}
            >
              <form className="form" onSubmit={handleSubmitTechnicalSkills} onClick={e => e.stopPropagation()}>
                {technicalSkillsValues.skillGroups?.map((item, index) => (
                  <div className="form__group-wrapper" key={index}>
                    <div className="form__inputs-wrapper">
                      <Select
                        options={skillOptions(item.skillGroupId)}
                        label={item.skillGroupName}
                        handleChange={value => handleChangeSkills(value, index)}
                        value={technicalSkillsValues.skillGroups[index].skills.map(item => ({
                          label: item.skill,
                          value: item.id,
                        }))}
                        isMulti
                        isSearchable
                      />
                    </div>
                    <button className="form__btn-clean-inputs" type={'button'} onClick={() => removeSkillsGroup(index)}>
                      <Icon iconName={'cross'} />
                    </button>
                  </div>
                ))}
                <div className="form__input-block add_button_block">
                  <Select
                    iconName={'plus'}
                    options={skillGroupsOptions}
                    externalClass={'skill_group_select'}
                    handleChange={onChooseSkillGroupe}
                    value={{ label: intl.formatMessage(messages.skillGroupButton), value: 'new' }}
                    externalMenuClass={'skill_group_select_menu'}
                    upward
                  />
                </div>
                <div className="form__buttons">
                  <Button
                    externalClass={'button--modal'}
                    onClick={clearTechnicalSkillsFormic}
                    type={'button'}
                    color="gray"
                  >
                    <FormattedMessage {...messages.clearButton} />
                  </Button>
                  <Button externalClass={'button--modal'} type={'submit'}>
                    <FormattedMessage {...messages.applyButton} />
                  </Button>
                </div>
              </form>
            </DropdownForm>
            <div
              onClick={() => {
                openExperienceFilter();
                setExperienceFieldValue('experienceFrom', values.experienceFrom);
                setExperienceFieldValue('experienceTo', values.experienceTo);
              }}
            >
              <DropdownForm
                placement="bottom"
                side="left"
                dropdownToggle={
                  <>
                    <FormattedMessage {...messages.experienceLabel} />:
                    <Icon iconName="filter-arrow" />
                  </>
                }
                dropdownToggleClass="form__btn-add-group"
                isOpen={isOpenExperienceFilter}
                onCloseRequest={closeExperienceFilter}
                onOpenRequest={openExperienceFilter}
                dropdownClass={'experience_filter'}
              >
                <form className="form" onSubmit={handleSubmitExperience} onClick={e => e.stopPropagation()}>
                  <div className="form__inputs-subwrapper">
                    <Input
                      name="experienceFrom"
                      label={intl.formatMessage(messages.fromLabel)}
                      type={'number'}
                      defaultValue={experienceValues.experienceFrom || undefined}
                      onChange={handleExperienceChange}
                      hasError={hasExperienceError('experienceFrom')}
                      errorMessage={experienceErrors?.experienceFrom as string}
                    />
                    <Input
                      name="experienceTo"
                      label={intl.formatMessage(messages.toLabel)}
                      type={'number'}
                      defaultValue={experienceValues.experienceTo || undefined}
                      onChange={handleExperienceChange}
                      hasError={hasExperienceError('experienceTo')}
                      errorMessage={experienceErrors?.experienceTo as string}
                    />
                  </div>
                  <div className="form__buttons">
                    <Button
                      externalClass={'button--modal'}
                      onClick={clearExperienceFormic}
                      type={'button'}
                      color="gray"
                    >
                      <FormattedMessage {...messages.clearButton} />
                    </Button>
                    <Button externalClass={'button--modal'} type={'submit'}>
                      <FormattedMessage {...messages.applyButton} />
                    </Button>
                  </div>
                </form>
              </DropdownForm>
            </div>
            <Filter
              isMulti
              label={intl.formatMessage(messages.projectsLabel)}
              options={projectsOptions}
              value={projectsValues}
              externalClass="filters__select"
              handleChange={e => {
                handleMultiParamsChange('projectIds')(e);
              }}
            />
            <Filter
              isMulti
              label={intl.formatMessage(messages.statusesLabel)}
              options={statusesOptions}
              value={statusesValues}
              externalClass="filters__select"
              handleChange={e => {
                handleMultiParamsChange('approvedStatus')(e);
              }}
            />
            {showClearButton && <FilterClearButton onClear={onClear} />}
            <FiltersControl
              handleSaveFilter={createNewSavedFilter}
              handleUpdateFilter={editSavedFilter}
              handleDeleteFilter={deleteSavedFilter}
              savedFiltersData={savedFiltersData}
              authUserId={authUserId}
              filterType={FilterTypes.RESUMES_FILTER}
              handleChange={handleFiltersControlChange}
              params={values}
              resetSavedFilterErrors={resetSavedFilterErrors}
              resetCurrentFilter={resetCurrentFilter}
              setResettFilterFlag={setResettFilterFlag}
            />
            <RefreshButton onRefresh={() => setResumesParams(values)} />
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ resumes, filters, auth }: RootState) => ({
  officesFilter: filters.officesFilter,
  departmentsFilter: filters.departmentsFilter,
  usersFilter: filters.usersFilter,
  companyPositionList: filters.companyPositionsFilter.positions,
  projectList: filters.projectsFilter.projects,
  values: resumes.resumesParams,
  technicalSkillList: filters.technicalSkillsFilter.skills,
  savedFiltersData: filters.savedFilters,
  authUserId: auth.currentUserInfo.id,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setResumesParams: (data: Partial<ResumesParams>) => dispatch(resumesActions.setResumesParams(data)),
  setSavedFiltersParams: (data: Partial<SavedFilterParams>) => dispatch(filtersActions.setSavedFiltersParams(data)),
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) =>
    dispatch(filtersActions.createNewSavedFilter(data)),
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) =>
    dispatch(filtersActions.editSavedFilter(data)),
  deleteSavedFilter: (data: { id: string; callback: () => void }) => dispatch(filtersActions.deleteSavedFilter(data)),
  resetSavedFilterErrors: () => dispatch(filtersActions.resetSavedFilterErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ResumesManagementFilter);
