import React, { memo, useCallback, useMemo, useState } from 'react';
import Filter from '../../Filter';
import { useIntl } from 'react-intl';
import messages from '../messages';
import { OptionTypeBase } from 'react-select';
import { UserInfo } from '../../../enums/users.enum';
import { DepartmentsInfoType } from '../../../types/libraries';
import { OfficeInfo } from '../../../enums/libraries.enum';
import { useFiltersListValue } from '../../../utils/hooks.utils';
import { FilterParamsName, FilterTypes } from '../../../constants/filters.constants';
import { PolicyGroupParams } from '../../../enums/params/policies.params';
import FilterClearButton from '../../FilterClearButton';
import FiltersControl from '../../FiltersControl';
import { SavedFilter, SavedFiltersDataType } from '../../../enums/filters.enum';
import RefreshButton from '../../RefreshButton';

type FiltersType = {
  users: UserInfo[];
  departments: DepartmentsInfoType[];
  offices: OfficeInfo[];
};

type PermissionsFilterProps = {
  filters: FiltersType;
  values: PolicyGroupParams;
  setPolicyGroupParams: () => void;
  handleChangeFilterParams: (name: string) => (data: OptionTypeBase) => void;
  handleUsersParamsChange: (name: string) => (data: OptionTypeBase) => void;
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) => void;
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) => void;
  deleteSavedFilter: (data: { id: string; callback: () => void }) => void;
  savedFiltersData: SavedFiltersDataType;
  authUserId: string;
  handleFiltersControlChange: (value: SavedFilter) => void;
  handleClear: () => void;
  resetSavedFilterErrors: () => void;
  showClearButton: boolean;
};

const PermissionsFilter = ({
  filters,
  values,
  handleChangeFilterParams,
  handleUsersParamsChange,
  setPolicyGroupParams,
  createNewSavedFilter,
  editSavedFilter,
  deleteSavedFilter,
  savedFiltersData,
  authUserId,
  handleFiltersControlChange,
  handleClear,
  resetSavedFilterErrors,
  showClearButton,
}: PermissionsFilterProps) => {
  const intl = useIntl();

  const [resetCurrentFilter, setResetCurrentFilter] = useState(false);

  const departmentsOptions = useMemo(
    () =>
      filters.departments.map((department: DepartmentsInfoType) => ({
        label: department.displayName,
        value: department.id,
      })),
    [filters.departments],
  );

  const officesOptions = useMemo(
    () =>
      filters.offices.map((offices: OfficeInfo) => ({
        label: offices.name,
        value: offices.id,
      })),
    [filters.offices],
  );

  const usersOptions = useMemo(
    () =>
      filters.users.map((user: UserInfo) => ({
        label: user.fullName,
        value: user,
      })),
    [filters.users],
  );

  const departmentValues = useFiltersListValue(departmentsOptions, values.departmentIds);

  const officeValues = useFiltersListValue(officesOptions, values.officeIds);

  const usersValues = useFiltersListValue(usersOptions, values.userIds);

  const onClear = useCallback(() => {
    setResetCurrentFilter(true);
    handleClear();
  }, []);

  const setResettFilterFlag = useCallback(() => {
    setResetCurrentFilter(false);
  }, []);

  return (
    <>
      <Filter
        isMulti
        label={intl.formatMessage(messages.officesLabel)}
        options={officesOptions}
        value={officeValues}
        handleChange={e => handleChangeFilterParams(FilterParamsName.OFFICE_IDS)(e)}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        label={intl.formatMessage(messages.departmentsLabel)}
        options={departmentsOptions}
        value={departmentValues}
        handleChange={e => handleChangeFilterParams(FilterParamsName.DEPARTMENT_IDS)(e)}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        isUsersFilter
        label={intl.formatMessage(messages.employeesLabel)}
        options={usersOptions}
        value={usersValues}
        handleChange={e => handleUsersParamsChange(FilterParamsName.USER_IDS)(e)}
        externalClass="filters__select"
      />
      {showClearButton && <FilterClearButton onClear={onClear} />}
      <FiltersControl
        handleSaveFilter={createNewSavedFilter}
        handleUpdateFilter={editSavedFilter}
        handleDeleteFilter={deleteSavedFilter}
        savedFiltersData={savedFiltersData}
        authUserId={authUserId}
        filterType={FilterTypes.PERMISSIONS_FILTER}
        handleChange={handleFiltersControlChange}
        params={values}
        resetSavedFilterErrors={resetSavedFilterErrors}
        unsavedParams={['active']}
        resetCurrentFilter={resetCurrentFilter}
        setResettFilterFlag={setResettFilterFlag}
      />
      <RefreshButton onRefresh={() => setPolicyGroupParams()} />
    </>
  );
};

export default memo(PermissionsFilter);
