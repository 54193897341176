import React, { useMemo, useState } from 'react';
import { Chart as ChartJS, Tooltip, Legend, LinearScale, Title, CategoryScale, TimeScale } from 'chart.js';
import { Bar } from 'react-chartjs-2';

import Button from '../../Button';
import Icon from '../../Icon';
import { EDashboardTitles } from '../../../pages/Dashboard';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import BlockSettingsModal from '../Modal/BlockSettingsModal';
import { BarElement } from 'chart.js';
import { legendHeight } from '../../Profile/Tabs/WorkTime/utils';
import moment from 'moment';
import { DATE_FORMAT } from '../../../constants/date.constants';

type DashboardProps = {
  title: EDashboardTitles;
  name?: string;
  data: any;
  months: any[];
  viewPolicy: boolean;
  updatePolicy: boolean;
  settingsData: any;
  settingsLoading: boolean;
  settingsError: string | null;
  getTypeList: () => void;
  putSettingsData: (data: any) => void;
  typeLabel: string;
  typesList: any;
  errors: string | null;
  onCloseSettings: () => void;
  range: string;
};

type TBarType = {
  totalIncome: number;
  totalExpense: number;
};

ChartJS.register(BarElement, LinearScale, Title, CategoryScale, TimeScale, Tooltip, Legend, legendHeight);

const DashboardBarChart = ({
  title,
  name,
  data,
  months,
  errors,
  viewPolicy,
  updatePolicy,
  settingsData,
  settingsError,
  settingsLoading,
  typeLabel,
  typesList,
  getTypeList,
  putSettingsData,
  onCloseSettings,
  range,
}: DashboardProps) => {
  const [openSettingsModal, setOpenSettingModal] = useState(false);

  const chartData = useMemo(() => {
    return {
      labels: months.map(date => moment(date).format(DATE_FORMAT.MMM)),
      datasets: [
        {
          label: 'Total Income',
          key: 'totalIncome',
          backgroundColor: '#5AAB18',
        },
        {
          label: 'Total Expenses',
          key: 'totalExpense',
          backgroundColor: '#F0504B',
        },
      ].map(item => ({
        id: item.label,
        label: item.label,
        data: months.map(date => {
          const tempData = data?.find((el: any) => moment(el.reportDate).isSame(date, 'month'));
          if (tempData) {
            const filed = item.key;
            return Number(tempData[filed as keyof TBarType]);
          }

          return 0;
        }),
        backgroundColor: item.backgroundColor,
        borderRadius: 6,
      })),
    };
  }, [data]);

  const openSetting = () => {
    setOpenSettingModal(true);
  };

  const closeSetting = () => {
    onCloseSettings();
    setOpenSettingModal(false);
  };

  const editSetting = (params: any) => {
    putSettingsData(params);
  };

  return (
    <div className="dashboard-table__block">
      <div className="dashboard-table__block-item">
        <h2 className="dashboard-table__block-title">
          {name ?? <FormattedMessage {...messages[title]} />} ({range})
        </h2>
        <div className="dashboard-table__block-item__range-container">
          <span className="dashboard-table__block-item__range-container_content">{range}</span>
          {viewPolicy ? (
            <Button color="gray" externalClass="cash-flow__report-button" onClick={openSetting}>
              <Icon iconName="pencil" externalClass="button__icon" />
            </Button>
          ) : null}
        </div>
      </div>
      {!errors ? (
        <div className="dashboard-chart">
          {data ? (
            <Bar
              data={chartData}
              options={{
                plugins: {
                  legend: {
                    labels: {
                      boxWidth: 12,
                      boxHeight: 12,
                    },
                  },
                },
              }}
            />
          ) : null}
        </div>
      ) : (
        <div className="dashboard-wrapper">
          <caption key={'caption'} className="table__no-data error" />
        </div>
      )}
      {openSettingsModal && (
        <BlockSettingsModal
          isOpen={openSettingsModal}
          getTypeList={getTypeList}
          title={title}
          updatePolicy={updatePolicy}
          typeLabel={typeLabel}
          typesList={typesList}
          onCloseRequest={closeSetting}
          editSetting={editSetting}
          loading={settingsLoading}
          error={settingsError}
          data={settingsData}
        />
      )}
    </div>
  );
};

export default DashboardBarChart;
