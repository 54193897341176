import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as librariesActions from '../../actions/libraries.actions';
import Table from '../../components/Table';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import ModalNewLanguageLevel from '../../components/LanguageLevels/Modals/ModalNewLanguageLevel';
import ModalEditLanguageLevel from '../../components/LanguageLevels/Modals/ModalEditLanguageLevel';
import ModalDeleteLanguageLevel from '../../components/LanguageLevels/Modals/ModalDeleteLanguageLevel';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { checkPolicies } from '../../utils/policies.utils';
import { DELETE_LANGUAGE_LEVEL, UPDATE_LANGUAGE_LEVEL } from '../../constants/policies.constants';
import AccessChecker from '../../components/AccessChecker';
import PoliciesContext from '../../PoliciesContext';
import { LanguageLevelInfoType } from '../../types/libraries';
import RefreshButton from '../../components/RefreshButton';

function LanguageLevels({
  getLanguageLevelsList,
  createNewLanguageLevel,
  editLanguageLevel,
  deleteLanguageLevel,
  languageLevelError,
  isLoading,
  tableData,
  resetErrors,
  resetState,
}: ConnectedProps<typeof connector>) {
  const policies = useContext(PoliciesContext);

  const intl = useIntl();

  const [modalNewLanguageLevelIsOpen, setModalNewLanguageLevelIsOpen] = useState(false);
  const [modalEditLanguageLevelIsOpen, setModalEditLanguageLevelIsOpen] = useState(false);
  const [modalDeleteLanguageLevelIsOpen, setModalDeleteLanguageLevelIsOpen] = useState(false);
  const [languageLevelClicked, setLanguageLevelClicked] = useState<LanguageLevelInfoType>({
    name: '',
    id: '',
  });

  useEffect(() => {
    getLanguageLevelsList();
    return () => {
      resetState();
    };
  }, []);

  const openNewLanguageLevelModal = useCallback(() => {
    setModalNewLanguageLevelIsOpen(true);
  }, []);

  const closeNewLanguageLevelModal = useCallback(() => {
    setModalNewLanguageLevelIsOpen(false);
  }, []);

  const openEditLanguageLevelModal = useCallback(() => {
    setModalEditLanguageLevelIsOpen(true);
  }, []);

  const closeEditLanguageLevelModal = useCallback(() => {
    setModalEditLanguageLevelIsOpen(false);
  }, []);

  const openDeleteLanguageLevelModal = useCallback(() => {
    setModalDeleteLanguageLevelIsOpen(true);
  }, []);

  const closeDeleteLanguageLevelModal = useCallback(() => {
    setModalDeleteLanguageLevelIsOpen(false);
  }, []);

  const setLanguageLevelCallback = useCallback(() => {
    setLanguageLevelClicked({
      name: '',
      id: '',
    });
  }, []);

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.nameColumn),
        modifier: (row: LanguageLevelInfoType) => row.name,
      },
    ],
    [],
  );

  const submitTable = useCallback(
    (data: Record<string, unknown>) => {
      const languageLevel = tableData[data.oldIndex as number];
      languageLevel.priority = data.newIndex;

      editLanguageLevel(languageLevel.id, {
        data: {
          priority: languageLevel.priority,
          name: languageLevel.name,
          id: languageLevel.id,
        },
        callback: () => null,
      });
    },
    [tableData],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: LanguageLevelInfoType) => {
          setLanguageLevelClicked(row);
          openEditLanguageLevelModal();
        },
        verifiablePolicies: [UPDATE_LANGUAGE_LEVEL],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: LanguageLevelInfoType) => {
          setLanguageLevelClicked(row);
          openDeleteLanguageLevelModal();
        },
        verifiablePolicies: [DELETE_LANGUAGE_LEVEL],
      },
    ],
    [],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom no-border">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[UPDATE_LANGUAGE_LEVEL]}>
                  <Button externalClass={'button--with-icon'} onClick={openNewLanguageLevelModal}>
                    <Icon iconName={'plus'} externalClass={'button__icon'} />
                    <span className="button__text">
                      <FormattedMessage {...messages.newButton} />
                    </span>
                  </Button>
                </AccessChecker>
                <RefreshButton onRefresh={() => getLanguageLevelsList()} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <Table
            externalClass={'table table--half'}
            tableColumns={tableColumns}
            tableData={tableData || []}
            loading={isLoading.getLanguageLevel}
            error={languageLevelError}
            tableActions={tableActions}
            onDragSort={submitTable}
            sortable={checkPolicies([UPDATE_LANGUAGE_LEVEL], policies)}
          />
        </div>
      </div>
      {modalNewLanguageLevelIsOpen && (
        <ModalNewLanguageLevel
          isOpen
          onCloseRequest={closeNewLanguageLevelModal}
          createNewLanguageLevel={createNewLanguageLevel}
          languageLevelError={languageLevelError}
          isLoading={isLoading.createLanguageLevel}
          resetErrors={resetErrors}
        />
      )}
      {modalEditLanguageLevelIsOpen && (
        <ModalEditLanguageLevel
          isOpen
          onCloseRequest={closeEditLanguageLevelModal}
          editLanguageLevel={editLanguageLevel}
          languageLevelData={languageLevelClicked}
          languageLevelError={languageLevelError}
          isLoading={isLoading.editLanguageLevel}
          resetErrors={resetErrors}
        />
      )}
      {modalDeleteLanguageLevelIsOpen && (
        <ModalDeleteLanguageLevel
          isOpen
          onCloseRequest={closeDeleteLanguageLevelModal}
          onDeleteRequest={(data: Record<string, unknown>) => {
            deleteLanguageLevel({
              ...data,
              setLanguageLevelCallback,
            });
          }}
          isLoading={isLoading.deleteLanguageLevel}
          languageLevelError={languageLevelError}
          languageLevelData={languageLevelClicked}
          resetErrors={resetErrors}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ libraries }: RootState) => ({
  tableData: libraries.languageLevelsTableData?.content,
  languageLevelError: libraries.errors.languageLevelError,
  isLoading: libraries.loading,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getLanguageLevelsList: () => dispatch(librariesActions.getLanguageLevelsList()),
  deleteLanguageLevel: (data: Record<string, unknown>) => dispatch(librariesActions.deleteLanguageLevel(data)),
  createNewLanguageLevel: (data: Record<string, unknown>) => dispatch(librariesActions.createNewLanguageLevel(data)),
  editLanguageLevel: (id: string, data: Record<string, unknown>) =>
    dispatch(librariesActions.editLanguageLevel({ ...data, id })),
  resetErrors: () => dispatch(librariesActions.resetErrors()),
  resetState: () => dispatch(librariesActions.resetState()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(LanguageLevels);
