import React, { useCallback, useEffect, useMemo } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import * as reportsActions from '../../actions/reports.actions';
import HierarchicalTable from '../../components/HierarchicalTable';
import { FormattedMessage, useIntl } from 'react-intl';
import { UserPreviewInfo } from '../../enums/users.enum';
import * as filtersActions from '../../actions/filters.actions';
import { AbsencePeriodsParams } from '../../enums/params/reports.params';
import InlineDatePicker from '../../components/InlineDatePicker';
import moment from 'moment';
import messages from './messages';
import AbsencePeriodsFilter from '../../components/AbsencePeriods/Filter/AbsencePeriodsFilter';
import { resetParamsChange, useParamsChange, useTableData, useUsersParamsChange } from '../../utils/hooks.utils';
import { useDataForTable } from './useDataForTable';
import { handleReportUsersStatuses } from '../../utils/reports.utils';
import { ReportsUserStatusesType } from '../../types/reports';
import DurationPicker from '../../components/Dropdown';
import Icon from '../../components/Icon';
import { exportAbsencePeriodsReport } from '../../actions/export.actions';
import { SavedFilterParams } from '../../enums/params/filters.params';
import { SavedFilter } from '../../enums/filters.enum';
import {
  checkParamsMatch,
  convertSavedFieldsToParams,
  getSavedFilterParams,
  getSavedFilters,
} from '../../utils/filters.utils';
import { FilterParamsName, FilterTypes } from '../../constants/filters.constants';
import { absencePeriodsUnsavedParams } from '../../constants/reports.constants';

function AbsencePeriods({
  tableData,
  isLoading,
  errors,
  setAbsencePeriodsParams,
  exportAbsencePeriodsReport,
  getUsersFilter,
  getOfficesFilter,
  getDepartmentsFilter,
  usersFilter,
  officesFilter,
  departmentsFilter,
  eventTypesFilter,
  params,
  reportsUsersStatuses,
  getEventTypesFilter,
  setReportsUsersStatuses,
  setSavedFiltersParams,
  createNewSavedFilter,
  savedFiltersData,
  editSavedFilter,
  deleteSavedFilter,
  resetSavedFilterErrors,
  authUserId,
}: ConnectedProps<typeof connector>) {
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    const currentSavedFilter = getSavedFilterParams(getSavedFilters(), FilterTypes.ABSENCE_PERIODS_REPORT_FILTER);

    setAbsencePeriodsParams(
      currentSavedFilter
        ? new AbsencePeriodsParams(currentSavedFilter)
        : {
            offices: officesFilter.value,
            departments: departmentsFilter.value,
            eventTypes: eventTypesFilter.value,
            targetEmployees: usersFilter.value,
            isAllowActive: reportsUsersStatuses.isAllowActive,
            isAllowPassive: reportsUsersStatuses.isAllowPassive,
          },
    );
    getUsersFilter();
    getOfficesFilter();
    getDepartmentsFilter();
    getEventTypesFilter();
    setSavedFiltersParams({ filterType: FilterTypes.ABSENCE_PERIODS_REPORT_FILTER });
  }, []);

  const tableDataWithTotal = useMemo(() => {
    if (tableData?.userList.length) {
      const newData = [
        ...tableData?.userList.sort((a: any, b: any) => a.user.fullName?.localeCompare(b.user.fullName)),
      ];
      newData?.push({
        allDays: tableData.allDays,
        calendarDays: tableData.calendarDays,
        workDays: tableData.workDays,
        user: new UserPreviewInfo(),
        totalItem: true,
      });

      return newData;
    }
    return tableData?.userList;
  }, [tableData]);

  const handleMultiParamsChange = useParamsChange(setAbsencePeriodsParams, dispatch);

  const handleUsersParamsChange = useUsersParamsChange(setAbsencePeriodsParams, dispatch);

  const onDateChange = (start: string, end: string) => {
    setAbsencePeriodsParams({
      dateTimeFrom: moment(start).format(),
      dateTimeTo: moment(end).format(),
    });
  };

  const { tableColumns } = useDataForTable();

  const filters = useMemo(
    () => ({
      departments: departmentsFilter.departments,
      eventTypes: eventTypesFilter.eventTypes,
      offices: officesFilter.offices,
      users: usersFilter.users,
    }),
    [departmentsFilter.departments, eventTypesFilter.eventTypes, officesFilter.offices, usersFilter.users],
  );

  const handleUsersStatuses = useCallback(
    data =>
      handleReportUsersStatuses((reportsUsersStatuses: ReportsUserStatusesType) => {
        setAbsencePeriodsParams(reportsUsersStatuses);
        setReportsUsersStatuses(reportsUsersStatuses);
      }, data),
    [],
  );

  const handleFiltersControlChange = useCallback(
    value => {
      setAbsencePeriodsParams(
        new AbsencePeriodsParams({
          ...convertSavedFieldsToParams(value.fields),
          dateTimeFrom: params.dateTimeFrom,
          dateTimeTo: params.dateTimeTo,
        }),
      );
    },
    [params],
  );

  const handleClear = useCallback(() => {
    setAbsencePeriodsParams(
      new AbsencePeriodsParams({ dateTimeFrom: params.dateTimeFrom, dateTimeTo: params.dateTimeTo }),
    );

    resetParamsChange(
      [
        FilterParamsName.OFFICES,
        FilterParamsName.DEPARTMENTS,
        FilterParamsName.TARGET_EMPLOYEES,
        FilterParamsName.EVENT_TYPES,
      ],
      dispatch,
    );
  }, [params]);

  const showClearButton = useMemo(
    () => !checkParamsMatch(params, new AbsencePeriodsParams(), absencePeriodsUnsavedParams),
    [params],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <div className="page__panel-top__wrapper--left">
              <h1 className="page__title">
                <FormattedMessage {...messages.pageTitle} />
              </h1>
            </div>
            <div className="page__panel-top__control">
              <InlineDatePicker
                onDateChange={onDateChange}
                defaultPeriodStart={params.dateTimeFrom}
                defaultPeriodEnd={params.dateTimeTo}
              />
              <DurationPicker
                dropdownToggle={<Icon iconName="dots" externalClass="dropdown__button-main-icon" />}
                dropdownList={[
                  {
                    label: intl.formatMessage(messages.exportToXLSLabel),
                    handler: exportAbsencePeriodsReport,
                  },
                ]}
              />
            </div>
          </div>
          <div className="page__panel-bottom">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AbsencePeriodsFilter
                  filters={filters}
                  values={params}
                  handleEventMultiParamsChange={handleMultiParamsChange}
                  handleUsersStatuses={handleUsersStatuses}
                  handleUsersParamsChange={handleUsersParamsChange}
                  createNewSavedFilter={createNewSavedFilter}
                  savedFiltersData={savedFiltersData}
                  authUserId={authUserId}
                  deleteSavedFilter={deleteSavedFilter}
                  editSavedFilter={editSavedFilter}
                  handleFiltersControlChange={handleFiltersControlChange}
                  handleClear={handleClear}
                  setAbsencePeriodsParams={setAbsencePeriodsParams}
                  resetSavedFilterErrors={resetSavedFilterErrors}
                  showClearButton={showClearButton}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <HierarchicalTable
            tableData={useTableData(tableDataWithTotal, ['dates'])}
            externalClass="fixed absence-periods-table"
            tableColumns={tableColumns}
            loading={isLoading}
            error={errors}
          />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ filters, reports, auth }: RootState) => ({
  errors: reports.errors.absencePeriodsErrors,
  isLoading: reports.loading.absencePeriods,
  eventTypesFilter: filters.eventTypesFilter,
  officesFilter: filters.officesFilter,
  departmentsFilter: filters.departmentsFilter,
  usersFilter: filters.usersFilter,
  params: reports.absencePeriodsParams,
  tableData: reports.absencePeriodsData,
  reportsUsersStatuses: reports.reportsUserStatuses,
  savedFiltersData: filters.savedFilters,
  authUserId: auth.currentUserInfo.id,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getUsersFilter: () => dispatch(filtersActions.getUsersFilter()),
  getOfficesFilter: () => dispatch(filtersActions.getOfficesFilter()),
  getDepartmentsFilter: () => dispatch(filtersActions.getDepartmentsFilter()),
  setAbsencePeriodsParams: (data: Partial<AbsencePeriodsParams>) =>
    dispatch(reportsActions.setAbsencePeriodsParams(data)),
  getEventTypesFilter: () => dispatch(filtersActions.getEventTypesFilter()),
  setReportsUsersStatuses: (data: ReportsUserStatusesType) => dispatch(reportsActions.setReportsUsersStatuses(data)),
  exportAbsencePeriodsReport: () => dispatch(exportAbsencePeriodsReport()),
  setSavedFiltersParams: (data: Partial<SavedFilterParams>) => dispatch(filtersActions.setSavedFiltersParams(data)),
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) =>
    dispatch(filtersActions.createNewSavedFilter(data)),
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) =>
    dispatch(filtersActions.editSavedFilter(data)),
  deleteSavedFilter: (data: { id: string; callback: () => void }) => dispatch(filtersActions.deleteSavedFilter(data)),
  resetSavedFilterErrors: () => dispatch(filtersActions.resetSavedFilterErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(AbsencePeriods);
