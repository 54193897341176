import React, { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import DurationPicker from '../../components/Dropdown';
import HierarchicalTable from '../../components/HierarchicalTable';
import Icon from '../../components/Icon';
import InlineDatePicker from '../../components/InlineDatePicker';
import * as reportsActions from '../../actions/reports.actions';
import * as filtersActions from '../../actions/filters.actions';
import messages from './messages';
import { ProjectsReportParams } from '../../enums/params/reports.params';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { resetParamsChange, useParamsChange, useTableData, useUsersParamsChange } from '../../utils/hooks.utils';
import { useDataForTable } from './useDataForTable';
import ProjectsReportFilter from '../../components/ProjectsReport/ProjectsReportFilter';
import { ProjectsGroupBy, projectsReportUnsavedParams, ReportsRoundingMode } from '../../constants/reports.constants';
import { getProjectsReportData, handleReportUsersStatuses } from '../../utils/reports.utils';
import { getJiraProjectsFilter } from '../../actions/filters.actions';
import { ReportsUserStatusesType } from '../../types/reports';
import { exportProjectsReport } from '../../actions/export.actions';
import { SavedFilter } from '../../enums/filters.enum';
import { SavedFilterParams } from '../../enums/params/filters.params';
import { FilterParamsName, FilterTypes } from '../../constants/filters.constants';
import {
  checkParamsMatch,
  convertSavedFieldsToParams,
  getSavedFilterParams,
  getSavedFilters,
} from '../../utils/filters.utils';

function ProjectsReport({
  projectsReportData,
  jiraProjectFilter,
  params,
  usersFilter,
  isLoading,
  projectsReportErrors,
  reportsUsersStatuses,
  roundingMode,
  isFoldSubtasks,
  exportProjectsReport,
  getUsersFilter,
  getJiraProjectsFilter,
  setProjectsReportParams,
  setReportsUsersStatuses,
  setRoundingMode,
  setFoldSubtask,
  setSavedFiltersParams,
  createNewSavedFilter,
  savedFiltersData,
  editSavedFilter,
  deleteSavedFilter,
  resetSavedFilterErrors,
  authUserId,
}: ConnectedProps<typeof connector>) {
  const intl = useIntl();

  const dispatch = useDispatch();
  const handleDataChange = useCallback((start: string, end: string) => {
    setProjectsReportParams({ dateFrom: start, dateTo: end });
  }, []);

  const { tableColumns } = useDataForTable(
    params.isWorkLogsRounded,
    params.isFoldSubtasks,
    params.isGroupByProject,
    params.isGroupByComponents,
    params.isGroupByEmployee,
    projectsReportData.usersPreviews,
  );

  const handleUsersParamsChange = useUsersParamsChange(setProjectsReportParams, dispatch);

  const handleMultiParamsChange = useParamsChange(setProjectsReportParams);

  const handleRoundingModeChange = useCallback(
    name => (data: any) => {
      const value = data.value;
      setProjectsReportParams({ [name]: value, isWorkLogsRounded: !!value });
      setRoundingMode(value);
    },
    [],
  );

  const handleFoldSubTasks = useCallback(() => {
    const isFold = !params.isFoldSubtasks;
    setProjectsReportParams({ isFoldSubtasks: isFold }, false);
    setFoldSubtask(isFold);
  }, [params.isFoldSubtasks]);

  const handleUsersStatuses = useCallback(
    data =>
      handleReportUsersStatuses(
        (reportsUsersStatuses: ReportsUserStatusesType) => {
          setProjectsReportParams(reportsUsersStatuses);
          setReportsUsersStatuses(reportsUsersStatuses);
        },
        data,
        true,
      ),
    [],
  );

  const tableData = useMemo(
    () => getProjectsReportData(projectsReportData, params.isGroupByComponents, params.isGroupByEmployee),
    [projectsReportData, params.isGroupByComponents, params.isGroupByEmployee],
  );

  const handleGroupByChange = useCallback((values: { label: string; value: ProjectsGroupBy }[]) => {
    setProjectsReportParams({
      isGroupByComponents: values.some(el => el.value === ProjectsGroupBy.GROUP_BY_COMPONENTS),
      isGroupByEmployee: values.some(el => el.value === ProjectsGroupBy.GROUP_BY_MEMBERS),
    });
  }, []);

  const filters = useMemo(
    () => ({
      users: usersFilter.users,
      jiraProjectsList: jiraProjectFilter.jiraProject,
    }),
    [usersFilter.users, jiraProjectFilter.jiraProject],
  );

  useEffect(() => {
    const isAllowAlien = reportsUsersStatuses.isAllowAlien;
    const isAllowActive = reportsUsersStatuses.isAllowActive;
    const isAllowPassive = reportsUsersStatuses.isAllowPassive;

    const currentSavedFilter = getSavedFilterParams(getSavedFilters(), FilterTypes.PROJECTS_REPORT_FILTER);

    setProjectsReportParams(
      currentSavedFilter
        ? new ProjectsReportParams(currentSavedFilter)
        : {
            portalUserIds: usersFilter.value,
            isAllowActive: isAllowAlien ? !!isAllowActive : isAllowActive,
            isAllowPassive: isAllowAlien ? !!isAllowPassive : isAllowPassive,
            isAllowAlien,
            roundingMode,
            isWorkLogsRounded: !!roundingMode,
            isFoldSubtasks,
            jiraProjectIds: jiraProjectFilter.value,
          },
    );

    getUsersFilter();
    getJiraProjectsFilter();
    setSavedFiltersParams({ filterType: FilterTypes.PROJECTS_REPORT_FILTER });
  }, []);

  const handleFiltersControlChange = useCallback(
    value => {
      setProjectsReportParams(
        new ProjectsReportParams({
          ...convertSavedFieldsToParams(value.fields),
          dateFrom: params.dateFrom,
          dateTo: params.dateTo,
        }),
      );
    },
    [params],
  );

  const handleClear = useCallback(() => {
    setProjectsReportParams(new ProjectsReportParams({ dateFrom: params.dateFrom, dateTo: params.dateTo }));
    resetParamsChange(
      [FilterParamsName.PORTAL_USER_IDS, FilterParamsName.JIRA_PROJECT_IDS, FilterParamsName.USER_IDS],
      dispatch,
    );
  }, [params]);

  const showClearButton = useMemo(
    () => !checkParamsMatch(params, new ProjectsReportParams(), projectsReportUnsavedParams),
    [params],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed employees-report-page">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
            <div className="page__panel-top__control">
              <div className="pagination page__panel-top__control__pagination">
                <InlineDatePicker
                  onDateChange={handleDataChange}
                  defaultPeriodStart={params.dateFrom}
                  defaultPeriodEnd={params.dateTo}
                />
                <DurationPicker
                  dropdownToggle={<Icon iconName="dots" externalClass="dropdown__button-main-icon" />}
                  dropdownList={[
                    {
                      label: intl.formatMessage(messages.exportToXLSLabel),
                      handler: exportProjectsReport,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="page__panel-bottom">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <ProjectsReportFilter
                  filters={filters}
                  values={params}
                  handleUsersParamsChange={handleUsersParamsChange}
                  handleGroupByChange={handleGroupByChange}
                  handleRoundingModeChange={handleRoundingModeChange}
                  handleFoldSubTasks={handleFoldSubTasks}
                  handleUsersStatuses={handleUsersStatuses}
                  handleMultiParamsChange={handleMultiParamsChange}
                  createNewSavedFilter={createNewSavedFilter}
                  savedFiltersData={savedFiltersData}
                  authUserId={authUserId}
                  deleteSavedFilter={deleteSavedFilter}
                  editSavedFilter={editSavedFilter}
                  handleFiltersControlChange={handleFiltersControlChange}
                  handleClear={handleClear}
                  setProjectsReportParams={setProjectsReportParams}
                  resetSavedFilterErrors={resetSavedFilterErrors}
                  showClearButton={showClearButton}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <HierarchicalTable
            tableData={useTableData(tableData, [
              'issues',
              ...(params.isGroupByComponents ? ['components'] : []),
              ...(params.isGroupByEmployee ? ['users'] : []),
              ...(!params.isFoldSubtasks ? ['childIssues'] : []),
            ])}
            tableColumns={tableColumns}
            loading={isLoading}
            error={projectsReportErrors}
            tableHeaderClassName="user_hours_header"
          />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ filters, reports, auth }: RootState) => ({
  projectsReportData: reports.projectsReportData,
  jiraProjectFilter: filters.jiraProjectFilter,
  params: reports.projectsReportParams,
  usersFilter: filters.usersFilter,
  isLoading: reports.loading.projectsReport,
  projectsReportErrors: reports.errors.projectsReportErrors,
  reportsUsersStatuses: reports.reportsUserStatuses,
  roundingMode: reports.roundingMode,
  isFoldSubtasks: reports.isFoldSubtasks,
  savedFiltersData: filters.savedFilters,
  authUserId: auth.currentUserInfo.id,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getUsersFilter: () => dispatch(filtersActions.getUsersFilter()),
  getJiraProjectsFilter: () => dispatch(getJiraProjectsFilter()),
  exportProjectsReport: () => dispatch(exportProjectsReport()),
  setProjectsReportParams: (data: Partial<ProjectsReportParams>, isGetList?: boolean) =>
    dispatch(reportsActions.setProjectsReportParams(data, isGetList)),
  setReportsUsersStatuses: (data: ReportsUserStatusesType) => dispatch(reportsActions.setReportsUsersStatuses(data)),
  setRoundingMode: (data: ReportsRoundingMode | null) => dispatch(reportsActions.setRoundingMode(data)),
  setFoldSubtask: (data: boolean) => dispatch(reportsActions.setFoldSubtask(data)),
  setSavedFiltersParams: (data: Partial<SavedFilterParams>) => dispatch(filtersActions.setSavedFiltersParams(data)),
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) =>
    dispatch(filtersActions.createNewSavedFilter(data)),
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) =>
    dispatch(filtersActions.editSavedFilter(data)),
  deleteSavedFilter: (data: { id: string; callback: () => void }) => dispatch(filtersActions.deleteSavedFilter(data)),
  resetSavedFilterErrors: () => dispatch(filtersActions.resetSavedFilterErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ProjectsReport);
