import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import Icon from '../../../Icon';
import Input from '../../../Input';
import DashboardTypes from './DashboardTypes';
import messages from '../../messages';
import classNames from 'classnames';
import { DashboardTopType } from '../../../../types/finance';

type ModalDraggableSettingsProps = {
  item: DashboardTopType;
  dragHandleProps: any;
  data: DashboardTopType[];
  opened: string[];
  errors: any;
  hasError: (form: string | (string | number)[]) => boolean;
  setOpened: (data: string[]) => void;
  changeName: (item: string, type: DashboardTopType) => void;
  changeType: (item: DashboardTopType, data: DashboardTopType, opened: string[]) => void;
  changeSubType: (item: DashboardTopType, data: DashboardTopType, sub: string, opened: string[]) => void;
  types: any[];
  removeType: (index: number) => void;
};

export const Item = ({
  item,
  dragHandleProps,
  opened,
  types,
  data,
  hasError,
  errors,
  setOpened,
  changeType,
  changeSubType,
  changeName,
  removeType,
}: ModalDraggableSettingsProps) => {
  const intl = useIntl();

  const index = data.findIndex((it: DashboardTopType) => it.position === item.position);

  const [input, setInput] = useState(item.name);

  const { onMouseDown, onTouchStart } = dragHandleProps;

  return (
    <>
      <div
        className={classNames('form__group-wrapper--with-gap dashboard-container', {
          opened: opened.includes(item.position),
        })}
        key={index}
      >
        {data.length > 1 ? (
          <button
            className="dashboard-modal-draggable__button"
            type={'button'}
            onTouchStart={e => {
              e.preventDefault();
              onTouchStart(e);
            }}
            onMouseDown={e => {
              setOpened([]);
              onMouseDown(e);
            }}
          >
            <Icon iconName={'drag-indicator'} externalClass={'dashboard-drag'} />
          </button>
        ) : null}
        <div
          className="cursor-pointer"
          onClick={() => {
            setOpened(
              opened.includes(item.position) ? opened.filter(it => item.position !== it) : [...opened, item.position],
            );
          }}
        >
          <Icon
            iconName={opened.includes(item.position) ? 'chevron-down' : 'chevron-right'}
            externalClass="dashboard-placeholder"
          />
        </div>
        <Input
          id={item.position}
          name="group"
          label={intl.formatMessage(messages.group)}
          onChange={e => setInput(e.target.value)}
          onBlur={() => changeName(input, item)}
          hasError={hasError(`types[${index}].name`)}
          errorMessage={errors?.types ? errors.types[index]?.name : undefined}
          defaultValue={input}
        />
        <button className="form__btn-clean-inputs" type={'button'} onClick={() => removeType(index)}>
          <Icon iconName={'trash'} externalClass={'form__btn-clean'} />
        </button>
      </div>
      {opened.includes(item.position) ? (
        <DashboardTypes
          isSub={'subExpenseTypes' in types[0]}
          types={types}
          checked={item.subTypes || []}
          opened={item.opened}
          changeType={(type, opened) => changeType(item, type, opened)}
          changeSubType={(data, sub, opened) => changeSubType(item, data, sub, opened)}
        />
      ) : null}
    </>
  );
};
