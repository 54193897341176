import React, { useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import moment, { Moment } from 'moment';
import Button from '../Button';
import Icon from '../Icon';
import { dateFormatEnd, dateFormatStart, InlineDatePickerPeriods } from './constants';
import CustomDateRangePicker from '../CustomDateRangePicker';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { DATE_FORMAT } from '../../constants/date.constants';
import Filter from '../Filter';

type InlineDatePickerProps = {
  pickerRef?: any;
  onDateChange: (start: string, end: string, periodFormat: PeriodSelectOptionsType) => void;
  defaultPeriod?: InlineDatePickerPeriods;
  isCustomDatePicker?: boolean;
  defaultPeriodStart?: string;
  isSingleDatePicker?: boolean;
  defaultPeriodEnd?: string;
  hidePeriodSelector?: boolean;
  hideTodayButton?: boolean;
  todayBtn?: boolean;
  weekBtn?: boolean;
  monthBtn?: boolean;
  previousMonth?: boolean;
  thisYear?: boolean;
};

export type PeriodSelectOptionsType = {
  value: InlineDatePickerPeriods;
  label: string;
};

function InlineDatePicker({
  pickerRef,
  onDateChange,
  defaultPeriod,
  defaultPeriodStart = moment().startOf('month').format(dateFormatStart),
  defaultPeriodEnd = moment().endOf('month').format(dateFormatStart),
  isCustomDatePicker = true,
  isSingleDatePicker = false,
  hidePeriodSelector,
  hideTodayButton,
  todayBtn = true,
  weekBtn = true,
  monthBtn = true,
  previousMonth = false,
  thisYear = false,
}: InlineDatePickerProps) {
  const intl = useIntl();

  const periodSelectOptions: PeriodSelectOptionsType[] = [
    { value: InlineDatePickerPeriods.YEAR_PERIOD, label: intl.formatMessage(messages.yearLabel) },
    { value: InlineDatePickerPeriods.MONTH_PERIOD, label: intl.formatMessage(messages.monthLabel) },
    { value: InlineDatePickerPeriods.WEEK_PERIOD, label: intl.formatMessage(messages.weekLabel) },
    { value: InlineDatePickerPeriods.DAY_PERIOD, label: intl.formatMessage(messages.dayLabel) },
  ];

  const [periodFormat, setPeriodFormat] = useState(
    defaultPeriod ? { value: defaultPeriod, label: defaultPeriod } : periodSelectOptions[1],
  );

  const [timePeriodStart, setTimePeriodStart] = useState(moment(defaultPeriodStart).format(dateFormatStart));
  const [timePeriodEnd, setTimePeriodEnd] = useState(moment(defaultPeriodEnd).format(dateFormatEnd));

  const periodSelectValue = useMemo(() => periodSelectOptions.find(el => el.value === periodFormat.value), [
    periodFormat,
  ]);

  const handleChangeDate = useCallback(
    (start: string, end: string, format?: PeriodSelectOptionsType) => {
      const dateStart = moment(start).format(dateFormatStart);
      const dateEnd = moment(end).format(dateFormatEnd);
      unstable_batchedUpdates(() => {
        setTimePeriodStart(dateStart);
        setTimePeriodEnd(dateEnd);
      });

      onDateChange(dateStart, dateEnd, format ? format : periodFormat);
    },
    [dateFormatEnd, dateFormatStart, periodFormat, onDateChange],
  );

  useImperativeHandle(pickerRef, () => ({
    handleChangeDate,
    setPeriodFormat,
  }));

  const onSelectChange = (arg: any) => {
    if (arg?.value === InlineDatePickerPeriods.DAY_PERIOD) {
      const start = moment().format(dateFormatStart);
      const end = moment().format(dateFormatEnd);
      setTimePeriodStart(start);
      setTimePeriodEnd(end);

      onDateChange(start, end, arg);
    } else {
      const start = moment().startOf(arg.value).format(dateFormatStart);
      const end = moment().endOf(arg.value).format(dateFormatEnd);
      setTimePeriodStart(start);
      setTimePeriodEnd(end);

      onDateChange(start, end, arg);
    }
    setPeriodFormat(arg);
  };

  const getPeriodDuration = (periodStart: Moment, periodEnd: Moment) =>
    Math.round(moment.duration(periodEnd.diff(periodStart)).as('days'));

  const getIsOneMonthPeriod = (periodStart: Moment, periodEnd: Moment) =>
    periodStart.clone().startOf('month').isSame(periodStart, 'day') &&
    periodStart.endOf('month').isSame(periodEnd, 'day');

  const setNextPoint = (point: InlineDatePickerPeriods) => {
    const periodStart = moment(timePeriodStart);
    const periodEnd = moment(timePeriodEnd);
    const isOneMonthPeriod = getIsOneMonthPeriod(periodStart.clone(), periodEnd.clone());
    const format = isOneMonthPeriod ? 'month' : point === InlineDatePickerPeriods.WEEK_PERIOD ? 'week' : point;

    const periodDuration = getPeriodDuration(periodStart, periodEnd);

    const currentStartPeriod = isCustomDatePicker
      ? periodStart.clone().add({ days: periodDuration }).format(dateFormatStart)
      : periodStart.add(1, format).format(dateFormatStart);
    const currentEndPeriod = isCustomDatePicker
      ? isOneMonthPeriod
        ? periodStart.add(1, format).endOf(format).format(dateFormatEnd)
        : periodEnd.add({ days: periodDuration }).format(dateFormatEnd)
      : periodEnd.add(1, format).format(dateFormatEnd);

    setTimePeriodStart(currentStartPeriod);
    setTimePeriodEnd(currentEndPeriod);

    onDateChange(currentStartPeriod, currentEndPeriod, periodFormat);
  };

  const setPrevPoint = (point: InlineDatePickerPeriods) => {
    const periodStart = moment(timePeriodStart);
    const periodEnd = moment(timePeriodEnd);
    const isOneMonthPeriod = getIsOneMonthPeriod(periodStart.clone(), periodEnd.clone());
    const format = isOneMonthPeriod ? 'month' : point === InlineDatePickerPeriods.WEEK_PERIOD ? 'week' : point;

    const periodDuration = getPeriodDuration(periodStart, periodEnd);

    const currentStartPeriod = isCustomDatePicker
      ? isOneMonthPeriod
        ? periodStart.clone().subtract(1, format).format(dateFormatStart)
        : periodStart.clone().subtract({ days: periodDuration }).format(dateFormatStart)
      : periodStart.subtract(1, format).format(dateFormatStart);
    const currentEndPeriod = isCustomDatePicker
      ? isOneMonthPeriod
        ? periodStart.subtract(1, format).endOf(format).format(dateFormatEnd)
        : periodEnd.subtract({ days: periodDuration }).format(dateFormatEnd)
      : periodEnd.subtract(1, format).format(dateFormatEnd);

    setTimePeriodStart(currentStartPeriod);
    setTimePeriodEnd(currentEndPeriod);

    onDateChange(currentStartPeriod, currentEndPeriod, periodFormat);
  };

  const onToday = (point: any) => {
    const startPeriod = moment().startOf(point).format(dateFormatStart);
    const endPeriod = moment().endOf(point).format(dateFormatEnd);
    setTimePeriodStart(startPeriod);
    setTimePeriodEnd(endPeriod);

    onDateChange(startPeriod, endPeriod, periodFormat);
  };

  const isDisabledTodayBtn =
    periodFormat?.value === InlineDatePickerPeriods.YEAR_PERIOD
      ? timePeriodStart === moment().startOf(InlineDatePickerPeriods.YEAR_PERIOD).format(dateFormatStart)
      : periodFormat?.value === InlineDatePickerPeriods.MONTH_PERIOD
      ? timePeriodStart === moment().startOf(InlineDatePickerPeriods.MONTH_PERIOD).format(dateFormatStart)
      : periodFormat?.value === InlineDatePickerPeriods.WEEK_PERIOD
      ? timePeriodStart === moment().startOf(InlineDatePickerPeriods.WEEK_PERIOD).format(dateFormatStart)
      : timePeriodStart === moment().format(dateFormatStart);

  const currentPeriodValue =
    periodFormat?.value === InlineDatePickerPeriods.YEAR_PERIOD
      ? moment(timePeriodStart).format(DATE_FORMAT.YYYY)
      : periodFormat?.value === InlineDatePickerPeriods.MONTH_PERIOD
      ? moment(timePeriodStart).format(DATE_FORMAT.MMM_YYYY)
      : periodFormat?.value === InlineDatePickerPeriods.WEEK_PERIOD
      ? moment(timePeriodStart).format(DATE_FORMAT.ll) + ' - ' + moment(timePeriodEnd).format(DATE_FORMAT.ll)
      : periodFormat?.value === InlineDatePickerPeriods.DAY_PERIOD
      ? moment(timePeriodStart).format(DATE_FORMAT.ll)
      : moment(timePeriodStart).format(DATE_FORMAT.ll) + ' - ' + moment(timePeriodEnd).format(DATE_FORMAT.ll);

  return (
    <div className="inline_date_picker">
      {!isCustomDatePicker && !hidePeriodSelector && (
        <Filter
          label=""
          options={periodSelectOptions}
          handleChange={onSelectChange}
          defaultValue={periodSelectOptions[0]}
          value={periodSelectValue}
          externalClass="period_selector"
        />
      )}
      {!isCustomDatePicker && !hideTodayButton && (
        <Button
          type="button"
          disabled={isDisabledTodayBtn}
          externalClass="today_button"
          color="bg-input"
          onClick={() => onToday(periodFormat.value)}
        >
          <FormattedMessage {...messages.todayButton} />
        </Button>
      )}
      <Button
        type="button"
        externalClass="switch_forward_arrow_button"
        color="bg-input"
        circle
        onClick={() => setPrevPoint(periodFormat.value)}
      >
        <Icon iconName="angle-left" />
      </Button>
      {isCustomDatePicker ? (
        <CustomDateRangePicker
          isSingleDatePicker={isSingleDatePicker}
          onChange={handleChangeDate}
          dateFormatStart={dateFormatStart}
          dateFormatEnd={dateFormatEnd}
          startDate={timePeriodStart.split(' ')[0]}
          endDate={timePeriodEnd.split(' ')[0]}
          todayBtn={todayBtn}
          weekBtn={weekBtn}
          monthBtn={monthBtn}
          previousMonth={previousMonth}
          thisYear={thisYear}
        />
      ) : (
        <div className="current_period">{currentPeriodValue}</div>
      )}
      <Button
        type="button"
        externalClass="switch_back_arrow_button"
        color="bg-input"
        circle
        onClick={() => setNextPoint(periodFormat.value)}
      >
        <Icon iconName="angle-right" />
      </Button>
    </div>
  );
}

export default React.memo(InlineDatePicker);
