import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as competenciesActions from '../../actions/competencies.actions';
import Table from '../../components/Table';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import ModalDeleteCompetenciesTypeData from '../../components/CompeteciesTypes/Modals/ModalDeleteCompetenciesType';
import ModalEditCompetenciesType from '../../components/CompeteciesTypes/Modals/ModalEditCompetenciesType';
import ModalNewCompetenciesType from '../../components/CompeteciesTypes/Modals/ModalNewCompetenciesType';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { CompetenciesTypesParams } from '../../enums/params/competencies.params';
import AccessChecker from '../../components/AccessChecker';
import { DELETE_COMPETENCE_TYPE, UPDATE_COMPETENCE_TYPE } from '../../constants/policies.constants';
import { CompetenciesTypeInfoType } from '../../types/competencies';
import RefreshButton from '../../components/RefreshButton';

function CompetenciesTypes({
  getCompetenciesTypesList,
  createNewCompetenciesType,
  editCompetenciesType,
  deleteCompetenciesType,
  setCompetenciesTypesParams,
  CompetenciesTypesError,
  isLoading,
  tableData,
  sortParams,
  resetErrors,
  resetState,
}: ConnectedProps<typeof connector>) {
  const [modalNewPositionIsOpen, setModalNewPositionIsOpen] = useState(false);
  const [modalEditPositionIsOpen, setModalEditPositionIsOpen] = useState(false);
  const [modalDeletePositionIsOpen, setModalDeletePositionIsOpen] = useState(false);
  const [CompetenciesTypeClicked, setCompetenciesTypeClicked] = useState<any>({});

  const intl = useIntl();

  useEffect(() => {
    getCompetenciesTypesList();
    return () => {
      resetState();
    };
  }, []);

  const openNewCompetenciesTypeModal = useCallback(() => {
    setModalNewPositionIsOpen(true);
  }, []);

  const closeNewCompetenciesTypeModal = useCallback(() => {
    setModalNewPositionIsOpen(false);
  }, []);

  const openEditCompetenciesTypeModal = useCallback(() => {
    setModalEditPositionIsOpen(true);
  }, []);

  const closeEditCompetenciesTypeModal = useCallback(() => {
    setModalEditPositionIsOpen(false);
  }, []);

  const openDeleteCompetenciesTypeModal = useCallback(() => {
    setModalDeletePositionIsOpen(true);
  }, []);

  const closeDeleteCompetenciesTypeModal = useCallback(() => {
    setModalDeletePositionIsOpen(false);
  }, []);

  const handleSort = useCallback((sortBy, direction) => setCompetenciesTypesParams({ sortBy, direction }), []);

  const setCompetenciesTypeCallback = useCallback(() => {
    setCompetenciesTypeClicked({});
  }, []);

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.nameColumn),
        sortName: 'name',
        modifier: (row: CompetenciesTypeInfoType) => row.name,
      },
    ],
    [],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: CompetenciesTypeInfoType) => {
          setCompetenciesTypeClicked(row);
          openEditCompetenciesTypeModal();
        },
        verifiablePolicies: [UPDATE_COMPETENCE_TYPE],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: CompetenciesTypeInfoType) => {
          setCompetenciesTypeClicked(row);
          openDeleteCompetenciesTypeModal();
        },
        verifiablePolicies: [DELETE_COMPETENCE_TYPE],
      },
    ],
    [],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom no-border">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[UPDATE_COMPETENCE_TYPE]}>
                  <Button externalClass={'button--with-icon'} onClick={openNewCompetenciesTypeModal}>
                    <Icon iconName={'plus'} externalClass={'button__icon'} />
                    <span className="button__text">
                      <FormattedMessage {...messages.newButton} />
                    </span>
                  </Button>
                </AccessChecker>
                <RefreshButton onRefresh={() => setCompetenciesTypesParams(sortParams)} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <Table
            externalClass={'table'}
            tableColumns={tableColumns}
            tableData={tableData || []}
            params={sortParams}
            loading={isLoading.getCompetenciesTypes}
            onSort={handleSort}
            error={CompetenciesTypesError}
            tableActions={tableActions}
          />
        </div>
      </div>
      {modalNewPositionIsOpen && (
        <ModalNewCompetenciesType
          isOpen
          onCloseRequest={closeNewCompetenciesTypeModal}
          createNewCompetenciesType={createNewCompetenciesType}
          CompetenciesTypeError={CompetenciesTypesError}
          isLoading={isLoading.createCompetenciesType}
          resetErrors={resetErrors}
        />
      )}
      {modalEditPositionIsOpen && (
        <ModalEditCompetenciesType
          isOpen
          onCloseRequest={closeEditCompetenciesTypeModal}
          editCompetenciesType={editCompetenciesType}
          CompetenciesTypeData={CompetenciesTypeClicked}
          CompetenciesTypeError={CompetenciesTypesError}
          isLoading={isLoading.editCompetenciesType}
          resetErrors={resetErrors}
        />
      )}
      {modalDeletePositionIsOpen && (
        <ModalDeleteCompetenciesTypeData
          isOpen
          onCloseRequest={closeDeleteCompetenciesTypeModal}
          onDeleteRequest={(data: Record<string, unknown>) => {
            deleteCompetenciesType({
              ...data,
              setCompetenciesTypeCallback,
            });
          }}
          isLoading={isLoading.deleteCompetenciesType}
          CompetenciesTypeError={CompetenciesTypesError}
          CompetenciesTypeData={CompetenciesTypeClicked}
          resetErrors={resetErrors}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ competencies }: RootState) => ({
  tableData: competencies.competenciesTypesTableData?.content,
  CompetenciesTypesError: competencies.errors.competenciesTypesError,
  isLoading: competencies.loading,
  sortParams: competencies.competenciesTypesParams,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getCompetenciesTypesList: () => dispatch(competenciesActions.getCompetenciesTypesList()),
  deleteCompetenciesType: (data: Record<string, unknown>) => dispatch(competenciesActions.deleteCompetenciesType(data)),
  createNewCompetenciesType: (data: Record<string, unknown>) =>
    dispatch(competenciesActions.createNewCompetenciesType(data)),
  editCompetenciesType: (id: string, data: Record<string, unknown>) =>
    dispatch(competenciesActions.editCompetenciesType({ ...data, id: id })),
  setCompetenciesTypesParams: (params: Partial<CompetenciesTypesParams>) =>
    dispatch(competenciesActions.setCompetenciesTypesParams(params)),
  resetErrors: () => dispatch(competenciesActions.resetErrors()),
  resetState: () => dispatch(competenciesActions.resetState()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(CompetenciesTypes);
