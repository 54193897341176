import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';

import moment from 'moment';

import * as filtersActions from '../../actions/filters.actions';
import * as financeActions from '../../actions/finance.actions';
import InlineDatePicker from '../../components/InlineDatePicker';
import { DATE_FORMAT } from '../../constants/date.constants';
import { DashboardBlockClass, PutDashboard, PutExpenseDashboard } from '../../enums/finance/finance.enum';
import { getBaseCurrency } from '../../utils';
import messages from './messages';
import DashboardTableBlock from '../../components/Dashboard/Table/DashboardTable';
import { OfficesBalanceParams } from '../../enums/params/finance.params';
import PlannedExpenseTable from '../../components/Dashboard/Table/PlannedExpense';
import DashboardHeadBlock, { EDashboardTopSetting } from '../../components/Dashboard/Table/DashboardHeadBlock';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import DashboardModal from '../../components/Dashboard/Modal/DashboardSettings/DashboardModal';
import DashboardPieChart from '../../components/Dashboard/Table/DashboardPieChart';
import DashboardBarChart from '../../components/Dashboard/Table/DashboardBar';
import PoliciesContext from '../../PoliciesContext';
import { UPDATE_DASHBOARD_SETTINGS, VIEW_DASHBOARD, VIEW_DASHBOARD_SETTINGS } from '../../constants/policies.constants';
import { DashboardTopSetting } from '../../types/finance';
import Dropdown from '../../components/Dropdown';
import RefreshButton from '../../components/RefreshButton';
import { InlineDatePickerPeriods } from '../../components/InlineDatePicker/constants';
import { DashboardChartsRangeParams } from '../../enums/params/dashboard.params';

export enum EDashboardTitles {
  REVENUE_AND_EXPENSE = 'REVENUE_AND_EXPENSE',
  PLANNED_EXPENSES_BY_OFFICE = 'PLANNED_EXPENSES_BY_OFFICE',
  FAVORITE_PLANNED_EXPENSE = 'FAVORITE_PLANNED_EXPENSE',
  PLANNED_AND_ACTUAL_REVENUE = 'PLANNED_AND_ACTUAL_REVENUE',
  CUSTOMER_INVOICE = 'CUSTOMER_INVOICE',
  REVENUE_BY_CUSTOMER = 'REVENUE_BY_CUSTOMER',
  EXPENSES_STRUCTURE = 'EXPENSES_STRUCTURE',
}

export enum EDashboardSettings {
  REVENUE_AND_EXPENSE = 'revenueAndExpenses',
  PLANNED_EXPENSES_BY_OFFICE = 'plannedExpensesByOffices',
  FAVORITE_PLANNED_EXPENSE = 'favoritePlannedExpenses',
  PLANNED_AND_ACTUAL_REVENUE = 'plannedAndActualRevenues',
  CUSTOMER_INVOICE = 'customerInvoices',
  REVENUE_BY_CUSTOMER = 'revenueByCustomers',
  EXPENSES_STRUCTURE = 'expenseStructures',
}

export enum EDashboardSettingNames {
  REVENUE_AND_EXPENSE = 'revenueAndExpenseBlockName',
  PLANNED_EXPENSES_BY_OFFICE = 'plannedExpensesByOfficeBlockName',
  FAVORITE_PLANNED_EXPENSE = 'favoritePlannedExpenseBlockName',
  PLANNED_AND_ACTUAL_REVENUE = 'plannedAndActualRevenueBlockName',
  CUSTOMER_INVOICE = 'customerInvoiceBlockName',
  REVENUE_BY_CUSTOMER = 'revenueByCustomerBlockName',
  EXPENSES_STRUCTURE = 'expenseStructureBlockName',
}

function Dashboard({
  tableData,
  isLoading,
  errors,
  params,
  chartRangeParams,
  finance,

  setDashboardParams,
  getOfficesFilter,
  getCurrenciesFilter,
  getClientList,
  getExpenseTypesList,
  getIncomeTypesList,
  getIncomeTypesFullList,
  setDashboardChartsRangeParams,

  getRevenueAndExpense,
  getDashboardPlannedExpenses,
  getDashboardClientSetting,
  getDashboardIncomeTopSetting,
  getDashboardExpenseTopSetting,
  getDashboardTopSetting,
  getDashboardSetting,
  getExpensesStructure,
  getDashboardIncome,
  getDashboardExpense,

  putRevenueAndExpense,
  putDashboardPlannedExpenses,
  putDashboardClientSetting,
  putDashboardIncomeTopSetting,
  putDashboardExpenseTopSetting,
  putDashboardTopSetting,
  putDashboardSetting,
  putExpensesStructure,

  resetState,
  resetErrors,
  currencies,
}: ConnectedProps<typeof connector>) {
  const intl = useIntl();
  const policies = useContext(PoliciesContext);

  const viewPolicy = useMemo(() => policies.find(policy => policy.policy.name === VIEW_DASHBOARD), [policies]);
  const viewSettingPolicy = useMemo(() => policies.find(policy => policy.policy.name === VIEW_DASHBOARD_SETTINGS), [
    policies,
  ]);
  const updateSettingPolicy = useMemo(() => policies.find(policy => policy.policy.name === UPDATE_DASHBOARD_SETTINGS), [
    policies,
  ]);

  const [openSettingsModal, setOpenSettingModal] = useState(false);
  const [openTopSettingsModal, setOpenTopSettingModal] = useState(false);

  useEffect(() => {
    setDashboardParams({});
  }, [viewPolicy]);

  useEffect(() => {
    getOfficesFilter();
    getCurrenciesFilter();

    getDashboardTopSetting();
    getDashboardIncomeTopSetting();
    getDashboardExpenseTopSetting();
    getDashboardSetting();
    getDashboardIncome();
    getDashboardExpense();

    return () => {
      resetState();
    };
  }, []);

  useEffect(() => {
    if (finance.dashboardSettingData) {
      finance.dashboardSettingData.dashboardBlockSettings.map((item: DashboardBlockClass) => {
        if (
          [EDashboardTitles.PLANNED_EXPENSES_BY_OFFICE, EDashboardTitles.FAVORITE_PLANNED_EXPENSE].includes(
            item.blockType,
          )
        ) {
          getDashboardPlannedExpenses(item.blockType);
        } else if (
          [
            EDashboardTitles.PLANNED_AND_ACTUAL_REVENUE,
            EDashboardTitles.CUSTOMER_INVOICE,
            EDashboardTitles.REVENUE_BY_CUSTOMER,
          ].includes(item.blockType)
        ) {
          getDashboardClientSetting(item.blockType);
        } else {
          getRevenueAndExpense();
          getExpensesStructure();
        }
      });
    }
  }, [finance.dashboardSettingData]);

  const baseCurrency = useMemo(() => getBaseCurrency(currencies), [currencies]);

  const handleDataChange = useCallback((start: string, end: string) => {
    setDashboardParams({
      dateFrom: moment(start).format(DATE_FORMAT.YYYY_MM_DD),
      dateTo: moment(end).format(DATE_FORMAT.YYYY_MM_DD),
    });
  }, []);

  const handleChangeForCharts = useCallback((start: string, end: string) => {
    setDashboardChartsRangeParams({
      dateFrom: moment(start).format(DATE_FORMAT.YYYY_MM_DD),
      dateTo: moment(end).format(DATE_FORMAT.YYYY_MM_DD),
    });
  }, []);

  const months = useMemo(() => {
    const startDate = moment(params.dateFrom);
    const endDate = moment(params.dateTo);

    const diff = endDate.diff(startDate, 'months', true);
    const range = [];

    for (let i = 0; i < diff; i++) {
      range.push(moment(params.dateFrom).add(i, 'month').format('YYYY-MM-DD'));
    }

    return range;
  }, [params.dateFrom, params.dateTo]);

  const dashboardChartsMonths = useMemo(() => {
    const startDate = moment(chartRangeParams.dateFrom);
    const endDate = moment(chartRangeParams.dateTo);

    const diff = endDate.diff(startDate, 'months', true);
    const range = [];

    for (let i = 0; i < diff; i++) {
      range.push(moment(chartRangeParams.dateFrom).add(i, 'month').format('YYYY-MM-DD'));
    }

    return range;
  }, [chartRangeParams.dateFrom, chartRangeParams.dateTo]);

  const range = useMemo(() => {
    return `${moment(params.dateFrom).format('ll')} - ${moment(params.dateTo).format('ll')}`;
  }, [params.dateFrom, params.dateTo]);

  const putClientSettings = (data: PutDashboard, blockType: EDashboardTitles) => {
    if (blockType === EDashboardTitles.REVENUE_AND_EXPENSE) {
      const revenue = {
        name: data.params.name,
        blockType,
        incomeTypeBlockSettings: data.params.types.map((item: any, index: number) => ({
          incomeTypeId: item.value,
          positionNumber: index,
        })),
      };

      putRevenueAndExpense({ ...data, params: revenue });
    } else {
      const client = {
        name: data.params.name,
        blockType,
        clientBlockSettings: data.params.types.map((item: any, index: number) => ({
          clientId: item.value,
          positionNumber: index,
        })),
      };

      putDashboardClientSetting({ ...data, params: client });
    }
  };

  const openSetting = () => {
    setOpenSettingModal(true);
  };

  const closeSetting = () => {
    setOpenSettingModal(false);
  };

  const openTopSetting = () => {
    setOpenTopSettingModal(true);
  };

  const closeTopSetting = () => {
    setOpenTopSettingModal(false);
  };

  const getTableActions = useMemo(
    () => [
      {
        label: (
          <>
            <FormattedMessage {...messages.dashboardTopEdit} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: () => {
          openTopSetting();
        },
      },
      {
        label: (
          <>
            <FormattedMessage {...messages.dashboardEdit} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: () => {
          openSetting();
        },
      },
    ],
    [],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
            <div className="page__panel-top__control">
              <div className="pagination page__panel-top__control__pagination">
                <InlineDatePicker
                  onDateChange={handleDataChange}
                  defaultPeriodStart={params.dateFrom}
                  defaultPeriodEnd={params.dateTo}
                  todayBtn={false}
                  weekBtn={false}
                  previousMonth
                  thisYear
                />
                <Dropdown
                  dropdownClass="dropdown--no-bg"
                  dropdownToggle={
                    <Button color="gray" externalClass="cash-flow__report-button">
                      <Icon iconName="dots" externalClass="button__icon" />
                    </Button>
                  }
                  dropdownList={getTableActions}
                  stopPropagation
                />
              </div>
            </div>
          </div>
          <div className="page__panel-bottom__wrapper--left">
            <RefreshButton
              onRefresh={() => {
                getDashboardIncome();
                getDashboardExpense();
                setDashboardParams(params);
              }}
            />
          </div>
        </div>
      </div>
      <div className="page__content dashboard-page">
        <div className="dashboard-chart-container">
          <div className="dashboard-page-flex">
            {finance.dashboardTopSettingData?.dashboardTopBlockSettings
              .filter((block: DashboardTopSetting) => block.show)
              .map((block: DashboardTopSetting, index: number) => (
                <DashboardHeadBlock
                  key={index}
                  blockType={block.blockType}
                  baseCurrency={baseCurrency}
                  months={dashboardChartsMonths}
                  settingsData={finance}
                  settingsLoading={finance.loading}
                  settingsError={finance.errors}
                  editDashboardTopSetting={data =>
                    block.blockType === EDashboardTopSetting.INCOME
                      ? putDashboardIncomeTopSetting(data)
                      : putDashboardExpenseTopSetting(data)
                  }
                  getExpenseList={getExpenseTypesList}
                  getIncomeList={getIncomeTypesFullList}
                  onCloseSettings={resetErrors}
                />
              ))}
          </div>
          <InlineDatePicker
            onDateChange={handleChangeForCharts}
            defaultPeriod={InlineDatePickerPeriods.YEAR_PERIOD}
            isCustomDatePicker={false}
            hideTodayButton
            hidePeriodSelector
            defaultPeriodStart={chartRangeParams.dateFrom}
            defaultPeriodEnd={chartRangeParams.dateTo}
          />
        </div>
        <div className="page__wrapper dashboard-table">
          {finance.dashboardSettingData
            ? finance.dashboardSettingData.dashboardBlockSettings
                .filter((setting: DashboardBlockClass) => !!setting.show)
                .map((setting: DashboardBlockClass) =>
                  [EDashboardTitles.PLANNED_AND_ACTUAL_REVENUE, EDashboardTitles.CUSTOMER_INVOICE].includes(
                    setting.blockType,
                  ) ? (
                    <DashboardTableBlock
                      title={setting.blockType}
                      viewPolicy={!!viewSettingPolicy}
                      updatePolicy={!!updateSettingPolicy}
                      name={tableData[EDashboardSettingNames[setting.blockType]]}
                      tableData={tableData?.[EDashboardSettings[setting.blockType]]}
                      typeLabel={intl.formatMessage(messages.clientLabel)}
                      typesList={finance.clientsListData}
                      baseCurrency={baseCurrency}
                      isLoading={finance.loading.getDashboard}
                      errors={errors}
                      settingsData={finance.dashboardClientSettingData}
                      settingsLoading={finance.loading.getDashboardClientSetting}
                      settingsError={finance.errors.dashboardClientSettingError}
                      getTypeList={getClientList}
                      putSettingsData={data => putClientSettings(data, setting.blockType)}
                      onCloseSettings={resetErrors}
                      range={range}
                    />
                  ) : [EDashboardTitles.PLANNED_EXPENSES_BY_OFFICE, EDashboardTitles.FAVORITE_PLANNED_EXPENSE].includes(
                      setting.blockType,
                    ) ? (
                    <PlannedExpenseTable
                      key={setting.blockType}
                      title={setting.blockType}
                      viewPolicy={!!viewSettingPolicy}
                      updatePolicy={!!updateSettingPolicy}
                      name={tableData[EDashboardSettingNames[setting.blockType]]}
                      tableData={tableData[EDashboardSettings[setting.blockType]]}
                      errors={errors}
                      types={finance.expenseTypesListData}
                      settingsData={finance.dashboardPlannedExpensesData}
                      settingsLoading={finance.loading.getDashboardPlannedExpenses}
                      settingsError={finance.errors.dashboardPlannedExpensesError}
                      isLoading={isLoading.getDashboard}
                      baseCurrency={baseCurrency}
                      getSettings={getDashboardPlannedExpenses}
                      putSettings={putDashboardPlannedExpenses}
                      getTypesList={getExpenseTypesList}
                      onCloseSettings={resetErrors}
                      range={range}
                    />
                  ) : [EDashboardTitles.EXPENSES_STRUCTURE, EDashboardTitles.REVENUE_BY_CUSTOMER].includes(
                      setting.blockType,
                    ) ? (
                    <DashboardPieChart
                      title={setting.blockType}
                      viewPolicy={!!viewSettingPolicy}
                      updatePolicy={!!updateSettingPolicy}
                      name={tableData[EDashboardSettingNames[setting.blockType]]}
                      data={tableData[EDashboardSettings[setting.blockType]]}
                      settingsData={
                        setting.blockType === EDashboardTitles.EXPENSES_STRUCTURE
                          ? finance.dashboardExpensesStructureData
                          : finance.dashboardClientSettingData
                      }
                      settingsLoading={
                        finance.loading[
                          setting.blockType === EDashboardTitles.EXPENSES_STRUCTURE
                            ? 'getExpensesStructure'
                            : 'getDashboardClientSetting'
                        ]
                      }
                      settingsError={
                        finance.errors[
                          setting.blockType === EDashboardTitles.EXPENSES_STRUCTURE
                            ? 'dashboardExpensesStructureError'
                            : 'dashboardClientSettingError'
                        ]
                      }
                      putSettingsData={data =>
                        setting.blockType === EDashboardTitles.EXPENSES_STRUCTURE
                          ? putExpensesStructure(data)
                          : putClientSettings(data, setting.blockType)
                      }
                      typeLabel={intl.formatMessage(messages.clientLabel)}
                      typesList={finance.clientsListData}
                      baseCurrency={baseCurrency}
                      errors={errors}
                      getTypeList={getClientList}
                      onCloseSettings={resetErrors}
                      range={range}
                    />
                  ) : (
                    <DashboardBarChart
                      data={tableData?.revenueAndExpenses}
                      viewPolicy={!!viewSettingPolicy}
                      updatePolicy={!!updateSettingPolicy}
                      months={months}
                      title={EDashboardTitles.REVENUE_AND_EXPENSE}
                      name={tableData[EDashboardSettingNames.REVENUE_AND_EXPENSE]}
                      typeLabel={intl.formatMessage(messages.incomeTypeLabel)}
                      typesList={finance.incomeTypesListData}
                      settingsData={finance.revenueAndExpenseData}
                      settingsLoading={finance.loading.getRevenueAndExpense}
                      settingsError={finance.errors.revenueAndExpenseError}
                      getTypeList={getIncomeTypesList}
                      errors={errors}
                      onCloseSettings={resetErrors}
                      putSettingsData={data => putClientSettings(data, setting.blockType)}
                      range={range}
                    />
                  ),
                )
            : null}
        </div>
      </div>
      {openSettingsModal && (
        <DashboardModal
          isOpen={openSettingsModal}
          onCloseRequest={closeSetting}
          editSetting={putDashboardSetting}
          loading={finance.loading.getDashboardSetting}
          error={finance.errors.dashboardSettingError}
          data={finance.dashboardSettingData}
        />
      )}
      {openTopSettingsModal && (
        <DashboardModal
          isOpen={openTopSettingsModal}
          topBlock
          onCloseRequest={closeTopSetting}
          editSetting={putDashboardTopSetting}
          loading={finance.loading.getDashboardTopSetting}
          error={finance.errors.dashboardTopSettingError}
          data={finance.dashboardTopSettingData}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ filters, auth, finance }: RootState) => ({
  finance: finance,
  tableData: finance.dashboardListData,
  officesFilter: filters.officesFilter,
  isLoading: finance.loading,
  errors: finance.errors.dashboardError,
  params: finance.dashboardParams,
  chartRangeParams: finance.dashboardChartsRangeParams,
  authUserId: auth.currentUserInfo.id,
  currencies: filters.currenciesFilter.currencies,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getOfficesFilter: () => dispatch(filtersActions.getOfficesFilter()),
  getCurrenciesFilter: () => dispatch(filtersActions.getCurrenciesFilter()),

  setDashboardParams: (data: Partial<OfficesBalanceParams>) => dispatch(financeActions.setDashboardParams(data)),
  setDashboardChartsRangeParams: (data: Partial<DashboardChartsRangeParams>) =>
    dispatch(financeActions.stDashboardChartsRangeParams(data)),
  getDashboardIncome: () => dispatch(financeActions.getDashboardIncome()),
  getDashboardExpense: () => dispatch(financeActions.getDashboardExpense()),

  getIncomeTypesList: () => dispatch(financeActions.getIncomeTypesList()),
  getIncomeTypesFullList: (data: any) => dispatch(financeActions.getIncomeTypesFullList(data)),
  getExpenseTypesList: (data: any) => dispatch(financeActions.getExpenseTypesFullList(data)),
  getProjectsList: () => dispatch(financeActions.getProjectsList()),
  getClientList: () => dispatch(financeActions.getClientsList()),

  getRevenueAndExpense: () => dispatch(financeActions.getRevenueAndExpense()),
  getExpensesStructure: () => dispatch(financeActions.getExpensesStructure()),
  getDashboardPlannedExpenses: (data: string) => dispatch(financeActions.getDashboardPlannedExpenses(data)),
  getDashboardClientSetting: (data: string) => dispatch(financeActions.getDashboardClientSetting(data)),
  getDashboardIncomeTopSetting: () => dispatch(financeActions.getDashboardIncomeTopSetting()),
  getDashboardExpenseTopSetting: () => dispatch(financeActions.getDashboardExpenseTopSetting()),
  getDashboardTopSetting: () => dispatch(financeActions.getDashboardTopSetting()),
  getDashboardSetting: () => dispatch(financeActions.getDashboardSetting()),

  putRevenueAndExpense: (data: PutExpenseDashboard) => dispatch(financeActions.putRevenueAndExpense(data)),
  putExpensesStructure: (data: any) => dispatch(financeActions.putExpensesStructure(data)),
  putDashboardPlannedExpenses: (data: PutExpenseDashboard) =>
    dispatch(financeActions.putDashboardPlannedExpenses(data)),
  putDashboardClientSetting: (data: PutExpenseDashboard) => dispatch(financeActions.putDashboardClientSetting(data)),
  putDashboardSetting: (data: any) => dispatch(financeActions.putDashboardSetting(data)),
  putDashboardTopSetting: (data: any) => dispatch(financeActions.putDashboardTopSetting(data)),
  putDashboardIncomeTopSetting: (data: any) => dispatch(financeActions.putDashboardIncomeTopSetting(data)),
  putDashboardExpenseTopSetting: (data: any) => dispatch(financeActions.putDashboardExpenseTopSetting(data)),

  resetErrors: () => dispatch(financeActions.resetErrors()),
  resetState: () => dispatch(financeActions.resetState()),
  resetSettings: () => dispatch(financeActions.resetCashFlowReportSetting()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Dashboard);
