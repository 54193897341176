import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Table from '../../components/Table';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import ModalDeleteProjectGroup from '../../components/ProjectGroups/Modals/ModalDeleteProjectGroup';
import * as planningActions from '../../actions/planning.actions';
import * as filtersActions from '../../actions/filters.actions';
import Pagination from '../../components/Pagination';
import ModalNewProjectGroup from '../../components/ProjectGroups/Modals/ModalNewProjectGroup';
import ModalEditProjectGroup from '../../components/ProjectGroups/Modals/ModalEditProjectGroup';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { ProjectGroupsParams } from '../../enums/params/planning.params';
import { DELETE_PROJECT_GROUP, UPDATE_PROJECT_GROUP } from '../../constants/policies.constants';
import AccessChecker from '../../components/AccessChecker';
import ProjectGroupsFilter from '../../components/ProjectGroups/Filter/ProjectGroupsFilter';
import { SavedFilterParams } from '../../enums/params/filters.params';
import { SavedFilter } from '../../enums/filters.enum';
import { convertSavedFieldsToParams, getSavedFilterParams, getSavedFilters } from '../../utils/filters.utils';
import { useParamsChange } from '../../utils/hooks.utils';
import { getJiraProjectsFilter } from '../../actions/filters.actions';
import { FilterTypes } from '../../constants/filters.constants';
import { isEqual } from 'lodash-es';

export type ProjectGroupType = {
  id: string;
  fullName: string;
  shortName: string;
  jiraProjects: number[];
  activeStatus: boolean;
  alertsStatus: boolean;
  color: string;
};

function ProjectGroups({
  tableData,
  isLoading,
  projectGroupsDataError,
  projectGroupsTableDataError,
  setProjectGroupsParams,
  getJiraProjectsFilter,
  jiraProjectFilter,
  createProjectGroup,
  deleteProjectGroup,
  editProjectGroup,
  projectGroupsParams,
  resetError,
  resetState,
  setSavedFiltersParams,
  createNewSavedFilter,
  savedFiltersData,
  editSavedFilter,
  deleteSavedFilter,
  resetSavedFilterErrors,
  authUserId,
}: ConnectedProps<typeof connector>) {
  const intl = useIntl();

  useEffect(() => {
    const currentSavedFilter = getSavedFilterParams(getSavedFilters(), FilterTypes.PROJECT_GROUPS_FILTER);

    setProjectGroupsParams(
      currentSavedFilter ? new ProjectGroupsParams(currentSavedFilter) : { jiraProjects: jiraProjectFilter.value },
    );
    getJiraProjectsFilter();
    setSavedFiltersParams({ filterType: FilterTypes.PROJECT_GROUPS_FILTER });
    return () => {
      resetState();
    };
  }, []);

  const [modalDeleteProjectGroupIsOpen, setModalDeleteProjectGroupIsOpen] = useState(false);
  const [modalNewProjectGroupIsOpen, setModalNewProjectGroupIsOpen] = useState(false);
  const [modalEditProjectGroupIsOpen, setModalEditProjectGroupIsOpen] = useState(false);
  const [clickedProjectGroup, setClickedProjectGroup] = useState<any>({});

  const closeDeleteProjectGroupModal = useCallback(() => {
    setModalDeleteProjectGroupIsOpen(false);
  }, []);

  const jiraProjectOptions = useMemo(
    () =>
      jiraProjectFilter.jiraProject?.map(({ id, name }: { id: string; name: string }) => ({
        value: id,
        label: name,
      })) || [],
    [jiraProjectFilter.jiraProject],
  );

  const openDeleteProjectGroupModal = useCallback(() => {
    setModalDeleteProjectGroupIsOpen(true);
  }, []);

  const closeNewProjectGroupModal = useCallback(() => {
    setModalNewProjectGroupIsOpen(false);
  }, []);

  const openNewProjectGroupModal = useCallback(() => {
    setModalNewProjectGroupIsOpen(true);
  }, []);

  const closeEditProjectGroupModal = useCallback(() => {
    setModalEditProjectGroupIsOpen(false);
  }, []);

  const openEditProjectGroupModal = useCallback(() => {
    setModalEditProjectGroupIsOpen(true);
  }, []);

  const handleSort = useCallback((sortBy, direction) => setProjectGroupsParams({ sortBy, direction }), []);

  const handleMultiParamsChange = useParamsChange(setProjectGroupsParams, null);

  const handleParamsChange = useParamsChange(setProjectGroupsParams, null, false);

  const handlePageChange = useCallback(
    ({ selected }) => {
      setProjectGroupsParams({ page: selected });
    },
    [tableData],
  );

  const handleSizeChange = useCallback(
    data => {
      setProjectGroupsParams({ size: data, page: 0 });
    },
    [tableData],
  );

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.fullNameTitle),
        modifier(row: ProjectGroupType) {
          return (
            <div className={'table__data-wrapper'}>
              <span className={'table__data-no-image'} style={{ backgroundColor: `#${row.color}` }}></span>
              <span>{row.fullName}</span>
            </div>
          );
        },
      },
      {
        name: intl.formatMessage(messages.shortNameTitle),
        modifier: (row: ProjectGroupType) => (
          <div className={'table__data-wrapper'}>
            <span>{row.shortName}</span>
          </div>
        ),
      },
      {
        name: intl.formatMessage(messages.jiraProjectsLabel),
        modifier: (row: ProjectGroupType) => (
          <div className={'table__data-wrapper'}>
            <span>{row.jiraProjects.length}</span>
          </div>
        ),
      },
      {
        name: intl.formatMessage(messages.alertsTitle),
        modifier: (row: ProjectGroupType) => (
          <div className={'table__data-wrapper'}>
            <span>
              {row.alertsStatus ? intl.formatMessage(messages.yesLabel) : intl.formatMessage(messages.noLabel)}
            </span>
          </div>
        ),
      },
      {
        name: intl.formatMessage(messages.statusLabel),
        modifier: (row: ProjectGroupType) => (
          <div className={'table__data-wrapper'}>
            <span className={row.activeStatus ? 'active-status' : 'inactive-status'}>
              {row.activeStatus ? intl.formatMessage(messages.activeLabel) : intl.formatMessage(messages.inactiveLabel)}
            </span>
          </div>
        ),
      },
    ],
    [],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: ProjectGroupType) => {
          setClickedProjectGroup(row);
          openEditProjectGroupModal();
        },
        verifiablePolicies: [UPDATE_PROJECT_GROUP],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: ProjectGroupType) => {
          setClickedProjectGroup(row);
          openDeleteProjectGroupModal();
        },
        verifiablePolicies: [DELETE_PROJECT_GROUP],
      },
    ],
    [],
  );

  const handleFiltersControlChange = useCallback(value => {
    setProjectGroupsParams(new ProjectGroupsParams(convertSavedFieldsToParams(value.fields)));
  }, []);

  const handleClear = useCallback(() => {
    setProjectGroupsParams(new ProjectGroupsParams());
  }, []);

  const showClearButton = useMemo(() => !isEqual(projectGroupsParams, new ProjectGroupsParams()), [
    projectGroupsParams,
  ]);

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[UPDATE_PROJECT_GROUP]}>
                  <Button externalClass={'button--with-icon'} onClick={openNewProjectGroupModal}>
                    <Icon iconName={'plus'} externalClass={'button__icon'} />
                    <span className="button__text">
                      <FormattedMessage {...messages.newButton} />
                    </span>
                  </Button>
                </AccessChecker>
                <ProjectGroupsFilter
                  jiraProjectOptions={jiraProjectOptions}
                  values={projectGroupsParams}
                  handleMultiParamsChange={handleMultiParamsChange}
                  createNewSavedFilter={createNewSavedFilter}
                  savedFiltersData={savedFiltersData}
                  authUserId={authUserId}
                  deleteSavedFilter={deleteSavedFilter}
                  editSavedFilter={editSavedFilter}
                  handleFiltersControlChange={handleFiltersControlChange}
                  handleClear={handleClear}
                  resetSavedFilterErrors={resetSavedFilterErrors}
                  handleParamsChange={handleParamsChange}
                  setProjectGroupsParams={setProjectGroupsParams}
                  handleSort={handleSort}
                  showClearButton={showClearButton}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <Table
            externalClass={'table'}
            tableColumns={tableColumns}
            tableData={tableData?.content || []}
            loading={isLoading.getProjectGroupsList}
            error={projectGroupsTableDataError}
            tableActions={tableActions}
          />
          {tableData && (
            <Pagination
              pageable={{
                ...tableData?.pageable,
                ...tableData?.sort,
                totalElements: tableData?.totalElements,
                numberOfElements: tableData?.numberOfElements,
                totalPages: tableData?.totalPages,
              }}
              onPageChange={data => handlePageChange(data)}
              onPageSizeChange={data => handleSizeChange(data)}
            />
          )}
        </div>
      </div>
      {modalDeleteProjectGroupIsOpen && (
        <ModalDeleteProjectGroup
          isOpen
          onCloseRequest={closeDeleteProjectGroupModal}
          onDeleteRequest={(data: Record<string, unknown>) => {
            deleteProjectGroup({
              ...data,
            });
          }}
          isLoading={isLoading.deleteProjectGroup}
          projectGroupData={clickedProjectGroup}
          projectGroupError={projectGroupsDataError}
          resetError={resetError}
        />
      )}
      {modalNewProjectGroupIsOpen && (
        <ModalNewProjectGroup
          isOpen
          onCloseRequest={closeNewProjectGroupModal}
          jiraProjectOptions={jiraProjectOptions}
          isLoading={isLoading.createProjectGroup}
          jiraProjectsListErrors={jiraProjectFilter.errors}
          createProjectGroup={createProjectGroup}
          projectGroupError={projectGroupsDataError}
          resetError={resetError}
        />
      )}
      {modalEditProjectGroupIsOpen && (
        <ModalEditProjectGroup
          isOpen
          onCloseRequest={closeEditProjectGroupModal}
          jiraProjectOptions={jiraProjectOptions}
          isLoading={isLoading.createProjectGroup}
          jiraProjectsListErrors={jiraProjectFilter.errors}
          editProjectGroup={editProjectGroup}
          projectGroupError={projectGroupsDataError}
          projectGroupData={clickedProjectGroup}
          resetError={resetError}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ planning, filters, auth }: RootState) => ({
  projectGroupsDataError: planning.errors.projectGroupsDataError,
  projectGroupsTableDataError: planning.errors.projectGroupsTableDataError,
  isLoading: planning.loading,
  tableData: planning.projectGroupsTableData,
  projectGroupsParams: planning.projectGroupsParams,
  savedFiltersData: filters.savedFilters,
  authUserId: auth.currentUserInfo.id,
  jiraProjectFilter: filters.jiraProjectFilter,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  createProjectGroup: (data: Record<string, unknown>) => dispatch(planningActions.createProjectGroup(data)),
  deleteProjectGroup: (data: Record<string, unknown>) => dispatch(planningActions.deleteProjectGroup(data)),
  editProjectGroup: (data: Record<string, unknown>) => dispatch(planningActions.editProjectGroup(data)),
  getJiraProjectsFilter: () => dispatch(getJiraProjectsFilter()),
  setProjectGroupsParams: (data: Partial<ProjectGroupsParams>) =>
    dispatch(planningActions.setProjectGroupsParams(data)),
  resetError: () => dispatch(planningActions.resetPlaningError()),
  resetState: () => dispatch(planningActions.resetPlaningState()),
  setSavedFiltersParams: (data: Partial<SavedFilterParams>) => dispatch(filtersActions.setSavedFiltersParams(data)),
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) =>
    dispatch(filtersActions.createNewSavedFilter(data)),
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) =>
    dispatch(filtersActions.editSavedFilter(data)),
  deleteSavedFilter: (data: { id: string; callback: () => void }) => dispatch(filtersActions.deleteSavedFilter(data)),
  resetSavedFilterErrors: () => dispatch(filtersActions.resetSavedFilterErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ProjectGroups);
