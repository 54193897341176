import React from 'react';
import { useIntl } from 'react-intl';

import { SortParams } from '../../../enums/params.enum';
import SortSelect from '../../SortSelect';
import messages from '../messages';
import { ExpenseTypesParams } from '../../../enums/params/finance.params';
import RefreshButton from '../../RefreshButton';

type ExpenseTypesFiltersProps = {
  values: SortParams;
  setExpenseTypesParams: (params: Partial<ExpenseTypesParams>) => void;
  handleSort: (sortBy: string, direction: string) => void;
};

function ExpenseTypesFilter({ values, handleSort, setExpenseTypesParams }: ExpenseTypesFiltersProps) {
  const intl = useIntl();

  return (
    <>
      <SortSelect
        sortOptions={[{ label: intl.formatMessage(messages.nameColumn), value: 'name' }]}
        params={new SortParams('', { sortBy: values.sortBy, direction: values.direction })}
        onSort={handleSort}
      />
      <RefreshButton onRefresh={() => setExpenseTypesParams(values)} />
    </>
  );
}

export default React.memo(ExpenseTypesFilter);
