import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import Icon from '../../components/Icon';
import messages from './messages';
import * as notificationsActions from '../../actions/notifications.actions';
import * as filtersActions from '../../actions/filters.actions';
import { useDataForTable } from './useDataForTable';
import AccessChecker from '../../components/AccessChecker';
import Button from '../../components/Button';
import { UPDATE_NOTIFICATION_TEMPLATE } from '../../constants/policies.constants';
import Table from '../../components/Table';
import ModalNewNotificationTemplate from '../../components/NotificationTemplates/Modals/ModalNewNotificationTemplate';
import { NotificationTemplate } from '../../enums/notifications.enum';
import ModalDeleteNotificationTemplate from '../../components/NotificationTemplates/Modals/ModalDeleteNotificationTemplate';
import ModalEditNotificationTemplate from '../../components/NotificationTemplates/Modals/ModalEditNotificationTemplate';
import { useParamsChange } from '../../utils/hooks.utils';
import NotificationTemplatesFilter from '../../components/NotificationTemplates/Filter/NotificationTemplatesFilter';
import { EmailTemplatesParams } from '../../enums/params/notifications.params';
import { isEqual } from 'lodash-es';

function NotificationTemplates({
  tableData,
  getEmailTemplatesList,
  isLoading,
  errors,
  createEmailTemplate,
  editEmailTemplate,
  deleteEmailTemplate,
  resetErrors,
  getEventTypes,
  allEventTypes,
  setEmailTemplatesParams,
  params,
}: ConnectedProps<typeof connector>) {
  const dispatch = useDispatch();

  const [modalNewNotificationTemplateIsOpen, setModalNewNotificationTemplateIsOpen] = useState(false);
  const [modalEditNotificationTemplatelIsOpen, setModalEditNotificationTemplateIsOpen] = useState(false);
  const [modalDeleteNotificationTemplateIsOpen, setModalDeleteNotificationTemplateIsOpen] = useState(false);
  const [notificationTemplateClicked, setNotificationTemplateClicked] = useState<NotificationTemplate>(
    new NotificationTemplate(),
  );

  const openNewNotificationTemplateModal = useCallback(() => {
    setModalNewNotificationTemplateIsOpen(true);
  }, []);

  const closeNewNotificationTemplateModal = useCallback(() => {
    setModalNewNotificationTemplateIsOpen(false);
    resetErrors();
  }, []);

  const openEditNotificationTemplateModal = useCallback(() => {
    setModalEditNotificationTemplateIsOpen(true);
  }, []);

  const closeEditNotificationTemplateModal = useCallback(() => {
    setModalEditNotificationTemplateIsOpen(false);
    resetErrors();
  }, []);

  const openDeleteNotificationTemplateModal = useCallback(() => {
    setModalDeleteNotificationTemplateIsOpen(true);
  }, []);

  const closeDeleteNotificationTemplateModal = useCallback(() => {
    setModalDeleteNotificationTemplateIsOpen(false);
    resetErrors();
  }, []);

  useEffect(() => {
    getEmailTemplatesList();
    getEventTypes();
  }, []);

  const { tableColumns, tableActions } = useDataForTable(
    setNotificationTemplateClicked,
    openEditNotificationTemplateModal,
    openDeleteNotificationTemplateModal,
  );

  const handleMultiParamsChange = useParamsChange(setEmailTemplatesParams, dispatch);

  const handleSort = useCallback((sortBy, direction) => setEmailTemplatesParams({ sortBy, direction }), []);

  const filters = useMemo(
    () => ({
      eventTypes: allEventTypes,
    }),
    [allEventTypes],
  );

  const handleClear = useCallback(() => {
    setEmailTemplatesParams(new EmailTemplatesParams());
  }, []);

  const showClearButton = useMemo(() => !isEqual(params, new EmailTemplatesParams()), [params]);

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom">
            <div className="page__panel-bottom__wrapper--people">
              <AccessChecker verifiablePolicies={[UPDATE_NOTIFICATION_TEMPLATE]}>
                <Button externalClass={'button--with-icon'} onClick={openNewNotificationTemplateModal}>
                  <Icon iconName={'plus'} externalClass={'button__icon'} />
                  <span className="button__text">
                    <FormattedMessage {...messages.newButton} />
                  </span>
                </Button>
              </AccessChecker>
              <div className="page__panel-bottom__wrapper--left">
                <NotificationTemplatesFilter
                  filters={filters}
                  values={params}
                  setEmailTemplatesParams={setEmailTemplatesParams}
                  handleMultiParamsChange={handleMultiParamsChange}
                  handleClear={handleClear}
                  showClearButton={showClearButton}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <Table
            externalClass={'table'}
            tableColumns={tableColumns}
            tableData={tableData || []}
            loading={isLoading.getEmailTemplatesList}
            error={errors}
            tableActions={tableActions}
            onSort={handleSort}
            params={params}
          />
        </div>
      </div>
      {modalNewNotificationTemplateIsOpen && (
        <ModalNewNotificationTemplate
          isOpen
          isLoading={isLoading.createEmailTemplate}
          error={errors}
          createEmailTemplate={createEmailTemplate}
          onCloseRequest={closeNewNotificationTemplateModal}
          getEventTypes={getEventTypes}
          eventTypes={allEventTypes}
          notificationTemplates={tableData}
        />
      )}
      {modalEditNotificationTemplatelIsOpen && (
        <ModalEditNotificationTemplate
          isOpen
          isLoading={isLoading.createEmailTemplate}
          error={errors}
          editEmailTemplate={editEmailTemplate}
          onCloseRequest={closeEditNotificationTemplateModal}
          notificationTemplate={notificationTemplateClicked}
        />
      )}

      {modalDeleteNotificationTemplateIsOpen && (
        <ModalDeleteNotificationTemplate
          isOpen
          onCloseRequest={closeDeleteNotificationTemplateModal}
          onDeleteRequest={deleteEmailTemplate}
          notificationTemplate={notificationTemplateClicked}
          error={errors}
          isLoading={isLoading.deleteEmailTemplate}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ notifications, filters }: RootState) => ({
  tableData: notifications.emailTemplatesList?.content,
  allEventTypes: filters.eventTypesFilter.eventTypes,
  isLoading: notifications.loading,
  errors: notifications.errors.emailTemplatesListError,
  params: notifications.emailTemplatesParams,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getEmailTemplatesList: () => dispatch(notificationsActions.getEmailTemplatesList()),
  createEmailTemplate: (data: any) => dispatch(notificationsActions.createEmailTemplate(data)),
  editEmailTemplate: (data: any) => dispatch(notificationsActions.editEmailTemplate(data)),
  deleteEmailTemplate: (data: any) => dispatch(notificationsActions.deleteEmailTemplate(data)),
  resetErrors: () => dispatch(notificationsActions.resetErrors()),
  getEventTypes: () => dispatch(filtersActions.getEventTypesFilter()),
  setEmailTemplatesParams: (data: Partial<EmailTemplatesParams>) =>
    dispatch(notificationsActions.setEmailTemplatesParams(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(NotificationTemplates);
