import React, { useCallback, useEffect, useState } from 'react';

import Icon from '../../../Icon';
import Checkbox from '../../../Checkbox';
import { DashboardTopType, SubExpenseType } from '../../../../types/finance';
import classNames from 'classnames';

type ModalDasboardTypesProps = {
  isSub: boolean;
  types: any[];
  checked: DashboardTopType[];
  opened?: string[];
  changeType: (type: DashboardTopType, opened: string[]) => void;
  changeSubType: (type: DashboardTopType, subType: string, opened: string[]) => void;
};

function DasboardTypes({ types, isSub, opened = [], checked, changeType, changeSubType }: ModalDasboardTypesProps) {
  const [checkedTypes, setCheckedTypes] = useState<any[]>([]);
  const [openedTypes, setOpenedTypes] = useState<string[]>(opened);

  useEffect(() => {
    if (types.length && checked.length) {
      const typeIds = checked.map(check => check.id);
      const checkTypes = types.filter(type => typeIds.includes(type.id));
      const data = checkTypes.map(type => {
        const checkedTyps = checked.filter(it => it.id === type.id);
        return {
          id: type.id,
          name: type.name,
          ...(isSub
            ? {
                subTypes: checkedTyps.map(item => item.subTypes || item.id).flat(),
              }
            : {}),
        };
      });

      setCheckedTypes(data);
    }
  }, [checked, types]);

  const checkEverySubTypes = useCallback(
    type => {
      if (checkedTypes.length) {
        const checkedType = checkedTypes.find((item: DashboardTopType) => item.id === type.id);
        const selectedType = types.find(item => item.id === type.id);

        if (checkedType && (!isSub || checkedType.subTypes.length === selectedType?.subExpenseTypes.length)) {
          return true;
        }
      }

      return false;
    },
    [checkedTypes, checked],
  );

  return (
    <div className="dashboard-expenses-container">
      {types.map((type: any) => {
        const selectedType = checkedTypes.find((item: DashboardTopType) => item.id === type.id);

        return (
          <>
            <div
              className="dashboard-expenses"
              onClick={() => {
                if (isSub)
                  setOpenedTypes(prev =>
                    prev.includes(type.id) ? prev.filter(it => it !== type.id) : [...prev, type.id],
                  );
              }}
            >
              {isSub ? (
                <Icon
                  iconName={openedTypes.includes(type.id) ? 'chevron-down' : 'chevron-right'}
                  externalClass="dashboard-placeholder"
                />
              ) : null}
              <Checkbox
                onChange={() => changeType(type, openedTypes)}
                id={type.id}
                externalClass={classNames({ 'clear-all-checkbox': !checkEverySubTypes(type) }, 'checkbox-no-label')}
                iconName={checkEverySubTypes(type) ? undefined : 'clear-all-checkbox'}
                label={type.name}
                checked={!!selectedType}
                checkedValue={!!selectedType}
              />
            </div>
            {'subExpenseTypes' in type && openedTypes.includes(type.id)
              ? type.subExpenseTypes.map((subType: SubExpenseType, index: number) => (
                  <div className="dashboard-subexpenses" key={index}>
                    <Checkbox
                      externalClass="form__checkbox-capitalazie-label"
                      onChange={() => changeSubType(type, subType.id, openedTypes)}
                      id={subType.id}
                      label={subType.name}
                      checked={selectedType ? selectedType.subTypes.includes(subType.id) : false}
                      checkedValue={selectedType ? selectedType.subTypes.includes(subType.id) : false}
                    />
                  </div>
                ))
              : null}
          </>
        );
      })}
    </div>
  );
}

export default DasboardTypes;
