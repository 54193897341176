import React, { useCallback, useMemo, useState } from 'react';
import { OptionTypeBase } from 'react-select';
import Filter from '../../Filter';
import { useIntl } from 'react-intl';
import messages from '../messages';
import { DepartmentsInfoType } from '../../../types/libraries';
import { OfficeInfo } from '../../../enums/libraries.enum';
import { UserInfo } from '../../../enums/users.enum';
import { ActiveEmployeesParams } from '../../../enums/params/planning.params';
import { FilterParamsName, FilterTypes } from '../../../constants/filters.constants';
import { useFiltersListValue } from '../../../utils/hooks.utils';
import FilterClearButton from '../../FilterClearButton';
import FiltersControl from '../../FiltersControl';
import { SavedFilter, SavedFiltersDataType } from '../../../enums/filters.enum';
import RefreshButton from '../../RefreshButton';

type FiltersType = {
  users: UserInfo[];
  departments: DepartmentsInfoType[];
  offices: OfficeInfo[];
};

type ActiveEmployeesFiltersProps = {
  filters: FiltersType;
  values: ActiveEmployeesParams;
  handleParamsChange: (name: string) => (data: OptionTypeBase) => void;
  setActiveEmployeesParams: (data: Partial<ActiveEmployeesParams>) => void;
  handleMultiParamsChange: (name: string) => (data: OptionTypeBase) => void;
  handleUsersParamsChange: (name: string) => (data: OptionTypeBase) => void;
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) => void;
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) => void;
  deleteSavedFilter: (data: { id: string; callback: () => void }) => void;
  savedFiltersData: SavedFiltersDataType;
  authUserId: string;
  handleFiltersControlChange: (value: SavedFilter) => void;
  handleClear: () => void;
  resetSavedFilterErrors: () => void;
  showClearButton: boolean;
};

function ActiveEmployeesFilter({
  filters,
  values,
  handleParamsChange,
  setActiveEmployeesParams,
  handleMultiParamsChange,
  handleUsersParamsChange,
  createNewSavedFilter,
  editSavedFilter,
  deleteSavedFilter,
  savedFiltersData,
  authUserId,
  handleFiltersControlChange,
  handleClear,
  resetSavedFilterErrors,
  showClearButton,
}: ActiveEmployeesFiltersProps) {
  const intl = useIntl();

  const [resetCurrentFilter, setResetCurrentFilter] = useState(false);

  const statusOptions = useMemo(
    () => [
      { value: null, label: intl.formatMessage(messages.allLabel) },
      { value: true, label: intl.formatMessage(messages.yesLabel) },
      { value: false, label: intl.formatMessage(messages.noLabel) },
    ],
    [],
  );

  const officesOptions = useMemo(
    () =>
      filters.offices?.map(office => ({
        label: office.name,
        value: office.id,
      })),
    [filters.offices],
  );

  const departmentsOptions = useMemo(
    () =>
      filters.departments?.map(department => ({
        label: department.displayName,
        value: department.id,
      })),
    [filters.departments],
  );

  const usersOptions = useMemo(
    () =>
      filters.users?.map(user => ({
        label: user.fullName,
        value: user,
      })),
    [filters.users],
  );

  const usersValues = useFiltersListValue(usersOptions, values.userIds);

  const officeValues = useFiltersListValue(officesOptions, values.officeIds);

  const departmentValues = useFiltersListValue(departmentsOptions, values.departmentIds);

  const statusesValues = useMemo(() => statusOptions.filter(el => el.value === values.includedInPlanning), [
    values.includedInPlanning,
  ]);

  const onClear = useCallback(() => {
    setResetCurrentFilter(true);
    handleClear();
  }, []);

  const setResettFilterFlag = useCallback(() => {
    setResetCurrentFilter(false);
  }, []);

  return (
    <>
      <Filter
        isMulti
        label={intl.formatMessage(messages.officesLabel)}
        options={officesOptions}
        value={officeValues}
        handleChange={handleMultiParamsChange(FilterParamsName.OFFICE_IDS)}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        label={intl.formatMessage(messages.departmentsLabel)}
        options={departmentsOptions}
        value={departmentValues}
        handleChange={handleMultiParamsChange(FilterParamsName.DEPARTMENT_IDS)}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        isUsersFilter
        label={intl.formatMessage(messages.membersLabel)}
        options={usersOptions}
        value={usersValues}
        handleChange={handleUsersParamsChange(FilterParamsName.USER_IDS)}
        externalClass="filters__select"
      />
      <Filter
        defaultValue={statusOptions[0]}
        label={intl.formatMessage(messages.includedInPlanningTitle)}
        options={statusOptions}
        value={statusesValues[0]}
        handleChange={handleParamsChange('includedInPlanning')}
        externalClass="filters__select"
      />
      {showClearButton && <FilterClearButton onClear={onClear} />}
      <FiltersControl
        handleSaveFilter={createNewSavedFilter}
        handleUpdateFilter={editSavedFilter}
        handleDeleteFilter={deleteSavedFilter}
        savedFiltersData={savedFiltersData}
        authUserId={authUserId}
        filterType={FilterTypes.ACTIVE_MEMBERS_FILTER}
        handleChange={handleFiltersControlChange}
        params={values}
        resetSavedFilterErrors={resetSavedFilterErrors}
        resetCurrentFilter={resetCurrentFilter}
        setResettFilterFlag={setResettFilterFlag}
      />
      <RefreshButton onRefresh={() => setActiveEmployeesParams(values)} />
    </>
  );
}

export default React.memo(ActiveEmployeesFilter);
