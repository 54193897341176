import React, { useCallback, useMemo, useState } from 'react';
import { OptionTypeBase } from 'react-select';
import { useIntl } from 'react-intl';
import messages from '../messages';
import Filter from '../../Filter';
import { UserInfo } from '../../../enums/users.enum';
import Checkbox from '../../Checkbox';
import { ProjectsReportParams } from '../../../enums/params/reports.params';
import {
  ProjectsGroupBy,
  projectsReportUnsavedParams,
  ReportUsersStatuses,
  roundingModeOptions,
} from '../../../constants/reports.constants';
import { FilterParamsName, FilterTypes } from '../../../constants/filters.constants';
import { useFiltersListValue } from '../../../utils/hooks.utils';
import FiltersControl from '../../FiltersControl';
import { SavedFilter, SavedFiltersDataType } from '../../../enums/filters.enum';
import FilterClearButton from '../../FilterClearButton';
import RefreshButton from '../../RefreshButton';

type ProjectsReportFiltersProps = {
  filters: any;
  setProjectsReportParams: (data: Partial<ProjectsReportParams>) => void;
  handleUsersParamsChange: (name: string) => (data: OptionTypeBase) => void;
  handleMultiParamsChange: (name: string) => (data: OptionTypeBase) => void;
  handleRoundingModeChange: (name: string) => (data: any) => void;
  handleFoldSubTasks: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleGroupByChange: (value: any) => void;
  handleUsersStatuses: (data: OptionTypeBase | OptionTypeBase[]) => void;
  values: ProjectsReportParams;
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) => void;
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) => void;
  deleteSavedFilter: (data: { id: string; callback: () => void }) => void;
  savedFiltersData: SavedFiltersDataType;
  authUserId: string;
  handleFiltersControlChange: (value: SavedFilter) => void;
  handleClear: () => void;
  resetSavedFilterErrors: () => void;
  showClearButton: boolean;
};

const ProjectsReportFilter = ({
  filters,
  values,
  handleGroupByChange,
  handleUsersParamsChange,
  handleFoldSubTasks,
  handleRoundingModeChange,
  handleUsersStatuses,
  setProjectsReportParams,
  handleMultiParamsChange,
  createNewSavedFilter,
  editSavedFilter,
  deleteSavedFilter,
  savedFiltersData,
  authUserId,
  handleFiltersControlChange,
  handleClear,
  resetSavedFilterErrors,
  showClearButton,
}: ProjectsReportFiltersProps) => {
  const intl = useIntl();

  const [resetCurrentFilter, setResetCurrentFilter] = useState(false);

  const employeesOptions = useMemo(
    () =>
      filters.users
        .filter((user: UserInfo) => user.jiraUsername)
        .map((user: UserInfo) => ({
          label: user.fullName,
          value: user,
        })),
    [filters.users],
  );

  const usersStatusesOptions = useMemo(
    () => [
      {
        label: intl.formatMessage(messages.activeLabel),
        value: ReportUsersStatuses.ACTIVE,
      },
      {
        label: intl.formatMessage(messages.inactiveLabel),
        value: ReportUsersStatuses.INACITVE,
      },
      {
        label: intl.formatMessage(messages.externalLabel),
        value: ReportUsersStatuses.EXTERNAL,
      },
    ],
    [],
  );

  const jiraProjectsOptions = useMemo(
    () => filters.jiraProjectsList?.map(({ id, name }: { id: string; name: string }) => ({ value: id, label: name })),
    [filters.jiraProjectsList],
  );

  const groupByOptions = useMemo(
    () => [
      {
        label: intl.formatMessage(messages.groupByComponents),
        value: ProjectsGroupBy.GROUP_BY_COMPONENTS,
      },
      {
        label: intl.formatMessage(messages.groupByMembers),
        value: ProjectsGroupBy.GROUP_BY_MEMBERS,
      },
    ],
    [],
  );

  const employeeValues = useFiltersListValue(employeesOptions, values.portalUserIds);

  const jiraProjectsValues = useFiltersListValue(jiraProjectsOptions, values.jiraProjectIds);

  const roundingModeValues = useMemo(() => roundingModeOptions.find(el => el.value === values.roundingMode), [
    values.roundingMode,
  ]);

  const groupByValues = useMemo(() => {
    const value: any = [];
    groupByOptions.forEach(el => {
      if (el.value === ProjectsGroupBy.GROUP_BY_COMPONENTS && values.isGroupByComponents) {
        value.push(el);
      }
      if (el.value === ProjectsGroupBy.GROUP_BY_MEMBERS && values.isGroupByEmployee) {
        value.push(el);
      }
    });
    return value;
  }, [values.isGroupByComponents, values.isGroupByEmployee]);

  const usersStatusesValues = useMemo(() => {
    const value: any = [];

    usersStatusesOptions.forEach(el => {
      if (el.value === ReportUsersStatuses.ACTIVE && values.isAllowActive) {
        value.push(el);
      }
      if (el.value === ReportUsersStatuses.INACITVE && values.isAllowPassive) {
        value.push(el);
      }
      if (el.value === ReportUsersStatuses.EXTERNAL && values.isAllowAlien) {
        value.push(el);
      }
    });
    return value;
  }, [values.isAllowActive, values.isAllowPassive, values.isAllowAlien]);

  const onClear = useCallback(() => {
    setResetCurrentFilter(true);
    handleClear();
  }, [values]);

  const setResettFilterFlag = useCallback(() => {
    setResetCurrentFilter(false);
  }, []);

  return (
    <>
      <Filter
        isMulti
        label={intl.formatMessage(messages.projectsLabel)}
        options={jiraProjectsOptions}
        handleChange={handleMultiParamsChange(FilterParamsName.JIRA_PROJECT_IDS)}
        value={jiraProjectsValues}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        isUsersFilter
        label={intl.formatMessage(messages.employeesLabel)}
        options={employeesOptions}
        handleChange={handleUsersParamsChange(FilterParamsName.PORTAL_USER_IDS)}
        value={employeeValues}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        label={intl.formatMessage(messages.statusLabel)}
        options={usersStatusesOptions}
        defaultValue={[usersStatusesOptions[0]]}
        handleChange={handleUsersStatuses}
        value={usersStatusesValues}
        externalClass="filters__select"
      />
      <Filter
        label=""
        options={roundingModeOptions}
        handleChange={handleRoundingModeChange('roundingMode')}
        defaultValue={roundingModeOptions[0]}
        value={roundingModeValues}
        externalClass="filters__select"
      />
      <Filter
        label="No Grouping"
        isMulti
        allLabel={false}
        options={groupByOptions}
        handleChange={handleGroupByChange}
        value={groupByValues}
        externalClass="filters__select"
      />
      <div className="check_box_wrapper filter">
        <Checkbox
          label={intl.formatMessage(messages.sumSubtaskTitle)}
          id="isFoldSubtasks"
          externalClass="checkbox-label"
          onChange={e => handleFoldSubTasks(e)}
          checkedValue={values.isFoldSubtasks}
        />
      </div>
      {showClearButton && <FilterClearButton onClear={onClear} />}
      <FiltersControl
        handleSaveFilter={createNewSavedFilter}
        handleUpdateFilter={editSavedFilter}
        handleDeleteFilter={deleteSavedFilter}
        savedFiltersData={savedFiltersData}
        authUserId={authUserId}
        filterType={FilterTypes.PROJECTS_REPORT_FILTER}
        handleChange={handleFiltersControlChange}
        params={values}
        resetSavedFilterErrors={resetSavedFilterErrors}
        unsavedParams={projectsReportUnsavedParams}
        resetCurrentFilter={resetCurrentFilter}
        setResettFilterFlag={setResettFilterFlag}
      />
      <RefreshButton onRefresh={() => setProjectsReportParams(values)} />
    </>
  );
};

export default React.memo(ProjectsReportFilter);
