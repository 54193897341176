import React, { useCallback, useMemo, useState } from 'react';
import { OptionTypeBase } from 'react-select';
import Filter from '../../Filter';
import { useIntl } from 'react-intl';
import messages from '../messages';
import { CompetenciesInfo } from '../../../enums/competencies.enum';
import { UserInfo } from '../../../enums/users.enum';
import { FilterParamsName, FilterTypes } from '../../../constants/filters.constants';
import { useFiltersListValue } from '../../../utils/hooks.utils';
import { AssessmentsParams } from '../../../enums/params/competencies.params';
import FilterClearButton from '../../FilterClearButton';
import FiltersControl from '../../FiltersControl';
import SortSelect from '../../SortSelect';
import { SavedFilter, SavedFiltersDataType } from '../../../enums/filters.enum';
import { assessmentsUnsavedParams } from '../../../constants/competencies.contsants';
import { SortParams } from '../../../enums/params.enum';
import RefreshButton from '../../RefreshButton';

type FiltersType = {
  competencies: CompetenciesInfo[];
  users: UserInfo[];
};

type AssessmentsFiltersProps = {
  filters: FiltersType;
  values: AssessmentsParams;
  handleMultiParamsChange: (name: string) => (data: OptionTypeBase) => void;
  handleUsersParamsChange: (name: string) => (data: OptionTypeBase) => void;
  setAssessmentsParams: (data: Partial<AssessmentsParams>) => void;
  handleSort: (sortBy: string, direction: string) => void;
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) => void;
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) => void;
  deleteSavedFilter: (data: { id: string; callback: () => void }) => void;
  savedFiltersData: SavedFiltersDataType;
  authUserId: string;
  handleFiltersControlChange: (value: SavedFilter) => void;
  handleClear: () => void;
  resetSavedFilterErrors: () => void;
  showClearButton: boolean;
};

function AssessmentsFilters({
  filters,
  values,
  handleMultiParamsChange,
  handleUsersParamsChange,
  setAssessmentsParams,
  handleSort,
  createNewSavedFilter,
  editSavedFilter,
  deleteSavedFilter,
  savedFiltersData,
  authUserId,
  handleFiltersControlChange,
  handleClear,
  resetSavedFilterErrors,
  showClearButton,
}: AssessmentsFiltersProps) {
  const intl = useIntl();

  const [resetCurrentFilter, setResetCurrentFilter] = useState(false);

  const employeesOptions = useMemo(
    () =>
      filters.users?.map(user => ({
        value: user,
        label: user.fullName,
      })),
    [filters.users],
  );

  const competenciesOptions = useMemo(
    () =>
      filters.competencies?.map(el => ({
        value: el.id,
        label: el.name,
      })),
    [filters.competencies],
  );

  const employeesValues = useFiltersListValue(employeesOptions, values.employees);

  const reviewersValues = useFiltersListValue(employeesOptions, values.reviewers);

  const organizersValues = useFiltersListValue(employeesOptions, values.organizers);

  const competenciesValues = useFiltersListValue(competenciesOptions, values.competencies);

  const onClear = useCallback(() => {
    setResetCurrentFilter(true);
    handleClear();
  }, [values]);

  const setResettFilterFlag = useCallback(() => {
    setResetCurrentFilter(false);
  }, []);

  return (
    <>
      <SortSelect
        sortOptions={[
          { label: intl.formatMessage(messages.assessmentDateTitle), value: 'assessmentDate' },
          { label: intl.formatMessage(messages.employeesLabel), value: 'employee.secondName' },
          { label: intl.formatMessage(messages.competenciesTitle), value: 'competence.name' },
          { label: intl.formatMessage(messages.lastUpdatesTitle), value: 'lastUpdateDate' },
          { label: intl.formatMessage(messages.updatersTitle), value: 'updater.secondName' },
        ]}
        params={new SortParams('', { sortBy: values.sortBy, direction: values.direction })}
        onSort={handleSort}
      />
      <Filter
        isMulti
        isUsersFilter
        label={intl.formatMessage(messages.employeesLabel)}
        options={employeesOptions}
        value={employeesValues}
        handleChange={handleUsersParamsChange(FilterParamsName.EMPLOYEES)}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        isUsersFilter
        label={intl.formatMessage(messages.reviewersFilter)}
        options={employeesOptions}
        value={reviewersValues}
        handleChange={handleUsersParamsChange('reviewers')}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        isUsersFilter
        label={intl.formatMessage(messages.organizersFilter)}
        options={employeesOptions}
        value={organizersValues}
        handleChange={handleUsersParamsChange('organizers')}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        label={intl.formatMessage(messages.competenciesTitle)}
        options={competenciesOptions}
        value={competenciesValues}
        handleChange={handleMultiParamsChange(FilterParamsName.COMPETENCIES)}
        externalClass="filters__select"
      />
      {showClearButton && <FilterClearButton onClear={onClear} />}
      <FiltersControl
        handleSaveFilter={createNewSavedFilter}
        handleUpdateFilter={editSavedFilter}
        handleDeleteFilter={deleteSavedFilter}
        savedFiltersData={savedFiltersData}
        authUserId={authUserId}
        filterType={FilterTypes.ASSESSMENTS_FILTER}
        handleChange={handleFiltersControlChange}
        params={values}
        resetSavedFilterErrors={resetSavedFilterErrors}
        unsavedParams={assessmentsUnsavedParams}
        resetCurrentFilter={resetCurrentFilter}
        setResettFilterFlag={setResettFilterFlag}
      />
      <RefreshButton onRefresh={() => setAssessmentsParams(values)} />
    </>
  );
}

export default React.memo(AssessmentsFilters);
