import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as recognitionActions from '../../actions/recognitions.actions';
import Table from '../../components/Table';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import ModalNewRecognitionType from '../../components/RecognitionTypes/Modals/ModalNewRecognitionType';
import ModalEditRecognitionType from '../../components/RecognitionTypes/Modals/ModalEditRecognitionType';
import ModalDeleteRecognitionType from '../../components/RecognitionTypes/Modals/ModalDeleteRecognitionType';
import RecognitionIcon from '../../components/RecognitionIcon';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { RecognitionTypesParams } from '../../enums/params/recognitions.params';
import AccessChecker from '../../components/AccessChecker';
import { DELETE_RECOGNITION_TYPE, UPDATE_RECOGNITION_TYPE } from '../../constants/policies.constants';
import RefreshButton from '../../components/RefreshButton';

export type RecognitionDataType = {
  name: string;
  icon: string;
  id: string;
  backgroundColor: string;
};

function RecognitionTypes({
  getRecognitionTypeList,
  createNewRecognitionType,
  editRecognitionType,
  deleteRecognitionType,
  recognitionTypeError,
  setRecognitionTypesParams,
  sortParams,
  isLoading,
  tableData,
  resetErrors,
  resetState,
}: ConnectedProps<typeof connector>) {
  const [modalNewRecognitionTypeIsOpen, setModalNewRecognitionTypeIsOpen] = useState(false);
  const [modalEditRecognitionTypeIsOpen, setModalEditRecognitionTypeIsOpen] = useState(false);
  const [modalDeleteRecognitionTypeIsOpen, setModalDeleteRecognitionTypeIsOpen] = useState(false);
  const [recognitionTypeClicked, setRecognitionTypeClicked] = useState<RecognitionDataType>({
    name: '',
    icon: '',
    id: '',
    backgroundColor: '',
  });

  const intl = useIntl();

  const handleSort = useCallback((sortBy, direction) => setRecognitionTypesParams({ sortBy, direction }), []);

  useEffect(() => {
    getRecognitionTypeList();
    return () => {
      resetState();
    };
  }, []);

  const openNewRecognitionTypeModal = useCallback(() => {
    setModalNewRecognitionTypeIsOpen(true);
  }, []);

  const closeNewRecognitionTypeModal = useCallback(() => {
    setModalNewRecognitionTypeIsOpen(false);
  }, []);

  const openEditRecognitionTypeModal = useCallback(() => {
    setModalEditRecognitionTypeIsOpen(true);
  }, []);

  const closeEditRecognitionTypeModal = useCallback(() => {
    setModalEditRecognitionTypeIsOpen(false);
  }, []);

  const openDeleteRecognitionTypeModal = useCallback(() => {
    setModalDeleteRecognitionTypeIsOpen(true);
  }, []);

  const closeDeleteRecognitionTypeModal = useCallback(() => {
    setModalDeleteRecognitionTypeIsOpen(false);
  }, []);

  const setRecognitionTypeCallback = useCallback(() => {
    setRecognitionTypeClicked({
      name: '',
      icon: '',
      id: '',
      backgroundColor: '',
    });
  }, []);

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.nameColumn),
        sortName: 'name',
        modifier: (row: RecognitionDataType) => (
          <div className={'table__data-wrapper'}>
            <RecognitionIcon icon={row.icon} color={`#${row.backgroundColor}`} />
            <span>{row.name}</span>
          </div>
        ),
      },
    ],
    [],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: RecognitionDataType) => {
          setRecognitionTypeClicked(row);
          openEditRecognitionTypeModal();
        },
        verifiablePolicies: [UPDATE_RECOGNITION_TYPE],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: RecognitionDataType) => {
          setRecognitionTypeClicked(row);
          openDeleteRecognitionTypeModal();
        },
        verifiablePolicies: [DELETE_RECOGNITION_TYPE],
      },
    ],
    [],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom no-border">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[UPDATE_RECOGNITION_TYPE]}>
                  <Button externalClass={'button--with-icon'} onClick={openNewRecognitionTypeModal}>
                    <Icon iconName={'plus'} externalClass={'button__icon'} />
                    <span className="button__text">
                      <FormattedMessage {...messages.newButton} />
                    </span>
                  </Button>
                </AccessChecker>
                <RefreshButton onRefresh={() => setRecognitionTypesParams(sortParams)} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <Table
            externalClass={'table table--half'}
            tableColumns={tableColumns}
            tableData={tableData || []}
            loading={isLoading.getRecognitionType}
            error={recognitionTypeError}
            tableActions={tableActions}
            params={sortParams}
            onSort={handleSort}
          />
        </div>
      </div>
      {modalNewRecognitionTypeIsOpen && (
        <ModalNewRecognitionType
          isOpen
          onCloseRequest={closeNewRecognitionTypeModal}
          createNewRecognitionType={createNewRecognitionType}
          recognitionTypeError={recognitionTypeError}
          isLoading={isLoading.createRecognitionType}
          resetErrors={resetErrors}
        />
      )}
      {modalEditRecognitionTypeIsOpen && (
        <ModalEditRecognitionType
          isOpen
          onCloseRequest={closeEditRecognitionTypeModal}
          editRecognitionType={editRecognitionType}
          recognitionTypeData={recognitionTypeClicked}
          recognitionTypeError={recognitionTypeError}
          isLoading={isLoading.editRecognitionType}
          resetErrors={resetErrors}
        />
      )}
      {modalDeleteRecognitionTypeIsOpen && (
        <ModalDeleteRecognitionType
          isOpen
          onCloseRequest={closeDeleteRecognitionTypeModal}
          onDeleteRequest={(data: Record<string, unknown>) => {
            deleteRecognitionType({
              ...data,
              setRecognitionTypeCallback,
            });
          }}
          isLoading={isLoading.deleteRecognitionType}
          recognitionTypeError={recognitionTypeError}
          recognitionTypeData={recognitionTypeClicked}
          resetErrors={resetErrors}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ recognitions }: RootState) => ({
  tableData: recognitions.recognitionTypeTableData,
  recognitionTypeError: recognitions.errors.recognitionTypeError,
  sortParams: recognitions.recognitionTypesParams,
  isLoading: recognitions.loading,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getRecognitionTypeList: () => dispatch(recognitionActions.getRecognitionTypeList()),
  deleteRecognitionType: (data: Record<string, unknown>) => dispatch(recognitionActions.deleteRecognitionType(data)),
  createNewRecognitionType: (data: Record<string, unknown>) =>
    dispatch(recognitionActions.createNewRecognitionType(data)),
  editRecognitionType: (id: string, data: Record<string, unknown>) =>
    dispatch(recognitionActions.editRecognitionType({ ...data, id })),
  setRecognitionTypesParams: (params: Partial<RecognitionTypesParams>) =>
    dispatch(recognitionActions.setRecognitionTypesParams(params)),
  resetErrors: () => dispatch(recognitionActions.resetErrors()),
  resetState: () => dispatch(recognitionActions.resetState()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(RecognitionTypes);
