import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import * as competenciesActions from '../../actions/competencies.actions';
import * as filtersActions from '../../actions/filters.actions';
import Table from '../../components/Table';
import moment from 'moment';
import ModalNewAssessment from '../../components/Assessments/Modals/ModalNewAssessment';
import ModalDeleteAssessment from '../../components/Assessments/Modals/ModalDeleteAssessment';
import ModalEditAssessment from '../../components/Assessments/Modals/ModalEditAssessment';
import AssessmentsFilters from '../../components/Assessments/Filters/Grid';
import Pagination from '../../components/Pagination';
import InlineDatePicker from '../../components/InlineDatePicker';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { AssessmentsInfo, ResultType } from '../../enums/competencies.enum';
import { AssessmentsParams } from '../../enums/params/competencies.params';
import TableUserAvatar from '../../components/TableUserAvatar';
import { DELETE_ASSESSMENT, UPDATE_ASSESSMENT } from '../../constants/policies.constants';
import AccessChecker from '../../components/AccessChecker';
import { DATE_FORMAT } from '../../constants/date.constants';
import { resetParamsChange, useParamsChange, useUsersParamsChange } from '../../utils/hooks.utils';
import { EventsParams } from '../../enums/params/schedule.params';
import r from '../../constants/routes.constants';
import { SavedFilterParams } from '../../enums/params/filters.params';
import { SavedFilter } from '../../enums/filters.enum';
import {
  checkParamsMatch,
  convertSavedFieldsToParams,
  getSavedFilterParams,
  getSavedFilters,
} from '../../utils/filters.utils';
import { FilterParamsName, FilterTypes } from '../../constants/filters.constants';
import { assessmentsUnsavedParams } from '../../constants/competencies.contsants';

function Assessments({
  assessmentsDataError,
  assessmentsData,
  lastAssessmentResults,
  getLastAssessmentResults,
  usersFilter,
  params,
  loading,
  competenciesFilter,
  eventList,
  eventsLoading,
  editAssessmentError,
  createAssessmentError,
  resetErrors,
  deleteAssessment,
  getUsersFilter,
  createNewAssessment,
  editAssessment,
  setAssessmentsParams,
  getCompetenciesFilter,
  getEventsForTargetEmployee,
  resetEventsFilter,
  getAssessments,
  resetState,
  resetLastAssessmentResults,
  setSavedFiltersParams,
  createNewSavedFilter,
  savedFiltersData,
  editSavedFilter,
  deleteSavedFilter,
  resetSavedFilterErrors,
  authUserId,
}: ConnectedProps<typeof connector>) {
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    const currentSavedFilter = getSavedFilterParams(getSavedFilters(), FilterTypes.ASSESSMENTS_FILTER);

    setAssessmentsParams(
      currentSavedFilter
        ? new AssessmentsParams(currentSavedFilter)
        : {
            employees: usersFilter.value,
            competencies: competenciesFilter.value,
          },
    );
    getCompetenciesFilter();
    getUsersFilter();
    setSavedFiltersParams({ filterType: FilterTypes.ASSESSMENTS_FILTER });

    return () => {
      resetState();
    };
  }, []);

  const [modalNewAssessmentIsOpen, setModalNewAssessmentIsOpen] = useState(false);
  const [modalEditAssessmentIsOpen, setModalEditAssessmentIsOpen] = useState(false);
  const [modalDeleteAssessmentIsOpen, setModalDeleteAssessmentIsOpen] = useState(false);
  const [clickedAssessment, setClickedAssessment] = useState<AssessmentsInfo>(new AssessmentsInfo({}));
  const openEditAssessmentModal = useCallback(() => {
    setModalEditAssessmentIsOpen(true);
  }, []);

  const closeEditAssessmentModal = useCallback(() => {
    modalEditAssessmentIsOpen && resetEventsFilter();
    setModalEditAssessmentIsOpen(false);
  }, [modalEditAssessmentIsOpen]);

  const closeNewAssessmentModal = useCallback(() => {
    modalNewAssessmentIsOpen && resetEventsFilter();
    setModalNewAssessmentIsOpen(false);
  }, [modalNewAssessmentIsOpen]);

  const openDeleteAssessmentModal = useCallback(() => {
    setModalDeleteAssessmentIsOpen(true);
  }, []);

  const closeDeleteAssessmentModal = useCallback(() => {
    setModalDeleteAssessmentIsOpen(false);
  }, []);

  const handleMultiParamsChange = useParamsChange(setAssessmentsParams, dispatch);

  const handleUsersParamsChange = useUsersParamsChange(setAssessmentsParams, dispatch);

  const handlePageChange = useCallback(({ selected }) => {
    setAssessmentsParams({ page: selected });
  }, []);

  const handleSizeChange = useCallback(data => {
    setAssessmentsParams({ size: data, page: 0 });
  }, []);

  const countResultsPercentage = useCallback((results = [], maxScore = 0) => {
    const sum = results.reduce((sum: number, current: ResultType) => sum + current.score, 0);
    return maxScore !== 0 ? `${Math.floor(+(Math.floor(sum) / maxScore).toFixed(2) * 100)}%` : ``;
  }, []);

  const normalizeDateFormat = (date: any, format: string) => {
    return moment(date).isValid() ? `${moment.utc(date).local().format(format)}` : '-';
  };

  const onDateChange = (start: string, end: string) => {
    setAssessmentsParams({ dateFrom: start, dateTo: end, page: 0 });
  };

  const createAssessment = useCallback(({ data, cb }: { data: AssessmentsInfo; cb: () => void }) => {
    createNewAssessment({
      data,
      callback: () => {
        closeNewAssessmentModal();
        getAssessments();
        cb();
      },
    });
  }, []);

  const updateAssessment = useCallback(({ data, cb }: { data: AssessmentsInfo; cb: () => void }) => {
    editAssessment({
      data,
      callback: () => {
        closeEditAssessmentModal();
        getAssessments();
        cb();
      },
    });
  }, []);

  const onDeleteAssessmentRequest = useCallback(id => {
    deleteAssessment({
      id,
      callback: () => {
        setModalDeleteAssessmentIsOpen(false);
        setClickedAssessment(new AssessmentsInfo({}));
        getAssessments();
      },
    });
  }, []);

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: AssessmentsInfo) => {
          setClickedAssessment(row);
          openEditAssessmentModal();
        },
        verifiablePolicies: [UPDATE_ASSESSMENT],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: AssessmentsInfo) => {
          setClickedAssessment(row);
          openDeleteAssessmentModal();
        },
        verifiablePolicies: [DELETE_ASSESSMENT],
      },
    ],
    [],
  );

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.assessmentDateTitle),
        modifier(row: AssessmentsInfo) {
          return (
            <NavLink className={'table__type-link'} to={`assessments/information/${row.id}`}>
              {normalizeDateFormat(row.assessmentDate, DATE_FORMAT.ll)}
            </NavLink>
          );
        },
      },
      {
        name: intl.formatMessage(messages.employeesLabel),
        modifier: (row: AssessmentsInfo) => <TableUserAvatar users={[row.employee]} fileSize={48} />,
      },
      {
        name: intl.formatMessage(messages.competenciesTitle),
        modifier: (row: AssessmentsInfo) =>
          `${row.competence?.name} ${countResultsPercentage(row.results, row.competence?.maxScore)}`,
      },
      {
        name: intl.formatMessage(messages.relatedEventTitle),
        modifier: (row: AssessmentsInfo) => {
          const title = row.event.eventTableLinkTitle;
          return row.eventId ? (
            row.event.isUserHaveAccess ? (
              <NavLink className={'table__type-link'} to={r.eventInformation.replace(':id', `${row.eventId}`)}>
                {title}
              </NavLink>
            ) : (
              <span>{title}</span>
            )
          ) : null;
        },
      },
      {
        name: intl.formatMessage(messages.organizersTitle),
        modifier: (row: AssessmentsInfo) => <TableUserAvatar users={row.organizers} fileSize={48} selectedTableUser />,
      },
      {
        name: intl.formatMessage(messages.reviewersTitle),
        modifier: (row: AssessmentsInfo) => <TableUserAvatar users={row.reviewers} fileSize={48} selectedTableUser />,
      },
      {
        name: intl.formatMessage(messages.lastUpdatesTitle),
        modifier: (row: AssessmentsInfo) => `${normalizeDateFormat(row.lastUpdateDate, 'lll')}`,
      },
      {
        name: intl.formatMessage(messages.updatersTitle),
        modifier: (row: AssessmentsInfo) => <TableUserAvatar users={[row.updater]} fileSize={48} />,
        sortName: 'updater.secondName',
      },
    ],
    [],
  );

  const handleSort = useCallback((sortBy, direction) => setAssessmentsParams({ sortBy, direction }), []);

  const filters = useMemo(() => ({ competencies: competenciesFilter.competencies, users: usersFilter.users }), [
    competenciesFilter.competencies,
    usersFilter.users,
  ]);

  const handleFiltersControlChange = useCallback(
    value => {
      setAssessmentsParams(
        new AssessmentsParams({
          ...convertSavedFieldsToParams(value.fields),
          dateFrom: params.dateFrom,
          dateTo: params.dateTo,
        }),
      );
    },
    [params],
  );

  const handleClear = useCallback(() => {
    setAssessmentsParams(new AssessmentsParams({ dateFrom: params.dateFrom, dateTo: params.dateTo }));

    resetParamsChange([FilterParamsName.EMPLOYEES, FilterParamsName.COMPETENCIES], dispatch);
  }, [params]);

  const showClearButton = useMemo(() => !checkParamsMatch(params, new AssessmentsParams(), assessmentsUnsavedParams), [
    params,
  ]);

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
            <InlineDatePicker
              onDateChange={onDateChange}
              defaultPeriodStart={params.dateFrom}
              defaultPeriodEnd={params.dateTo}
            />
          </div>
          <div className="page__panel-bottom page__panel-bottom__wrapper--people">
            <div className="page__panel-bottom__wrapper--left">
              <AccessChecker verifiablePolicies={[UPDATE_ASSESSMENT]}>
                <Button externalClass={'button--with-icon'} onClick={() => setModalNewAssessmentIsOpen(true)}>
                  <Icon iconName={'plus'} externalClass={'button__icon'} />
                  <span className="button__text">
                    <FormattedMessage {...messages.newButton} />
                  </span>
                </Button>
              </AccessChecker>
              <AssessmentsFilters
                filters={filters}
                values={params}
                handleMultiParamsChange={handleMultiParamsChange}
                handleUsersParamsChange={handleUsersParamsChange}
                createNewSavedFilter={createNewSavedFilter}
                savedFiltersData={savedFiltersData}
                authUserId={authUserId}
                deleteSavedFilter={deleteSavedFilter}
                editSavedFilter={editSavedFilter}
                handleFiltersControlChange={handleFiltersControlChange}
                handleClear={handleClear}
                setAssessmentsParams={setAssessmentsParams}
                resetSavedFilterErrors={resetSavedFilterErrors}
                handleSort={handleSort}
                showClearButton={showClearButton}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <Table
            externalClass={'table'}
            tableColumns={tableColumns}
            tableData={assessmentsData?.content || []}
            loading={loading.getAssessments}
            error={assessmentsDataError}
            tableActions={tableActions}
          />
          <Pagination
            pageable={{
              offset: assessmentsData?.pageable?.offset,
              empty: assessmentsData?.empty,
              totalPages: assessmentsData?.totalPages,
              totalElements: assessmentsData?.totalElements,
              numberOfElements: assessmentsData?.numberOfElements,
              pageSize: assessmentsData?.pageable?.pageSize,
            }}
            onPageChange={(data: any) => handlePageChange(data)}
            onPageSizeChange={(data: any) => handleSizeChange(data)}
          />
        </div>
      </div>
      {modalNewAssessmentIsOpen && (
        <ModalNewAssessment
          isOpen
          onCloseRequest={closeNewAssessmentModal}
          lastAssessmentResults={lastAssessmentResults}
          getLastAssessmentResults={getLastAssessmentResults}
          createNewAssessment={createAssessment}
          users={usersFilter.users}
          assessmentError={createAssessmentError}
          isLoading={loading.createNewAssessment}
          competencies={competenciesFilter.competencies}
          getEventsForTargetEmployee={getEventsForTargetEmployee}
          events={eventList}
          eventsLoading={eventsLoading}
          resetErrors={resetErrors}
          resetLastAssessmentResults={resetLastAssessmentResults}
        />
      )}
      {modalEditAssessmentIsOpen && (
        <ModalEditAssessment
          isOpen
          onCloseRequest={closeEditAssessmentModal}
          editAssessment={updateAssessment}
          users={usersFilter.users}
          assessmentError={editAssessmentError}
          isLoading={loading.editAssessment}
          assessment={clickedAssessment}
          events={eventList}
          eventsLoading={eventsLoading}
          resetErrors={resetErrors}
          lastAssessmentResults={lastAssessmentResults}
          getEventsForTargetEmployee={getEventsForTargetEmployee}
          getLastAssessmentResults={getLastAssessmentResults}
          resetLastAssessmentResults={resetLastAssessmentResults}
        />
      )}
      {modalDeleteAssessmentIsOpen && (
        <ModalDeleteAssessment
          isOpen
          onCloseRequest={closeDeleteAssessmentModal}
          assessmentError={assessmentsDataError}
          assessmentData={clickedAssessment}
          onDeleteRequest={onDeleteAssessmentRequest}
          isLoading={loading.deleteAssessment}
          resetErrors={resetErrors}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ competencies, users, filters, auth }: RootState) => ({
  assessmentsData: competencies.assessmentsTableData,
  lastAssessmentResults: competencies.lastAssessmentData,
  assessmentsDataError: competencies.errors?.assessmentsDataError,
  createAssessmentError: competencies.errors?.createAssessmentError,
  editAssessmentError: competencies.errors?.editAssessmentError,
  usersFilter: filters.usersFilter,
  error: users.errors.users,
  loading: competencies.loading,
  competenciesFilter: filters.competenciesFilter,
  params: competencies.assessmentsParams,
  eventList: filters.eventsFilter.events,
  eventsLoading: filters.eventsFilter.loading,
  savedFiltersData: filters.savedFilters,
  authUserId: auth.currentUserInfo.id,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getAssessments: () => dispatch(competenciesActions.getFilteredAssessments()),
  getUsersFilter: () => dispatch(filtersActions.getUsersFilter()),
  getCompetenciesFilter: () => dispatch(filtersActions.getCompetenciesFilter()),
  deleteAssessment: (data: { id: string; callback: () => void }) =>
    dispatch(competenciesActions.deleteAssessment(data)),
  createNewAssessment: (data: any) => dispatch(competenciesActions.createNewAssessment(data)),
  getLastAssessmentResults: (data: any) => dispatch(competenciesActions.getLastAssessmentResults(data)),
  editAssessment: (data: any) => dispatch(competenciesActions.editAssessment(data)),
  setAssessmentsParams: (data: Partial<AssessmentsParams>) => dispatch(competenciesActions.setAssessmentsParams(data)),
  getEventsForTargetEmployee: (data: Partial<EventsParams>) => dispatch(filtersActions.getEventsFilter(data)),
  resetEventsFilter: () => dispatch(filtersActions.resetEventsFilter()),
  resetErrors: () => dispatch(competenciesActions.resetErrors()),
  resetState: () => dispatch(competenciesActions.resetState()),
  resetLastAssessmentResults: () => dispatch(competenciesActions.resetLastAssessmentResults()),
  setSavedFiltersParams: (data: Partial<SavedFilterParams>) => dispatch(filtersActions.setSavedFiltersParams(data)),
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) =>
    dispatch(filtersActions.createNewSavedFilter(data)),
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) =>
    dispatch(filtersActions.editSavedFilter(data)),
  deleteSavedFilter: (data: { id: string; callback: () => void }) => dispatch(filtersActions.deleteSavedFilter(data)),
  resetSavedFilterErrors: () => dispatch(filtersActions.resetSavedFilterErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Assessments);
