import React, { useCallback, useContext, useMemo, useState } from 'react';
import { OptionTypeBase } from 'react-select';
import Filter from '../../Filter';
import { useIntl } from 'react-intl';
import messages from '../messages';
import { checkPolicies } from '../../../utils/policies.utils';
import { DELETE_ACTIVE_EMPLOYEE, UPDATE_USER_EXTENDED } from '../../../constants/policies.constants';
import PoliciesContext from '../../../PoliciesContext';
import { FilterParamsName, FilterTypes } from '../../../constants/filters.constants';
import { UsersParams } from '../../../enums/params/users.params';
import { UserInfo } from '../../../enums/users.enum';
import {
  CompanyPositionInfoType,
  DepartmentsInfoType,
  SkillLevelInfoType,
  TechnicalSkillInfoType,
  SpecializationInfoType,
} from '../../../types/libraries';
import { OfficeInfo } from '../../../enums/libraries.enum';
import { useFiltersListValue } from '../../../utils/hooks.utils';
import { isEmpty } from 'lodash';
import { v4 } from 'uuid';
import FiltersControl from '../../FiltersControl';
import { SavedFilter, SavedFiltersDataType } from '../../../enums/filters.enum';
import FilterClearButton from '../../FilterClearButton';
import RefreshButton from '../../RefreshButton';

type FiltersType = {
  users: UserInfo[];
  departments: DepartmentsInfoType[];
  offices: OfficeInfo[];
  skills: TechnicalSkillInfoType[];
  skillLevels: SkillLevelInfoType[];
  specializations: SpecializationInfoType[];
  positions: CompanyPositionInfoType[];
  hrCurators: UserInfo[];
};

type GridFiltersProps = {
  filters: FiltersType;
  values: UsersParams;
  handleMultiParamsChange: (name: string) => (data: OptionTypeBase) => void;
  handleParamsChange: (name: string) => (data: OptionTypeBase) => void;
  handleUsersParamsChange: (name: string) => (data: OptionTypeBase) => void;
  setUsersParams: (data: Partial<UsersParams>) => void;
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) => void;
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) => void;
  deleteSavedFilter: (data: { id: string; callback: () => void }) => void;
  savedFiltersData: SavedFiltersDataType;
  authUserId: string;
  handleFiltersControlChange: (value: SavedFilter) => void;
  handleClear: () => void;
  resetSavedFilterErrors: () => void;
  showClearButton: boolean;
};

const specializationsTypeOption = [
  { name: 'Primary', id: v4() },
  { name: 'Other', id: v4() },
];

function GridFilters({
  filters,
  values,
  handleMultiParamsChange,
  handleParamsChange,
  handleUsersParamsChange,
  setUsersParams,
  createNewSavedFilter,
  editSavedFilter,
  deleteSavedFilter,
  savedFiltersData,
  authUserId,
  handleFiltersControlChange,
  handleClear,
  resetSavedFilterErrors,
  showClearButton,
}: GridFiltersProps) {
  const policies = useContext(PoliciesContext);
  const intl = useIntl();
  const statusOptions = useMemo(
    () => [
      { label: intl.formatMessage(messages.allLabel), value: null },
      { label: intl.formatMessage(messages.activeLabel), value: true },
      { label: intl.formatMessage(messages.inactiveLabel), value: false },
    ],
    [],
  );

  const [resetCurrentFilter, setResetCurrentFilter] = useState(false);

  const officesOptions = useMemo(
    () =>
      filters.offices?.map(office => ({
        label: office.name,
        value: office.id,
      })),
    [filters.offices],
  );

  const departmentsOptions = useMemo(
    () =>
      filters.departments?.map(department => ({
        label: department.displayName,
        value: department.id,
      })),
    [filters.departments],
  );

  const usersOptions = useMemo(
    () =>
      filters.users?.map(user => ({
        label: user.fullName,
        value: user,
      })),
    [filters.users],
  );

  const skillsOptions = useMemo(
    () =>
      filters.skills?.map(skill => ({
        label: skill.name,
        options: skill.skills.map(el => ({
          label: el.skill,
          value: el.id,
        })),
      })),
    [filters.skills],
  );

  const specializationsOptions = useMemo(
    () =>
      filters.specializations?.map(specialization => ({
        label: specialization.name,
        value: specialization.id,
      })),
    [filters.specializations],
  );

  const positionsOptions = useMemo(
    () =>
      filters.positions?.map(position => ({
        label: position.displayName,
        value: position.id,
      })),
    [filters.positions],
  );

  const hrCuratorsOptions = useMemo(
    () =>
      filters.hrCurators?.map(user => ({
        label: user.fullName,
        value: user,
      })),
    [filters.hrCurators],
  );

  const statusValues = statusOptions.find(el => el.value === values.active);

  const employeesValues = useFiltersListValue(usersOptions, values.userIds);

  const hrCuratorValues = useFiltersListValue(hrCuratorsOptions, values.hrCuratorIds);

  const officeValues = useFiltersListValue(officesOptions, values.officeIds);

  const departmentValues = useFiltersListValue(departmentsOptions, values.departmentIds);

  const positionsValues = useFiltersListValue(positionsOptions, values.positionIds);

  const skillsValues = useMemo(() => {
    const value: OptionTypeBase = [];
    skillsOptions.forEach(el => {
      const skills = el.options.filter(opt =>
        values.technicalSkills.some(technicalSkill => technicalSkill.skillGroupSkillId === opt.value),
      );

      value.push(...skills);
    });
    return value;
  }, [values.technicalSkills, skillsOptions]);

  const specializationsValues = useMemo(() => {
    return specializationsOptions.filter(
      opt =>
        values.mainSpecializationIds.some(id => id === opt.value) ||
        values.secondarySpecializationIds.some(id => id === opt.value),
    );
  }, [values.mainSpecializationIds, values.secondarySpecializationIds, specializationsOptions]);

  const handleClearSkillsFilter = useCallback(() => {
    setUsersParams({
      technicalSkills: [],
    });
  }, []);

  const handleClearSpecializationsFilter = useCallback(() => {
    setUsersParams({
      mainSpecializationIds: [],
      secondarySpecializationIds: [],
    });
  }, []);

  const handleSkillLevelClick = (optionId: string, skillLevelId: string) => {
    const skillsValue = values.technicalSkills.filter(el => el.skillGroupSkillId !== optionId);
    let checkedSkill = values.technicalSkills.find(el => el.skillGroupSkillId === optionId);
    if (checkedSkill) {
      if (checkedSkill.skillLevelIds.some(id => id === skillLevelId)) {
        checkedSkill.skillLevelIds = checkedSkill.skillLevelIds.filter(id => id !== skillLevelId);
      } else {
        checkedSkill.skillLevelIds.push(skillLevelId);
      }
    } else {
      checkedSkill = { skillGroupSkillId: optionId, skillLevelIds: [skillLevelId] };
    }

    setUsersParams({
      technicalSkills: [...skillsValue, ...(!isEmpty(checkedSkill?.skillLevelIds) ? [checkedSkill] : [])],
    });
  };

  const getSkillsNestedOptions = useCallback(() => filters.skillLevels, [filters.skillLevels]);

  const getSkillsActiveNestedOptionsCount = useCallback(
    value => values.technicalSkills.find(skill => skill.skillGroupSkillId === value)?.skillLevelIds.length,
    [values.technicalSkills],
  );

  const handleSkillsCheckedNestedOption = useCallback(
    (optionId: string, nestedOptionId: string) =>
      values.technicalSkills?.some(
        el => el.skillGroupSkillId === optionId && el.skillLevelIds?.some(id => id === nestedOptionId),
      ),
    [values.technicalSkills],
  );

  const formatSkillsSelectedOptions = useCallback(
    (options: OptionTypeBase[]) => {
      const selectedOptions: { skillGroupSkillId: string; skillLevelId: string }[] = [];
      values.technicalSkills.forEach(skill => {
        skill.skillLevelIds.forEach(skillLevel => {
          selectedOptions.push({ skillGroupSkillId: skill.skillGroupSkillId, skillLevelId: skillLevel });
        });
      });

      const formatedOptions = selectedOptions
        .map(el => {
          const skillLabel = options.find(skillOpt => skillOpt.value === el.skillGroupSkillId)?.label;
          const skillLevelLabel = filters.skillLevels.find(skillLevel => el.skillLevelId === skillLevel.id)?.name;
          return {
            label: `${skillLabel}: ${skillLevelLabel}`,
            value: el.skillGroupSkillId,
          };
        })
        .sort((a, b) => a.label.split(':')[0].localeCompare(b.label.split(':')[0]));

      return formatedOptions;
    },
    [values.technicalSkills, filters.skillLevels],
  );

  const formatSpecializationsSelectedOptions = useCallback(
    (options: OptionTypeBase[]) => {
      const mainSpecOptions = values.mainSpecializationIds.map(id => {
        const specLabel = options.find(opt => opt.value === id)?.label;
        return {
          label: `${specLabel}: ${specializationsTypeOption[0].name}`,
          value: id,
        };
      });

      const otherSpecOptions = values.secondarySpecializationIds.map(id => {
        const specLabel = options.find(opt => opt.value === id)?.label;
        return {
          label: `${specLabel}: ${specializationsTypeOption[1].name}`,
          value: id,
        };
      });

      return [...mainSpecOptions, ...otherSpecOptions].sort((a, b) =>
        a.label.split(':')[0].localeCompare(b.label.split(':')[0]),
      );
    },
    [values.secondarySpecializationIds, values.mainSpecializationIds],
  );

  const handleSpecializationsClick = useCallback(
    (optionId: string, specializationTypeId: string) => {
      if (specializationTypeId === specializationsTypeOption[0].id) {
        const primarySpecValues = values.mainSpecializationIds.filter(id => id !== optionId);
        if (primarySpecValues.length === values.mainSpecializationIds.length) {
          primarySpecValues.push(optionId);
        }
        setUsersParams({
          mainSpecializationIds: primarySpecValues,
        });
      } else {
        const otherSpecValues = values.secondarySpecializationIds.filter(id => id !== optionId);
        if (otherSpecValues.length === values.secondarySpecializationIds.length) {
          otherSpecValues.push(optionId);
        }
        setUsersParams({
          secondarySpecializationIds: otherSpecValues,
        });
      }
    },
    [values.mainSpecializationIds, values.secondarySpecializationIds],
  );

  const getSpecializationsNestedOptions = useCallback(() => specializationsTypeOption, []);

  const getSpecializationsActiveNestedOptionsCount = useCallback(
    value => {
      let count = 0;

      if (values.mainSpecializationIds.find(id => id === value)) {
        count++;
      }
      if (values.secondarySpecializationIds.find(id => id === value)) {
        count++;
      }
      return count || undefined;
    },
    [values.mainSpecializationIds, values.secondarySpecializationIds],
  );

  const handleSpecializationsCheckedNestedOption = useCallback(
    (optionId: string, nestedOptionId: string) => {
      if (nestedOptionId === specializationsTypeOption[0].id) {
        return values.mainSpecializationIds.some(id => id === optionId);
      } else {
        return values.secondarySpecializationIds.some(id => id === optionId);
      }
    },
    [values.mainSpecializationIds, values.secondarySpecializationIds],
  );

  const onClear = useCallback(() => {
    setResetCurrentFilter(true);
    handleClear();
  }, []);

  const setResettFilterFlag = useCallback(() => {
    setResetCurrentFilter(false);
  }, []);

  return (
    <>
      <Filter
        isMulti
        label={intl.formatMessage(messages.officesLabel)}
        options={officesOptions}
        value={officeValues}
        handleChange={handleMultiParamsChange(FilterParamsName.OFFICE_IDS)}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        label={intl.formatMessage(messages.departmentsLabel)}
        options={departmentsOptions}
        value={departmentValues}
        handleChange={handleMultiParamsChange(FilterParamsName.DEPARTMENT_IDS)}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        isUsersFilter
        label={intl.formatMessage(messages.employeesLabel)}
        options={usersOptions}
        value={employeesValues}
        handleChange={handleUsersParamsChange(FilterParamsName.USER_IDS)}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        isUsersFilter
        label={intl.formatMessage(messages.gridTalentCuratorsFilter)}
        options={hrCuratorsOptions}
        value={hrCuratorValues}
        handleChange={handleUsersParamsChange('hrCuratorIds')}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        isNestedFilter
        isGrouped
        label={intl.formatMessage(messages.skillsColumn)}
        options={skillsOptions}
        showCheckbox={false}
        value={skillsValues}
        handleClearOptions={handleClearSkillsFilter}
        formatSelectedOptions={formatSkillsSelectedOptions}
        getNestedOptions={getSkillsNestedOptions}
        getActiveNestedOptionsCount={getSkillsActiveNestedOptionsCount}
        handleNestedOptionClick={handleSkillLevelClick}
        handleCheckedNestedOption={handleSkillsCheckedNestedOption}
        externalClass="filters__select skills-filter"
      />
      <Filter
        isMulti
        isNestedFilter
        label={intl.formatMessage(messages.specializationsFilterLabel)}
        options={specializationsOptions}
        showCheckbox={false}
        value={specializationsValues}
        handleClearOptions={handleClearSpecializationsFilter}
        formatSelectedOptions={formatSpecializationsSelectedOptions}
        getNestedOptions={getSpecializationsNestedOptions}
        getActiveNestedOptionsCount={getSpecializationsActiveNestedOptionsCount}
        handleNestedOptionClick={handleSpecializationsClick}
        handleCheckedNestedOption={handleSpecializationsCheckedNestedOption}
        externalClass="filters__select skills-filter"
      />
      <Filter
        isMulti
        label={intl.formatMessage(messages.positionsLabel)}
        options={positionsOptions}
        value={positionsValues}
        handleChange={handleMultiParamsChange(FilterParamsName.POSITIONS_IDS)}
        externalClass="filters__select"
      />
      {checkPolicies([UPDATE_USER_EXTENDED, DELETE_ACTIVE_EMPLOYEE], policies) && (
        <Filter
          label={intl.formatMessage(messages.statusLabel)}
          options={statusOptions}
          defaultValue={statusOptions[1]}
          value={statusValues}
          handleChange={handleParamsChange('active')}
          externalClass="filters__select"
        />
      )}
      {showClearButton && <FilterClearButton onClear={onClear} />}
      <FiltersControl
        handleSaveFilter={createNewSavedFilter}
        handleUpdateFilter={editSavedFilter}
        handleDeleteFilter={deleteSavedFilter}
        savedFiltersData={savedFiltersData}
        authUserId={authUserId}
        filterType={FilterTypes.USER_FILTER}
        handleChange={handleFiltersControlChange}
        params={values}
        resetSavedFilterErrors={resetSavedFilterErrors}
        resetCurrentFilter={resetCurrentFilter}
        setResettFilterFlag={setResettFilterFlag}
      />
      <RefreshButton onRefresh={() => setUsersParams(values)} />
    </>
  );
}

export default React.memo(GridFilters);
