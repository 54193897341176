import React, { useCallback, useMemo, useState } from 'react';
import { OptionTypeBase } from 'react-select';
import { useIntl } from 'react-intl';
import messages from '../messages';
import Filter from '../../Filter';
import { FilterParamsName, FilterTypes } from '../../../constants/filters.constants';
import { UserInfo } from '../../../enums/users.enum';
import { useFiltersListValue } from '../../../utils/hooks.utils';
import FiltersControl from '../../FiltersControl';
import { SavedFilter, SavedFiltersDataType } from '../../../enums/filters.enum';
import FilterClearButton from '../../FilterClearButton';
import { DepartmentsInfoType } from '../../../types/libraries';
import { ResourcesParams } from '../../../enums/params/planning.params';
import Checkbox from '../../Checkbox';
import { resourcesUnsavedParams } from '../../../constants/planning.constants';
import RefreshButton from '../../RefreshButton';

type FiltersType = {
  users: UserInfo[];
  departments: DepartmentsInfoType[];
  projectGroups: { id: string; fullName: string }[];
  offices: { id: string; name: string }[];
};

type ResourcesFilterProps = {
  filters: FiltersType;
  values: ResourcesParams;
  handleMultiParamsChange: (name: string) => (data: OptionTypeBase) => void;
  handleUsersParamsChange: (name: string) => (data: OptionTypeBase) => void;
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) => void;
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) => void;
  deleteSavedFilter: (data: { id: string; callback: () => void }) => void;
  savedFiltersData: SavedFiltersDataType;
  authUserId: string;
  handleFiltersControlChange: (value: SavedFilter) => void;
  handleClear: () => void;
  resetSavedFilterErrors: () => void;
  setResourcesParams: (data: Partial<ResourcesParams>) => void;
  showClearButton: boolean;
};

const ResourcesFilter = ({
  filters,
  values,
  handleMultiParamsChange,
  handleUsersParamsChange,
  createNewSavedFilter,
  editSavedFilter,
  deleteSavedFilter,
  savedFiltersData,
  authUserId,
  handleFiltersControlChange,
  handleClear,
  resetSavedFilterErrors,
  setResourcesParams,
  showClearButton,
}: ResourcesFilterProps) => {
  const intl = useIntl();

  const [resetCurrentFilter, setResetCurrentFilter] = useState(false);

  const usersOptions = useMemo(() => filters.users.map(item => ({ value: item, label: item.fullName })), [
    filters.users,
  ]);

  const projectGroupsOptions = useMemo(
    () =>
      filters.projectGroups.map(({ id, fullName }) => ({
        value: id,
        label: fullName,
      })),
    [filters.projectGroups],
  );

  const officesOptions = useMemo(
    () =>
      filters.offices.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [filters.offices],
  );

  const departmentsOptions = useMemo(
    () =>
      filters.departments.map(({ id, displayName }: DepartmentsInfoType) => ({
        value: id,
        label: displayName,
      })),
    [filters.departments],
  );

  const usersValues = useFiltersListValue(usersOptions, values.userIds);

  const officesValues = useFiltersListValue(officesOptions, values.officeIds);

  const departmentsValues = useFiltersListValue(departmentsOptions, values.departmentIds);

  const projectGroupsValues = useFiltersListValue(projectGroupsOptions, values.projectGroupIds);

  const onClear = useCallback(() => {
    setResetCurrentFilter(true);
    handleClear();
  }, [values]);

  const setResettFilterFlag = useCallback(() => {
    setResetCurrentFilter(false);
  }, []);

  return (
    <>
      <Filter
        isMulti
        label={intl.formatMessage(messages.projectGroupsLabel)}
        options={projectGroupsOptions}
        value={projectGroupsValues}
        handleChange={handleMultiParamsChange(FilterParamsName.PROJECT_GROUP_IDS)}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        label={intl.formatMessage(messages.officesLabel)}
        options={officesOptions}
        value={officesValues}
        handleChange={handleMultiParamsChange(FilterParamsName.OFFICE_IDS)}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        label={intl.formatMessage(messages.departmentsLabel)}
        options={departmentsOptions}
        value={departmentsValues}
        handleChange={handleMultiParamsChange(FilterParamsName.DEPARTMENT_IDS)}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        isUsersFilter
        label={intl.formatMessage(messages.employeesLabel)}
        options={usersOptions}
        value={usersValues}
        handleChange={handleUsersParamsChange(FilterParamsName.USER_IDS)}
        externalClass="filters__select"
      />
      <div className="check_box_wrapper filter">
        <Checkbox
          label={intl.formatMessage(messages.withoutFullCaseloadLabel)}
          id={'withoutFullCaseload'}
          externalClass={'checkbox-label'}
          onChange={event => setResourcesParams({ withoutFullCaseload: event.target.checked })}
          checkedValue={values.withoutFullCaseload}
        />
      </div>
      {showClearButton && <FilterClearButton onClear={onClear} />}
      <FiltersControl
        handleSaveFilter={createNewSavedFilter}
        handleUpdateFilter={editSavedFilter}
        handleDeleteFilter={deleteSavedFilter}
        savedFiltersData={savedFiltersData}
        authUserId={authUserId}
        filterType={FilterTypes.RESOURCES_FILTER}
        handleChange={handleFiltersControlChange}
        params={values}
        resetSavedFilterErrors={resetSavedFilterErrors}
        unsavedParams={resourcesUnsavedParams}
        resetCurrentFilter={resetCurrentFilter}
        setResettFilterFlag={setResettFilterFlag}
      />
      <RefreshButton onRefresh={() => setResourcesParams(values)} />
    </>
  );
};

export default React.memo(ResourcesFilter);
