import React, { useCallback, useMemo, useState } from 'react';
import { OptionTypeBase } from 'react-select';
import { useIntl } from 'react-intl';
import messages from '../messages';
import Filter from '../../Filter';
import { FilterParamsName, FilterTypes } from '../../../constants/filters.constants';
import { EventsParams } from '../../../enums/params/schedule.params';
import { UserInfo } from '../../../enums/users.enum';
import { OfficeInfo } from '../../../enums/libraries.enum';
import { EventTypeInfo } from '../../../enums/calendar.enum';
import { DepartmentsInfoType } from '../../../types/libraries';
import { useFiltersListValue } from '../../../utils/hooks.utils';
import SortSelect from '../../SortSelect';
import FiltersControl from '../../FiltersControl';
import { SavedFilter, SavedFiltersDataType } from '../../../enums/filters.enum';
import FilterClearButton from '../../FilterClearButton';
import { scheduleUnsavedParams } from '../../../constants/schedule.constants';
import { SortParams } from '../../../enums/params.enum';
import RefreshButton from '../../RefreshButton';

type FiltersType = {
  users: UserInfo[];
  departments: DepartmentsInfoType[];
  offices: OfficeInfo[];
  eventTypes: EventTypeInfo[];
};

type EventFiltersProps = {
  filters: FiltersType;
  values: EventsParams;
  setEventParams: (data: Partial<EventsParams>, isGetEventsList?: boolean) => void;
  handleEventMultiParamsChange: (name: string) => (data: OptionTypeBase) => void;
  handleUsersParamsChange: (name: string) => (data: OptionTypeBase) => void;
  handleSort: (sortBy: string, direction: string) => void;
  showSortSelect: boolean;
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) => void;
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) => void;
  deleteSavedFilter: (data: { id: string; callback: () => void }) => void;
  savedFiltersData: SavedFiltersDataType;
  authUserId: string;
  handleFiltersControlChange: (value: SavedFilter) => void;
  handleClear: () => void;
  resetSavedFilterErrors: () => void;
  showClearButton: boolean;
};

const EventFilter = ({
  filters,
  values,
  handleEventMultiParamsChange,
  handleUsersParamsChange,
  handleSort,
  showSortSelect,
  createNewSavedFilter,
  editSavedFilter,
  deleteSavedFilter,
  savedFiltersData,
  authUserId,
  handleFiltersControlChange,
  setEventParams,
  handleClear,
  resetSavedFilterErrors,
  showClearButton,
}: EventFiltersProps) => {
  const intl = useIntl();

  const [resetCurrentFilter, setResetCurrentFilter] = useState(false);

  const officesOptions = useMemo(
    () =>
      filters.offices?.map(office => ({
        label: office.name,
        value: office.id,
      })),
    [filters.offices],
  );

  const departmentsOptions = useMemo(
    () =>
      filters.departments?.map(department => ({
        label: department.displayName,
        value: department.id,
      })),
    [filters.departments],
  );

  const usersOptions = useMemo(
    () =>
      filters.users?.map(user => ({
        label: user.fullName,
        value: user,
      })),
    [filters.users],
  );

  const eventTypesOptions = useMemo(
    () =>
      filters.eventTypes?.map(office => ({
        label: office.name,
        value: office.id,
      })),
    [filters.eventTypes],
  );

  const employeesValues = useFiltersListValue(usersOptions, values.targetEmployees);

  const officeValues = useFiltersListValue(officesOptions, values.offices);

  const departmentValues = useFiltersListValue(departmentsOptions, values.departments);

  const eventTypeValues = useFiltersListValue(eventTypesOptions, values.eventTypes);

  const onClear = useCallback(() => {
    setResetCurrentFilter(true);
    handleClear();
  }, [values]);

  const setResettFilterFlag = useCallback(() => {
    setResetCurrentFilter(false);
  }, []);

  return (
    <>
      {showSortSelect && (
        <SortSelect
          sortOptions={[
            { label: intl.formatMessage(messages.targetMemberLabel), value: 'targetEmployee.secondName' },
            { label: intl.formatMessage(messages.datesLabel), value: 'startDate' },
            { label: intl.formatMessage(messages.titleLabel), value: 'title' },
            { label: intl.formatMessage(messages.typeLabel), value: 'eventType.name' },
          ]}
          params={new SortParams('', { sortBy: values.sortBy, direction: values.direction })}
          onSort={handleSort}
        />
      )}
      <Filter
        isMulti
        label={intl.formatMessage(messages.officesLabel)}
        options={officesOptions}
        value={officeValues}
        handleChange={handleEventMultiParamsChange(FilterParamsName.OFFICES)}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        label={intl.formatMessage(messages.departmentsLabel)}
        options={departmentsOptions}
        value={departmentValues}
        handleChange={handleEventMultiParamsChange(FilterParamsName.DEPARTMENTS)}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        isUsersFilter
        label={intl.formatMessage(messages.employeesLabel)}
        options={usersOptions}
        value={employeesValues}
        handleChange={handleUsersParamsChange(FilterParamsName.TARGET_EMPLOYEES)}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        label={intl.formatMessage(messages.eventTypesLabel)}
        options={eventTypesOptions}
        value={eventTypeValues}
        handleChange={handleEventMultiParamsChange(FilterParamsName.EVENT_TYPES)}
        externalClass="filters__select"
      />
      {showClearButton && <FilterClearButton onClear={onClear} />}
      <FiltersControl
        handleSaveFilter={createNewSavedFilter}
        handleUpdateFilter={editSavedFilter}
        handleDeleteFilter={deleteSavedFilter}
        savedFiltersData={savedFiltersData}
        authUserId={authUserId}
        filterType={FilterTypes.EVENT_FILTER}
        handleChange={handleFiltersControlChange}
        params={values}
        resetSavedFilterErrors={resetSavedFilterErrors}
        unsavedParams={scheduleUnsavedParams}
        resetCurrentFilter={resetCurrentFilter}
        setResettFilterFlag={setResettFilterFlag}
      />
      <RefreshButton onRefresh={() => setEventParams(values)} />
    </>
  );
};

export default React.memo(EventFilter);
