import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as candidatesActions from '../../actions/candidates.actions';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Table from '../../components/Table';
import {
  UPDATE_CANDIDATE_RECEIVING_SOURCE,
  DELETE_CANDIDATE_RECEIVING_SOURCE,
} from '../../constants/policies.constants';
import AccessChecker from '../../components/AccessChecker';
import ModalNewCandidateReceivingSource from '../../components/CandidateReceivingSources/Modals/ModalNewCandidateReceivingSource';
import { SortParams } from '../../enums/params.enum';
import ModalEditCandidateReceivingSource from '../../components/CandidateReceivingSources/Modals/ModalEditCandidateReceivingSource';
import ModalDeleteCandidateReceivingSource from '../../components/CandidateReceivingSources/Modals/ModalDeleteCandidateReceivingSource';
import { CandidateReceivingSourceType } from '../../types/candidates';
import Pagination from '../../components/Pagination';
import RefreshButton from '../../components/RefreshButton';

function CandidateReceivingSources({
  tableData,
  isLoading,
  candidateReceivingSourcesListError,
  candidateReceivingSourceError,
  params,
  getCandidateReceivingSourcesList,
  setCandidateReceivingSourcesParams,
  createCandidateReceivingSource,
  editCandidateReceivingSource,
  deleteCandidateReceivingSource,
  resetState,
  resetErrors,
}: ConnectedProps<typeof connector>) {
  const intl = useIntl();

  const [modalNewCandidateReceivingSourceIsOpen, setModalNewCandidateReceivingSourceIsOpen] = useState(false);
  const [modalEditCandidateReceivingSourceIsOpen, setModalEditCandidateReceivingSourceIsOpen] = useState(false);
  const [modalDeleteCandidateReceivingSourceIsOpen, setModalDeleteCandidateReceivingSourceIsOpen] = useState(false);
  const [candidateReceivingSourceClicked, setCandidateReceivingSourceClicked] = useState<CandidateReceivingSourceType>({
    id: '',
    name: '',
  });

  useEffect(() => {
    getCandidateReceivingSourcesList();
    return () => {
      resetState();
    };
  }, []);

  const openNewCandidateReceivingSourceModal = useCallback(() => {
    setModalNewCandidateReceivingSourceIsOpen(true);
  }, []);

  const closeNewCandidateReceivingSourceModal = useCallback(() => {
    setModalNewCandidateReceivingSourceIsOpen(false);
    resetErrors();
  }, []);

  const openEditCandidateReceivingSourceModal = useCallback(() => {
    setModalEditCandidateReceivingSourceIsOpen(true);
  }, []);

  const closeEditCandidateReceivingSourceModal = useCallback(() => {
    setModalEditCandidateReceivingSourceIsOpen(false);
    resetErrors();
  }, []);

  const openDeleteCandidateReceivingSourceModal = useCallback(() => {
    setModalDeleteCandidateReceivingSourceIsOpen(true);
  }, []);

  const closeDeleteCandidateReceivingSourceModal = useCallback(() => {
    setModalDeleteCandidateReceivingSourceIsOpen(false);
    resetErrors();
  }, []);

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.nameColumn),
        modifier: (row: CandidateReceivingSourceType) => <span>{row.name}</span>,
        sortName: 'name',
      },
    ],
    [],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: CandidateReceivingSourceType) => {
          setCandidateReceivingSourceClicked(row);
          openEditCandidateReceivingSourceModal();
        },
        verifiablePolicies: [UPDATE_CANDIDATE_RECEIVING_SOURCE],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: CandidateReceivingSourceType) => {
          setCandidateReceivingSourceClicked(row);
          openDeleteCandidateReceivingSourceModal();
        },
        verifiablePolicies: [DELETE_CANDIDATE_RECEIVING_SOURCE],
      },
    ],
    [],
  );

  const sortParams = useMemo(() => new SortParams('name', params), [params]);

  const pageable = useMemo(
    () => ({
      ...tableData?.pageable,
      ...tableData?.sort,
      totalElements: tableData?.totalElements,
      numberOfElements: tableData?.numberOfElements,
      totalPages: tableData?.totalPages,
    }),
    [tableData],
  );

  const handlePageChange = useCallback(({ selected }) => {
    setCandidateReceivingSourcesParams({ page: selected });
  }, []);

  const handleSizeChange = useCallback(data => {
    setCandidateReceivingSourcesParams({ size: data, page: 0 });
  }, []);

  const handleSort = useCallback((sortBy, direction) => setCandidateReceivingSourcesParams({ sortBy, direction }), []);

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom no-border">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[UPDATE_CANDIDATE_RECEIVING_SOURCE]}>
                  <Button externalClass={'button--with-icon'} onClick={openNewCandidateReceivingSourceModal}>
                    <Icon iconName={'plus'} externalClass={'button__icon'} />
                    <span className="button__text">
                      <FormattedMessage {...messages.newButton} />
                    </span>
                  </Button>
                </AccessChecker>
                <RefreshButton onRefresh={() => setCandidateReceivingSourcesParams(params)} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content half-content">
        <div className="page__wrapper">
          <Table
            tableColumns={tableColumns}
            tableData={tableData?.content || []}
            loading={isLoading.getReceivingSourcesList}
            error={candidateReceivingSourcesListError}
            tableActions={tableActions}
            params={sortParams}
            onSort={handleSort}
          />
          <Pagination pageable={pageable} onPageChange={handlePageChange} onPageSizeChange={handleSizeChange} />
        </div>
      </div>
      {modalNewCandidateReceivingSourceIsOpen && (
        <ModalNewCandidateReceivingSource
          isOpen
          error={candidateReceivingSourceError}
          isLoading={isLoading.createCandidateReceivingSource}
          onCloseRequest={closeNewCandidateReceivingSourceModal}
          createCandidateReceivingSource={createCandidateReceivingSource}
        />
      )}
      {modalEditCandidateReceivingSourceIsOpen && (
        <ModalEditCandidateReceivingSource
          candidateReceivingSource={candidateReceivingSourceClicked}
          isOpen
          error={candidateReceivingSourceError}
          isLoading={isLoading.editCandidateReceivingSource}
          onCloseRequest={closeEditCandidateReceivingSourceModal}
          editCandidateReceivingSource={editCandidateReceivingSource}
        />
      )}
      {modalDeleteCandidateReceivingSourceIsOpen && (
        <ModalDeleteCandidateReceivingSource
          candidateReceivingSource={candidateReceivingSourceClicked}
          isOpen
          isLoading={isLoading.deleteCandidateReceivingSource}
          error={candidateReceivingSourceError}
          onCloseRequest={closeDeleteCandidateReceivingSourceModal}
          onDeleteRequest={deleteCandidateReceivingSource}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ candidates }: RootState) => ({
  tableData: candidates.candidateReceivingSourcesList,
  isLoading: candidates.loading,
  candidateReceivingSourcesListError: candidates.errors.candidateReceivingSourcesListError,
  candidateReceivingSourceError: candidates.errors.candidateReceivingSourceError,
  params: candidates.candidateReceivingSourcesParams,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getCandidateReceivingSourcesList: () => dispatch(candidatesActions.getCandidateReceivingSourcesList()),
  setCandidateReceivingSourcesParams: (params: Partial<any>) =>
    dispatch(candidatesActions.setCandidateReceivingSourcesParams(params)),
  createCandidateReceivingSource: (data: { data: CandidateReceivingSourceType; callback: () => void }) =>
    dispatch(candidatesActions.createCandidateReceivingSource(data)),
  editCandidateReceivingSource: (data: { data: CandidateReceivingSourceType; callback: () => void }) =>
    dispatch(candidatesActions.editCandidateReceivingSource(data)),
  deleteCandidateReceivingSource: (data: { data: string; callback: () => void }) =>
    dispatch(candidatesActions.deleteCandidateReceivingSource(data)),
  resetState: () => dispatch(candidatesActions.resetState()),
  resetErrors: () => dispatch(candidatesActions.resetErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(CandidateReceivingSources);
