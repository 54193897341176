import React, { memo, useCallback, useMemo, useState } from 'react';
import Filter from '../../Filter';
import { useIntl } from 'react-intl';
import messages from '../messages';
import { OptionTypeBase } from 'react-select';
import { Client, FinanceProject } from '../../../enums/finance/finance.enum';
import { FinancePlanParams } from '../../../enums/params/finance.params';
import { useFiltersListValue } from '../../../utils/hooks.utils';
import FilterClearButton from '../../FilterClearButton';
import FiltersControl from '../../FiltersControl';
import { SavedFilter, SavedFiltersDataType } from '../../../enums/filters.enum';
import { FilterTypes } from '../../../constants/filters.constants';
import { financePlansUnsavedParams } from '../../../constants/finance.constants';
import RefreshButton from '../../RefreshButton';

type FiltersType = {
  clients: Client[];
  projects: FinanceProject[];
};

type PollsFiltersProps = {
  handleMultiParamsChange: (name: string) => (data: OptionTypeBase) => void;
  filters: FiltersType;
  values: FinancePlanParams;
  setPlansParams: (data: Partial<FinancePlanParams>) => void;
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) => void;
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) => void;
  deleteSavedFilter: (data: { id: string; callback: () => void }) => void;
  savedFiltersData: SavedFiltersDataType;
  authUserId: string;
  handleFiltersControlChange: (value: SavedFilter) => void;
  handleClear: () => void;
  resetSavedFilterErrors: () => void;
  showClearButton: boolean;
};

const PlansFilters = ({
  handleMultiParamsChange,
  filters,
  values,
  createNewSavedFilter,
  setPlansParams,
  editSavedFilter,
  deleteSavedFilter,
  savedFiltersData,
  authUserId,
  handleFiltersControlChange,
  handleClear,
  resetSavedFilterErrors,
  showClearButton,
}: PollsFiltersProps) => {
  const intl = useIntl();

  const [resetCurrentFilter, setResetCurrentFilter] = useState(false);

  const { projectsLabel, clientsLabel } = messages;

  const clientsOptions = useMemo(
    () =>
      filters.clients?.map(({ id, name }: Client) => ({
        value: id,
        label: name,
      })),
    [filters.clients],
  );

  const projectsOptions = useMemo(
    () =>
      filters.projects?.map(({ id, name }: FinanceProject) => ({
        value: id,
        label: name,
      })),
    [filters.projects],
  );

  const clientsValues = useFiltersListValue(clientsOptions, values.clientIds);
  const projectsValues = useFiltersListValue(projectsOptions, values.projectIds);

  const onClear = useCallback(() => {
    setResetCurrentFilter(true);
    handleClear();
  }, [values]);

  const setResettFilterFlag = useCallback(() => {
    setResetCurrentFilter(false);
  }, []);

  return (
    <>
      <Filter
        isMulti
        label={intl.formatMessage(clientsLabel)}
        options={clientsOptions}
        value={clientsValues}
        handleChange={e => handleMultiParamsChange('clientIds')(e)}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        label={intl.formatMessage(projectsLabel)}
        options={projectsOptions}
        handleChange={e => handleMultiParamsChange('projectIds')(e)}
        externalClass="filters__select"
        value={projectsValues}
      />
      {showClearButton && <FilterClearButton onClear={onClear} />}
      <FiltersControl
        handleSaveFilter={createNewSavedFilter}
        handleUpdateFilter={editSavedFilter}
        handleDeleteFilter={deleteSavedFilter}
        savedFiltersData={savedFiltersData}
        authUserId={authUserId}
        filterType={FilterTypes.PLANNING_FILTER}
        handleChange={handleFiltersControlChange}
        params={values}
        resetSavedFilterErrors={resetSavedFilterErrors}
        unsavedParams={financePlansUnsavedParams}
        resetCurrentFilter={resetCurrentFilter}
        setResettFilterFlag={setResettFilterFlag}
      />
      <RefreshButton onRefresh={() => setPlansParams(values)} />
    </>
  );
};

export default memo(PlansFilters);
