import React, { useCallback, useMemo } from 'react';
import { OptionTypeBase } from 'react-select';
import { useIntl } from 'react-intl';
import messages from '../messages';
import Filter from '../../Filter';
import { useFiltersListValue } from '../../../utils/hooks.utils';
import { PollTemplateCategoryInfoType } from '../../../types/questionnaires';
import { PollTemplatesParams } from '../../../enums/params/questionnaires.params';
import Search from '../../Search';
import { debounce } from 'lodash-es';
import RefreshButton from '../../RefreshButton';

type FiltersType = {
  categories: PollTemplateCategoryInfoType[];
};

type PollTemplateFilterProps = {
  filters: FiltersType;
  values: PollTemplatesParams;
  handleMultiParamsChange: (name: string) => (data: OptionTypeBase) => void;
  setPollTemplatesParams: (data: Partial<PollTemplatesParams>) => void;
};

const PollTemplateFilter = ({
  filters,
  values,
  handleMultiParamsChange,
  setPollTemplatesParams,
}: PollTemplateFilterProps) => {
  const intl = useIntl();

  const categoriesOptions = useMemo(
    () =>
      filters.categories?.map(category => ({
        label: category.name,
        value: category.id,
      })),
    [filters.categories],
  );

  const categoriesValues = useFiltersListValue(categoriesOptions, values.categoryIds);

  const handleParamsChange = useCallback(
    (value: OptionTypeBase, name = '') => handleMultiParamsChange(name)(value),
    [],
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPollTemplatesParams({ name: e.target.value, page: 0 });
  };

  const handleClearSearch = () => {
    setPollTemplatesParams({ name: '', page: 0 });
  };

  const debouncedChangeHandler = useMemo(() => debounce(handleSearchChange, 500), []);

  return (
    <>
      <Search
        placeholder={intl.formatMessage(messages.searchLabel)}
        defaultValue={values.name}
        onChange={debouncedChangeHandler}
        handleClearBtn={handleClearSearch}
      />
      <Filter
        isMulti
        label={intl.formatMessage(messages.categorisLabel)}
        options={categoriesOptions}
        value={categoriesValues}
        paramsName="categoryIds"
        handleChange={handleParamsChange}
        externalClass="filters__select"
      />
      <RefreshButton onRefresh={() => setPollTemplatesParams(values)} />
    </>
  );
};

export default React.memo(PollTemplateFilter);
