import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import DraggableList from 'react-draggable-list';

import { useFormik } from 'formik';
import get from 'lodash-es/get';

import { EDashboardTopSetting } from '../../Table/DashboardHeadBlock';
import Modal from '../../../Modal';
import messages from '../../messages';
import Icon from '../../../Icon';
import ErrorMessage from '../../../ErrorMessage';
import Button from '../../../Button';
import { Item } from './Draggable';
import {
  DashboardExpenseTypeGroup,
  DashboardTopType,
  EditDashboardTopSetting,
  ExpenseType,
  IncomeType,
  SubExpenseType,
} from '../../../../types/finance';
import { DASHBOARD_TOP_SCHEMA } from '../../../../enums/finance/finance.enum';

type DashboardTopModalProps = {
  getExpenseList: (data: { callback: (results: any) => void }) => void;
  getIncomeList: (data: { callback: (results: any) => void }) => void;
  onCloseRequest: () => void;
  editDashboardTopSetting: (data: { params: EditDashboardTopSetting[]; callback: () => void }) => void;
  resetSettings: () => void;
  isOpen: boolean;
  data: any;
  loading: boolean;
  error: string | null;
  blockType: EDashboardTopSetting;
};

function DashboardTopModal({
  onCloseRequest,
  editDashboardTopSetting,
  resetSettings,
  error,
  data,
  loading,
  isOpen,
  blockType,
  getExpenseList,
  getIncomeList,
}: DashboardTopModalProps) {
  const intl = useIntl();

  const containerRef = useRef(null);

  const onListChange = (newList: any) => {
    setFieldValue('types', newList);
  };

  const [types, setTypes] = useState<any[]>([]);
  const [opened, setOpened] = useState<string[]>([]);

  const { values, errors, touched, handleSubmit, setFieldValue } = useFormik<{
    types: any[];
  }>({
    initialValues: {
      types: [],
    },
    validateOnChange: false,
    validationSchema: DASHBOARD_TOP_SCHEMA,
    onSubmit: values => {
      const data = values.types.map((type, index) => ({
        name: type.name,
        id: type.id,
        positionNumber: index,
        ...(blockType === EDashboardTopSetting.INCOME
          ? {
              incomeTypeIds: type.subTypes
                .filter((sub: DashboardTopType) => !sub.sourceType)
                .map((it: DashboardTopType) => it.id),
              additionalSourceTypes: type.subTypes
                .filter((sub: DashboardTopType) => sub.sourceType)
                .reduce(
                  (o: any, value: DashboardTopType) => ({
                    ...o,
                    ['REVENUE_FROM_PROJECT']: value.id,
                  }),
                  {},
                ),
            }
          : {
              expenseTypeGroupSettings: type.subTypes.map((exp: DashboardTopType) => ({
                expenseTypeId: exp.id,
                subExpenseTypeIds: exp.subTypes,
              })),
            }),
      }));

      return editDashboardTopSetting({ params: data, callback: onCloseRequest });
    },
  });

  useEffect(() => {
    if (blockType === EDashboardTopSetting.INCOME) {
      getIncomeList({
        callback: results => {
          setTypes([
            {
              id: intl.formatMessage(messages.revenueFromProjects),
              name: intl.formatMessage(messages.revenueFromProjects),
              sourceType: true,
            },
            ...results.content.map((item: IncomeType) => ({
              id: item.id,
              name: item.name + (item.isDeleted ? ' (deleted)' : ''),
            })),
          ]);
        },
      });
    } else {
      getExpenseList({
        callback: results => {
          setTypes(
            results.content.map((item: ExpenseType) => ({
              id: item.id,
              name: item.name + (item.isDeleted ? ' (deleted)' : ''),
              subExpenseTypes: item.subExpenseTypes,
            })),
          );
        },
      });
    }

    return () => {
      resetSettings();
    };
  }, [blockType]);

  useEffect(() => {
    if (data) {
      setFieldValue(
        'types',
        data.map((type: EditDashboardTopSetting, index: number) => ({
          name: type.name,
          subTypes:
            blockType === EDashboardTopSetting.INCOME
              ? [
                  ...(type.additionalSourceTypes
                    ? [
                        {
                          id: type.additionalSourceTypes.REVENUE_FROM_PROJECT,
                          name: type.additionalSourceTypes.REVENUE_FROM_PROJECT,
                          sourceType: true,
                        },
                      ]
                    : []),
                  ...(type.incomeTypes ?? []),
                ]
              : type.expenseTypeGroupSettings?.map((group: DashboardExpenseTypeGroup) => ({
                  id: group.expenseType?.id,
                  name: group.expenseType?.name,
                  subTypes: group.subExpenseTypeIds,
                })),
          position: index,
        })),
      );
    }
  }, [data]);

  const changeType = useCallback(
    (item: DashboardTopType, type: any, opened: string[]) => {
      if (item.subTypes.find((item: any) => item.id === type.id)) {
        setFieldValue(
          'types',
          values.types.map(value =>
            value.position === item.position
              ? { ...value, subTypes: value.subTypes.filter((sub: DashboardTopType) => sub.id !== type.id), opened }
              : value,
          ),
        );
      } else {
        setFieldValue(
          'types',
          values.types.map(value =>
            value.position === item.position
              ? {
                  ...value,
                  subTypes: [
                    ...value.subTypes,
                    {
                      id: type.id,
                      name: type.name,
                      sourceType: type.sourceType,
                      ...(type.subExpenseTypes
                        ? { subTypes: type.subExpenseTypes.map((sub: SubExpenseType) => sub.id) }
                        : {}),
                    },
                  ],
                  opened,
                }
              : value,
          ),
        );
      }
    },
    [values.types],
  );

  const changeSubType = useCallback(
    (item: DashboardTopType, type: DashboardTopType, subType: string, opened: string[]) => {
      const selected = item.subTypes.find((item: DashboardTopType) => item.id === type.id);

      if (selected) {
        const changed = selected.subTypes.includes(subType)
          ? { ...selected, subTypes: selected.subTypes.filter((it: string) => it !== subType) }
          : { ...selected, subTypes: [...selected.subTypes, subType] };

        if (changed.subTypes.length) {
          setFieldValue(
            'types',
            values.types.map(value =>
              value.position === item.position
                ? {
                    ...value,
                    subTypes: [...value.subTypes.filter((it: DashboardTopType) => it.id !== type.id), changed],
                    opened,
                  }
                : value,
            ),
          );
        } else {
          setFieldValue(
            'types',
            values.types.map(value =>
              value.position === item.position
                ? { ...value, subTypes: value.subTypes.filter((sub: DashboardTopType) => sub.id !== type.id), opened }
                : value,
            ),
          );
        }
      } else
        setFieldValue(
          'types',
          values.types.map(value =>
            value.position === item.position
              ? {
                  ...value,
                  subTypes: [...value.subTypes, { id: type.id, name: type.name, subTypes: [subType] }],
                  opened,
                }
              : value,
          ),
        );
    },
    [values.types],
  );

  const changeName = useCallback(
    (name: string, type: DashboardTopType) => {
      setFieldValue(
        'types',
        values.types.map(item => (item.position === type.position ? { ...item, name } : item)),
      );
    },
    [values.types],
  );

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const addNewType = useCallback(() => {
    setFieldValue('types', [...values.types, { position: values.types.length + 1, name: '', subTypes: [] }]);
  }, [values]);

  const removeType = useCallback(
    index => {
      values.types.splice(index, 1);
      setFieldValue('types', values.types);
    },
    [values],
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onCloseRequest}
        title={intl.formatMessage(messages[blockType])}
        size="small"
        classNameModal={'dashboard-modal center'}
      >
        <form className="modal__form form" onSubmit={handleSubmit}>
          <div className="form__inputs-wrapper">
            <div ref={containerRef} className="dashboard-draggable-container">
              <DraggableList
                itemKey="position"
                //@ts-ignore
                template={temp =>
                  Item({
                    item: temp.item,
                    dragHandleProps: temp.dragHandleProps,
                    data: values.types,
                    opened,
                    errors,
                    types,
                    setOpened: data => setOpened(data),
                    changeType,
                    changeSubType,
                    changeName,
                    hasError,
                    removeType,
                  })
                }
                list={values.types}
                onMoveEnd={(newList: any) => onListChange(newList)}
                container={() => containerRef.current}
              />
            </div>

            <button className="form__btn-add-group" onClick={addNewType} type={'button'}>
              <Icon iconName={'plus'} externalClass={'form__icon-btn-add'} />
              <FormattedMessage {...messages.addButton} />
            </button>
          </div>
          <ErrorMessage>{error}</ErrorMessage>
          <div className="form__buttons display-center">
            <Button
              color="gray"
              externalClass="button--modal button--cancel"
              onClick={onCloseRequest}
              type="button"
              block
            >
              <FormattedMessage {...messages.cancelButton} />
            </Button>
            <Button externalClass="button--modal" type="submit" loading={loading} disabled={loading} block>
              <FormattedMessage {...messages.saveButton} />
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default DashboardTopModal;
