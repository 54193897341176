import { defineMessages } from 'react-intl';
import {
  deleteButton,
  addButton,
  cancelButton,
  saveButton,
  editButton,
  newButton,
  nameColumn,
  totalRow,
  required,
} from '../../i18n/defaultMessage';

export default defineMessages({
  editButton,
  deleteButton,
  required,
  newButton,
  addButton,
  cancelButton,
  saveButton,
  nameColumn,
  totalRow,
  REVENUE_AND_EXPENSE: {
    id: 'finance.cash.flow.title.revenue.expense',
    defaultMessage: 'Revenue and Expense',
  },
  PLANNED_EXPENSES_BY_OFFICE: {
    id: 'finance.cash.flow.title.planned.expenses.office',
    defaultMessage: 'Planned Expenses #1 (Planned Expenses by Offices)',
  },
  FAVORITE_PLANNED_EXPENSE: {
    id: 'finance.cash.flow.title.favorite.planned.expense',
    defaultMessage: 'Planned Expenses #2 (Favorite Planned Expenses)',
  },
  PLANNED_AND_ACTUAL_REVENUE: {
    id: 'finance.cash.flow.title.planned.actual.revenue',
    defaultMessage: 'Planned and Actual Revenue',
  },
  CUSTOMER_INVOICE: {
    id: 'finance.cash.flow.title.customer.invoice',
    defaultMessage: 'Customer Invoices',
  },
  REVENUE_BY_CUSTOMER: {
    id: 'finance.cash.flow.title.revenue.invoice',
    defaultMessage: 'Revenue by Customers',
  },
  EXPENSES_STRUCTURE: {
    id: 'finance.cash.flow.title.expenses.structure',
    defaultMessage: 'Expenses Structure',
  },
  dashboard: {
    id: 'finance.cash.flow.title.dashboard',
    defaultMessage: 'Dashboard',
  },
  showEmptyRecords: {
    id: 'finance.dashboard.modal.label.show.empty.records',
    defaultMessage: 'Show Empty Expenses',
  },
  expenseColumn: {
    id: 'finance.dashboard.column.expense',
    defaultMessage: 'Expense Type / Expense Sub-Type',
  },
  incomeColumn: {
    id: 'finance.dashboard.column.income',
    defaultMessage: 'Income Type / Payer',
  },
  clientColumn: {
    id: 'finance.dashboard.column.client',
    defaultMessage: 'Clients',
  },
  officeColumn: {
    id: 'finance.dashboard.column.offices',
    defaultMessage: 'Offices',
  },
  clientInvoiceColumn: {
    id: 'finance.dashboard.column.client.invoice',
    defaultMessage: 'Clients / Invoice #',
  },
  invoiceAmount: {
    id: 'finance.dashboard.column.invoices.amount',
    defaultMessage: 'Invoices Amount',
  },
  overdueAmount: {
    id: 'finance.dashboard.column.overdue.amount',
    defaultMessage: 'Overdue Amount',
  },
  due: {
    id: 'finance.dashboard.column.due',
    defaultMessage: 'Due',
  },
  sendingDate: {
    id: 'finance.dashboard.column.sending.date',
    defaultMessage: 'Sending Date',
  },
  revenueFromProjects: {
    id: 'finance.dashboard.column.revenue.from.projects',
    defaultMessage: 'Revenue from projects (finalized data)',
  },
  plannedAmount: {
    id: 'finance.dashboard.column.planned.amount',
    defaultMessage: 'Planned Amount',
  },
  paidAmount: {
    id: 'finance.dashboard.column.paid.amount',
    defaultMessage: 'Paid Amount',
  },
  paidPeriod: {
    id: 'finance.dashboard.column.paid.period',
    defaultMessage: 'Paid Period',
  },
  group: {
    id: 'finance.dashboard.column.group',
    defaultMessage: 'Group',
  },
  NET_INCOME: {
    id: 'finance.dashboard.column.net.income',
    defaultMessage: 'Net Income',
  },
  INCOME: {
    id: 'finance.dashboard.title.income',
    defaultMessage: 'Incomes',
  },
  EXPENSE: {
    id: 'finance.dashboard.title.expense',
    defaultMessage: 'Expenses',
  },
});
