import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect, ConnectedProps, useDispatch } from 'react-redux';

import moment from 'moment';

import * as filtersActions from '../../actions/filters.actions';
import * as financeActions from '../../actions/finance.actions';
import { getBaseCurrency } from '../../utils';
import messages from './messages';
import ProfitLossTable from '../../components/ProfitLossReport/Table/ProfitLossTable';
import TotalTable from '../../components/ProfitLossReport/Table/TotalTable';
import FinancialRevenueTable from '../../components/ProfitLossReport/Table/FinancialRevenueTable';
import CostOfGoodsTable from '../../components/ProfitLossReport/Table/CostOfGoodsTable';
import FinancialExpenseTable from '../../components/ProfitLossReport/Table/FinancialExpenseTable';
import { OfficesBalanceParams } from '../../enums/params/finance.params';
import OfficesBalanceFilters from '../../components/OfficesBalance/Filters/OfficesBalanceFilters';
import PoliciesContext from '../../PoliciesContext';
import { VIEW_PROFIT_LOSS_REPORT } from '../../constants/policies.constants';
import { resetParamsChange, useParamsChange } from '../../utils/hooks.utils';
import { DATE_FORMAT } from '../../constants/date.constants';
import { FilterParamsName } from '../../constants/filters.constants';
import { checkParamsMatch } from '../../utils/filters.utils';
import { officesBalanceUnsavedParams } from '../../constants/finance.constants';
import InlineDatePicker from '../../components/InlineDatePicker';

export enum EProfitLossTitles {
  REVENUE_FROM_CLIENT = 'REVENUE_FROM_CLIENT',
  OPERATING_INCOME = 'OPERATING_INCOME',
  NET_INCOME = 'NET_INCOME',
  GROSS_PROFIT = 'GROSS_PROFIT',
  REVENUE_EXPENSE = 'REVENUE_EXPENSE',
  TOTAL_GOODS = 'TOTAL_GOODS',
  FINANCIAL_REVENUE = 'FINANCIAL_REVENUE',
  COST_PROJECT = 'COST_PROJECT',
  FINANCIAL_EXPENSE = 'FINANCIAL_EXPENSE',
}

export enum EProfitLossCost {
  COST_OF_GOODS = 'COST_OF_GOODS',
  COST_OF_PRODUCTS = 'COST_OF_PRODUCTS',
  COST_OF_INTERNAL_ACTIVITY = 'COST_OF_INTERNAL_ACTIVITY',
}

export enum EProfitLossExpense {
  OPERATING_EXPENSES = 'OPERATING_EXPENSES',
  OTHER_FINANCIAL_EXPENSES = 'OTHER_FINANCIAL_EXPENSES',
}

function ProfitLossReport({
  tableData,
  officesFilter,
  isLoading,
  errors,
  params,
  finance,
  currencies,
  getOfficesFilter,
  getCurrenciesFilter,
  resetState,
  resetErrors,

  getIncomeTypesList,
  getExpenseTypesList,
  getProjectsList,
  getClientList,

  getRevenueSettings,
  putRevenueSettings,

  getCostProjectSettings,
  putCostProjectSettings,

  getOperatingIncomeSettings,
  putOperatingIncomeSettings,

  getNetIncomeSettings,
  putNetIncomeSettings,

  getGrossProfitSettings,
  putGrossProfitSettings,

  getRevenueExpenseSettings,
  putRevenueExpenseSettings,

  getTotalGoodsSettings,
  putTotalGoodsSettings,

  getFinancialRevenueSettings,
  putFinancialRevenueSettings,

  getFinancialExpenseSettings,
  putFinancialExpenseSettings,
  setProfitLossParams,
}: ConnectedProps<typeof connector>) {
  const dispatch = useDispatch();
  const policies = useContext(PoliciesContext);

  const viewPolicy = useMemo(() => policies.find(policy => policy.policy.name === VIEW_PROFIT_LOSS_REPORT), [policies]);

  useEffect(() => {
    setProfitLossParams({
      officeIds: officesFilter.value?.filter(
        (el: string) => !viewPolicy?.isOfficeSpecific || viewPolicy?.officeIds?.some(id => id === el),
      ),
    });
  }, [viewPolicy]);

  useEffect(() => {
    getOfficesFilter();
    getCurrenciesFilter();

    getOperatingIncomeSettings();
    getNetIncomeSettings();
    getGrossProfitSettings();
    getRevenueExpenseSettings();
    getTotalGoodsSettings();
    getFinancialRevenueSettings();
    getRevenueSettings();

    getCostProjectSettings(EProfitLossCost.COST_OF_GOODS);
    getCostProjectSettings(EProfitLossCost.COST_OF_PRODUCTS);
    getCostProjectSettings(EProfitLossCost.COST_OF_INTERNAL_ACTIVITY);

    getFinancialExpenseSettings(EProfitLossExpense.OPERATING_EXPENSES);
    getFinancialExpenseSettings(EProfitLossExpense.OTHER_FINANCIAL_EXPENSES);

    return () => {
      resetState();
    };
  }, []);

  const baseCurrency = useMemo(() => getBaseCurrency(currencies), [currencies]);

  const handleMultiParamsChange = useParamsChange(data => {
    setProfitLossParams(data);
  }, dispatch);

  const handleDataChange = useCallback((start: string, end: string) => {
    setProfitLossParams({
      dateFrom: moment(start).format(DATE_FORMAT.YYYY_MM_DD),
      dateTo: moment(end).format(DATE_FORMAT.YYYY_MM_DD),
    });
  }, []);

  const months = useMemo(() => {
    const startDate = moment(params.dateFrom);
    const endDate = moment(params.dateTo);

    const diff = endDate.diff(startDate, 'months', true);
    const range = [];

    for (let i = 0; i < diff; i++) {
      range.push(moment(params.dateFrom).add(i, 'month').format('YYYY-MM-DD'));
    }

    return range;
  }, [params.dateFrom, params.dateTo]);

  const filters = useMemo(
    () => ({
      offices: officesFilter.offices,
    }),
    [officesFilter.offices],
  );

  const handleClear = useCallback(() => {
    setProfitLossParams(new OfficesBalanceParams());

    resetParamsChange([FilterParamsName.OFFICE_IDS], dispatch);
  }, []);

  const showClearButton = useMemo(
    () => !checkParamsMatch(params, new OfficesBalanceParams(), officesBalanceUnsavedParams),
    [params],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
            <div className="page__panel-top__control">
              <div className="pagination page__panel-top__control__pagination">
                <InlineDatePicker
                  onDateChange={handleDataChange}
                  defaultPeriodStart={params.dateFrom}
                  defaultPeriodEnd={params.dateTo}
                  todayBtn={false}
                  weekBtn={false}
                />
              </div>
            </div>
          </div>
          <div className="page__panel-bottom no-border">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <OfficesBalanceFilters
                  filters={filters}
                  values={params}
                  handleMultiParamsChange={handleMultiParamsChange}
                  handleClear={handleClear}
                  setParams={setProfitLossParams}
                  policyOfficiesIds={viewPolicy?.officeIds}
                  isOfficeSpecific={!!viewPolicy?.isOfficeSpecific}
                  showClearButton={showClearButton}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <ProfitLossTable
            key={EProfitLossTitles.REVENUE_FROM_CLIENT}
            title={EProfitLossTitles.REVENUE_FROM_CLIENT}
            tableData={tableData?.revenueFromClients}
            months={months}
            errors={errors}
            clients={finance.clientsListData}
            settingsData={finance.revenueFromClientData}
            settingsLoading={finance.loading.getRevenueFromClient}
            settingsError={finance.errors.revenueFromClientError}
            isLoading={isLoading.getProfitLoss}
            baseCurrency={baseCurrency}
            getSettings={getRevenueSettings}
            putSettings={putRevenueSettings}
            getClientList={getClientList}
            onCloseSettings={resetErrors}
          />
          {[EProfitLossCost.COST_OF_GOODS, EProfitLossCost.COST_OF_PRODUCTS].map(item => (
            <CostOfGoodsTable
              key={item}
              title={item}
              tableData={
                tableData?.costOfProjects
                  ? tableData.costOfProjects.find((it: any) => it.blockType === item)?.costOfProjectBlockData
                  : null
              }
              months={months}
              errors={errors}
              types={finance.projectsListData}
              settingsData={finance.costProjectData}
              settingsLoading={finance.loading.getCostProject}
              settingsError={finance.errors.costProjectError}
              isLoading={isLoading.getProfitLoss}
              baseCurrency={baseCurrency}
              getSettings={getCostProjectSettings}
              putSettings={putCostProjectSettings}
              getTypesList={getProjectsList}
              onCloseSettings={resetErrors}
            />
          ))}
          <TotalTable
            baseCurrency={baseCurrency}
            isLoading={isLoading.getProfitLoss}
            errors={errors}
            months={months}
            key={EProfitLossTitles.TOTAL_GOODS}
            title={EProfitLossTitles.TOTAL_GOODS}
            tableData={tableData}
            settingsData={finance.totalGoodsData}
            settingsLoading={finance.loading.getTotalGoods}
            settingsError={finance.errors.totalGoodsError}
            putSettings={putTotalGoodsSettings}
            onCloseSettings={resetErrors}
          />
          <TotalTable
            baseCurrency={baseCurrency}
            isLoading={isLoading.getProfitLoss}
            errors={errors}
            months={months}
            key={EProfitLossTitles.GROSS_PROFIT}
            title={EProfitLossTitles.GROSS_PROFIT}
            tableData={tableData}
            settingsData={finance.grossProfitData}
            settingsLoading={finance.loading.getGrossProfit}
            settingsError={finance.errors.grossProfitError}
            putSettings={putGrossProfitSettings}
            onCloseSettings={resetErrors}
          />
          <CostOfGoodsTable
            key={EProfitLossCost.COST_OF_INTERNAL_ACTIVITY}
            title={EProfitLossCost.COST_OF_INTERNAL_ACTIVITY}
            tableData={
              tableData?.costOfProjects
                ? tableData.costOfProjects.find((it: any) => it.blockType === EProfitLossCost.COST_OF_INTERNAL_ACTIVITY)
                    ?.costOfProjectBlockData
                : null
            }
            months={months}
            errors={errors}
            types={finance.projectsListData}
            settingsData={finance.costProjectData}
            settingsLoading={finance.loading.getCostProject}
            settingsError={finance.errors.costProjectError}
            isLoading={isLoading.getProfitLoss}
            baseCurrency={baseCurrency}
            getSettings={getCostProjectSettings}
            putSettings={putCostProjectSettings}
            getTypesList={getProjectsList}
            onCloseSettings={resetErrors}
          />
          <FinancialExpenseTable
            key={EProfitLossExpense.OPERATING_EXPENSES}
            title={EProfitLossExpense.OPERATING_EXPENSES}
            tableData={
              tableData?.financialExpenses?.find((it: any) => it.blockType === EProfitLossExpense.OPERATING_EXPENSES)
                ?.financialExpenseBlockData
            }
            months={months}
            errors={errors}
            types={finance.expenseTypesListData}
            settingsData={finance.financialExpenseData}
            settingsLoading={finance.loading.getFinancialExpense}
            settingsError={finance.errors.financialExpenseError}
            isLoading={isLoading.getProfitLoss}
            baseCurrency={baseCurrency}
            getSettings={getFinancialExpenseSettings}
            putSettings={putFinancialExpenseSettings}
            getTypesList={getExpenseTypesList}
            onCloseSettings={resetErrors}
          />
          <TotalTable
            baseCurrency={baseCurrency}
            isLoading={isLoading.getProfitLoss}
            errors={errors}
            months={months}
            key={EProfitLossTitles.OPERATING_INCOME}
            title={EProfitLossTitles.OPERATING_INCOME}
            tableData={tableData}
            settingsData={finance.operatingIncomeData}
            settingsLoading={finance.loading.getOperatingIncome}
            settingsError={finance.errors.operatingIncomeError}
            putSettings={putOperatingIncomeSettings}
            onCloseSettings={resetErrors}
          />
          <FinancialRevenueTable
            key={EProfitLossTitles.FINANCIAL_REVENUE}
            title={EProfitLossTitles.FINANCIAL_REVENUE}
            tableData={tableData?.financialRevenues}
            months={months}
            errors={errors}
            incomes={finance.incomeTypesListData}
            settingsData={finance.financialRevenueData}
            settingsLoading={finance.loading.getFinancialRevenue}
            settingsError={finance.errors.financialRevenueError}
            isLoading={isLoading.getProfitLoss}
            baseCurrency={baseCurrency}
            getSettings={getFinancialRevenueSettings}
            putSettings={putFinancialRevenueSettings}
            getIncomeList={getIncomeTypesList}
            onCloseSettings={resetErrors}
          />
          <FinancialExpenseTable
            key={EProfitLossExpense.OTHER_FINANCIAL_EXPENSES}
            title={EProfitLossExpense.OTHER_FINANCIAL_EXPENSES}
            tableData={
              tableData?.financialExpenses?.find(
                (it: any) => it.blockType === EProfitLossExpense.OTHER_FINANCIAL_EXPENSES,
              )?.financialExpenseBlockData
            }
            months={months}
            errors={errors}
            types={finance.expenseTypesListData}
            settingsData={finance.financialExpenseData}
            settingsLoading={finance.loading.getFinancialExpense}
            settingsError={finance.errors.financialExpenseError}
            isLoading={isLoading.getProfitLoss}
            baseCurrency={baseCurrency}
            getSettings={getFinancialExpenseSettings}
            putSettings={putFinancialExpenseSettings}
            getTypesList={getExpenseTypesList}
            onCloseSettings={resetErrors}
          />
          <TotalTable
            baseCurrency={baseCurrency}
            isLoading={isLoading.getProfitLoss}
            errors={errors}
            months={months}
            key={EProfitLossTitles.REVENUE_EXPENSE}
            title={EProfitLossTitles.REVENUE_EXPENSE}
            tableData={tableData}
            settingsData={finance.revenueExpenseData}
            settingsLoading={finance.loading.getRevenueExpense}
            settingsError={finance.errors.revenueExpenseError}
            putSettings={putRevenueExpenseSettings}
            onCloseSettings={resetErrors}
          />
          <TotalTable
            baseCurrency={baseCurrency}
            isLoading={isLoading.getProfitLoss}
            errors={errors}
            months={months}
            key={EProfitLossTitles.NET_INCOME}
            title={EProfitLossTitles.NET_INCOME}
            tableData={tableData}
            settingsData={finance.netIncomeData}
            settingsLoading={finance.loading.getNetIncome}
            settingsError={finance.errors.netIncomeError}
            putSettings={putNetIncomeSettings}
            onCloseSettings={resetErrors}
          />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ filters, auth, finance }: RootState) => ({
  finance: finance,
  tableData: finance.profitLossListData,
  officesFilter: filters.officesFilter,
  isLoading: finance.loading,
  errors: finance.errors.profitLossError,
  params: finance.profitLossParams,
  authUserId: auth.currentUserInfo.id,
  currencies: filters.currenciesFilter.currencies,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getOfficesFilter: () => dispatch(filtersActions.getOfficesFilter()),
  getCurrenciesFilter: () => dispatch(filtersActions.getCurrenciesFilter()),
  resetState: () => dispatch(financeActions.resetState()),
  resetErrors: () => dispatch(financeActions.resetErrors()),

  getIncomeTypesList: () => dispatch(financeActions.getIncomeTypesList()),
  getExpenseTypesList: (data: any) => dispatch(financeActions.getExpenseTypesFullList(data)),
  getProjectsList: () => dispatch(financeActions.getProjectsList()),
  getClientList: () => dispatch(financeActions.getClientsList()),

  setProfitLossParams: (data: Partial<OfficesBalanceParams>) => dispatch(financeActions.setProfitLossParams(data)),

  getRevenueSettings: () => dispatch(financeActions.getProfitLossRevenueClient()),
  putRevenueSettings: (data: any) => dispatch(financeActions.putProfitLossRevenueClient(data)),

  getCostProjectSettings: (data: string) => dispatch(financeActions.getProfitLossCostOfProject(data)),
  putCostProjectSettings: (data: any) => dispatch(financeActions.putProfitLossCostOfProject(data)),

  getOperatingIncomeSettings: () => dispatch(financeActions.getProfitLossOperatingIncome()),
  putOperatingIncomeSettings: (data: any) => dispatch(financeActions.putProfitLossOperatingIncome(data)),

  getNetIncomeSettings: () => dispatch(financeActions.getProfitLossNetIncome()),
  putNetIncomeSettings: (data: any) => dispatch(financeActions.putProfitLossNetIncome(data)),

  getGrossProfitSettings: () => dispatch(financeActions.getProfitLossGrossProfit()),
  putGrossProfitSettings: (data: any) => dispatch(financeActions.putProfitLossGrossProfit(data)),

  getRevenueExpenseSettings: () => dispatch(financeActions.getProfitLossRevenueExpense()),
  putRevenueExpenseSettings: (data: any) => dispatch(financeActions.putProfitLossRevenueExpense(data)),

  getTotalGoodsSettings: () => dispatch(financeActions.getProfitLossTotalGoods()),
  putTotalGoodsSettings: (data: any) => dispatch(financeActions.putProfitLossTotalGoods(data)),

  getFinancialRevenueSettings: () => dispatch(financeActions.getProfitLossFinancialRevenue()),
  putFinancialRevenueSettings: (data: any) => dispatch(financeActions.putProfitLossFinancialRevenue(data)),

  getFinancialExpenseSettings: (data: string) => dispatch(financeActions.getProfitLossFinancialExpense(data)),
  putFinancialExpenseSettings: (data: any) => dispatch(financeActions.putProfitLossFinancialExpense(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ProfitLossReport);
