import React, { useMemo, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, BubbleDataPoint, ScatterDataPoint } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import { CurrencyType } from '../../../types/finance';
import Button from '../../Button';
import Icon from '../../Icon';
import { EDashboardTitles } from '../../../pages/Dashboard';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import BlockSettingsModal from '../Modal/BlockSettingsModal';
import { DashboardPieData } from '../../../enums/finance/finance.enum';
import ModalEditProfitLossNameSettings from '../../ProfitLossReport/Modal/ProfitLossNameModal';
import { formatValue } from 'react-currency-input-field';

type DashboardProps = {
  title: EDashboardTitles;
  name?: string;
  data: any;
  viewPolicy: boolean;
  updatePolicy: boolean;
  baseCurrency: CurrencyType | undefined;
  settingsData: any;
  settingsLoading: boolean;
  settingsError: string | null;
  getTypeList: () => void;
  putSettingsData: (data: any) => void;
  typeLabel: string;
  typesList: any;
  errors: string | null;
  onCloseSettings: () => void;
  range: string;
};

type PieChartDataset = number | ScatterDataPoint | BubbleDataPoint | null;

ChartJS.register(ArcElement, Tooltip, Legend);

const DashboardPieChart = ({
  title,
  data,
  name,
  viewPolicy,
  updatePolicy,
  settingsData,
  settingsError,
  settingsLoading,
  typeLabel,
  typesList,
  getTypeList,
  putSettingsData,
  onCloseSettings,
  baseCurrency,
  errors,
  range,
}: DashboardProps) => {
  const [openSettingsModal, setOpenSettingModal] = useState(false);

  const convertedData = useMemo(() => {
    return {
      labels: data
        ? title === EDashboardTitles.REVENUE_BY_CUSTOMER
          ? data.map(
              (item: DashboardPieData) =>
                `${item.expenseType ? item.expenseType.name : item.client?.name} - ${Number(item.percentage)}%`,
            )
          : data
              .sort((a: DashboardPieData, b: DashboardPieData) => {
                const aName = a.expenseType?.name || a.client?.name || '';
                const bName = b.expenseType?.name || b.client?.name || '';

                if (aName < bName) {
                  return -1;
                }

                if (aName > bName) {
                  return 1;
                }

                return 0;
              })
              .map(
                (item: DashboardPieData) =>
                  `${item.expenseType ? item.expenseType.name : item.client?.name} - ${Number(item.percentage)}%`,
              )
        : [],
      datasets: [
        {
          data: data ? data.map((item: any) => Number(item.amount)) : [],
          backgroundColor: ['#F0504B', '#FF8A00', '#F3CA04', '#5AAB18', '#1BACC0', '#2E99FC', '#9C47C7'],
          borderWidth: 1,
          cutout: '80%',
        },
      ],
    };
  }, [data]);

  const openSetting = () => {
    setOpenSettingModal(true);
  };

  const closeSetting = () => {
    onCloseSettings();
    setOpenSettingModal(false);
  };

  const editSetting = (data: any) => {
    if (title === EDashboardTitles.EXPENSES_STRUCTURE) {
      putSettingsData({ ...data, params: { name: data.params, blockType: EDashboardTitles.EXPENSES_STRUCTURE } });
    } else putSettingsData(data);
  };

  return (
    <div className="dashboard-table__block">
      <div className="dashboard-table__block-item">
        <h2 className="dashboard-table__block-title">
          {name ?? <FormattedMessage {...messages[title]} />} ({range})
        </h2>
        <div className="dashboard-table__block-item__range-container">
          <span className="dashboard-table__block-item__range-container_content">{range}</span>
          {viewPolicy ? (
            <Button color="gray" externalClass="cash-flow__report-button" onClick={openSetting}>
              <Icon iconName="pencil" externalClass="button__icon" />
            </Button>
          ) : null}
        </div>
      </div>
      {!errors ? (
        <div className="dashboard-chart">
          {data && data.length ? (
            <Doughnut
              className="dashboard-doughnut"
              data={convertedData}
              options={{
                aspectRatio: 2,
                layout: {
                  padding: 30,
                },
                plugins: {
                  legend: {
                    position: 'right',
                    labels: {
                      boxWidth: 16,
                      boxHeight: 16,
                    },
                  },
                },
              }}
              plugins={[
                {
                  id: 'text',
                  beforeDraw: function (chart) {
                    const width = chart.width,
                      height = chart.height,
                      ctx = chart.ctx;

                    ctx.restore();
                    const fontSize = (height / 150).toFixed(2);
                    ctx.font = fontSize + 'em sans-serif';
                    ctx.textBaseline = 'middle';

                    const text =
                        formatValue({
                          value: Math.round(
                            chart.config.data.datasets[0].data.reduce(
                              (partialSum: number, a: PieChartDataset) => partialSum + Number(a),
                              0,
                            ),
                          ).toString(),
                        }) + ` ${baseCurrency?.name || ''}`,
                      textX = Math.round((width - (chart.legend?.width || 0) - ctx.measureText(text).width) / 2),
                      textY = height / 2;

                    ctx.fillText(text, textX, textY);
                    ctx.save();
                  },
                },
              ]}
            />
          ) : null}
        </div>
      ) : (
        <div className="dashboard-wrapper">
          <caption key={'caption'} className="table__no-data error" />
        </div>
      )}
      {openSettingsModal && title === EDashboardTitles.REVENUE_BY_CUSTOMER && (
        <BlockSettingsModal
          isOpen={openSettingsModal}
          getTypeList={getTypeList}
          title={title}
          updatePolicy={updatePolicy}
          typeLabel={typeLabel}
          typesList={typesList}
          onCloseRequest={closeSetting}
          editSetting={editSetting}
          loading={settingsLoading}
          error={settingsError}
          data={settingsData}
        />
      )}
      {openSettingsModal && title === EDashboardTitles.EXPENSES_STRUCTURE && (
        <ModalEditProfitLossNameSettings
          isOpen={openSettingsModal}
          onCloseRequest={closeSetting}
          editSetting={editSetting}
          title={title}
          name={settingsData?.name}
        />
      )}
    </div>
  );
};

export default DashboardPieChart;
