import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as candidatesActions from '../../actions/candidates.actions';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Table from '../../components/Table';
import { UPDATE_CANDIDATE_STATUS, DELETE_CANDIDATE_STATUS } from '../../constants/policies.constants';
import AccessChecker from '../../components/AccessChecker';
import { CandidateStatus } from '../../enums/candidates.enums';
import ModalNewCandidateStatus from '../../components/CandidateStatuses/Modals/ModalNewCandidateStatus';
import ModalEditCandidateStatus from '../../components/CandidateStatuses/Modals/ModalEditCandidateStatus';
import ModalDeleteCandidateStatus from '../../components/CandidateStatuses/Modals/ModalDeleteCandidateStatus';
import Label from '../../components/Label';
import { checkPolicies } from '../../utils/policies.utils';
import PoliciesContext from '../../PoliciesContext';
import RefreshButton from '../../components/RefreshButton';

function CandidateStatuses({
  tableData,
  isLoading,
  candidateStatusesListError,
  candidateStatusError,
  getCandidateStatusesList,
  createCandidateStatus,
  editCandidateStatus,
  deleteCandidateStatus,
  resetState,
  resetErrors,
}: ConnectedProps<typeof connector>) {
  const intl = useIntl();
  const policies = useContext(PoliciesContext);
  const [modalNewCandidateStatusIsOpen, setModalNewCandidateStatusIsOpen] = useState(false);
  const [modalEditCandidateStatusIsOpen, setModalEditCandidateStatusIsOpen] = useState(false);
  const [modalDeleteCandidateStatusIsOpen, setModalDeleteCandidateStatusIsOpen] = useState(false);
  const [candidateStatusClicked, setCandidateStatusClicked] = useState<CandidateStatus>(new CandidateStatus());

  useEffect(() => {
    getCandidateStatusesList();
    return () => {
      resetState();
    };
  }, []);

  const openNewCandidateStatusModal = useCallback(() => {
    setModalNewCandidateStatusIsOpen(true);
  }, []);

  const closeNewCandidateStatusModal = useCallback(() => {
    setModalNewCandidateStatusIsOpen(false);
    resetErrors();
  }, []);

  const openEditCandidateStatusModal = useCallback(() => {
    setModalEditCandidateStatusIsOpen(true);
  }, []);

  const closeEditCandidateStatusModal = useCallback(() => {
    setModalEditCandidateStatusIsOpen(false);
    resetErrors();
  }, []);

  const openDeleteCandidateStatusModal = useCallback(() => {
    setModalDeleteCandidateStatusIsOpen(true);
  }, []);

  const closeDeleteCandidateStatusModal = useCallback(() => {
    setModalDeleteCandidateStatusIsOpen(false);
    resetErrors();
  }, []);

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.nameColumn),
        modifier: (row: CandidateStatus) => <span style={{ color: `#${row.color}` }}>{row.name}</span>,
      },
      {
        name: '',
        modifier: (row: CandidateStatus) => (
          <span>
            {row.isDefault && <Label type="default-status">{intl.formatMessage(messages.defaultColumnLabel)}</Label>}
          </span>
        ),
      },
    ],
    [],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: CandidateStatus) => {
          setCandidateStatusClicked(row);
          openEditCandidateStatusModal();
        },
        verifiablePolicies: [UPDATE_CANDIDATE_STATUS],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: CandidateStatus) => {
          setCandidateStatusClicked(row);
          openDeleteCandidateStatusModal();
        },
        verifiablePolicies: [DELETE_CANDIDATE_STATUS],
      },
    ],
    [],
  );

  const submitTable = useCallback(
    (data: Record<string, unknown>) => {
      const status = tableData[data.oldIndex as number];
      editCandidateStatus({
        data: new CandidateStatus({ ...status, position: data.newIndex }),
        callback: () => null,
      });
    },
    [tableData],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom no-border">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[UPDATE_CANDIDATE_STATUS]}>
                  <Button externalClass={'button--with-icon'} onClick={openNewCandidateStatusModal}>
                    <Icon iconName={'plus'} externalClass={'button__icon'} />
                    <span className="button__text">
                      <FormattedMessage {...messages.newButton} />
                    </span>
                  </Button>
                </AccessChecker>
                <RefreshButton onRefresh={() => getCandidateStatusesList()} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content half-content">
        <div className="page__wrapper">
          <Table
            tableColumns={tableColumns}
            tableData={tableData}
            loading={isLoading.getCandidateStatusesList}
            error={candidateStatusesListError}
            tableActions={tableActions}
            onDragSort={submitTable}
            sortable={checkPolicies([UPDATE_CANDIDATE_STATUS], policies)}
          />
        </div>
      </div>
      {modalNewCandidateStatusIsOpen && (
        <ModalNewCandidateStatus
          isOpen
          error={candidateStatusError}
          isLoading={isLoading.createCandidateStatus}
          onCloseRequest={closeNewCandidateStatusModal}
          createCandidateStatus={createCandidateStatus}
        />
      )}
      {modalEditCandidateStatusIsOpen && (
        <ModalEditCandidateStatus
          candidateStatus={candidateStatusClicked}
          isOpen
          error={candidateStatusError}
          isLoading={isLoading.editCandidateStatus}
          onCloseRequest={closeEditCandidateStatusModal}
          editCandidateStatus={editCandidateStatus}
        />
      )}
      {modalDeleteCandidateStatusIsOpen && (
        <ModalDeleteCandidateStatus
          candidateStatus={candidateStatusClicked}
          isOpen
          isLoading={isLoading.deleteCandidateStatus}
          error={candidateStatusError}
          onCloseRequest={closeDeleteCandidateStatusModal}
          onDeleteRequest={deleteCandidateStatus}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ candidates }: RootState) => ({
  tableData: candidates.candidateStatusesList?.content || [],
  isLoading: candidates.loading,
  candidateStatusesListError: candidates.errors.candidateStatusesListError,
  candidateStatusError: candidates.errors.candidateStatusError,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getCandidateStatusesList: () => dispatch(candidatesActions.getCandidateStatusesList()),
  createCandidateStatus: (data: { data: CandidateStatus; callback: () => void }) =>
    dispatch(candidatesActions.createCandidateStatus(data)),
  editCandidateStatus: (data: { data: CandidateStatus; callback: () => void }) =>
    dispatch(candidatesActions.editCandidateStatus(data)),
  deleteCandidateStatus: (data: { data: string; callback: () => void }) =>
    dispatch(candidatesActions.deleteCandidateStatus(data)),
  resetState: () => dispatch(candidatesActions.resetState()),
  resetErrors: () => dispatch(candidatesActions.resetErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(CandidateStatuses);
