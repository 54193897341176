import React, { useCallback, useEffect, useMemo } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import * as planningActions from '../../actions/planning.actions';
import moment from 'moment';
import DurationPicker from '../../components/Dropdown';
import Icon from '../../components/Icon';
import InlineDatePicker from '../../components/InlineDatePicker';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { UserInfo } from '../../enums/users.enum';
import TableUserAvatar from '../../components/TableUserAvatar';
import * as filtersActions from '../../actions/filters.actions';
import { ProjectHoursParams } from '../../enums/params/planning.params';
import { DATE_FORMAT } from '../../constants/date.constants';
import { getTime } from '../../utils/planning.utils';
import HierarchicalTable from '../../components/HierarchicalTable';
import { resetParamsChange, useParamsChange, useTableData } from '../../utils/hooks.utils';
import { getTableCell } from '../../utils/table.utils';
import { DepthLevels } from '../../constants/tables.constants';
import { EFileExtensions } from '../../constants/export.constants';
import { exportProjectHours } from '../../actions/export.actions';
import ProjectHoursFilter from '../../components/ProjectHours/Filter/ProjectHoursFilter';
import { SavedFilter } from '../../enums/filters.enum';
import { SavedFilterParams } from '../../enums/params/filters.params';
import {
  checkParamsMatch,
  convertSavedFieldsToParams,
  getSavedFilterParams,
  getSavedFilters,
} from '../../utils/filters.utils';
import { FilterParamsName, FilterTypes } from '../../constants/filters.constants';
import { projectHoursUnsavedParams } from '../../constants/planning.constants';

export type ProjectHoursType = {
  employees: EmployeeType[];
  factEmployees: number;
  factHours: number;
  factPlan: number;
  planEmployees: number;
  planHours: number;
  projectGroup: { id: string; fullName: string; shortName: string };
  projectsCount: number;
};

type EmployeeType = {
  factHours: number;
  factPlan: number;
  planHours: number;
  user: { id: string; firstName: string; secondName: string; photo: any };
};

function ProjectHours({
  tableData,
  params,
  getOfficesFilter,
  getDepartmentsFilter,
  getProjectGroupsFilter,
  officesFilter,
  departmentsFilter,
  projectGroupsFilter,
  isLoading,
  setProjectHoursParams,
  projectHoursDataError,
  exportProjectHours,
  setSavedFiltersParams,
  createNewSavedFilter,
  savedFiltersData,
  editSavedFilter,
  deleteSavedFilter,
  resetSavedFilterErrors,
  authUserId,
}: ConnectedProps<typeof connector>) {
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    setProjectHoursParams({});

    const currentSavedFilter = getSavedFilterParams(getSavedFilters(), FilterTypes.PROJECT_HOURS_DETAILS_FILTER);

    setProjectHoursParams(
      currentSavedFilter
        ? new ProjectHoursParams(currentSavedFilter)
        : {
            officeIds: officesFilter.value,
            departmentIds: departmentsFilter.value,
            projectGroupIds: projectGroupsFilter.value,
          },
    );
    getOfficesFilter();
    getDepartmentsFilter();
    getProjectGroupsFilter();
    setSavedFiltersParams({ filterType: FilterTypes.PROJECT_HOURS_DETAILS_FILTER });
  }, []);

  const handleMultiParamsChange = useParamsChange(setProjectHoursParams, dispatch);

  const newTableData = useMemo(() => {
    const newData = [...(tableData?.complex || [])];

    newData?.push({
      factEmployees: null,
      factHours: tableData?.factHours,
      factPlan: tableData?.factPlan,
      planEmployees: null,
      planHours: tableData?.planHours,
      projectGroup: { id: '', fullName: intl.formatMessage(messages.totalRow), shortName: '' },
      projectsCount: tableData?.projectsCount,
    });
    return newData;
  }, [tableData]);

  const onDateChange = (start: string, end: string) => {
    setProjectHoursParams({
      dateFrom: moment(start).format(DATE_FORMAT.YYYY_MM_DD),
      dateTo: moment(end).format(DATE_FORMAT.YYYY_MM_DD),
    });
  };

  const tableColumns = useMemo(
    () => [
      {
        id: 'departmentName',
        Header: () => <span>{intl.formatMessage(messages.projectGroupsEmployeesTitle)}</span>,
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: ProjectHoursType) => <span>{row.projectGroup.fullName}</span>,
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: EmployeeType) => <TableUserAvatar users={[new UserInfo(row.user)]} fileSize={48} />,
            },
          ]),
      },
      {
        Header: intl.formatMessage(messages.projectsLabel),
        accessor: 'projectsCount',
      },
      {
        id: 'employeesFactPlanTitle',
        Header: intl.formatMessage(messages.employeesFactPlanTitle),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: ProjectHoursType) => {
                const factEmployees = row.factEmployees;
                const planEmployees = row.planEmployees;
                return (
                  <span>
                    {factEmployees !== null && planEmployees !== null ? `${factEmployees} / ${planEmployees}` : ''}
                  </span>
                );
              },
            },
          ]),
      },
      {
        id: 'planTitle',
        Header: intl.formatMessage(messages.planColumn),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: ProjectHoursType) => <span>{getTime(row.planHours)}</span>,
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: EmployeeType) => <span>{getTime(row.planHours)}</span>,
            },
          ]),
      },
      {
        id: 'factTitle',
        Header: intl.formatMessage(messages.factColumn),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: (row: ProjectHoursType) => <span>{getTime(row.factHours)}</span>,
            },
            {
              depth: DepthLevels.SECOND,
              content: (row: EmployeeType) => <span>{getTime(row.factHours)}</span>,
            },
          ]),
      },
      {
        Header: intl.formatMessage(messages.planFactTitle),
        accessor: 'factPlan',
      },
    ],
    [],
  );

  const handleExternalRowClass = useCallback((row: any) => {
    if (row.depth === 1) {
      return row.id.split('.')[1] % 2 === 0 ? 'even' : '';
    }
    return '';
  }, []);

  const dropdownList = useMemo(
    () => [
      {
        label: intl.formatMessage(messages.exportToXLSLabel),
        handler: () => exportProjectHours(EFileExtensions.XLS),
      },
      {
        label: intl.formatMessage(messages.exportToPDFLabel),
        handler: () => exportProjectHours(EFileExtensions.PDF),
      },
    ],
    [],
  );

  const filters = useMemo(
    () => ({
      departments: departmentsFilter.departments,
      offices: officesFilter.offices,
      projectGroups: projectGroupsFilter.projectGroups,
    }),
    [officesFilter.offices, departmentsFilter.departments, projectGroupsFilter.projectGroups],
  );

  const handleFiltersControlChange = useCallback(
    value => {
      setProjectHoursParams(
        new ProjectHoursParams({
          ...convertSavedFieldsToParams(value.fields),
          dateFrom: params.dateFrom,
          dateTo: params.dateTo,
        }),
      );
    },
    [params],
  );

  const handleClear = useCallback(() => {
    setProjectHoursParams(new ProjectHoursParams({ dateFrom: params.dateFrom, dateTo: params.dateTo }));
    resetParamsChange(
      [FilterParamsName.PROJECT_GROUP_IDS, FilterParamsName.DEPARTMENT_IDS, FilterParamsName.OFFICE_IDS],
      dispatch,
    );
  }, [params]);

  const showClearButton = useMemo(
    () => !checkParamsMatch(params, new ProjectHoursParams(), projectHoursUnsavedParams),
    [params],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <div className="page__panel-top__wrapper--left">
              <h1 className="page__title">
                <FormattedMessage {...messages.pageTitle} />
              </h1>
            </div>
            <div className="page__panel-top__control">
              <InlineDatePicker
                onDateChange={onDateChange}
                defaultPeriodStart={params.dateFrom}
                defaultPeriodEnd={params.dateTo}
              />
              <DurationPicker
                dropdownToggle={<Icon iconName="dots" externalClass="dropdown__button-main-icon" />}
                dropdownList={dropdownList}
              />
            </div>
          </div>
          <div className="page__panel-bottom">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <ProjectHoursFilter
                  filters={filters}
                  values={params}
                  handleMultiParamsChange={handleMultiParamsChange}
                  createNewSavedFilter={createNewSavedFilter}
                  savedFiltersData={savedFiltersData}
                  authUserId={authUserId}
                  deleteSavedFilter={deleteSavedFilter}
                  editSavedFilter={editSavedFilter}
                  handleFiltersControlChange={handleFiltersControlChange}
                  handleClear={handleClear}
                  setProjectHoursParams={setProjectHoursParams}
                  resetSavedFilterErrors={resetSavedFilterErrors}
                  showClearButton={showClearButton}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <HierarchicalTable
            tableData={useTableData(newTableData, ['employees'])}
            tableHeaderClassName="user_hours_header"
            tableColumns={tableColumns}
            externalClass="project_hours_table fixed"
            loading={isLoading.getProjectHoursData}
            error={projectHoursDataError}
            externalRowClass={handleExternalRowClass}
            disabledDepthHover={[DepthLevels.SECOND]}
          />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ planning, filters, auth }: RootState) => ({
  projectHoursDataError: planning.errors.projectHoursDataError,
  isLoading: planning.loading,
  tableData: planning.projectHoursTableData,
  officesFilter: filters.officesFilter,
  departmentsFilter: filters.departmentsFilter,
  projectGroupsFilter: filters.projectGroupsFilter,
  params: planning.projectHoursParams,
  savedFiltersData: filters.savedFilters,
  authUserId: auth.currentUserInfo.id,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getOfficesFilter: () => dispatch(filtersActions.getOfficesFilter()),
  getDepartmentsFilter: () => dispatch(filtersActions.getDepartmentsFilter()),
  getProjectGroupsFilter: () => dispatch(filtersActions.getProjectGroupsFilter()),
  setProjectHoursParams: (data: Partial<ProjectHoursParams>) => dispatch(planningActions.setProjectHoursParams(data)),
  exportProjectHours: (ext: EFileExtensions) => dispatch(exportProjectHours(ext)),
  resetState: () => dispatch(planningActions.resetPlaningState()),
  setSavedFiltersParams: (data: Partial<SavedFilterParams>) => dispatch(filtersActions.setSavedFiltersParams(data)),
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) =>
    dispatch(filtersActions.createNewSavedFilter(data)),
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) =>
    dispatch(filtersActions.editSavedFilter(data)),
  deleteSavedFilter: (data: { id: string; callback: () => void }) => dispatch(filtersActions.deleteSavedFilter(data)),
  resetSavedFilterErrors: () => dispatch(filtersActions.resetSavedFilterErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ProjectHours);
