import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as questionnairesActions from '../../actions/questionnaires.actions';
import { PollTemplate } from '../../enums/questionnaires.enum';
import Icon from '../../components/Icon';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { PollTemplatesCategoryParams } from '../../enums/params/questionnaires.params';
import { UPDATE_TEMPLATE_CATEGORY } from '../../constants/policies.constants';
import AccessChecker from '../../components/AccessChecker';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import { useDataForTable } from './useDataForTable';
import { PollTemplateCategoryInfoType } from '../../types/questionnaires';
import ModalNewPollCategory from '../../components/PollCategory/Modals/ModalNewPollCategory';
import ModalEditPollCategory from '../../components/PollCategory/Modals/ModalEditPollCategory';
import ModalDeletePollCategory from '../../components/PollCategory/Modals/ModalDeletePollCategory';
import Button from '../../components/Button';
import RefreshButton from '../../components/RefreshButton';

export type CategoryDataType = {
  position: number;
  name: string;
  id: string;
  templates: PollTemplate[];
};

function PollTemplates({
  isLoading,
  tableData,
  sortParams,
  pollTemplatesCategoryListError,
  pollTemplatesCategoryError,
  setPollTemplatesCategoryParams,
  createNewPollTemplatesCategory,
  editPollTemplatesCategory,
  deletePollTemplatesCategory,
  getPollTemplatesCategoryList,
  resetErrors,
  resetState,
}: ConnectedProps<typeof connector>) {
  const [modalNewPollCategoryOpen, setModalNewPollCategoryOpen] = useState(false);
  const [modalEditCategoryIsOpen, setModaEditlPollCategoryIsOpen] = useState(false);
  const [modalDeleteCategoryIsOpen, setModalDeletePollCategoryIsOpen] = useState(false);
  const [categoryClicked, setCategoryClicked] = useState<PollTemplateCategoryInfoType>({ id: '', name: '' });

  useEffect(() => {
    getPollTemplatesCategoryList();
    return () => {
      resetState();
    };
  }, []);

  const openNewPollCategoryModal = useCallback(() => {
    setModalNewPollCategoryOpen(true);
  }, []);

  const closeNewPollCategoryModal = useCallback(() => {
    setModalNewPollCategoryOpen(false);
  }, []);

  const openEditPollCategoryModal = useCallback(() => {
    setModaEditlPollCategoryIsOpen(true);
  }, []);

  const closeEditPollCategoryModal = useCallback(() => {
    setModaEditlPollCategoryIsOpen(false);
  }, []);

  const openDeletePollCategoryModal = useCallback(() => {
    setModalDeletePollCategoryIsOpen(true);
  }, []);

  const closeDeletePollCategoryModal = useCallback(() => {
    setModalDeletePollCategoryIsOpen(false);
  }, []);

  const { tableColumns, tableActions } = useDataForTable(
    setCategoryClicked,
    openEditPollCategoryModal,
    openDeletePollCategoryModal,
  );

  const pageable = useMemo(
    () => ({
      ...tableData?.pageable,
      ...tableData?.sort,
      totalElements: tableData?.totalElements,
      numberOfElements: tableData?.numberOfElements,
      totalPages: tableData?.totalPages,
    }),
    [tableData],
  );

  const handlePageChange = useCallback(({ selected }) => {
    setPollTemplatesCategoryParams({ page: selected });
  }, []);

  const handleSizeChange = useCallback(data => {
    setPollTemplatesCategoryParams({ size: data, page: 0 });
  }, []);

  const handleSort = useCallback((sortBy, direction) => setPollTemplatesCategoryParams({ sortBy, direction }), []);

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom no-border">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[UPDATE_TEMPLATE_CATEGORY]}>
                  <Button externalClass={'button--with-icon'} onClick={openNewPollCategoryModal}>
                    <Icon iconName={'plus'} externalClass={'button__icon'} />
                    <span className="button__text">
                      <FormattedMessage {...messages.newButton} />
                    </span>
                  </Button>
                </AccessChecker>
                <RefreshButton onRefresh={() => setPollTemplatesCategoryParams(sortParams)} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content half-content">
        <div className="page__wrapper">
          <Table
            tableColumns={tableColumns}
            tableData={tableData?.content || []}
            params={sortParams}
            tableActions={tableActions}
            onSort={handleSort}
            error={pollTemplatesCategoryListError}
            loading={isLoading.getPollTemplatesCategories}
          />
          <Pagination pageable={pageable} onPageChange={handlePageChange} onPageSizeChange={handleSizeChange} />
        </div>
      </div>
      {modalNewPollCategoryOpen && (
        <ModalNewPollCategory
          isOpen
          onCloseRequest={closeNewPollCategoryModal}
          createNewPollTemplatesCategory={createNewPollTemplatesCategory}
          pollTemplatesCategoryError={pollTemplatesCategoryError}
          isLoading={isLoading.createPollTemplatesCategory}
          resetErrors={resetErrors}
        />
      )}
      {modalEditCategoryIsOpen && (
        <ModalEditPollCategory
          isOpen
          onCloseRequest={closeEditPollCategoryModal}
          editPollTemplatesCategory={editPollTemplatesCategory}
          categoryData={categoryClicked}
          categoryError={pollTemplatesCategoryError}
          isLoading={isLoading.editPollTemplatesCategory}
          resetErrors={resetErrors}
        />
      )}
      {modalDeleteCategoryIsOpen && (
        <ModalDeletePollCategory
          isOpen
          onCloseRequest={closeDeletePollCategoryModal}
          onDeleteRequest={deletePollTemplatesCategory}
          isLoading={isLoading.deletePollTemplatesCategory}
          categoryError={pollTemplatesCategoryError}
          categoryData={categoryClicked}
          resetErrors={resetErrors}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ questionnaires }: RootState) => ({
  tableData: questionnaires.pollTemplatesCategoryTableData,
  pollTemplatesCategoryListError: questionnaires.errors.pollTemplatesCategoryListError,
  pollTemplatesCategoryError: questionnaires.errors.pollTemplatesCategoryError,
  isLoading: questionnaires.loading,
  sortParams: questionnaires.pollTemplatesParams,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getPollTemplatesCategoryList: () => dispatch(questionnairesActions.getPollTemplatesCategoryList()),
  deletePollTemplatesCategory: (data: Record<string, unknown>) =>
    dispatch(questionnairesActions.deletePollTemplatesCategory(data)),
  createNewPollTemplatesCategory: (data: Record<string, unknown>) =>
    dispatch(questionnairesActions.createNewPollTemplatesCategory(data)),
  editPollTemplatesCategory: (data: Record<string, unknown>) =>
    dispatch(questionnairesActions.editPollTemplatesCategory(data)),
  setPollTemplatesCategoryParams: (data: Partial<PollTemplatesCategoryParams>) =>
    dispatch(questionnairesActions.setPollTemplatesCategoryParams(data)),
  resetErrors: () => dispatch(questionnairesActions.resetErrors()),
  resetState: () => dispatch(questionnairesActions.resetState()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(PollTemplates);
