import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import * as financeActions from '../../actions/finance.actions';
import * as filtersActions from '../../actions/filters.actions';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import { Salary, SeleriesMonth, UserReports } from '../../enums/finance/finance.enum';
import ModalNewSalary from '../../components/Salaries/Modals/ModalNewSalary';
import ModalDeleteSalary from '../../components/Salaries/Modals/ModalDeleteSalary';
import ModalEditSalary from '../../components/Salaries/Modals/ModalEditSalary';
import InlineDatePicker from '../../components/InlineDatePicker';
import { DATE_FORMAT } from '../../constants/date.constants';
import moment from 'moment';
import SalariesFilter from '../../components/Salaries/Filters/SalariesFilter';
import AccessChecker from '../../components/AccessChecker';
import { UPDATE_SALARY, VIEW_SALARY } from '../../constants/policies.constants';
import { InlineDatePickerPeriods } from '../../components/InlineDatePicker/constants';
import { resetParamsChange, useParamsChange, useTableData, useUsersParamsChange } from '../../utils/hooks.utils';
import { useDataForTable } from './useDataForTable';
import { UserInfo } from '../../enums/users.enum';
import { FilterParamsName, FilterTypes } from '../../constants/filters.constants';
import {
  checkParamsMatch,
  convertSavedFieldsToParams,
  getSavedFilterParams,
  getSavedFilters,
} from '../../utils/filters.utils';
import { SalariesParams } from '../../enums/params/finance.params';
import { SavedFilterParams } from '../../enums/params/filters.params';
import { SavedFilter } from '../../enums/filters.enum';
import PoliciesContext from '../../PoliciesContext';
import isEqual from 'lodash-es/isEqual';
import { salariesUnsavedParams } from '../../constants/finance.constants';
import HierarchicalTable from '../../components/HierarchicalTable';
import { OfficeInfo } from '../../enums/libraries.enum';

function Salaries({
  tableData,
  currentSalary,
  isLoading,
  errors,
  getSalary,
  editSalary,
  deleteSalary,
  resetState,
  resetErrors,
  usersFilter,
  getUsersFilter,
  setSalariesParams,
  getOfficesFilter,
  getDepartmentsFilter,
  departmentsFilter,
  officesFilter,
  params,
  setSavedFiltersParams,
  createNewSavedFilter,
  savedFiltersData,
  editSavedFilter,
  deleteSavedFilter,
  resetSavedFilterErrors,
  authUserId,
}: ConnectedProps<typeof connector>) {
  const dispatch = useDispatch();
  const policies = useContext(PoliciesContext);

  const viewPolicy = useMemo(() => policies.find(policy => policy.policy.name === VIEW_SALARY), [policies]);

  const [modalNewSalaryIsOpen, setModalNewSalaryIsOpen] = useState(false);
  const [modalEditSalaryIsOpen, setModalEditSalaryIsOpen] = useState(false);
  const [modalDeleteSalaryIsOpen, setModalDeleteSalaryIsOpen] = useState(false);
  const [salaryClicked, setSalaryClicked] = useState<Salary>(new Salary());
  const [userMonthClicked, setUserMonthClicked] = useState<{ user: UserInfo; month: string } | null>(null);

  useEffect(() => {
    getOfficesFilter();
    getDepartmentsFilter();
    getUsersFilter();
    setSavedFiltersParams({ filterType: FilterTypes.SALARIES_FILTER });

    return () => {
      resetState();
    };
  }, []);

  useEffect(() => {
    const currentSavedFilter = getSavedFilterParams(getSavedFilters(), FilterTypes.SALARIES_FILTER);

    if (currentSavedFilter && viewPolicy?.isOfficeSpecific) {
      currentSavedFilter.officeIds = currentSavedFilter.officeIds?.filter(
        (el: string) => !viewPolicy?.isOfficeSpecific || viewPolicy?.officeIds?.some(id => id === el),
      );
    }

    setSalariesParams(
      currentSavedFilter
        ? new SalariesParams(currentSavedFilter)
        : {
            officeIds: officesFilter.value?.filter(
              (el: string) => !viewPolicy?.isOfficeSpecific || viewPolicy?.officeIds?.some(id => id === el),
            ),
            departments: departmentsFilter.value,
            users: usersFilter.value,
          },
    );
  }, [viewPolicy]);

  useEffect(() => {
    currentSalary && setSalaryClicked(new Salary(currentSalary));
  }, [currentSalary]);

  const openNewSalaryModal = useCallback(() => {
    setModalNewSalaryIsOpen(true);
  }, []);

  const closeNewSalaryModal = useCallback(() => {
    setModalNewSalaryIsOpen(false);
    resetErrors();
  }, []);

  const closeEditSalaryModal = useCallback(() => {
    setModalEditSalaryIsOpen(false);
    resetErrors();
  }, []);

  const closeDeleteSalaryModal = useCallback(() => {
    setModalDeleteSalaryIsOpen(false);
    resetErrors();
  }, []);

  const onDateChange = (start: string, end: string) => {
    setSalariesParams({
      dateFrom: moment(start).format(DATE_FORMAT.YYYY_MM_DD),
      dateTo: moment(end).format(DATE_FORMAT.YYYY_MM_DD),
    });
  };

  const handleMultiParamsChange = useParamsChange(setSalariesParams, dispatch);

  const handleUsersParamsChange = useUsersParamsChange(setSalariesParams, dispatch);

  const convertedSalaries = useMemo(() => {
    if (tableData?.userReports.length) {
      const offices = (params.officeIds.length
        ? officesFilter.offices.filter((office: OfficeInfo) => params.officeIds.includes(office.id))
        : officesFilter.offices
      )
        ?.filter((el: OfficeInfo) => !viewPolicy?.isOfficeSpecific || viewPolicy?.officeIds?.some(id => id === el.id))
        .filter((office: OfficeInfo) =>
          tableData.userReports.map((it: Salary) => it.user.officeId).includes(office.id),
        );

      const rows = offices.map((office: OfficeInfo) => {
        const filteredUsers = tableData.userReports.filter((it: UserReports) => it.user.officeId === office.id);

        return {
          office: office.name,
          id: office.id,
          total: filteredUsers.reduce((sum: any, current: UserReports) => sum + current.total, 0),
          totalPay: filteredUsers.reduce((sum: any, current: UserReports) => sum + current.totalPay, 0),
          totalTax: filteredUsers.reduce((sum: any, current: UserReports) => sum + current.totalTax, 0),
          months: filteredUsers[0].months.map((month: SeleriesMonth, index: number) => ({
            month: month.month,
            pay: filteredUsers.reduce((sum: any, current: UserReports) => sum + current.months[index].pay, 0),
            tax: filteredUsers.reduce((sum: any, current: UserReports) => sum + current.months[index].tax, 0),
            total: filteredUsers.reduce((sum: any, current: UserReports) => sum + current.months[index].total, 0),
          })),
          users: filteredUsers,
        };
      });

      rows.push({
        total: tableData.total,
        totalPay: tableData.totalPay,
        totalTax: tableData.totalTax,
        totalItem: true,
        months: tableData.monthReports.map((item: any) => ({
          pay: item.totalPay,
          tax: item.totalTax,
          total: item.total,
        })),
      });

      return rows;
    }

    return null;
  }, [tableData]);

  const { tableColumns, tableHeaderItems } = useDataForTable(
    convertedSalaries,
    getSalary,
    setModalEditSalaryIsOpen,
    setModalDeleteSalaryIsOpen,
    openNewSalaryModal,
    setUserMonthClicked,
  );

  const filters = useMemo(
    () => ({
      departments: departmentsFilter.departments,
      offices: officesFilter.offices,
      users: usersFilter.users,
    }),
    [departmentsFilter.departments, officesFilter.offices, usersFilter.users],
  );

  const handleFiltersControlChange = useCallback(
    value => {
      const fields = convertSavedFieldsToParams(value?.fields);

      if (viewPolicy?.isOfficeSpecific) {
        fields.officeIds = fields.officeIds?.filter(
          (el: string) => !viewPolicy?.isOfficeSpecific || viewPolicy?.officeIds?.some(id => id === el),
        );
      }

      setSalariesParams(
        new SalariesParams({
          ...fields,
          dateFrom: params.dateFrom,
          dateTo: params.dateTo,
        }),
      );
    },
    [viewPolicy, params],
  );

  const handleClear = useCallback(() => {
    setSalariesParams(new SalariesParams({ dateFrom: params.dateFrom, dateTo: params.dateTo }));
    resetParamsChange([FilterParamsName.USERS, FilterParamsName.DEPARTMENTS, FilterParamsName.OFFICE_IDS], dispatch);
  }, [params]);

  const filteredSavedFiltersData = useMemo(
    () =>
      viewPolicy?.isOfficeSpecific
        ? {
            ...savedFiltersData,
            filtersList: savedFiltersData.filtersList
              .map((filter: SavedFilter) => {
                const fields = convertSavedFieldsToParams(filter.fields);

                if (fields.officeIds) {
                  fields.officeIds = fields.officeIds.filter(
                    (el: string) => !viewPolicy?.isOfficeSpecific || viewPolicy?.officeIds?.some(id => id === el),
                  );

                  if (isEqual(fields, { officeIds: [] })) {
                    return null;
                  }
                }
                return filter;
              })
              .filter((filter: SavedFilter | null) => filter),
          }
        : savedFiltersData,
    [savedFiltersData, viewPolicy],
  );

  const showClearButton = useMemo(() => !checkParamsMatch(params, new SalariesParams(), salariesUnsavedParams), [
    params,
  ]);

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
            <InlineDatePicker
              onDateChange={onDateChange}
              defaultPeriod={InlineDatePickerPeriods.YEAR_PERIOD}
              defaultPeriodStart={params.dateFrom}
              defaultPeriodEnd={params.dateTo}
              todayBtn={false}
              weekBtn={false}
            />
          </div>
          <div className="page__panel-bottom no-border">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[UPDATE_SALARY]}>
                  <Button externalClass={'button--with-icon'} onClick={openNewSalaryModal}>
                    <Icon iconName={'plus'} externalClass={'button__icon'} />
                    <span className="button__text">
                      <FormattedMessage {...messages.newButton} />
                    </span>
                  </Button>
                </AccessChecker>
                <SalariesFilter
                  filters={filters}
                  values={params}
                  handleChangeFilterParams={handleMultiParamsChange}
                  handleUsersParamsChange={handleUsersParamsChange}
                  setSalariesParams={setSalariesParams}
                  createNewSavedFilter={createNewSavedFilter}
                  savedFiltersData={filteredSavedFiltersData}
                  authUserId={authUserId}
                  deleteSavedFilter={deleteSavedFilter}
                  editSavedFilter={editSavedFilter}
                  handleFiltersControlChange={handleFiltersControlChange}
                  handleClear={handleClear}
                  resetSavedFilterErrors={resetSavedFilterErrors}
                  policyOfficiesIds={viewPolicy?.officeIds}
                  isOfficeSpecific={!!viewPolicy?.isOfficeSpecific}
                  showClearButton={showClearButton}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content active_employees_page">
        <div className="page__wrapper">
          <div className="page__scrollable-table-wrapper cash-flow-report">
            <div className="page__scrollable-table-wrapper__inner cash-flow-report-wrapper salaries-wrapper">
              <HierarchicalTable
                tableData={useTableData(convertedSalaries, ['users'])}
                tableColumns={tableColumns}
                loading={isLoading.getSalariesList}
                error={errors.salariesError}
                tableHeaderItems={tableHeaderItems}
              />
            </div>
          </div>
        </div>
      </div>
      {modalNewSalaryIsOpen && (
        <ModalNewSalary
          isOpen
          onCloseRequest={closeNewSalaryModal}
          createSalary={editSalary}
          error={errors.salariesError}
          isLoading={isLoading.createSalary}
          users={usersFilter.users}
          userMonthClicked={userMonthClicked}
          setUserMonthClicked={setUserMonthClicked}
        />
      )}
      {modalEditSalaryIsOpen && (
        <ModalEditSalary
          isOpen
          onCloseRequest={closeEditSalaryModal}
          editSalary={editSalary}
          isLoading={isLoading.editSalary}
          error={errors.salariesError}
          salary={salaryClicked}
          users={usersFilter.users}
        />
      )}
      {modalDeleteSalaryIsOpen && (
        <ModalDeleteSalary
          isOpen
          onCloseRequest={closeDeleteSalaryModal}
          onDeleteRequest={deleteSalary}
          isLoading={isLoading.deleteSalary}
          error={errors.salariesError}
          salary={salaryClicked}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ finance, filters, auth }: RootState) => ({
  errors: finance.errors,
  isLoading: finance.loading,
  tableData: finance.salariesListData,
  currentSalary: finance.currentSalary,
  usersFilter: filters.usersFilter,
  officesFilter: filters.officesFilter,
  departmentsFilter: filters.departmentsFilter,
  params: finance.salariesParams,
  savedFiltersData: filters.savedFilters,
  authUserId: auth.currentUserInfo.id,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getSalary: (id: string) => dispatch(financeActions.getSalary(id)),
  editSalary: (data: { data: Salary; callback: () => void }) => dispatch(financeActions.editSalary(data)),
  deleteSalary: (data: { id: string; deleteFutureSalaries: boolean; callback: () => void }) =>
    dispatch(financeActions.deleteSalary(data)),
  resetState: () => dispatch(financeActions.resetState()),
  resetErrors: () => dispatch(financeActions.resetErrors()),
  getUsersFilter: () => dispatch(filtersActions.getUsersFilter()),
  setSalariesParams: (data: Partial<SalariesParams>) => dispatch(financeActions.setSalariesParams(data)),
  getOfficesFilter: () => dispatch(filtersActions.getOfficesFilter()),
  getDepartmentsFilter: () => dispatch(filtersActions.getDepartmentsFilter()),
  setSavedFiltersParams: (data: Partial<SavedFilterParams>) => dispatch(filtersActions.setSavedFiltersParams(data)),
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) =>
    dispatch(filtersActions.createNewSavedFilter(data)),
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) =>
    dispatch(filtersActions.editSavedFilter(data)),
  deleteSavedFilter: (data: { id: string; callback: () => void }) => dispatch(filtersActions.deleteSavedFilter(data)),
  resetSavedFilterErrors: () => dispatch(filtersActions.resetSavedFilterErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Salaries);
