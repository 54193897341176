import React, { useCallback, useMemo, useState } from 'react';
import { OptionTypeBase } from 'react-select';
import { useIntl } from 'react-intl';
import messages from '../messages';
import Filter from '../../Filter';
import { UserInfo } from '../../../enums/users.enum';
import Checkbox from '../../Checkbox';
import { EmployeesReportParams } from '../../../enums/params/reports.params';
import { OfficeInfo } from '../../../enums/libraries.enum';
import { useFiltersListValue } from '../../../utils/hooks.utils';
import { FilterParamsName, FilterTypes } from '../../../constants/filters.constants';
import { DepartmentsInfoType } from '../../../types/libraries';
import {
  employeesReportUnsavedParams,
  ReportUsersStatuses,
  roundingModeOptions,
} from '../../../constants/reports.constants';
import { SavedFilter, SavedFiltersDataType } from '../../../enums/filters.enum';
import FiltersControl from '../../FiltersControl';
import FilterClearButton from '../../FilterClearButton';
import RefreshButton from '../../RefreshButton';

type FiltersType = {
  offices: OfficeInfo[];
  users: UserInfo[];
  departments: DepartmentsInfoType[];
};

type EmployeesReportFiltersProps = {
  filters: FiltersType;
  handleMultiParamsChange: (name: string) => (data: OptionTypeBase) => void;
  handleRoundingModeChange: (name: string) => (data: any) => void;
  handleUsersParamsChange: (name: string) => (data: any) => void;
  handleUsersStatuses: (data: OptionTypeBase | OptionTypeBase[]) => void;
  handleFoldIssues: () => void;
  handleFoldSubTasks: () => void;
  values: EmployeesReportParams;
  setEmployeesReportParams: (data: Partial<EmployeesReportParams>) => void;
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) => void;
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) => void;
  deleteSavedFilter: (data: { id: string; callback: () => void }) => void;
  savedFiltersData: SavedFiltersDataType;
  authUserId: string;
  handleFiltersControlChange: (value: SavedFilter) => void;
  handleClear: () => void;
  resetSavedFilterErrors: () => void;
  showClearButton: boolean;
};

const EmployeesReportFilter = ({
  filters,
  values,
  handleMultiParamsChange,
  handleUsersStatuses,
  handleFoldIssues,
  handleFoldSubTasks,
  handleRoundingModeChange,
  setEmployeesReportParams,
  handleUsersParamsChange,
  createNewSavedFilter,
  editSavedFilter,
  deleteSavedFilter,
  savedFiltersData,
  authUserId,
  handleFiltersControlChange,
  handleClear,
  resetSavedFilterErrors,
  showClearButton,
}: EmployeesReportFiltersProps) => {
  const intl = useIntl();

  const [resetCurrentFilter, setResetCurrentFilter] = useState(false);

  const employeesOptions = useMemo(
    () =>
      filters.users.map((user: UserInfo) => ({
        label: user.fullName,
        value: user,
      })),
    [filters.users],
  );

  const officesOptions = useMemo(
    () =>
      filters.offices?.map((office: OfficeInfo) => ({
        label: office.name,
        value: office.id,
      })),
    [filters.offices],
  );

  const departmentOptions = useMemo(
    () =>
      filters.departments?.map((department: DepartmentsInfoType) => ({
        label: department.displayName,
        value: department.id,
      })),
    [filters.departments],
  );

  const usersStatusesOptions = useMemo(
    () => [
      {
        label: intl.formatMessage(messages.activeLabel),
        value: ReportUsersStatuses.ACTIVE,
      },
      {
        label: intl.formatMessage(messages.inactiveLabel),
        value: ReportUsersStatuses.INACITVE,
      },
      {
        label: intl.formatMessage(messages.externalLabel),
        value: ReportUsersStatuses.EXTERNAL,
      },
    ],
    [],
  );

  const employeeValues = useFiltersListValue(employeesOptions, values.portalUserIds);

  const officesValues = useFiltersListValue(officesOptions, values.offices);

  const departmentsValues = useFiltersListValue(departmentOptions, values.departmentIds);

  const roundingModeValues = useMemo(() => roundingModeOptions.find(el => el.value === values.roundingMode), [
    values.roundingMode,
  ]);

  const statusesValues = useMemo(
    () =>
      usersStatusesOptions.filter(el => {
        switch (el.value) {
          case ReportUsersStatuses.ACTIVE: {
            return values.isAllowActive;
          }
          case ReportUsersStatuses.INACITVE: {
            return values.isAllowPassive;
          }
          case ReportUsersStatuses.EXTERNAL: {
            return values.isAllowAlien;
          }
        }
      }),
    [values.isAllowActive, values.isAllowAlien, values.isAllowPassive],
  );

  const onClear = useCallback(() => {
    setResetCurrentFilter(true);
    handleClear();
  }, [values]);

  const setResettFilterFlag = useCallback(() => {
    setResetCurrentFilter(false);
  }, []);

  return (
    <>
      <Filter
        isMulti
        label={intl.formatMessage(messages.officesLabel)}
        options={officesOptions}
        handleChange={handleMultiParamsChange(FilterParamsName.OFFICES)}
        value={officesValues}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        label={intl.formatMessage(messages.departmentsLabel)}
        options={departmentOptions}
        handleChange={handleMultiParamsChange(FilterParamsName.DEPARTMENT_IDS)}
        value={departmentsValues}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        isUsersFilter
        label={intl.formatMessage(messages.employeesLabel)}
        options={employeesOptions}
        handleChange={handleUsersParamsChange(FilterParamsName.PORTAL_USER_IDS)}
        value={employeeValues}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        label={intl.formatMessage(messages.statusLabel)}
        options={usersStatusesOptions}
        defaultValue={[usersStatusesOptions[0]]}
        value={statusesValues}
        handleChange={handleUsersStatuses}
        externalClass="filters__select"
      />
      <Filter
        label=""
        options={roundingModeOptions}
        handleChange={handleRoundingModeChange('roundingMode')}
        defaultValue={roundingModeOptions[0]}
        value={roundingModeValues}
        externalClass="filters__select"
      />
      <div className="check_box_wrapper filter">
        <Checkbox
          label={intl.formatMessage(messages.sumTaskTitle)}
          id="isFoldIssuesToProject"
          externalClass="checkbox-label"
          onChange={handleFoldIssues}
          checkedValue={values.isFoldIssuesToProject}
        />
      </div>
      <div className="check_box_wrapper filter">
        <Checkbox
          label={intl.formatMessage(messages.sumSubtaskTitle)}
          id="isFoldSubtasksToParentTask"
          externalClass="checkbox-label"
          onChange={handleFoldSubTasks}
          checkedValue={values.isFoldSubtasksToParentTask}
        />
      </div>
      {showClearButton && <FilterClearButton onClear={onClear} />}
      <FiltersControl
        handleSaveFilter={createNewSavedFilter}
        handleUpdateFilter={editSavedFilter}
        handleDeleteFilter={deleteSavedFilter}
        savedFiltersData={savedFiltersData}
        authUserId={authUserId}
        filterType={FilterTypes.MEMBERS_REPORT_FILTER}
        handleChange={handleFiltersControlChange}
        params={values}
        resetSavedFilterErrors={resetSavedFilterErrors}
        unsavedParams={employeesReportUnsavedParams}
        resetCurrentFilter={resetCurrentFilter}
        setResettFilterFlag={setResettFilterFlag}
      />
      <RefreshButton onRefresh={() => setEmployeesReportParams(values)} />
    </>
  );
};

export default React.memo(EmployeesReportFilter);
