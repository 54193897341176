import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as financeActions from '../../actions/finance.actions';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Table from '../../components/Table';
import ModalDeleteSupplier from '../../components/Suppliers/Modals/ModalDeleteSuppliers';
import ModalEditSupplier from '../../components/Suppliers/Modals/ModalEditSuppliers';
import ModalNewSupplier from '../../components/Suppliers/Modals/ModalNewSuppliers';
import { SuppliersParams } from '../../enums/params/finance.params';
import AccessChecker from '../../components/AccessChecker';
import { DELETE_SUPPLIER, UPDATE_SUPPLIER } from '../../constants/policies.constants';
import { SupplierType } from '../../types/finance';
import { getCurrenciesFilter } from '../../actions/filters.actions';
import RefreshButton from '../../components/RefreshButton';

function Suppliers({
  tableData,
  currencyList,
  isLoading,
  errors,
  getCurrenciesFilter,
  getSuppliersList,
  createSupplier,
  editSupplier,
  deleteSupplier,
  resetState,
  resetErrors,
  setSuppliersParams,
  sortParams,
}: ConnectedProps<typeof connector>) {
  const intl = useIntl();

  const [modalNewSupplierIsOpen, setModalNewSupplierIsOpen] = useState(false);
  const [modalEditSupplierIsOpen, setModalEditSupplierIsOpen] = useState(false);
  const [modalDeleteSupplierIsOpen, setModalDeleteSupplierIsOpen] = useState(false);
  const [supplierClicked, setSupplierClicked] = useState<SupplierType>({
    name: '',
    id: '',
    currency: { id: '', isBaseCurrency: false, name: '' },
    currencyId: '',
  });

  const handleSort = useCallback((sortBy, direction) => setSuppliersParams({ sortBy, direction }), []);

  useEffect(() => {
    getSuppliersList();
    return () => {
      resetState();
    };
  }, []);

  const openNewSupplierModal = useCallback(() => {
    setModalNewSupplierIsOpen(true);
  }, []);

  const closeNewSupplierModal = useCallback(() => {
    setModalNewSupplierIsOpen(false);
    resetErrors();
  }, []);

  const closeEditSupplierModal = useCallback(() => {
    setModalEditSupplierIsOpen(false);
    resetErrors();
  }, []);

  const closeDeleteSupplierModal = useCallback(() => {
    setModalDeleteSupplierIsOpen(false);
    resetErrors();
  }, []);

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.nameColumn),
        modifier: (row: SupplierType) => row.name,
        sortName: 'name',
      },
      {
        name: intl.formatMessage(messages.currencyLabel),
        modifier: (row: SupplierType) => row.currency.name,
      },
    ],
    [],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: SupplierType) => {
          setSupplierClicked(row);
          setModalEditSupplierIsOpen(true);
        },
        verifiablePolicies: [UPDATE_SUPPLIER],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: SupplierType) => {
          setSupplierClicked(row);
          setModalDeleteSupplierIsOpen(true);
        },
        verifiablePolicies: [DELETE_SUPPLIER],
      },
    ],
    [],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom no-border">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[UPDATE_SUPPLIER]}>
                  <Button externalClass={'button--with-icon'} onClick={openNewSupplierModal}>
                    <Icon iconName={'plus'} externalClass={'button__icon'} />
                    <span className="button__text">
                      <FormattedMessage {...messages.newButton} />
                    </span>
                  </Button>
                </AccessChecker>
                <RefreshButton onRefresh={() => setSuppliersParams(sortParams)} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <Table
            externalClass={'table table--half suppliers-table'}
            tableColumns={tableColumns}
            tableData={tableData || []}
            loading={isLoading.getSuppliersList}
            error={errors.suppliersError}
            tableActions={tableActions}
            onSort={handleSort}
            params={sortParams}
          />
        </div>
      </div>
      {modalNewSupplierIsOpen && (
        <ModalNewSupplier
          isOpen
          onCloseRequest={closeNewSupplierModal}
          createSupplier={createSupplier}
          error={errors.suppliersError}
          isLoading={isLoading.createSupplier}
          getCurrenciesFilter={getCurrenciesFilter}
          currenciesList={currencyList?.sort((a: any, b: any) => a.name.localeCompare(b.name))}
        />
      )}
      {modalEditSupplierIsOpen && (
        <ModalEditSupplier
          isOpen
          onCloseRequest={closeEditSupplierModal}
          editSupplier={editSupplier}
          isLoading={isLoading.editSupplier}
          error={errors.suppliersError}
          supplier={supplierClicked}
          getCurrenciesFilter={getCurrenciesFilter}
          currenciesList={currencyList?.sort((a: any, b: any) => a.name.localeCompare(b.name))}
        />
      )}
      {modalDeleteSupplierIsOpen && (
        <ModalDeleteSupplier
          isOpen
          onCloseRequest={closeDeleteSupplierModal}
          onDeleteRequest={deleteSupplier}
          isLoading={isLoading.deleteSupplier}
          error={errors.suppliersError}
          supplier={supplierClicked}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ finance, filters }: RootState) => ({
  errors: finance.errors,
  isLoading: finance.loading,
  tableData: finance.suppliersListData?.content,
  currencyList: filters.currenciesFilter.currencies,
  sortParams: finance.suppliersParams,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getCurrenciesFilter: () => dispatch(getCurrenciesFilter()),
  getSuppliersList: () => dispatch(financeActions.getSuppliersList()),
  createSupplier: (data: { data: SupplierType; callback: () => void }) => dispatch(financeActions.createSupplier(data)),
  editSupplier: (data: { data: SupplierType; callback: () => void }) => dispatch(financeActions.editSupplier(data)),
  deleteSupplier: (data: { id: string; callback: () => void }) => dispatch(financeActions.deleteSupplier(data)),
  resetState: () => dispatch(financeActions.resetState()),
  resetErrors: () => dispatch(financeActions.resetErrors()),
  setSuppliersParams: (params: Partial<SuppliersParams>) => dispatch(financeActions.setSuppliersParams(params)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Suppliers);
