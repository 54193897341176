import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as librariesActions from '../../actions/libraries.actions';
import Table from '../../components/Table';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import ModalNewCompanyPosition from '../../components/CompanyPositions/Modals/ModalNewCompanyPosition';
import ModalEditCompanyPosition from '../../components/CompanyPositions/Modals/ModalEditCompanyPosition';
import ModalDeleteCompanyPosition from '../../components/CompanyPositions/Modals/ModalDeleteCompanyPosition';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import AccessChecker from '../../components/AccessChecker';
import { DELETE_COMPANY_POSITION, UPDATE_COMPANY_POSITION } from '../../constants/policies.constants';
import { checkPolicies } from '../../utils/policies.utils';
import PoliciesContext from '../../PoliciesContext';
import { CompanyPositionInfoType } from '../../types/libraries';
import { getGradesFilter } from '../../actions/filters.actions';
import RefreshButton from '../../components/RefreshButton';

function CompanyPositions({
  companyPositionsError,
  isLoading,
  tableData,
  gradeList,
  getCompanyPositionsList,
  createNewCompanyPosition,
  editCompanyPosition,
  deleteCompanyPosition,
  getGradesFilters,
  resetErrors,
  resetState,
}: ConnectedProps<typeof connector>) {
  const intl = useIntl();

  const policies = useContext(PoliciesContext);

  const [modalNewPositionIsOpen, setModalNewPositionIsOpen] = useState(false);
  const [modalEditPositionIsOpen, setModalEditPositionIsOpen] = useState(false);
  const [modalDeletePositionIsOpen, setModalDeletePositionIsOpen] = useState(false);
  const [companyPositionClicked, setCompanyPositionClicked] = useState<CompanyPositionInfoType>({
    displayName: '',
    id: '',
    priority: 0,
    gradeName: '',
    gradeId: '',
  });

  useEffect(() => {
    getCompanyPositionsList();
    getGradesFilters();
    return () => {
      resetState();
    };
  }, []);

  const openNewCompanyPositionModal = useCallback(() => {
    setModalNewPositionIsOpen(true);
  }, []);

  const closeNewCompanyPositionModal = useCallback(() => {
    setModalNewPositionIsOpen(false);
  }, []);

  const openEditCompanyPositionModal = useCallback(() => {
    setModalEditPositionIsOpen(true);
  }, []);

  const closeEditCompanyPositionModal = useCallback(() => {
    setModalEditPositionIsOpen(false);
  }, []);

  const openDeleteCompanyPositionModal = useCallback(() => {
    setModalDeletePositionIsOpen(true);
  }, []);

  const closeDeleteCompanyPositionModal = useCallback(() => {
    setModalDeletePositionIsOpen(false);
  }, []);

  const setCompanyPositionCallback = useCallback(() => {
    setCompanyPositionClicked({
      displayName: '',
      id: '',
      priority: 0,
      gradeName: '',
      gradeId: '',
    });
  }, []);

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.nameColumn),
        modifier: (row: CompanyPositionInfoType) => (
          <div className={'table__data-wrapper'}>
            <span>{row.displayName}</span>
          </div>
        ),
      },
      {
        name: intl.formatMessage(messages.gredeColumn),
        modifier: (row: CompanyPositionInfoType) => row.gradeName,
      },
    ],
    [],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            {intl.formatMessage(messages.editButton)}
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: CompanyPositionInfoType) => {
          setCompanyPositionClicked(row);
          openEditCompanyPositionModal();
        },
        verifiablePolicies: [UPDATE_COMPANY_POSITION],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            {intl.formatMessage(messages.deleteButton)}
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: CompanyPositionInfoType) => {
          setCompanyPositionClicked(row);
          openDeleteCompanyPositionModal();
        },
        verifiablePolicies: [DELETE_COMPANY_POSITION],
      },
    ],
    [],
  );

  const submitTable = useCallback(
    (data: Record<string, unknown>) => {
      const position = tableData[data.oldIndex as number];
      position.priority = data.newIndex;

      editCompanyPosition(position.id, {
        data: {
          priority: position.priority,
          displayName: position.displayName,
          id: position.id,
          gradeName: position.gradeName,
          gradeId: position.gradeId,
        },
        callback: () => null,
      });
    },
    [tableData],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom no-border">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[UPDATE_COMPANY_POSITION]}>
                  <Button externalClass={'button--with-icon'} onClick={openNewCompanyPositionModal}>
                    <Icon iconName={'plus'} externalClass={'button__icon'} />
                    <span className="button__text">
                      <FormattedMessage {...messages.newButton} />
                    </span>
                  </Button>
                </AccessChecker>
                <RefreshButton onRefresh={() => getCompanyPositionsList()} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <Table
            externalClass={'table'}
            tableColumns={tableColumns}
            tableData={tableData || []}
            loading={isLoading.getCompanyPositions}
            error={companyPositionsError}
            tableActions={tableActions}
            onDragSort={submitTable}
            sortable={checkPolicies([UPDATE_COMPANY_POSITION], policies)}
          />
        </div>
      </div>
      {modalNewPositionIsOpen && (
        <ModalNewCompanyPosition
          isOpen
          onCloseRequest={closeNewCompanyPositionModal}
          createNewCompanyPosition={createNewCompanyPosition}
          companyPositionError={companyPositionsError}
          isLoading={isLoading.createCompanyPosition}
          resetErrors={resetErrors}
          gradesList={gradeList}
        />
      )}
      {modalEditPositionIsOpen && (
        <ModalEditCompanyPosition
          isOpen
          onCloseRequest={closeEditCompanyPositionModal}
          editCompanyPosition={editCompanyPosition}
          companyPositionData={companyPositionClicked}
          companyPositionError={companyPositionsError}
          isLoading={isLoading.editCompanyPosition}
          resetErrors={resetErrors}
          gradesList={gradeList}
        />
      )}
      {modalDeletePositionIsOpen && (
        <ModalDeleteCompanyPosition
          isOpen
          onCloseRequest={closeDeleteCompanyPositionModal}
          onDeleteRequest={(data: Record<string, unknown>) => {
            deleteCompanyPosition({
              ...data,
              setCompanyPositionCallback,
            });
          }}
          isLoading={isLoading.deleteCompanyPosition}
          companyPositionError={companyPositionsError}
          companyPositionData={companyPositionClicked}
          resetErrors={resetErrors}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ libraries, filters }: RootState) => ({
  tableData: libraries.companyPositionsTableData?.content,
  companyPositionsError: libraries.errors.companyPositionError,
  isLoading: libraries.loading,
  gradeList: filters.gradesFilter.grades,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getCompanyPositionsList: () => dispatch(librariesActions.getCompanyPositionsList()),
  deleteCompanyPosition: (data: Record<string, unknown>) => dispatch(librariesActions.deleteCompanyPosition(data)),
  createNewCompanyPosition: (data: Record<string, unknown>) =>
    dispatch(librariesActions.createNewCompanyPosition(data)),
  editCompanyPosition: (id: string, data: Record<string, unknown>) =>
    dispatch(librariesActions.editCompanyPosition({ ...data, id })),
  resetErrors: () => dispatch(librariesActions.resetErrors()),
  getGradesFilters: () => dispatch(getGradesFilter()),
  resetState: () => dispatch(librariesActions.resetState()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(CompanyPositions);
