import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as financeActions from '../../actions/finance.actions';
import * as filtersActions from '../../actions/filters.actions';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import { FinanceProject } from '../../enums/finance/finance.enum';
import Table from '../../components/Table';
import ModalDeleteFinanceProject from '../../components/FinanceProjects/Modals/ModalDeleteFinanceProject';
import ModalNewFinanceProject from '../../components/FinanceProjects/Modals/ModalNewFinanceProject';
import ModalEditFinanceProject from '../../components/FinanceProjects/Modals/ModalEditFinanceProject';
import Pagination from '../../components/Pagination';
import { FinanceProjectsParams } from '../../enums/params/finance.params';
import { SortParams } from '../../enums/params.enum';
import { useDataForTable } from './useDataForTable';
import AccessChecker from '../../components/AccessChecker';
import { UPDATE_FINANCE_PROJECT } from '../../constants/policies.constants';
import RefreshButton from '../../components/RefreshButton';

function FinanceProjects({
  tableData,
  currencyList,
  clientList,
  isLoading,
  errors,
  getProjectsList,
  getClientsFilter,
  getCurrenciesFilter,
  getJiraProjectsFilter,
  getProjectTypesFilter,
  createProject,
  editProject,
  deleteProject,
  changeProjectStatus,
  resetState,
  resetErrors,
  jiraProjectFilter,
  projectTypeList,
  setProjectsParams,
  params,
}: ConnectedProps<typeof connector>) {
  const [modalNewProjectIsOpen, setModalNewProjectIsOpen] = useState(false);
  const [modalEditProjectIsOpen, setModalEditProjectIsOpen] = useState(false);
  const [modalDeleteProjectIsOpen, setModalDeleteProjectIsOpen] = useState(false);
  const [projectClicked, setProjectClicked] = useState<FinanceProject>(new FinanceProject());

  useEffect(() => {
    getProjectsList();
    return () => {
      resetState();
    };
  }, []);

  const openNewProjectModal = useCallback(() => {
    setModalNewProjectIsOpen(true);
  }, []);

  const closeNewProjectModal = useCallback(() => {
    setModalNewProjectIsOpen(false);
    resetErrors();
  }, []);

  const closeEditProjectModal = useCallback(() => {
    setModalEditProjectIsOpen(false);
    resetErrors();
  }, []);

  const closeDeleteProjectModal = useCallback(() => {
    setModalDeleteProjectIsOpen(false);
    resetErrors();
  }, []);

  const handleSort = useCallback((sortBy, direction) => setProjectsParams({ sortBy, direction }), []);

  const handlePageChange = useCallback(({ selected }) => {
    setProjectsParams({ page: selected });
  }, []);

  const handleSizeChange = useCallback(data => {
    setProjectsParams({ size: data, page: 0 });
  }, []);

  const sortParams = useMemo(() => new SortParams('name', params), [params]);

  const { tableColumns, tableActions } = useDataForTable(
    setProjectClicked,
    setModalEditProjectIsOpen,
    changeProjectStatus,
    setModalDeleteProjectIsOpen,
  );

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.projectsLabel} />
            </h1>
          </div>
          <div className="page__panel-bottom no-border">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[UPDATE_FINANCE_PROJECT]}>
                  <Button externalClass={'button--with-icon'} onClick={openNewProjectModal}>
                    <Icon iconName={'plus'} externalClass={'button__icon'} />
                    <span className="button__text">
                      <FormattedMessage {...messages.newButton} />
                    </span>
                  </Button>
                </AccessChecker>
                <RefreshButton onRefresh={() => setProjectsParams(params)} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content active_employees_page">
        <div className="page__wrapper">
          <Table
            externalClass={'tablei expense-types-table'}
            tableColumns={tableColumns}
            tableData={tableData?.content || []}
            loading={isLoading.getProjectsList}
            error={errors}
            tableActions={tableActions}
            onSort={handleSort}
            params={sortParams}
          />
          {tableData && (
            <Pagination
              pageable={{
                ...tableData?.pageable,
                ...tableData?.sort,
                totalElements: tableData?.totalElements,
                numberOfElements: tableData?.numberOfElements,
                totalPages: tableData?.totalPages,
              }}
              onPageChange={data => handlePageChange(data)}
              onPageSizeChange={data => handleSizeChange(data)}
            />
          )}
        </div>
      </div>
      {modalNewProjectIsOpen && (
        <ModalNewFinanceProject
          isOpen
          onCloseRequest={closeNewProjectModal}
          createProject={createProject}
          error={errors}
          isLoading={isLoading.createProject}
          getCurrenciesFilter={getCurrenciesFilter}
          getClientsFilter={getClientsFilter}
          currenciesList={currencyList}
          clients={clientList}
          jiraProjects={jiraProjectFilter.jiraProject}
          jiraProjectsErrors={jiraProjectFilter.errors}
          getJiraProjects={getJiraProjectsFilter}
          getProjectTypesFilter={getProjectTypesFilter}
          projectTypes={projectTypeList}
        />
      )}
      {modalEditProjectIsOpen && (
        <ModalEditFinanceProject
          isOpen
          onCloseRequest={closeEditProjectModal}
          editProject={editProject}
          isLoading={isLoading.editProject}
          project={projectClicked}
          error={errors}
          getCurrenciesFilter={getCurrenciesFilter}
          getClientsFilter={getClientsFilter}
          currenciesList={currencyList}
          clients={clientList}
          jiraProjects={jiraProjectFilter.jiraProject}
          jiraProjectsErrors={jiraProjectFilter.errors}
          getJiraProjects={getJiraProjectsFilter}
          getProjectTypesFilter={getProjectTypesFilter}
          projectTypes={projectTypeList}
        />
      )}
      {modalDeleteProjectIsOpen && (
        <ModalDeleteFinanceProject
          isOpen
          onCloseRequest={closeDeleteProjectModal}
          onDeleteRequest={deleteProject}
          isLoading={isLoading.deleteProject}
          error={errors}
          project={projectClicked}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ finance, filters }: RootState) => ({
  errors: finance.errors.projectsError,
  isLoading: finance.loading,
  tableData: finance.projectsListData,
  currencyList: filters.currenciesFilter.currencies,
  clientList: filters.clientsFilter.clients,
  jiraProjectFilter: filters.jiraProjectFilter,
  projectTypeList: filters.projectTypesFilter.projectTypes,
  params: finance.projectParams,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getProjectsList: () => dispatch(financeActions.getProjectsList()),
  getClientsFilter: () => dispatch(filtersActions.getClientsFilter()),
  getCurrenciesFilter: () => dispatch(filtersActions.getCurrenciesFilter()),
  getProjectTypesFilter: () => dispatch(filtersActions.getProjectTypesFilter()),
  getJiraProjectsFilter: () => dispatch(filtersActions.getJiraProjectsFilter()),
  createProject: (data: { data: FinanceProject; callback: () => void }) => dispatch(financeActions.createProject(data)),
  editProject: (data: { data: FinanceProject; callback: () => void }) => dispatch(financeActions.editProject(data)),
  deleteProject: (data: { id: string; callback: () => void }) => dispatch(financeActions.deleteProject(data)),
  changeProjectStatus: (id: string, isActive: string) => dispatch(financeActions.changeProjectStatus(id, isActive)),
  setProjectsParams: (data: Partial<FinanceProjectsParams>) => dispatch(financeActions.setProjectsParams(data)),
  resetState: () => dispatch(financeActions.resetState()),
  resetErrors: () => dispatch(financeActions.resetErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(FinanceProjects);
