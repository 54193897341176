import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import DraggableList from 'react-draggable-list';

import { useFormik } from 'formik';
import get from 'lodash-es/get';

import { RejectValueErrors } from '../../../enums/error.enum';
import {
  CASH_FLOW_REPORT_SCHEMA,
  CashFlowSettingFormValues,
  Client,
  DashboardBlock,
  PutDashboard,
} from '../../../enums/finance/finance.enum';
import { useSetFieldsErrors } from '../../../utils/hooks.utils';
import Button from '../../Button';
import ErrorMessage from '../../ErrorMessage';
import Modal from '../../Modal';
import messages from '../messages';
import Icon from '../../Icon';
import Input from '../../Input';
import { Item } from '../../Modal/ModalDraggable';
import { EDashboardTitles } from '../../../pages/Dashboard';
import { IncomeType } from '../../../types/finance';

type BlockSettingsModalProps = {
  getTypeList: () => void;
  onCloseRequest: () => void;
  editSetting: (data: PutDashboard) => void;
  isOpen: boolean;
  updatePolicy: boolean;
  title: EDashboardTitles;
  typeLabel: string;
  data: any;
  typesList: any;
  loading: boolean;
  error: string | RejectValueErrors[] | null;
};

function BlockSettingsModal({
  onCloseRequest,
  editSetting,
  error,
  title,
  updatePolicy,
  typeLabel,
  data,
  typesList,
  loading,
  isOpen,
  getTypeList,
}: BlockSettingsModalProps) {
  const intl = useIntl();

  const containerRef = useRef(null);

  const onListChange = (newList: any) => {
    setFieldValue('types', newList);
  };

  const [types, setTypes] = useState([]);

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue, setFieldError } = useFormik({
    initialValues: new CashFlowSettingFormValues({
      name: '',
      types: [],
    }),
    validateOnChange: false,
    validationSchema: CASH_FLOW_REPORT_SCHEMA,
    onSubmit: values => {
      return editSetting({ params: values, callback: onCloseRequest });
    },
  });

  useSetFieldsErrors(error, setFieldError);

  useEffect(() => {
    getTypeList();
  }, []);

  useEffect(() => {
    if (typesList?.content?.length)
      setTypes(
        typesList.content.map((item: Client | IncomeType) => ({
          value: item.id,
          label: item.name,
        })),
      );
  }, [typesList]);

  useEffect(() => {
    if (data) {
      if (data[title]) {
        setFieldValue('name', data[title].name);

        setFieldValue(
          'types',
          data[title].clientBlockSettings.map((type: DashboardBlock, index: number) => ({
            value: type.clientId,
            label: type.client?.name,
            position: index,
          })),
        );
      } else {
        setFieldValue('name', data.name);

        setFieldValue(
          'types',
          data.incomeTypeBlockSettings.map((type: DashboardBlock, index: number) => ({
            value: type.incomeTypeId,
            label: type.incomeType?.name,
            position: index,
          })),
        );
      }
    }
  }, [data]);

  const hasError = useCallback(
    (fieldName: string | (string | number)[]) => {
      return Boolean(get(errors, fieldName) && get(touched, fieldName));
    },
    [errors, touched],
  );

  const handleTypeChange = (data: any, index: number) => {
    setFieldValue(
      'types',
      values.types.map((type, typeIndex) => (typeIndex === index ? { ...type, ...data } : type)),
    );
  };

  const addNewType = useCallback(() => {
    setFieldValue('types', [...values.types, { position: values.types.length + 1 }]);
  }, [values]);

  const removeType = useCallback(
    index => () => {
      values.types.splice(index, 1);
      setFieldValue('types', values.types);
    },
    [values],
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onCloseRequest}
        title={intl.formatMessage(messages[title])}
        size="small"
        classNameModal={'cash-flow-report-modal center'}
      >
        <form className="modal__form form" onSubmit={handleSubmit}>
          <div className="form__inputs-wrapper">
            <div className="form__input-block">
              <Input
                id={'name'}
                name={'name'}
                label={intl.formatMessage(messages.nameColumn)}
                defaultValue={values?.name || ''}
                onChange={handleChange}
                hasError={hasError('name')}
                errorMessage={errors?.name}
              />
            </div>
            {values.types?.length ? (
              <div ref={containerRef} className="cash-flow-draggable-container">
                <DraggableList
                  itemKey="position"
                  //@ts-ignore
                  template={temp =>
                    Item({
                      item: temp.item,
                      dragHandleProps: temp.dragHandleProps,
                      data: values.types,
                      label: typeLabel,
                      errors,
                      handleTypeChange,
                      types,
                      hasError,
                      removeType,
                    })
                  }
                  list={values.types}
                  onMoveEnd={(newList: any) => onListChange(newList)}
                  container={() => containerRef.current}
                />
              </div>
            ) : null}

            <button className="form__btn-add-group" onClick={addNewType} type={'button'}>
              <Icon iconName={'plus'} externalClass={'form__icon-btn-add'} />
              <FormattedMessage {...messages.addButton} />
            </button>
          </div>
          <ErrorMessage>{error}</ErrorMessage>
          <div className="form__buttons display-center">
            <Button
              color="gray"
              externalClass="button--modal button--cancel"
              onClick={onCloseRequest}
              type="button"
              block
            >
              <FormattedMessage {...messages.cancelButton} />
            </Button>
            {updatePolicy ? (
              <Button externalClass="button--modal" type="submit" loading={loading} disabled={loading} block>
                <FormattedMessage {...messages.saveButton} />
              </Button>
            ) : null}
          </div>
        </form>
      </Modal>
    </>
  );
}

export default BlockSettingsModal;
