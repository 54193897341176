import React from 'react';

import Button from '../Button';
import Icon from '../Icon';

type RefreshButtonProps = {
  onRefresh: () => void;
};

function RefreshButton({ onRefresh }: RefreshButtonProps) {
  return (
    <Button color="gray" externalClass="button-filter" onClick={onRefresh}>
      <Icon iconName="refresh" externalClass="button__icon" />
    </Button>
  );
}

export default RefreshButton;
