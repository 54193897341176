import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as financeActions from '../../actions/finance.actions';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Table from '../../components/Table';
import ModalDeleteIncomeType from '../../components/IncomeTypes/Modals/ModalDeleteIncomeType';
import ModalNewIncomeType from '../../components/IncomeTypes/Modals/ModalNewIncomeType';
import ModalEditIncomeType from '../../components/IncomeTypes/Modals/ModalEditIncomeType';
import { IncomeTypesParams } from '../../enums/params/finance.params';
import AccessChecker from '../../components/AccessChecker';
import { DELETE_INCOME_TYPE, UPDATE_INCOME_TYPE } from '../../constants/policies.constants';
import { IncomeType } from '../../types/finance';
import RefreshButton from '../../components/RefreshButton';

function IncomeTypes({
  tableData,
  isLoading,
  errors,
  getIncomeTypesList,
  createIncomeType,
  editIncomeType,
  deleteIncomeType,
  resetState,
  resetErrors,
  setIncomeTypesParams,
  sortParams,
}: ConnectedProps<typeof connector>) {
  const intl = useIntl();

  const [modalNewIncomeTypeIsOpen, setModalNewIncomeTypeIsOpen] = useState(false);
  const [modalEditIncomeTypeIsOpen, setModalEditIncomeTypeIsOpen] = useState(false);
  const [modalDeleteIncomeTypeIsOpen, setModalDeleteIncomeTypeIsOpen] = useState(false);
  const [incomeTypeClicked, setIncomeTypeClicked] = useState<IncomeType>({
    name: '',
    id: '',
  });

  const handleSort = useCallback((sortBy, direction) => setIncomeTypesParams({ sortBy, direction }), []);

  useEffect(() => {
    getIncomeTypesList();
    return () => {
      resetState();
    };
  }, []);

  const openNewIncomeTypeModal = useCallback(() => {
    setModalNewIncomeTypeIsOpen(true);
  }, []);

  const closeNewIncomeTypeModal = useCallback(() => {
    setModalNewIncomeTypeIsOpen(false);
    resetErrors();
  }, []);

  const closeEditIncomeTypeModal = useCallback(() => {
    setModalEditIncomeTypeIsOpen(false);
    resetErrors();
  }, []);

  const closeDeleteIncomeTypeModal = useCallback(() => {
    setModalDeleteIncomeTypeIsOpen(false);
    resetErrors();
  }, []);

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.nameColumn),
        modifier: (row: IncomeType) => row.name,
        sortName: 'name',
      },
    ],
    [],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: IncomeType) => {
          setIncomeTypeClicked(row);
          setModalEditIncomeTypeIsOpen(true);
        },
        verifiablePolicies: [UPDATE_INCOME_TYPE],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: IncomeType) => {
          setIncomeTypeClicked(row);
          setModalDeleteIncomeTypeIsOpen(true);
        },
        verifiablePolicies: [DELETE_INCOME_TYPE],
      },
    ],
    [],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom no-border">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[UPDATE_INCOME_TYPE]}>
                  <Button externalClass={'button--with-icon'} onClick={openNewIncomeTypeModal}>
                    <Icon iconName={'plus'} externalClass={'button__icon'} />
                    <span className="button__text">
                      <FormattedMessage {...messages.newButton} />
                    </span>
                  </Button>
                </AccessChecker>
                <RefreshButton onRefresh={() => setIncomeTypesParams(sortParams)} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <Table
            externalClass={'table table--half'}
            tableColumns={tableColumns}
            tableData={tableData || []}
            loading={isLoading.getIncomeTypesList}
            error={errors.incomeTypesError}
            tableActions={tableActions}
            onSort={handleSort}
            params={sortParams}
          />
        </div>
      </div>
      {modalNewIncomeTypeIsOpen && (
        <ModalNewIncomeType
          isOpen
          onCloseRequest={closeNewIncomeTypeModal}
          createIncomeType={createIncomeType}
          error={errors.incomeTypesError}
          isLoading={isLoading.createIncomeType}
        />
      )}
      {modalEditIncomeTypeIsOpen && (
        <ModalEditIncomeType
          isOpen
          onCloseRequest={closeEditIncomeTypeModal}
          editIncomeType={editIncomeType}
          isLoading={isLoading.editIncomeType}
          error={errors.incomeTypesError}
          incomeType={incomeTypeClicked}
        />
      )}
      {modalDeleteIncomeTypeIsOpen && (
        <ModalDeleteIncomeType
          isOpen
          onCloseRequest={closeDeleteIncomeTypeModal}
          onDeleteRequest={deleteIncomeType}
          isLoading={isLoading.deleteIncomeType}
          error={errors.incomeTypesError}
          incomeType={incomeTypeClicked}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ finance }: RootState) => ({
  errors: finance.errors,
  isLoading: finance.loading,
  tableData: finance.incomeTypesListData?.content,
  sortParams: finance.incomeTypesParams,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getIncomeTypesList: () => dispatch(financeActions.getIncomeTypesList()),
  createIncomeType: (data: { data: IncomeType; callback: () => void }) =>
    dispatch(financeActions.createIncomeType(data)),
  editIncomeType: (data: { data: IncomeType; callback: () => void }) => dispatch(financeActions.editIncomeType(data)),
  deleteIncomeType: (data: { id: string; callback: () => void }) => dispatch(financeActions.deleteIncomeType(data)),
  resetState: () => dispatch(financeActions.resetState()),
  resetErrors: () => dispatch(financeActions.resetErrors()),
  setIncomeTypesParams: (params: Partial<IncomeTypesParams>) => dispatch(financeActions.setIncomeTypesParams(params)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(IncomeTypes);
