import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as candidatesActions from '../../actions/candidates.actions';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Table from '../../components/Table';
import { UPDATE_CANDIDATE_SPECIALIZATION, DELETE_CANDIDATE_SPECIALIZATION } from '../../constants/policies.constants';
import AccessChecker from '../../components/AccessChecker';
import { SortParams } from '../../enums/params.enum';
import { CandidateSpecializationParams } from '../../enums/params/candidates.params';
import { CandidateSpecializationType } from '../../types/candidates';
import ModalNewCandidateSpecialization from '../../components/CandidateSpecializations/Modals/ModalNewCandidateSpecialization';
import ModalEditCandidateSpecialization from '../../components/CandidateSpecializations/Modals/ModalEditCandidateSpecialization';
import ModalDeleteCandidateSpecialization from '../../components/CandidateSpecializations/Modals/ModalDeleteCandidateSpecialization';
import Pagination from '../../components/Pagination';
import RefreshButton from '../../components/RefreshButton';

function CandidateSpecializations({
  tableData,
  isLoading,
  candidateSpecializationsListError,
  candidateSpecializationError,
  params,
  getCandidateSpecializationsList,
  setCandidateSpecializationsParams,
  createCandidateSpecialization,
  editCandidateSpecialization,
  deleteCandidateSpecialization,
  resetState,
  resetErrors,
}: ConnectedProps<typeof connector>) {
  const intl = useIntl();

  const [modalNewCandidateSpecializationIsOpen, setModalNewCandidateSpecializationIsOpen] = useState(false);
  const [modalEditCandidateSpecializationIsOpen, setModalEditCandidateSpecializationIsOpen] = useState(false);
  const [modalDeleteCandidateSpecializationIsOpen, setModalDeleteCandidateSpecializationIsOpen] = useState(false);
  const [candidateSpecializationClicked, setCandidateSpecializationClicked] = useState<CandidateSpecializationType>({
    id: '',
    name: '',
  });

  useEffect(() => {
    getCandidateSpecializationsList();
    return () => {
      resetState();
    };
  }, []);

  const openNewCandidateSpecializationModal = useCallback(() => {
    setModalNewCandidateSpecializationIsOpen(true);
  }, []);

  const closeNewCandidateSpecializationModal = useCallback(() => {
    setModalNewCandidateSpecializationIsOpen(false);
    resetErrors();
  }, []);

  const openEditCandidateSpecializationModal = useCallback(() => {
    setModalEditCandidateSpecializationIsOpen(true);
  }, []);

  const closeEditCandidateSpecializationModal = useCallback(() => {
    setModalEditCandidateSpecializationIsOpen(false);
    resetErrors();
  }, []);

  const openDeleteCandidateSpecializationModal = useCallback(() => {
    setModalDeleteCandidateSpecializationIsOpen(true);
  }, []);

  const closeDeleteCandidateSpecializationModal = useCallback(() => {
    setModalDeleteCandidateSpecializationIsOpen(false);
    resetErrors();
  }, []);

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.nameColumn),
        modifier: (row: CandidateSpecializationType) => <span>{row.name}</span>,
        sortName: 'name',
      },
    ],
    [],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: CandidateSpecializationType) => {
          setCandidateSpecializationClicked(row);
          openEditCandidateSpecializationModal();
        },
        verifiablePolicies: [UPDATE_CANDIDATE_SPECIALIZATION],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: CandidateSpecializationType) => {
          setCandidateSpecializationClicked(row);
          openDeleteCandidateSpecializationModal();
        },
        verifiablePolicies: [DELETE_CANDIDATE_SPECIALIZATION],
      },
    ],
    [],
  );

  const sortParams = useMemo(() => new SortParams('name', params), [params]);

  const pageable = useMemo(
    () => ({
      ...tableData?.pageable,
      ...tableData?.sort,
      totalElements: tableData?.totalElements,
      numberOfElements: tableData?.numberOfElements,
      totalPages: tableData?.totalPages,
    }),
    [tableData],
  );

  const handlePageChange = useCallback(({ selected }) => {
    setCandidateSpecializationsParams({ page: selected });
  }, []);

  const handleSizeChange = useCallback(data => {
    setCandidateSpecializationsParams({ size: data, page: 0 });
  }, []);

  const handleSort = useCallback((sortBy, direction) => setCandidateSpecializationsParams({ sortBy, direction }), []);

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom no-border">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[UPDATE_CANDIDATE_SPECIALIZATION]}>
                  <Button externalClass={'button--with-icon'} onClick={openNewCandidateSpecializationModal}>
                    <Icon iconName={'plus'} externalClass={'button__icon'} />
                    <span className="button__text">
                      <FormattedMessage {...messages.newButton} />
                    </span>
                  </Button>
                </AccessChecker>
                <RefreshButton onRefresh={() => setCandidateSpecializationsParams(params)} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content half-content">
        <div className="page__wrapper">
          <Table
            tableColumns={tableColumns}
            tableData={tableData?.content || []}
            loading={isLoading.getCandidateSpecializationsList}
            error={candidateSpecializationsListError}
            tableActions={tableActions}
            params={sortParams}
            onSort={handleSort}
          />
          <Pagination pageable={pageable} onPageChange={handlePageChange} onPageSizeChange={handleSizeChange} />
        </div>
      </div>
      {modalNewCandidateSpecializationIsOpen && (
        <ModalNewCandidateSpecialization
          isOpen
          error={candidateSpecializationError}
          isLoading={isLoading.createCandidateSpecialization}
          onCloseRequest={closeNewCandidateSpecializationModal}
          createCandidateSpecialization={createCandidateSpecialization}
        />
      )}
      {modalEditCandidateSpecializationIsOpen && (
        <ModalEditCandidateSpecialization
          candidateSpecialization={candidateSpecializationClicked}
          isOpen
          error={candidateSpecializationError}
          isLoading={isLoading.editCandidateSpecialization}
          onCloseRequest={closeEditCandidateSpecializationModal}
          editCandidateSpecialization={editCandidateSpecialization}
        />
      )}
      {modalDeleteCandidateSpecializationIsOpen && (
        <ModalDeleteCandidateSpecialization
          candidateSpecialization={candidateSpecializationClicked}
          isOpen
          isLoading={isLoading.deleteCandidateSpecialization}
          error={candidateSpecializationError}
          onCloseRequest={closeDeleteCandidateSpecializationModal}
          onDeleteRequest={deleteCandidateSpecialization}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ candidates }: RootState) => ({
  tableData: candidates.candidateSpecializationsList,
  isLoading: candidates.loading,
  candidateSpecializationsListError: candidates.errors.candidateSpecializationsListError,
  candidateSpecializationError: candidates.errors.candidateSpecializationError,
  params: candidates.candidateSpecializationsParams,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getCandidateSpecializationsList: () => dispatch(candidatesActions.getCandidateSpecializationsList()),
  setCandidateSpecializationsParams: (params: Partial<CandidateSpecializationParams>) =>
    dispatch(candidatesActions.setCandidateSpecializationsParams(params)),
  createCandidateSpecialization: (data: { data: CandidateSpecializationType; callback: () => void }) =>
    dispatch(candidatesActions.createCandidateSpecialization(data)),
  editCandidateSpecialization: (data: { data: CandidateSpecializationType; callback: () => void }) =>
    dispatch(candidatesActions.editCandidateSpecialization(data)),
  deleteCandidateSpecialization: (data: { data: string; callback: () => void }) =>
    dispatch(candidatesActions.deleteCandidateSpecialization(data)),
  resetState: () => dispatch(candidatesActions.resetState()),
  resetErrors: () => dispatch(candidatesActions.resetErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(CandidateSpecializations);
