import React, { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import DurationPicker from '../../components/Dropdown';
import Icon from '../../components/Icon';
import InlineDatePicker from '../../components/InlineDatePicker';
import messages from './messages';
import EmployeesReportFilter from '../../components/EmployeesReport/EmployeesReportFilter';
import * as filtersActions from '../../actions/filters.actions';
import * as reportsActions from '../../actions/reports.actions';
import { EmployeesReportParams } from '../../enums/params/reports.params';
import HierarchicalTable from '../../components/HierarchicalTable';
import { useDataForTable } from './useDataForTable';
import { resetParamsChange, useParamsChange, useTableData, useUsersParamsChange } from '../../utils/hooks.utils';
import { handleReportUsersStatuses } from '../../utils/reports.utils';
import { ReportsUserStatusesType } from '../../types/reports';
import { employeesReportUnsavedParams, ReportsRoundingMode } from '../../constants/reports.constants';
import { isEmpty } from 'lodash-es';
import { exportEmployeesReport } from '../../actions/export.actions';
import { SavedFilterParams } from '../../enums/params/filters.params';
import { SavedFilter } from '../../enums/filters.enum';
import {
  checkParamsMatch,
  convertSavedFieldsToParams,
  getSavedFilterParams,
  getSavedFilters,
} from '../../utils/filters.utils';
import { FilterParamsName, FilterTypes } from '../../constants/filters.constants';

function Employees({
  employeesReportData,
  params,
  departmentsFilter,
  officesFilter,
  usersFilter,
  isLoading,
  employeeReportErrors,
  reportsUsersStatuses,
  roundingMode,
  isFoldSubtasks,
  setEmployeesReportParams,
  getOfficesFilter,
  getDepartmentsFilter,
  getUsersFilter,
  exportEmployeesReport,
  setReportsUsersStatuses,
  setRoundingMode,
  setFoldSubtask,
  setSavedFiltersParams,
  createNewSavedFilter,
  savedFiltersData,
  editSavedFilter,
  deleteSavedFilter,
  resetSavedFilterErrors,
  authUserId,
}: ConnectedProps<typeof connector>) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const newTableData = useMemo(() => {
    const jiraUsers = employeesReportData.jiraUsers;
    return [
      ...jiraUsers,
      ...(!isEmpty(jiraUsers)
        ? [
            {
              id: '',
              displayName: 'Total',
              timeSpent: employeesReportData.totalTimeSpent,
            },
          ]
        : []),
    ];
  }, [employeesReportData]);

  const handleFoldIssues = useCallback(() => {
    setEmployeesReportParams({ isFoldIssuesToProject: !params.isFoldIssuesToProject }, false);
  }, [params.isFoldIssuesToProject]);

  const handleFoldSubTasks = useCallback(() => {
    const isFold = !params.isFoldSubtasksToParentTask;
    setEmployeesReportParams({ isFoldSubtasksToParentTask: !params.isFoldSubtasksToParentTask }, false);
    setFoldSubtask(isFold);
  }, [params.isFoldSubtasksToParentTask]);

  const handleMultiParamsChange = useParamsChange(setEmployeesReportParams, dispatch);

  const handleUsersParamsChange = useUsersParamsChange(setEmployeesReportParams, dispatch);

  const handleRoundingModeChange = useCallback(
    name => (data: any) => {
      const value = data.value;
      setEmployeesReportParams({ [name]: value, isWorkLogsRounded: !!value });
      setRoundingMode(value);
    },
    [],
  );

  const handleUsersStatuses = useCallback(
    data =>
      handleReportUsersStatuses(
        (reportsUsersStatuses: ReportsUserStatusesType) => {
          setEmployeesReportParams(reportsUsersStatuses);
          setReportsUsersStatuses(reportsUsersStatuses);
        },
        data,
        true,
      ),
    [],
  );

  const handleDataChange = useCallback((start: string, end: string) => {
    setEmployeesReportParams({ dateFrom: start, dateTo: end });
  }, []);

  useEffect(() => {
    const isAllowAlien = reportsUsersStatuses.isAllowAlien;
    const isAllowActive = reportsUsersStatuses.isAllowActive;
    const isAllowPassive = reportsUsersStatuses.isAllowPassive;
    const currentSavedFilter = getSavedFilterParams(getSavedFilters(), FilterTypes.MEMBERS_REPORT_FILTER);

    setEmployeesReportParams(
      currentSavedFilter
        ? new EmployeesReportParams(currentSavedFilter)
        : {
            offices: officesFilter.value,
            departmentIds: departmentsFilter.value,
            portalUserIds: usersFilter.value,
            isAllowActive: isAllowAlien ? !!isAllowActive : isAllowActive,
            isAllowPassive: isAllowAlien ? !!isAllowPassive : isAllowPassive,
            isAllowAlien,
            roundingMode,
            isWorkLogsRounded: !!roundingMode,
            isFoldSubtasksToParentTask: isFoldSubtasks,
          },
    );

    getOfficesFilter();
    getDepartmentsFilter();
    getUsersFilter();
    setSavedFiltersParams({ filterType: FilterTypes.MEMBERS_REPORT_FILTER });
  }, []);

  const { tableColumns } = useDataForTable(
    params.isWorkLogsRounded,
    params.isFoldIssuesToProject,
    params.isFoldSubtasksToParentTask,
  );

  const filters = useMemo(
    () => ({ departments: departmentsFilter.departments, offices: officesFilter.offices, users: usersFilter.users }),
    [departmentsFilter.departments, officesFilter.offices, usersFilter.users],
  );

  const handleFiltersControlChange = useCallback(
    value => {
      setEmployeesReportParams(
        new EmployeesReportParams({
          ...convertSavedFieldsToParams(value.fields),
          dateFrom: params.dateFrom,
          dateTo: params.dateTo,
        }),
      );
    },
    [params],
  );

  const handleClear = useCallback(() => {
    setEmployeesReportParams(new EmployeesReportParams({ dateFrom: params.dateFrom, dateTo: params.dateTo }));
    resetParamsChange(
      [FilterParamsName.OFFICES, FilterParamsName.DEPARTMENT_IDS, FilterParamsName.PORTAL_USER_IDS],
      dispatch,
    );
    setRoundingMode(null);
    setFoldSubtask(false);
    setReportsUsersStatuses({
      isAllowActive: true,
      isAllowPassive: false,
      isAllowAlien: false,
    });
  }, [params]);

  const showClearButton = useMemo(
    () => !checkParamsMatch(params, new EmployeesReportParams(), employeesReportUnsavedParams),
    [params],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed employees-report-page">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
            <div className="page__panel-top__control">
              <div className="pagination page__panel-top__control__pagination">
                <InlineDatePicker
                  onDateChange={handleDataChange}
                  defaultPeriodStart={params.dateFrom}
                  defaultPeriodEnd={params.dateTo}
                />
                <DurationPicker
                  dropdownToggle={<Icon iconName="dots" externalClass="dropdown__button-main-icon" />}
                  dropdownList={[
                    {
                      label: intl.formatMessage(messages.exportToXLSLabel),
                      handler: exportEmployeesReport,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="page__panel-bottom">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <EmployeesReportFilter
                  filters={filters}
                  values={params}
                  handleMultiParamsChange={handleMultiParamsChange}
                  handleRoundingModeChange={handleRoundingModeChange}
                  handleUsersStatuses={handleUsersStatuses}
                  handleFoldIssues={handleFoldIssues}
                  handleFoldSubTasks={handleFoldSubTasks}
                  handleUsersParamsChange={handleUsersParamsChange}
                  createNewSavedFilter={createNewSavedFilter}
                  savedFiltersData={savedFiltersData}
                  authUserId={authUserId}
                  deleteSavedFilter={deleteSavedFilter}
                  editSavedFilter={editSavedFilter}
                  setEmployeesReportParams={setEmployeesReportParams}
                  handleFiltersControlChange={handleFiltersControlChange}
                  handleClear={handleClear}
                  resetSavedFilterErrors={resetSavedFilterErrors}
                  showClearButton={showClearButton}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <HierarchicalTable
            tableData={useTableData(newTableData, [
              'projects',
              ...(!params.isFoldIssuesToProject ? ['issues'] : []),
              ...(!params.isFoldSubtasksToParentTask ? ['childIssues'] : []),
            ])}
            tableColumns={tableColumns}
            loading={isLoading}
            error={employeeReportErrors}
            tableHeaderClassName="user_hours_header"
          />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ filters, reports, auth }: RootState) => ({
  employeesReportData: reports.employeesReportData,
  params: reports.employeesReportParams,
  departmentsFilter: filters.departmentsFilter,
  officesFilter: filters.officesFilter,
  usersFilter: filters.usersFilter,
  isLoading: reports.loading.employeesReport,
  employeeReportErrors: reports.errors.employeesReportErrors,
  reportsUsersStatuses: reports.reportsUserStatuses,
  roundingMode: reports.roundingMode,
  isFoldSubtasks: reports.isFoldSubtasks,
  savedFiltersData: filters.savedFilters,
  authUserId: auth.currentUserInfo.id,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getOfficesFilter: () => dispatch(filtersActions.getOfficesFilter()),
  getUsersFilter: () => dispatch(filtersActions.getUsersFilter()),
  getDepartmentsFilter: () => dispatch(filtersActions.getDepartmentsFilter()),
  getEmployeesReport: () => dispatch(reportsActions.getEmployeesReport()),
  exportEmployeesReport: () => dispatch(exportEmployeesReport()),
  setEmployeesReportParams: (data: Partial<EmployeesReportParams>, isGetList?: boolean) =>
    dispatch(reportsActions.setEmployeesReportParams(data, isGetList)),
  setReportsUsersStatuses: (data: ReportsUserStatusesType) => dispatch(reportsActions.setReportsUsersStatuses(data)),
  setRoundingMode: (data: ReportsRoundingMode | null) => dispatch(reportsActions.setRoundingMode(data)),
  setFoldSubtask: (data: boolean) => dispatch(reportsActions.setFoldSubtask(data)),
  setSavedFiltersParams: (data: Partial<SavedFilterParams>) => dispatch(filtersActions.setSavedFiltersParams(data)),
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) =>
    dispatch(filtersActions.createNewSavedFilter(data)),
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) =>
    dispatch(filtersActions.editSavedFilter(data)),
  deleteSavedFilter: (data: { id: string; callback: () => void }) => dispatch(filtersActions.deleteSavedFilter(data)),
  resetSavedFilterErrors: () => dispatch(filtersActions.resetSavedFilterErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Employees);
