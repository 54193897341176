import React, { memo, useCallback, useMemo } from 'react';
import Filter from '../../Filter';
import { useIntl } from 'react-intl';
import messages from '../messages';
import { OptionTypeBase } from 'react-select';
import { Client } from '../../../enums/finance/finance.enum';
import { useFiltersListValue } from '../../../utils/hooks.utils';
import FilterClearButton from '../../FilterClearButton';
import { InvoicesParams } from '../../../enums/params/cashflow.params';
import SortSelect from '../../SortSelect';
import { SortParams } from '../../../enums/params.enum';
import RefreshButton from '../../RefreshButton';

type FiltersType = {
  clients: Client[];
};

type InvoiceFiltersProps = {
  setInvoicesParams: (data: Partial<InvoicesParams>) => void;
  handleMultiParamsChange: (name: string) => (data: OptionTypeBase) => void;
  filters: FiltersType;
  values: InvoicesParams;
  handleClear: () => void;
  handleSort: (sortBy: string, direction: string) => void;
  showClearButton: boolean;
};

const InvoiceFilter = ({
  handleMultiParamsChange,
  filters,
  values,
  handleClear,
  setInvoicesParams,
  handleSort,
  showClearButton,
}: InvoiceFiltersProps) => {
  const intl = useIntl();

  const clientsOptions = useMemo(
    () =>
      filters.clients?.map(({ id, name }: Client) => ({
        value: id,
        label: name,
      })),
    [filters.clients],
  );

  const clientsValues = useFiltersListValue(clientsOptions, values.clientIds);

  const onClear = useCallback(() => {
    handleClear();
  }, [values]);

  return (
    <>
      <SortSelect
        sortOptions={[
          { label: intl.formatMessage(messages.clientLabel), value: 'client' },
          { label: intl.formatMessage(messages.projectLabel), value: 'financeProject' },
          { label: intl.formatMessage(messages.preparationDate), value: 'preparationDate' },
          { label: intl.formatMessage(messages.sendingDate), value: 'sendingDate' },
          { label: intl.formatMessage(messages.closingDate), value: 'closingDate' },
          { label: intl.formatMessage(messages.invoiceNumber), value: 'invoiceNumber' },
          { label: intl.formatMessage(messages.invoiceAmount), value: 'invoiceAmount' },
        ]}
        params={new SortParams('', { sortBy: values.sortBy, direction: values.direction })}
        onSort={handleSort}
      />
      <Filter
        isMulti
        label={intl.formatMessage(messages.clientLabel)}
        options={clientsOptions}
        value={clientsValues}
        handleChange={e => handleMultiParamsChange('clientIds')(e)}
        externalClass="filters__select"
      />
      {showClearButton && <FilterClearButton onClear={onClear} />}
      <RefreshButton onRefresh={() => setInvoicesParams(values)} />
    </>
  );
};

export default memo(InvoiceFilter);
