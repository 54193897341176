import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as librariesActions from '../../actions/libraries.actions';
import Table from '../../components/Table';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import ModalNewForeignLanguage from '../../components/ForeignLanguages/Modals/ModalNewForeignLanguage';
import ModalEditForeignLanguage from '../../components/ForeignLanguages/Modals/ModalEditForeignLanguage';
import ModalDeleteForeignLanguage from '../../components/ForeignLanguages/Modals/ModalDeleteForeignLanguage';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { ForeignLanguagesParams } from '../../enums/params/libraries.params';
import { DELETE_FOREIGN_LANGUAGE, UPDATE_FOREIGN_LANGUAGE } from '../../constants/policies.constants';
import AccessChecker from '../../components/AccessChecker';
import { ForeignLanguageInfoType } from '../../types/libraries';
import RefreshButton from '../../components/RefreshButton';

function ForeignLanguages({
  getForeignLanguagesList,
  createNewForeignLanguage,
  editForeignLanguage,
  deleteForeignLanguage,
  setForeignLanguageParams,
  resetErrors,
  resetState,
  sortParams,
  foreignLanguageError,
  isLoading,
  tableData,
}: ConnectedProps<typeof connector>) {
  const intl = useIntl();

  const [modalNewForeignLanguageIsOpen, setModalNewForeignLanguageIsOpen] = useState(false);
  const [modalEditForeignLanguageIsOpen, setModalEditForeignLanguageIsOpen] = useState(false);
  const [modalDeleteForeignLanguageIsOpen, setModalDeleteForeignLanguageIsOpen] = useState(false);
  const [foreignLanguageClicked, setForeignLanguageClicked] = useState<ForeignLanguageInfoType>({
    name: '',
    id: '',
  });

  useEffect(() => {
    getForeignLanguagesList();
    return () => {
      resetState();
    };
  }, []);

  const openNewForeignLanguageModal = useCallback(() => {
    setModalNewForeignLanguageIsOpen(true);
  }, []);

  const closeNewForeignLanguageModal = useCallback(() => {
    setModalNewForeignLanguageIsOpen(false);
  }, []);

  const openEditForeignLanguageModal = useCallback(() => {
    setModalEditForeignLanguageIsOpen(true);
  }, []);

  const closeEditForeignLanguageModal = useCallback(() => {
    setModalEditForeignLanguageIsOpen(false);
  }, []);

  const openDeleteForeignLanguageModal = useCallback(() => {
    setModalDeleteForeignLanguageIsOpen(true);
  }, []);

  const closeDeleteForeignLanguageModal = useCallback(() => {
    setModalDeleteForeignLanguageIsOpen(false);
  }, []);

  const setForeignLanguageCallback = useCallback(() => {
    setForeignLanguageClicked({
      name: '',
      id: '',
    });
  }, []);

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.nameColumn),
        sortName: 'name',
        modifier: (row: ForeignLanguageInfoType) => row.name,
      },
    ],
    [],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: ForeignLanguageInfoType) => {
          setForeignLanguageClicked(row);
          openEditForeignLanguageModal();
        },
        verifiablePolicies: [UPDATE_FOREIGN_LANGUAGE],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: ForeignLanguageInfoType) => {
          setForeignLanguageClicked(row);
          openDeleteForeignLanguageModal();
        },
        verifiablePolicies: [DELETE_FOREIGN_LANGUAGE],
      },
    ],
    [],
  );

  const handleSort = useCallback((sortBy, direction) => setForeignLanguageParams({ sortBy, direction }), []);

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom no-border">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[UPDATE_FOREIGN_LANGUAGE]}>
                  <Button externalClass={'button--with-icon'} onClick={openNewForeignLanguageModal}>
                    <Icon iconName={'plus'} externalClass={'button__icon'} />
                    <span className="button__text">
                      <FormattedMessage {...messages.newButton} />
                    </span>
                  </Button>
                </AccessChecker>
                <RefreshButton onRefresh={() => setForeignLanguageParams(sortParams)} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <Table
            externalClass={'table table--half'}
            tableColumns={tableColumns}
            tableData={tableData || []}
            loading={isLoading.getForeignLanguage}
            error={foreignLanguageError}
            tableActions={tableActions}
            params={sortParams}
            onSort={handleSort}
          />
        </div>
      </div>
      {modalNewForeignLanguageIsOpen && (
        <ModalNewForeignLanguage
          isOpen
          onCloseRequest={closeNewForeignLanguageModal}
          createNewForeignLanguage={createNewForeignLanguage}
          foreignLanguageError={foreignLanguageError}
          isLoading={isLoading.createForeignLanguage}
          resetErrors={resetErrors}
        />
      )}
      {modalEditForeignLanguageIsOpen && (
        <ModalEditForeignLanguage
          isOpen
          onCloseRequest={closeEditForeignLanguageModal}
          editForeignLanguage={editForeignLanguage}
          foreignLanguageData={foreignLanguageClicked}
          foreignLanguageError={foreignLanguageError}
          isLoading={isLoading.editForeignLanguage}
          resetErrors={resetErrors}
        />
      )}
      {modalDeleteForeignLanguageIsOpen && (
        <ModalDeleteForeignLanguage
          isOpen
          onCloseRequest={closeDeleteForeignLanguageModal}
          onDeleteRequest={(data: Record<string, unknown>) => {
            deleteForeignLanguage({
              ...data,
              setForeignLanguageCallback,
            });
          }}
          isLoading={isLoading.deleteForeignLanguage}
          foreignLanguageError={foreignLanguageError}
          foreignLanguageData={foreignLanguageClicked}
          resetErrors={resetErrors}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ libraries }: RootState) => ({
  tableData: libraries.foreignLanguagesTableData?.content,
  foreignLanguageError: libraries.errors.foreignLanguageError,
  isLoading: libraries.loading,
  sortParams: libraries.foreignLanguagesParams,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getForeignLanguagesList: () => dispatch(librariesActions.getForeignLanguagesList()),
  deleteForeignLanguage: (data: Record<string, unknown>) => dispatch(librariesActions.deleteForeignLanguage(data)),
  createNewForeignLanguage: (data: Record<string, unknown>) =>
    dispatch(librariesActions.createNewForeignLanguage(data)),
  editForeignLanguage: (id: string, data: Record<string, unknown>) =>
    dispatch(librariesActions.editForeignLanguage({ ...data, id })),
  resetErrors: () => dispatch(librariesActions.resetErrors()),
  setForeignLanguageParams: (data: Partial<ForeignLanguagesParams>) =>
    dispatch(librariesActions.setForeignLanguageParams(data)),
  resetState: () => dispatch(librariesActions.resetState()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ForeignLanguages);
