import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { OptionTypeBase } from 'react-select';
import { FilterParamsName } from '../../../constants/filters.constants';
import { OfficeInfo } from '../../../enums/libraries.enum';
import { OfficesBalanceParams } from '../../../enums/params/finance.params';
import { useFiltersListValue } from '../../../utils/hooks.utils';
import Filter from '../../Filter';
import FilterClearButton from '../../FilterClearButton';
import messages from '../messages';
import RefreshButton from '../../RefreshButton';

type FiltersType = {
  offices: OfficeInfo[];
};

type OfficesBalanceFiltersProps = {
  filters: FiltersType;
  values: OfficesBalanceParams;
  setParams: (data: Partial<OfficesBalanceParams>) => void;
  handleMultiParamsChange: (name: string) => (data: OptionTypeBase) => void;
  handleClear: () => void;
  policyOfficiesIds: string[] | undefined;
  isOfficeSpecific: boolean;
  showClearButton: boolean;
};

function OfficesBalanceFilters({
  filters,
  values,
  handleMultiParamsChange,
  setParams,
  handleClear,
  policyOfficiesIds,
  isOfficeSpecific,
  showClearButton,
}: OfficesBalanceFiltersProps) {
  const intl = useIntl();

  const officesOptions = useMemo(
    () =>
      filters.offices
        ?.filter(el => !isOfficeSpecific || policyOfficiesIds?.some(id => id === el.id))
        .map((el: Record<string, any>) => ({
          value: el.id,
          label: el.name,
        })),
    [filters.offices, policyOfficiesIds, isOfficeSpecific],
  );

  const officeValues = useFiltersListValue(officesOptions, values.officeIds)?.filter(
    el => !isOfficeSpecific || policyOfficiesIds?.some(id => id === el.value),
  );

  const onClear = useCallback(() => {
    handleClear();
  }, []);

  return (
    <>
      <Filter
        isMulti
        label={intl.formatMessage(messages.officesLabel)}
        options={officesOptions}
        value={officeValues}
        handleChange={e => handleMultiParamsChange(FilterParamsName.OFFICE_IDS)(e)}
        externalClass="filters__select"
      />
      {showClearButton && <FilterClearButton onClear={onClear} />}
      <RefreshButton onRefresh={() => setParams(values)} />
    </>
  );
}

export default React.memo(OfficesBalanceFilters);
