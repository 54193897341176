import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as librariesActions from '../../actions/libraries.actions';
import Table from '../../components/Table';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import ModalNewProject from '../../components/Projects/Modals/ModalNewProject';
import ModalEditProject from '../../components/Projects/Modals/ModalEditProject';
import ModalDeleteProject from '../../components/Projects/Modals/ModalDeleteProject';
import ModalProject from '../../components/Projects/Modals/ModalProject';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { ProjectsParams } from '../../enums/params/libraries.params';
import { DELETE_PROJECT, UPDATE_PROJECT } from '../../constants/policies.constants';
import AccessChecker from '../../components/AccessChecker';
import { ProjectInfoType } from '../../types/libraries';
import RefreshButton from '../../components/RefreshButton';

function Projects({
  getProjectsList,
  createNewProject,
  editProject,
  deleteProject,
  setProjectsParams,
  resetErrors,
  resetState,
  projectsError,
  isLoading,
  tableData,
  sortParams,
}: ConnectedProps<typeof connector>) {
  const [modalNewProjectIsOpen, setModalNewProjectIsOpen] = useState(false);
  const [modalEditProjectIsOpen, setModalEditProjectIsOpen] = useState(false);
  const [modalDeleteProjectIsOpen, setModalDeleteProjectIsOpen] = useState(false);
  const [modalProjectIsOpen, setModalProjectIsOpen] = useState(false);
  const [projectClicked, setProjectClicked] = useState<ProjectInfoType>({
    description: '',
    name: '',
    id: '',
  });

  useEffect(() => {
    getProjectsList();
    return () => {
      resetState();
    };
  }, []);

  const intl = useIntl();

  const openNewProjectModal = useCallback(() => {
    setModalNewProjectIsOpen(true);
  }, []);

  const closeNewProjectModal = useCallback(() => {
    setModalNewProjectIsOpen(false);
  }, []);

  const openEditProjectModal = useCallback(() => {
    setModalEditProjectIsOpen(true);
  }, []);

  const closeEditProjectModal = useCallback(() => {
    setModalEditProjectIsOpen(false);
  }, []);

  const openDeleteProjectModal = useCallback(() => {
    setModalDeleteProjectIsOpen(true);
  }, []);

  const closeDeleteProjectModal = useCallback(() => {
    setModalDeleteProjectIsOpen(false);
  }, []);

  const openProjectModal = useCallback((project: ProjectInfoType) => {
    setProjectClicked(project);
    setModalProjectIsOpen(true);
  }, []);

  const closeProjectModal = useCallback(() => {
    setModalProjectIsOpen(false);
  }, []);

  const setProjectCallback = useCallback(() => {
    setProjectClicked({
      description: '',
      name: '',
      id: '',
    });
  }, []);

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.nameColumn),
        sortName: 'name',
        modifier: (row: ProjectInfoType) => (
          <span className={'table_button--open-modal'} onClick={() => openProjectModal(row)}>
            {row.name}
          </span>
        ),
      },
    ],
    [],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: ProjectInfoType) => {
          setProjectClicked(row);
          openEditProjectModal();
        },
        verifiablePolicies: [UPDATE_PROJECT],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: ProjectInfoType) => {
          setProjectClicked(row);
          openDeleteProjectModal();
        },
        verifiablePolicies: [DELETE_PROJECT],
      },
    ],
    [],
  );

  const handleSort = useCallback((sortBy, direction) => setProjectsParams({ sortBy, direction }), []);

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.projectsLabel} />
            </h1>
          </div>
          <div className="page__panel-bottom no-border">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[UPDATE_PROJECT]}>
                  <Button externalClass={'button--with-icon'} onClick={openNewProjectModal}>
                    <Icon iconName={'plus'} externalClass={'button__icon'} />
                    <span className="button__text">
                      <FormattedMessage {...messages.newButton} />
                    </span>
                  </Button>
                </AccessChecker>
                <RefreshButton onRefresh={() => setProjectsParams(sortParams)} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <Table
            externalClass={'table table--projects table--half'}
            tableColumns={tableColumns}
            tableData={tableData || []}
            loading={isLoading.getProjects}
            error={projectsError}
            tableActions={tableActions}
            params={sortParams}
            onSort={handleSort}
          />
        </div>
      </div>
      {modalNewProjectIsOpen && (
        <ModalNewProject
          isOpen
          onCloseRequest={closeNewProjectModal}
          createNewProject={createNewProject}
          projectError={projectsError}
          isLoading={isLoading.createProject}
          resetErrors={resetErrors}
        />
      )}
      {modalEditProjectIsOpen && (
        <ModalEditProject
          isOpen
          onCloseRequest={closeEditProjectModal}
          editProject={editProject}
          projectData={projectClicked}
          projectError={projectsError}
          isLoading={isLoading.editProject}
          resetErrors={resetErrors}
        />
      )}
      {modalDeleteProjectIsOpen && (
        <ModalDeleteProject
          isOpen
          onCloseRequest={closeDeleteProjectModal}
          onDeleteRequest={(data: Record<string, unknown>) => {
            deleteProject({
              ...data,
              setProjectCallback,
            });
          }}
          isLoading={isLoading.deleteProject}
          projectError={projectsError}
          projectData={projectClicked}
          resetErrors={resetErrors}
        />
      )}
      {modalProjectIsOpen && (
        <ModalProject
          isOpen
          onCloseRequest={closeProjectModal}
          data={projectClicked}
          onDeleteRequest={openDeleteProjectModal}
          onEditRequest={openEditProjectModal}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ libraries }: RootState) => ({
  tableData: libraries.projectsTableData?.content,
  projectsError: libraries.errors.projectError,
  isLoading: libraries.loading,
  sortParams: libraries.projectsParams,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getProjectsList: () => dispatch(librariesActions.getProjectsList()),
  deleteProject: (data: Record<string, unknown>) => dispatch(librariesActions.deleteProject(data)),
  createNewProject: (data: Record<string, unknown>) => dispatch(librariesActions.createNewProject(data)),
  editProject: (id: string, data: Record<string, unknown>) => dispatch(librariesActions.editProject({ ...data, id })),
  resetErrors: () => dispatch(librariesActions.resetErrors()),
  setProjectsParams: (data: Partial<ProjectsParams>) => dispatch(librariesActions.setProjectsParams(data)),
  resetState: () => dispatch(librariesActions.resetState()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Projects);
