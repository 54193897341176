import React, { useMemo, useState } from 'react';

import { CurrencyType } from '../../../types/finance';
import Button from '../../Button';
import Icon from '../../Icon';
import HierarchicalTable from '../../HierarchicalTable';
import { EDashboardTitles } from '../../../pages/Dashboard';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import BlockSettingsModal from '../Modal/BlockSettingsModal';
import { useDataForTable } from './useTableData';
import { useDataForTable as useCustomerData } from './useDataForInvoice';
import { Client, DashboardRevenue, PutDashboard } from '../../../enums/finance/finance.enum';
import moment from 'moment';
import { DATE_FORMAT } from '../../../constants/date.constants';
import { useTableData } from '../../../utils/hooks.utils';
import classNames from 'classnames';

type DashboardProps = {
  title: EDashboardTitles;
  name?: string;
  tableData: DashboardRevenue[];
  errors: string | null;
  isLoading: boolean;
  viewPolicy: boolean;
  updatePolicy: boolean;
  baseCurrency: CurrencyType | undefined;
  settingsData: any;
  settingsLoading: boolean;
  settingsError: string | null;
  getTypeList: () => void;
  putSettingsData: (data: PutDashboard) => void;
  typeLabel: string;
  typesList: any;
  onCloseSettings: () => void;
  range: string;
};

const DashboardTableBlock = ({
  title,
  name,
  tableData,
  baseCurrency,
  errors,
  viewPolicy,
  updatePolicy,
  isLoading,
  settingsData,
  settingsError,
  settingsLoading,
  typeLabel,
  typesList,
  getTypeList,
  putSettingsData,
  onCloseSettings,
  range,
}: DashboardProps) => {
  const [openSettingsModal, setOpenSettingModal] = useState(false);

  const getDashboardData = (clients: Client[], data: DashboardRevenue[]) => {
    const tableClients = clients.map((client: Client) => {
      const filteredData = data.filter((item: DashboardRevenue) => item.clientId === client.id);

      return {
        id: client.id,
        name: client.name,
        actualAmount: filteredData
          .map((it: DashboardRevenue) => Number(it.paidAmount || 0))
          .reduce((partialSum: number, a: number) => partialSum + a, 0),
        plannedAmount: filteredData
          .map((it: DashboardRevenue) => Number(it.plannedAmount || 0))
          .reduce((partialSum: number, a: number) => partialSum + a, 0),
        subTypes: filteredData
          .filter((it: DashboardRevenue) => !!it)
          .map((type: DashboardRevenue, index: number) => ({
            name: moment(type.reportDate).format(DATE_FORMAT.MMM_YYYY),
            id: index,
            actualAmount: Number(type.paidAmount),
            plannedAmount: Number(type.plannedAmount),
          })),
      };
    });

    const total = {
      totalItem: true,
      actualAmount: data
        .map((it: DashboardRevenue) => Number(it.paidAmount || 0))
        .reduce((partialSum: number, a: number) => partialSum + a, 0),
      plannedAmount: data
        .map((it: DashboardRevenue) => Number(it.plannedAmount || 0))
        .reduce((partialSum: number, a: number) => partialSum + a, 0),
    };

    return [...tableClients, total];
  };

  const getCustomerData = (clients: Client[], data: DashboardRevenue[]) => {
    const tableClients = clients.map((client: Client) => {
      const filteredData = data.filter((item: DashboardRevenue) => item.clientId === client.id);

      return {
        id: client.id,
        name: client.name,
        invoiceAmount: filteredData
          .map((it: DashboardRevenue) => Number(it.invoiceAmount || 0))
          .reduce((partialSum: number, a: number) => partialSum + a, 0),
        overdueAmount: filteredData
          .map((it: DashboardRevenue) => Number(it.overdueAmount || 0))
          .reduce((partialSum: number, a: number) => partialSum + a, 0),
        subTypes: filteredData
          .filter((it: DashboardRevenue) => !!it)
          .map((type: DashboardRevenue, index: number) => ({
            name: type.invoiceNumber,
            id: index,
            invoiceAmount: type.invoiceAmount,
            overdueAmount: type.overdueAmount,
            due: { payDate: type.payBeforeDate, overdue: type.overdueDays },
            sendingDate: type.sendingDate,
            paidPeriod: type.paymentTerms,
          })),
      };
    });

    const total = {
      totalItem: true,
      invoiceAmount: data
        .map((it: DashboardRevenue) => Number(it.invoiceAmount || 0))
        .reduce((partialSum: number, a: number) => partialSum + a, 0),
      overdueAmount: data
        .map((it: DashboardRevenue) => Number(it.overdueAmount || 0))
        .reduce((partialSum: number, a: number) => partialSum + a, 0),
    };

    return [...tableClients, total];
  };

  const convertedData = useMemo(() => {
    if (tableData) {
      const clients = tableData
        .map((data: any) => data.client)
        .reduce((item: Client[], o: Client) => {
          if (!item.some((obj: Client) => obj.id === o.id)) {
            item.push(o);
          }

          return item;
        }, []);

      if (clients?.length) {
        if (title != EDashboardTitles.CUSTOMER_INVOICE) {
          return getDashboardData(clients, tableData);
        } else {
          return getCustomerData(clients, tableData);
        }
      }
    }

    return [];
  }, [tableData]);

  const openSetting = () => {
    setOpenSettingModal(true);
  };

  const closeSetting = () => {
    onCloseSettings();
    setOpenSettingModal(false);
  };

  const editSetting = (params: PutDashboard) => {
    putSettingsData(params);
  };

  const { tableColumns } =
    title != EDashboardTitles.CUSTOMER_INVOICE
      ? useDataForTable(convertedData, baseCurrency)
      : useCustomerData(convertedData, baseCurrency);

  return (
    <div className="dashboard-table__block">
      <div className="dashboard-table__block-item">
        <h2 className="dashboard-table__block-title">{name ?? <FormattedMessage {...messages[title]} />}</h2>
        <div className="dashboard-table__block-item__range-container">
          <span className="dashboard-table__block-item__range-container_content">{range}</span>
          {viewPolicy ? (
            <Button color="gray" externalClass="cash-flow__report-button" onClick={openSetting}>
              <Icon iconName="pencil" externalClass="button__icon" />
            </Button>
          ) : null}
        </div>
      </div>
      <div
        className={classNames('dashboard-wrapper', { 'invoice-wrapper': title === EDashboardTitles.CUSTOMER_INVOICE })}
      >
        <HierarchicalTable
          tableData={useTableData(convertedData, ['subTypes'])}
          tableColumns={tableColumns}
          loading={isLoading}
          error={errors}
        />
      </div>
      {openSettingsModal && (
        <BlockSettingsModal
          isOpen={openSettingsModal}
          getTypeList={getTypeList}
          title={title}
          updatePolicy={updatePolicy}
          typeLabel={typeLabel}
          typesList={typesList}
          onCloseRequest={closeSetting}
          editSetting={editSetting}
          loading={settingsLoading}
          error={settingsError}
          data={settingsData}
        />
      )}
    </div>
  );
};

export default DashboardTableBlock;
