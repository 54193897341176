import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatValue } from 'react-currency-input-field';

import messages from '../messages';
import { CurrencyType } from '../../../types/finance';
import { getTableCell } from '../../../utils/table.utils';
import { DepthLevels } from '../../../constants/tables.constants';
import moment from 'moment';
import { DATE_FORMAT } from '../../../constants/date.constants';
import classNames from 'classnames';

export const useDataForTable = (tableData: any, baseCurrency: CurrencyType | undefined) => {
  const intl = useIntl();

  const tableColumns = useMemo(() => {
    if (tableData?.length) {
      const typesColumns = {
        id: 'name',
        headClassName: 'dashboard-table__max-scope-for-four',
        Header: intl.formatMessage(messages.clientInvoiceColumn),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: row => (row.totalItem ? <FormattedMessage {...messages.totalRow} /> : row.name),
            },
            {
              depth: DepthLevels.SECOND,
              content: row => row.name,
            },
          ]),
      };

      const invoice = {
        id: 'invoice',
        headClassName: 'dashboard-table__max-scope-for-four-item',
        Header: intl.formatMessage(messages.invoiceAmount),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: row => (
                <span>
                  {row?.invoiceAmount ? (
                    <>
                      {formatValue({
                        value: (row?.invoiceAmount).toString(),
                      })}
                      &nbsp;
                      {baseCurrency?.name}
                    </>
                  ) : (
                    '-'
                  )}
                </span>
              ),
            },
            {
              depth: DepthLevels.SECOND,
              content: row => (
                <span>
                  {row?.invoiceAmount ? (
                    <>
                      {formatValue({
                        value: (row?.invoiceAmount).toString(),
                      })}
                      &nbsp;
                      {baseCurrency?.name}
                    </>
                  ) : (
                    '-'
                  )}
                </span>
              ),
            },
          ]),
      };

      const overdue = {
        id: 'overdue',
        headClassName: 'dashboard-table__max-scope-for-four-item',
        Header: intl.formatMessage(messages.overdueAmount),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: row => (
                <span>
                  {row?.overdueAmount ? (
                    <>
                      {formatValue({
                        value: (row?.overdueAmount).toString(),
                      })}
                      &nbsp;
                      {baseCurrency?.name}
                    </>
                  ) : (
                    '-'
                  )}
                </span>
              ),
            },
            {
              depth: DepthLevels.SECOND,
              content: row => (
                <span>
                  {row?.overdueAmount ? (
                    <>
                      {formatValue({
                        value: (row?.overdueAmount).toString(),
                      })}
                      &nbsp;
                      {baseCurrency?.name}
                    </>
                  ) : row?.invoiceAmount ? (
                    'Paid'
                  ) : (
                    '-'
                  )}
                </span>
              ),
            },
          ]),
      };

      const due = {
        id: 'due',
        headClassName: 'dashboard-table__max-scope-for-four-item',
        Header: intl.formatMessage(messages.due),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: () => <span></span>,
            },
            {
              depth: DepthLevels.SECOND,
              content: row => (
                <span>
                  {row?.due ? (
                    <div className="due-date">
                      <span className="pay-date">{moment(row.due.payDate).format(DATE_FORMAT.MMM_DD_YYYY)}</span>
                      <span className={classNames('overdue-days', { red: row.due.overdue >= 0 })}>
                        {row.due.overdue < 0
                          ? `In ${Math.abs(row.due.overdue)} day${Math.abs(row.due.overdue) > 1 ? 's' : ''}`
                          : `${Math.abs(row.due.overdue)} day${Math.abs(row.due.overdue) > 1 ? 's' : ''} ago`}
                      </span>
                    </div>
                  ) : (
                    '-'
                  )}
                </span>
              ),
            },
          ]),
      };

      const sending = {
        id: 'sending',
        headClassName: 'dashboard-table__max-scope-for-four-item',
        Header: intl.formatMessage(messages.sendingDate),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: () => <span></span>,
            },
            {
              depth: DepthLevels.SECOND,
              content: row => (
                <span>{row?.sendingDate ? moment(row.sendingDate).format(DATE_FORMAT.MMM_DD_YYYY) : '-'}</span>
              ),
            },
          ]),
      };

      const paidPeriod = {
        id: 'paidPeriod',
        headClassName: 'dashboard-table__max-scope-for-four-item',
        Header: intl.formatMessage(messages.paidPeriod),
        Cell: ({ row }: any) =>
          getTableCell(row, [
            {
              depth: DepthLevels.FIRST,
              content: () => <span></span>,
            },
            {
              depth: DepthLevels.SECOND,
              content: row => (
                <span>
                  {row?.paidPeriod ? (
                    <>
                      {formatValue({
                        value: (row?.paidPeriod || 0).toString(),
                      })}
                      &nbsp;day{row.paidPeriod > 1 ? 's' : ''}
                    </>
                  ) : (
                    '-'
                  )}
                </span>
              ),
            },
          ]),
      };

      return [typesColumns, invoice, overdue, sending, paidPeriod, due];
    }
    return [];
  }, [tableData]);

  return {
    tableColumns,
  };
};
