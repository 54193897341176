import {
  ExpensesReportItem,
  IncomesReportItem,
  FinancePlan,
  FinancePlanUser,
  FinanceProject,
  SalaryReport,
  Transaction,
  PlannedExpenseReportItem,
  EmployeeHours,
  MonthReportParts,
} from './../enums/finance/finance.enum';
import { AnyAction } from 'redux';
import * as ActionTypes from '../constants/finance.constants';
import { RejectValueErrors } from '../enums/error.enum';
import { getReducerErrors } from '../utils/reducerError.utils';
import * as paramsTypes from '../enums/params/finance.params';
import * as dashboardTypes from '../enums/params/dashboard.params';
import * as cashflowTypes from '../enums/params/cashflow.params';
import { Client, ChekedInfo } from '../enums/finance/finance.enum';
import { ClonePlanCheckedInfoType, ImportResourcesCheckedInfoType } from '../types/finance';

type initialStateType = {
  incomeTypesListData: any;
  currenciesListData: any;
  clientsListData: any;
  suppliersListData: any;
  projectTypesListData: any;
  expenseTypesListData: any;
  projectsListData: any;
  profitLossListData: any;
  salariesListData: any;
  currentSalary: any;
  plansListData: any;
  currentPlanData: FinancePlan;
  currenPlanMonth: string;
  planEmployeesListData: any;
  datesOfExistingPlans: string[];
  employeeHours: EmployeeHours;
  incomesExpensesReportListData: any;
  officesBalanceListData: any;
  dashboardListData: any;
  cashFlowReportListData: any;
  cashFlowReportSetting: any;
  cashFlowReportBalanceSetting: any;
  cashFlowReportTotalSetting: any;
  transactionsListData: any;
  transactionsTotal: {
    difference: number;
    expensesTotal: number;
    incomesTotal: number;
  };

  revenueAndExpenseData: any;
  dashboardPlannedExpensesData: any;
  dashboardClientSettingData: any;
  dashboardSettingData: any;
  dashboardExpensesStructureData: any;
  dashboardTopSettingData: any;
  dashboardIncomeTopSettingData: any;
  dashboardExpenseTopSettingData: any;
  dashboardIncomeData: any;
  dashboardExpenseData: any;
  incomePositionNumber: number;
  expensePositionNumber: number;

  revenueFromClientData: any;
  costProjectData: any;
  operatingIncomeData: any;
  netIncomeData: any;
  grossProfitData: any;
  revenueExpenseData: any;
  totalGoodsData: any;
  financialRevenueData: any;
  financialExpenseData: any;

  clonePlanCheckedInfo: ChekedInfo[];
  importResourcesCheckedInfo: ChekedInfo[];
  plannedExpense: {
    expenseReportParts: PlannedExpenseReportItem;
    monthReportParts: MonthReportParts;
    total: number;
  } | null;
  invoicesListData: any;
  salariesParams: paramsTypes.SalariesParams;
  incomeTypesParams: paramsTypes.IncomeTypesParams;
  currenciesParams: paramsTypes.CurrenciesParams;
  clientsParams: paramsTypes.ClientsParams;
  suppliersParams: paramsTypes.SuppliersParams;
  projectTypesParams: paramsTypes.ProjectTypesParams;
  expenseTypesParams: paramsTypes.ExpenseTypesParams;
  projectParams: paramsTypes.FinanceProjectsParams;
  planParams: paramsTypes.FinancePlanParams;
  planEmployeesParams: paramsTypes.FinancePlanEmployeesParams;
  incomesExpensesReportParams: paramsTypes.IncomesExpensesReportParams;
  transactionsParams: paramsTypes.TransactionsParams;
  invoicesParams: cashflowTypes.InvoicesParams;
  plannedExpenseParams: paramsTypes.PlannedExpenseParams;
  officesBalanceParams: paramsTypes.OfficesBalanceParams;
  cashFlowReportParams: cashflowTypes.CashFlowReportParams;
  dashboardParams: paramsTypes.OfficesBalanceParams;
  dashboardChartsRangeParams: dashboardTypes.DashboardChartsRangeParams;
  profitLossParams: paramsTypes.OfficesBalanceParams;
  loading: {
    getIncomeTypesList: boolean;
    createIncomeType: boolean;
    editIncomeType: boolean;
    deleteIncomeType: boolean;
    getCurrenciesList: boolean;
    createCurrency: boolean;
    editCurrency: boolean;
    deleteCurrency: boolean;
    getClientsList: boolean;
    createClient: boolean;
    editClient: boolean;
    deleteClient: boolean;
    getSuppliersList: boolean;
    createSupplier: boolean;
    editSupplier: boolean;
    deleteSupplier: boolean;
    getProjectTypesList: boolean;
    createProjectType: boolean;
    editProjectType: boolean;
    deleteProjectType: boolean;
    getExpenseTypesList: boolean;
    createExpenseType: boolean;
    editExpenseType: boolean;
    deleteExpenseType: boolean;
    getProjectsList: boolean;
    createProject: boolean;
    editProject: boolean;
    deleteProject: boolean;
    getSalariesList: boolean;
    getSalary: boolean;
    createSalary: boolean;
    editSalary: boolean;
    deleteSalary: boolean;
    getPlansList: boolean;
    getPlan: boolean;
    createPlan: boolean;
    editPlan: boolean;
    clonePlan: boolean;
    deletePlan: boolean;
    getPlanEmployeesList: boolean;
    getEmployeeHours: boolean;
    createPlanEmployee: boolean;
    editPlanEmployee: boolean;
    deletePlanEmployee: boolean;
    getIncomesExpensesReport: boolean;
    getTransactions: boolean;
    getInvoices: boolean;
    createInvoice: boolean;
    editInvoice: boolean;
    deleteInvoice: boolean;
    cloneInvoice: boolean;
    uploadInvoice: boolean;
    createTransaction: boolean;
    importTransactions: boolean;
    editTransaction: boolean;
    deleteTransaction: boolean;
    importResources: boolean;
    getDatesOfExistingPlans: boolean;
    getPlannedExpense: boolean;
    createPlannedExpense: boolean;
    editPlannedExpense: boolean;
    deletePlannedExpense: boolean;
    getOfficesBalance: boolean;
    editOfficeBalance: boolean;
    getCashFlowReport: boolean;
    getCashFlowReportSetting: boolean;

    getDashboard: boolean;
    getRevenueAndExpense: boolean;
    getDashboardPlannedExpenses: boolean;
    getDashboardClientSetting: boolean;
    getDashboardSetting: boolean;
    getExpensesStructure: boolean;
    getDashboardTopSetting: boolean;
    getDashboardIncomeTopSetting: boolean;
    getDashboardExpenseTopSetting: boolean;
    getDashboardIncome: boolean;
    getDashboardExpense: boolean;

    getRevenueFromClient: boolean;
    getCostProject: boolean;
    getOperatingIncome: boolean;
    getNetIncome: boolean;
    getGrossProfit: boolean;
    getRevenueExpense: boolean;
    getTotalGoods: boolean;
    getFinancialRevenue: boolean;
    getFinancialExpense: boolean;
    getProfitLoss: boolean;
  };
  errors: {
    incomeTypesError: string | RejectValueErrors[] | null;
    currenciesError: string | RejectValueErrors[] | null;
    clientsError: string | RejectValueErrors[] | null;
    suppliersError: string | RejectValueErrors[] | null;
    projectTypesError: string | RejectValueErrors[] | null;
    expenseTypesError: string | RejectValueErrors[] | null;
    projectsError: string | RejectValueErrors[] | null;
    salariesError: string | RejectValueErrors[] | null;
    planError: string | RejectValueErrors[] | null;
    planEmployeesError: string | RejectValueErrors[] | null;
    employeeHoursError: string | RejectValueErrors[] | null;
    incomesExpensesReportError: string | RejectValueErrors[] | null;
    officesBalanceError: string | RejectValueErrors[] | null;

    revenueFromClientError: string | null;
    costProjectError: string | null;
    operatingIncomeError: string | null;
    netIncomeError: string | null;
    grossProfitError: string | null;
    revenueExpenseError: string | null;
    totalGoodsError: string | null;
    financialRevenueError: string | null;
    financialExpenseError: string | null;

    revenueAndExpenseError: string | null;
    dashboardPlannedExpensesError: string | null;
    dashboardClientSettingError: string | null;
    dashboardSettingError: string | null;
    dashboardExpensesStructureError: string | null;
    dashboardTopSettingError: string | null;
    dashboardIncomeTopSettingError: string | null;
    dashboardExpenseTopSettingError: string | null;
    dashboardIncomeError: string | null;
    dashboardExpenseError: string | null;

    dashboardError: string | RejectValueErrors[] | null;
    cashFlowReportError: string | RejectValueErrors[] | null;
    cashFlowReportSettingError: string | RejectValueErrors[] | null;
    transactionListError: string | null;
    invoiceListError: string | null;
    invoiceError: string | null;
    transactionError: string | RejectValueErrors[] | null;
    importTransationsError: string | RejectValueErrors[] | null;
    importResourcesError: string | RejectValueErrors[] | null;
    datesOfExistingPlans: string | null;
    plannedExpenseError: string | RejectValueErrors[] | null;
    profitLossError: string | RejectValueErrors[] | null;
  };
};

const initialState: initialStateType = {
  incomeTypesListData: null,
  currenciesListData: null,
  clientsListData: null,
  suppliersListData: null,
  projectTypesListData: null,
  expenseTypesListData: null,
  projectsListData: null,
  salariesListData: null,
  currentSalary: null,
  plansListData: null,
  currentPlanData: new FinancePlan(),
  currenPlanMonth: '',
  planEmployeesListData: null,
  datesOfExistingPlans: [],
  employeeHours: new EmployeeHours(),
  incomesExpensesReportListData: null,
  invoicesListData: [],
  officesBalanceListData: [],
  dashboardListData: [],
  cashFlowReportListData: [],
  cashFlowReportSetting: null,
  cashFlowReportBalanceSetting: null,
  cashFlowReportTotalSetting: null,
  transactionsListData: null,
  transactionsTotal: {
    difference: 0,
    expensesTotal: 0,
    incomesTotal: 0,
  },

  revenueAndExpenseData: null,
  dashboardPlannedExpensesData: null,
  dashboardClientSettingData: null,
  dashboardSettingData: null,
  dashboardExpensesStructureData: null,
  dashboardTopSettingData: null,
  dashboardIncomeTopSettingData: null,
  dashboardExpenseTopSettingData: null,
  dashboardIncomeData: null,
  dashboardExpenseData: null,
  incomePositionNumber: 0,
  expensePositionNumber: 0,

  revenueFromClientData: null,
  costProjectData: null,
  operatingIncomeData: null,
  netIncomeData: null,
  grossProfitData: null,
  revenueExpenseData: null,
  totalGoodsData: null,
  financialRevenueData: null,
  financialExpenseData: null,

  profitLossListData: null,
  clonePlanCheckedInfo: [],
  importResourcesCheckedInfo: [],
  plannedExpense: null,
  salariesParams: new paramsTypes.SalariesParams(),
  incomeTypesParams: new paramsTypes.IncomeTypesParams(),
  currenciesParams: new paramsTypes.CurrenciesParams(),
  clientsParams: new paramsTypes.ClientsParams(),
  suppliersParams: new paramsTypes.SuppliersParams(),
  projectTypesParams: new paramsTypes.ProjectTypesParams(),
  expenseTypesParams: new paramsTypes.ExpenseTypesParams(),
  projectParams: new paramsTypes.FinanceProjectsParams(),
  planParams: new paramsTypes.FinancePlanParams(),
  planEmployeesParams: new paramsTypes.FinancePlanEmployeesParams(),
  incomesExpensesReportParams: new paramsTypes.IncomesExpensesReportParams(),
  officesBalanceParams: new paramsTypes.OfficesBalanceParams(),
  dashboardParams: new dashboardTypes.DashboardParams(),
  dashboardChartsRangeParams: new dashboardTypes.DashboardChartsRangeParams(),
  cashFlowReportParams: new cashflowTypes.CashFlowReportParams(),
  profitLossParams: new paramsTypes.OfficesBalanceParams(),
  transactionsParams: new paramsTypes.TransactionsParams(),
  invoicesParams: new cashflowTypes.InvoicesParams(),
  plannedExpenseParams: new paramsTypes.PlannedExpenseParams(),
  loading: {
    getIncomeTypesList: false,
    createIncomeType: false,
    editIncomeType: false,
    deleteIncomeType: false,
    getCurrenciesList: false,
    createCurrency: false,
    editCurrency: false,
    deleteCurrency: false,
    getClientsList: false,
    createClient: false,
    editClient: false,
    deleteClient: false,
    getSuppliersList: false,
    createSupplier: false,
    editSupplier: false,
    deleteSupplier: false,
    getProjectTypesList: false,
    createProjectType: false,
    editProjectType: false,
    deleteProjectType: false,
    getExpenseTypesList: false,
    createExpenseType: false,
    editExpenseType: false,
    deleteExpenseType: false,
    getProjectsList: false,
    createProject: false,
    editProject: false,
    deleteProject: false,
    getSalariesList: false,
    getSalary: false,
    createSalary: false,
    editSalary: false,
    deleteSalary: false,
    getPlansList: false,
    getPlan: false,
    createPlan: false,
    editPlan: false,
    clonePlan: false,
    deletePlan: false,
    getPlanEmployeesList: false,
    getEmployeeHours: false,
    createPlanEmployee: false,
    editPlanEmployee: false,
    deletePlanEmployee: false,
    getIncomesExpensesReport: false,
    getTransactions: false,
    getInvoices: false,
    createInvoice: false,
    editInvoice: false,
    deleteInvoice: false,
    cloneInvoice: false,
    uploadInvoice: false,
    createTransaction: false,
    importTransactions: false,
    editTransaction: false,
    deleteTransaction: false,
    importResources: false,
    getDatesOfExistingPlans: false,
    getPlannedExpense: false,
    createPlannedExpense: false,
    editPlannedExpense: false,
    deletePlannedExpense: false,
    getOfficesBalance: false,
    editOfficeBalance: false,

    getDashboard: false,
    getRevenueAndExpense: false,
    getDashboardPlannedExpenses: false,
    getDashboardClientSetting: false,
    getDashboardSetting: false,
    getExpensesStructure: false,
    getDashboardTopSetting: false,
    getDashboardIncomeTopSetting: false,
    getDashboardExpenseTopSetting: false,
    getDashboardIncome: false,
    getDashboardExpense: false,

    getCashFlowReport: false,
    getCashFlowReportSetting: false,
    getRevenueFromClient: false,
    getCostProject: false,
    getOperatingIncome: false,
    getNetIncome: false,
    getGrossProfit: false,
    getRevenueExpense: false,
    getTotalGoods: false,
    getFinancialRevenue: false,
    getFinancialExpense: false,
    getProfitLoss: false,
  },
  errors: {
    incomeTypesError: null,
    currenciesError: null,
    clientsError: null,
    suppliersError: null,
    projectTypesError: null,
    expenseTypesError: null,
    projectsError: null,
    salariesError: null,
    planError: null,
    planEmployeesError: null,
    employeeHoursError: null,
    incomesExpensesReportError: null,
    officesBalanceError: null,

    dashboardError: null,
    revenueAndExpenseError: null,
    dashboardPlannedExpensesError: null,
    dashboardClientSettingError: null,
    dashboardSettingError: null,
    dashboardExpensesStructureError: null,
    dashboardTopSettingError: null,
    dashboardIncomeTopSettingError: null,
    dashboardExpenseTopSettingError: null,
    dashboardIncomeError: null,
    dashboardExpenseError: null,

    cashFlowReportError: null,
    cashFlowReportSettingError: null,
    transactionListError: null,
    invoiceListError: null,
    invoiceError: null,
    transactionError: null,
    importTransationsError: null,
    importResourcesError: null,
    datesOfExistingPlans: null,
    plannedExpenseError: null,
    revenueFromClientError: null,
    costProjectError: null,
    operatingIncomeError: null,
    netIncomeError: null,
    grossProfitError: null,
    revenueExpenseError: null,
    totalGoodsError: null,
    financialRevenueError: null,
    financialExpenseError: null,
    profitLossError: null,
  },
};

const financeReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionTypes.RESET_FINANCE_ERRORS:
      return {
        ...state,
        errors: initialState.errors,
      };

    case ActionTypes.RESET_FINANCE_STATE:
      return {
        ...initialState,
        salariesParams: state.salariesParams,
        incomeTypesParams: state.incomeTypesParams,
        currenciesParams: state.currenciesParams,
        clientsParams: state.clientsParams,
        suppliersParams: state.suppliersParams,
        projectTypesParams: state.projectTypesParams,
        expenseTypesParams: state.expenseTypesParams,
        projectParams: state.projectParams,
        transactionsParams: state.transactionsParams,
        invoicesParams: state.invoicesParams,
        planParams: state.planParams,
      };

    case ActionTypes.GET_INCOME_TYPES_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getIncomeTypesList: true },
        errors: { ...state.errors, incomeTypesError: null },
      };

    case ActionTypes.GET_INCOME_TYPES_LIST_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getIncomeTypesList: false },
        incomeTypesListData: action.payload,
        errors: { ...state.errors, incomeTypesError: null },
      };

    case ActionTypes.GET_INCOME_TYPES_LIST_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getIncomeTypesList: false },
        errors: { ...state.errors, incomeTypesError: action.payload.message },
      };

    case ActionTypes.POST_INCOME_TYPE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createIncomeType: true },
      };

    case ActionTypes.POST_INCOME_TYPE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, createIncomeType: false },
        errors: { ...state.errors, incomeTypesError: null },
      };

    case ActionTypes.POST_INCOME_TYPE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, createIncomeType: false },
        errors: {
          ...state.errors,
          incomeTypesError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.PUT_INCOME_TYPE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editIncomeType: true },
      };

    case ActionTypes.PUT_INCOME_TYPE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, editIncomeType: false },
        errors: { ...state.errors, incomeTypesError: null },
      };

    case ActionTypes.PUT_INCOME_TYPE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, editIncomeType: false },
        errors: {
          ...state.errors,
          incomeTypesError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.DELETE_INCOME_TYPE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteIncomeType: true },
      };

    case ActionTypes.DELETE_INCOME_TYPE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteIncomeType: false },
        errors: { ...state.errors, incomeTypesError: null },
      };

    case ActionTypes.DELETE_INCOME_TYPE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, deleteIncomeType: false },
        errors: {
          ...state.errors,
          incomeTypesError: action.payload.message,
        },
      };

    case ActionTypes.SET_INCOME_TYPES_PARAMS:
      return {
        ...state,
        incomeTypesParams: new paramsTypes.IncomeTypesParams({ ...state.incomeTypesParams, ...action.payload }),
        loading: { ...state.loading, getIncomeTypesList: true },
      };

    case ActionTypes.GET_CURRENCIES_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getCurrenciesList: true },
        errors: { ...state.errors, currenciesError: null },
      };

    case ActionTypes.GET_CURRENCIES_LIST_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getCurrenciesList: false },
        currenciesListData: action.payload,
        errors: { ...state.errors, currenciesError: null },
      };

    case ActionTypes.GET_CURRENCIES_LIST_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getCurrenciesList: false },
        errors: { ...state.errors, currenciesError: action.payload.message },
      };

    case ActionTypes.POST_CURRENCY_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createCurrency: true },
      };

    case ActionTypes.POST_CURRENCY_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, createCurrency: false },
        errors: { ...state.errors, currenciesError: null },
      };

    case ActionTypes.POST_CURRENCY_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, createCurrency: false },
        errors: {
          ...state.errors,
          currenciesError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.PUT_CURRENCY_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editCurrency: true },
      };

    case ActionTypes.PUT_CURRENCY_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, editCurrency: false },
        errors: { ...state.errors, currenciesError: null },
      };

    case ActionTypes.PUT_CURRENCY_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, editCurrency: false },
        errors: {
          ...state.errors,
          currenciesError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.DELETE_CURRENCY_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteCurrency: true },
      };

    case ActionTypes.DELETE_CURRENCY_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteCurrency: false },
        errors: { ...state.errors, currenciesError: null },
      };

    case ActionTypes.DELETE_CURRENCY_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, deleteCurrency: false },
        errors: {
          ...state.errors,
          currenciesError: action.payload.message,
        },
      };

    case ActionTypes.SET_CURRENCIES_PARAMS:
      return {
        ...state,
        currenciesParams: new paramsTypes.CurrenciesParams({ ...state.currenciesParams, ...action.payload }),
        loading: { ...state.loading, getCurrenciesList: true },
      };

    case ActionTypes.GET_PROJECT_TYPES_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getProjectTypesList: true },
        errors: { ...state.errors, projectTypesError: null },
      };

    case ActionTypes.GET_PROJECT_TYPES_LIST_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getProjectTypesList: false },
        projectTypesListData: action.payload,
        errors: { ...state.errors, projectTypesError: null },
      };

    case ActionTypes.GET_PROJECT_TYPES_LIST_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getProjectTypesList: false },
        errors: { ...state.errors, projectTypesError: action.payload.message },
      };

    case ActionTypes.POST_PROJECT_TYPE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createProjectType: true },
      };

    case ActionTypes.POST_PROJECT_TYPE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, createProjectType: false },
        errors: { ...state.errors, projectTypesError: null },
      };

    case ActionTypes.POST_PROJECT_TYPE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, createProjectType: false },
        errors: {
          ...state.errors,
          projectTypesError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.PUT_PROJECT_TYPE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editProjectType: true },
      };

    case ActionTypes.PUT_PROJECT_TYPE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, editProjectType: false },
        errors: { ...state.errors, projectTypesError: null },
      };

    case ActionTypes.PUT_PROJECT_TYPE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, editProjectType: false },
        errors: {
          ...state.errors,
          projectTypesError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.DELETE_PROJECT_TYPE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteProjectType: true },
      };

    case ActionTypes.DELETE_PROJECT_TYPE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteProjectType: false },
        errors: { ...state.errors, projectTypesError: null },
      };

    case ActionTypes.DELETE_PROJECT_TYPE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, deleteProjectType: false },
        errors: {
          ...state.errors,
          projectTypesError: action.payload.message,
        },
      };

    case ActionTypes.SET_PROJECT_TYPES_PARAMS:
      return {
        ...state,
        projectTypesParams: new paramsTypes.ProjectTypesParams({ ...state.projectTypesParams, ...action.payload }),
        loading: { ...state.loading, getProjectTypesList: true },
      };

    case ActionTypes.GET_EXPENSE_TYPES_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getExpenseTypesList: true },
        errors: { ...state.errors, expenseTypesError: null },
      };

    case ActionTypes.GET_EXPENSE_TYPES_LIST_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getExpenseTypesList: false },
        expenseTypesListData: action.payload,
        errors: { ...state.errors, expenseTypesError: null },
      };

    case ActionTypes.GET_EXPENSE_TYPES_LIST_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getExpenseTypesList: false },
        errors: { ...state.errors, expenseTypesError: action.payload.message },
      };

    case ActionTypes.POST_EXPENSE_TYPE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createIncomeType: true },
      };

    case ActionTypes.POST_EXPENSE_TYPE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, createIncomeType: false },
        errors: { ...state.errors, incomeTypesError: null },
      };

    case ActionTypes.POST_EXPENSE_TYPE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, createIncomeType: false },
        errors: {
          ...state.errors,
          incomeTypesError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.PUT_EXPENSE_TYPE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editExpenseType: true },
      };

    case ActionTypes.PUT_EXPENSE_TYPE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, editExpenseType: false },
        errors: { ...state.errors, expenseTypesError: null },
      };

    case ActionTypes.PUT_EXPENSE_TYPE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, editExpenseType: false },
        errors: {
          ...state.errors,
          expenseTypesError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.DELETE_EXPENSE_TYPE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteExpenseType: true },
      };

    case ActionTypes.DELETE_EXPENSE_TYPE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteExpenseType: false },
        errors: { ...state.errors, expenseTypesError: null },
      };

    case ActionTypes.DELETE_EXPENSE_TYPE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, deleteExpenseType: false },
        errors: {
          ...state.errors,
          expenseTypesError: action.payload.message,
        },
      };

    case ActionTypes.SET_EXPENSE_TYPES_PARAMS:
      return {
        ...state,
        expenseTypesParams: new paramsTypes.ExpenseTypesParams({ ...state.expenseTypesParams, ...action.payload }),
        loading: { ...state.loading, getExpenseTypesList: true },
      };

    case ActionTypes.GET_SUPPLIERS_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getSuppliersList: true },
        errors: { ...state.errors, suppliersError: null },
      };

    case ActionTypes.GET_SUPPLIERS_LIST_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getSuppliersList: false },
        suppliersListData: action.payload,
        errors: { ...state.errors, suppliersError: null },
      };

    case ActionTypes.GET_SUPPLIERS_LIST_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getSuppliersList: false },
        errors: { ...state.errors, suppliersError: action.payload.message },
      };

    case ActionTypes.POST_SUPPLIER_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createSupplier: true },
      };

    case ActionTypes.POST_SUPPLIER_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, createSupplier: false },
        errors: { ...state.errors, suppliersError: null },
      };

    case ActionTypes.POST_SUPPLIER_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, createSupplier: false },
        errors: {
          ...state.errors,
          suppliersError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.PUT_SUPPLIER_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editSupplier: true },
      };

    case ActionTypes.PUT_SUPPLIER_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, editSupplier: false },
        errors: { ...state.errors, suppliersError: null },
      };

    case ActionTypes.PUT_SUPPLIER_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, editSupplier: false },
        errors: {
          ...state.errors,
          suppliersError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.DELETE_SUPPLIER_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteSupplier: true },
      };

    case ActionTypes.DELETE_SUPPLIER_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteSupplier: false },
        errors: { ...state.errors, suppliersError: null },
      };

    case ActionTypes.DELETE_SUPPLIER_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, deleteSupplier: false },
        errors: {
          ...state.errors,
          suppliersError: action.payload.message,
        },
      };

    case ActionTypes.SET_SUPPLIERS_PARAMS:
      return {
        ...state,
        suppliersParams: new paramsTypes.SuppliersParams({ ...state.suppliersParams, ...action.payload }),
        loading: { ...state.loading, getSuppliersList: true },
      };

    case ActionTypes.GET_CLIENTS_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getClientsList: true },
        errors: { ...state.errors, clientsError: null },
      };

    case ActionTypes.GET_CLIENTS_LIST_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getClientsList: false },
        clientsListData: {
          ...action.payload,
          content: action.payload?.content.map((item: Client) => new Client(item)),
        },
        errors: { ...state.errors, clientsError: null },
      };

    case ActionTypes.GET_CLIENTS_LIST_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getClientsList: false },
        errors: { ...state.errors, clientsError: action.payload.message },
      };

    case ActionTypes.POST_CLIENT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createClient: true },
      };

    case ActionTypes.POST_CLIENT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, createClient: false },
        errors: { ...state.errors, currenciesError: null },
      };

    case ActionTypes.POST_CLIENT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, createClient: false },
        errors: {
          ...state.errors,
          clientsError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.PUT_CLIENT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editClient: true },
      };

    case ActionTypes.PUT_CLIENT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, editClient: false },
        errors: { ...state.errors, clientsError: null },
      };

    case ActionTypes.PUT_CLIENT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, editClient: false },
        errors: {
          ...state.errors,
          clientsError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.DELETE_CLIENT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteClient: true },
      };

    case ActionTypes.DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteClient: false },
        errors: { ...state.errors, clientsError: null },
      };

    case ActionTypes.DELETE_CLIENT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, deleteClient: false },
        errors: {
          ...state.errors,
          clientsError: action.payload.message,
        },
      };

    case ActionTypes.SET_CLIENTS_PARAMS:
      return {
        ...state,
        clientsParams: new paramsTypes.ClientsParams({ ...state.clientsParams, ...action.payload }),
        loading: { ...state.loading, getClientsList: true },
      };

    case ActionTypes.GET_SALARIES_LIST_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getSalariesList: false },
        salariesListData: new SalaryReport(action.payload),
        errors: { ...state.errors, salariesError: null },
      };

    case ActionTypes.GET_SALARIES_LIST_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getSalariesList: false },
        errors: { ...state.errors, salariesError: action.payload.message },
      };

    case ActionTypes.GET_SALARY_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getSalary: true },
        errors: { ...state.errors, salariesError: null },
      };

    case ActionTypes.GET_SALARY_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getSalary: false },
        currentSalary: action.payload,
        errors: { ...state.errors, salariesError: null },
      };

    case ActionTypes.GET_SALARY_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getSalary: false },
        errors: { ...state.errors, salariesError: action.payload.message },
      };

    case ActionTypes.PUT_SALARY_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editSalary: true },
      };

    case ActionTypes.PUT_SALARY_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, editSalary: false },
        errors: { ...state.errors, salariesError: null },
      };

    case ActionTypes.PUT_SALARY_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, editSalary: false },
        errors: {
          ...state.errors,
          salariesError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.DELETE_SALARY_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteSalary: true },
      };

    case ActionTypes.DELETE_SALARY_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteSalary: false },
        errors: { ...state.errors, salariesError: null },
      };

    case ActionTypes.DELETE_SALARY_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, deleteSalary: false },
        errors: {
          ...state.errors,
          salariesError: action.payload.message,
        },
      };

    case ActionTypes.SET_SALARIES_PARAMS:
      return {
        ...state,
        salariesParams: new paramsTypes.SalariesParams({ ...state.salariesParams, ...action.payload }),
        loading: { ...state.loading, getSalariesList: true },
      };

    case ActionTypes.GET_FINANCE_PROJECTS_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getProjectsList: true },
        errors: { ...state.errors, projectsError: null },
      };

    case ActionTypes.GET_FINANCE_PROJECTS_LIST_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getProjectsList: false },
        projectsListData: {
          ...action.payload,
          content: action.payload.content.map((item: FinanceProject) => new FinanceProject(item)),
        },
        errors: { ...state.errors, projectsError: null },
      };

    case ActionTypes.GET_FINANCE_PROJECTS_LIST_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getProjectsList: false },
        errors: { ...state.errors, projectsError: action.payload.message },
      };

    case ActionTypes.POST_FINANCE_PROJECT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createProject: true },
      };

    case ActionTypes.POST_FINANCE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, createProject: false },
        errors: { ...state.errors, projectsError: null },
      };

    case ActionTypes.POST_FINANCE_PROJECT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, createProject: false },
        errors: {
          ...state.errors,
          projectsError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.PUT_FINANCE_PROJECT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editProject: true },
      };

    case ActionTypes.PUT_FINANCE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, editProject: false },
        errors: { ...state.errors, projectsError: null },
      };

    case ActionTypes.PUT_FINANCE_PROJECT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, editProject: false },
        errors: {
          ...state.errors,
          projectsError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.DELETE_FINANCE_PROJECT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteProject: true },
      };

    case ActionTypes.DELETE_FINANCE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteProject: false },
        errors: { ...state.errors, projectsError: null },
      };

    case ActionTypes.DELETE_FINANCE_PROJECT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, deleteProject: false },
        errors: {
          ...state.errors,
          projectsError: action.payload.message,
        },
      };

    case ActionTypes.SET_FINANCE_PROJECTS_PARAMS:
      return {
        ...state,
        projectParams: new paramsTypes.FinanceProjectsParams({ ...state.projectParams, ...action.payload }),
        loading: { ...state.loading, getProjectsList: true },
      };

    case ActionTypes.GET_INCOMES_EXPENSES_REPORT_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getIncomesExpensesReport: true },
        errors: { ...state.errors, incomesExpensesReportError: null },
      };

    case ActionTypes.GET_INCOMES_EXPENSES_REPORT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getIncomesExpensesReport: false },
        incomesExpensesReportListData: {
          ...action.payload,
          expenses: action.payload.expenses?.map((item: ExpensesReportItem) => new ExpensesReportItem(item)),
          incomes: action.payload.incomes?.map((item: IncomesReportItem) => new IncomesReportItem(item)),
        },
        errors: { ...state.errors, incomesExpensesReportError: null },
      };

    case ActionTypes.GET_INCOMES_EXPENSES_REPORT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getIncomesExpensesReport: false },
        errors: { ...state.errors, incomesExpensesReportError: action.payload.message },
      };

    case ActionTypes.SET_INCOMES_EXPENSES_REPORT_PARAMS:
      return {
        ...state,
        incomesExpensesReportParams: new paramsTypes.IncomesExpensesReportParams({
          ...state.incomesExpensesReportParams,
          ...action.payload,
        }),
        loading: { ...state.loading, getIncomesExpensesReport: true },
        errors: { ...state.errors, incomesExpensesReportError: null },
      };

    case ActionTypes.GET_PROFIT_LOSS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getProfitLoss: false },
        profitLossListData: action.payload,
        errors: { ...state.errors, profitLossError: null },
      };

    case ActionTypes.GET_PROFIT_LOSS_ERROR:
      return {
        ...state,
        loading: { ...state.loading, getProfitLoss: false },
        errors: { ...state.errors, profitLossError: action.payload.message },
      };

    case ActionTypes.GET_PROFIT_LOSS:
      return {
        ...state,
        profitLossParams: new paramsTypes.OfficesBalanceParams({
          ...state.profitLossParams,
          ...action.payload,
        }),
        loading: { ...state.loading, getProfitLoss: true },
        errors: { ...state.errors, profitLossError: null },
      };

    case ActionTypes.GET_PROFIT_LOSS_REVENUE_CLIENT:
      return {
        ...state,
        loading: { ...state.loading, getRevenueFromClient: true },
        errors: { ...state.errors, revenueFromClientError: null },
      };

    case ActionTypes.GET_PROFIT_LOSS_REVENUE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getRevenueFromClient: false },
        revenueFromClientData: action.payload,
        errors: { ...state.errors, revenueFromClientError: null },
      };

    case (ActionTypes.GET_PROFIT_LOSS_REVENUE_CLIENT_ERROR, ActionTypes.PUT_PROFIT_LOSS_REVENUE_CLIENT_ERROR):
      return {
        ...state,
        loading: { ...state.loading, getRevenueFromClient: false },
        errors: { ...state.errors, revenueFromClientError: action.payload.message },
      };

    case ActionTypes.GET_PROFIT_LOSS_COST_PROJECT:
      return {
        ...state,
        loading: { ...state.loading, getCostProject: true },
        errors: { ...state.errors, costProjectError: null },
      };

    case ActionTypes.GET_PROFIT_LOSS_COST_PROJECT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getCostProject: false },
        costProjectData: action.payload
          ? { ...state.costProjectData, [action.payload.blockType]: action.payload }
          : state.costProjectData,
        errors: { ...state.errors, costProjectError: null },
      };

    case (ActionTypes.GET_PROFIT_LOSS_COST_PROJECT_ERROR, ActionTypes.PUT_PROFIT_LOSS_COST_PROJECT_ERROR):
      return {
        ...state,
        loading: { ...state.loading, getCostProject: false },
        errors: { ...state.errors, costProjectError: action.payload.message },
      };

    case ActionTypes.GET_PROFIT_LOSS_OPERATING_INCOME:
      return {
        ...state,
        loading: { ...state.loading, getOperatingIncome: true },
        errors: { ...state.errors, operatingIncomeError: null },
      };

    case ActionTypes.GET_PROFIT_LOSS_OPERATING_INCOME_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getOperatingIncome: false },
        operatingIncomeData: action.payload,
        errors: { ...state.errors, operatingIncomeError: null },
      };

    case (ActionTypes.GET_PROFIT_LOSS_OPERATING_INCOME_ERROR, ActionTypes.PUT_PROFIT_LOSS_OPERATING_INCOME_ERROR):
      return {
        ...state,
        loading: { ...state.loading, getOperatingIncome: false },
        errors: { ...state.errors, operatingIncomeError: action.payload.message },
      };

    case ActionTypes.GET_PROFIT_LOSS_NET_INCOME:
      return {
        ...state,
        loading: { ...state.loading, getNetIncome: true },
        errors: { ...state.errors, netIncomeError: null },
      };

    case ActionTypes.GET_PROFIT_LOSS_NET_INCOME_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getNetIncome: false },
        netIncomeData: action.payload,
        errors: { ...state.errors, netIncomeError: null },
      };

    case (ActionTypes.GET_PROFIT_LOSS_NET_INCOME_ERROR, ActionTypes.PUT_PROFIT_LOSS_NET_INCOME_ERROR):
      return {
        ...state,
        loading: { ...state.loading, getNetIncome: false },
        errors: { ...state.errors, netIncomeError: action.payload.message },
      };

    case ActionTypes.GET_PROFIT_LOSS_GROSS_PROFIT:
      return {
        ...state,
        loading: { ...state.loading, getGrossProfit: true },
        errors: { ...state.errors, grossProfitError: null },
      };

    case ActionTypes.GET_PROFIT_LOSS_GROSS_PROFIT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getGrossProfit: false },
        grossProfitData: action.payload,
        errors: { ...state.errors, grossProfitError: null },
      };

    case (ActionTypes.GET_PROFIT_LOSS_GROSS_PROFIT_ERROR, ActionTypes.PUT_PROFIT_LOSS_GROSS_PROFIT_ERROR):
      return {
        ...state,
        loading: { ...state.loading, getGrossProfit: false },
        errors: { ...state.errors, grossProfitError: action.payload.message },
      };

    case ActionTypes.GET_PROFIT_LOSS_REVENUE_EXPENSE:
      return {
        ...state,
        loading: { ...state.loading, getRevenueExpense: true },
        errors: { ...state.errors, revenueExpenseError: null },
      };

    case ActionTypes.GET_PROFIT_LOSS_REVENUE_EXPENSE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getRevenueExpense: false },
        revenueExpenseData: action.payload,
        errors: { ...state.errors, revenueExpenseError: null },
      };

    case (ActionTypes.GET_PROFIT_LOSS_REVENUE_EXPENSE_ERROR, ActionTypes.PUT_PROFIT_LOSS_REVENUE_EXPENSE_ERROR):
      return {
        ...state,
        loading: { ...state.loading, getRevenueExpense: false },
        errors: { ...state.errors, revenueExpenseError: action.payload.message },
      };

    case ActionTypes.GET_PROFIT_LOSS_TOTAL_GOODS:
      return {
        ...state,
        loading: { ...state.loading, getTotalGoods: true },
        errors: { ...state.errors, totalGoodsError: null },
      };

    case ActionTypes.GET_PROFIT_LOSS_TOTAL_GOODS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getTotalGoods: false },
        totalGoodsData: action.payload,
        errors: { ...state.errors, totalGoodsError: null },
      };

    case (ActionTypes.GET_PROFIT_LOSS_TOTAL_GOODS_ERROR, ActionTypes.PUT_PROFIT_LOSS_TOTAL_GOODS_ERROR):
      return {
        ...state,
        loading: { ...state.loading, getTotalGoods: false },
        errors: { ...state.errors, totalGoodsError: action.payload.message },
      };

    case ActionTypes.GET_PROFIT_LOSS_FINANCIAL_REVENUE:
      return {
        ...state,
        loading: { ...state.loading, getFinancialRevenue: true },
        errors: { ...state.errors, financialRevenueError: null },
      };

    case ActionTypes.GET_PROFIT_LOSS_FINANCIAL_REVENUE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getFinancialRevenue: false },
        financialRevenueData: action.payload,
        errors: { ...state.errors, financialRevenueError: null },
      };

    case (ActionTypes.GET_PROFIT_LOSS_FINANCIAL_REVENUE_ERROR, ActionTypes.PUT_PROFIT_LOSS_FINANCIAL_REVENUE_ERROR):
      return {
        ...state,
        loading: { ...state.loading, getFinancialRevenue: false },
        errors: { ...state.errors, financialRevenueError: action.payload.message },
      };

    case ActionTypes.GET_PROFIT_LOSS_FINANCIAL_EXPENSE:
      return {
        ...state,
        loading: { ...state.loading, getFinancialExpense: true },
        errors: { ...state.errors, financialExpenseError: null },
      };

    case ActionTypes.GET_PROFIT_LOSS_FINANCIAL_EXPENSE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getFinancialExpense: false },
        financialExpenseData: action.payload
          ? { ...state.financialExpenseData, [action.payload.blockType]: action.payload }
          : state.financialExpenseData,
        errors: { ...state.errors, financialExpenseError: null },
      };

    case (ActionTypes.GET_PROFIT_LOSS_FINANCIAL_EXPENSE_ERROR, ActionTypes.PUT_PROFIT_LOSS_FINANCIAL_EXPENSE_ERROR):
      return {
        ...state,
        loading: { ...state.loading, getFinancialExpense: false },
        errors: { ...state.errors, financialExpenseError: action.payload.message },
      };

    case ActionTypes.GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getDashboard: false },
        dashboardListData: action.payload,
        errors: { ...state.errors, dashboardError: null },
      };

    case ActionTypes.GET_DASHBOARD_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getDashboard: false },
        errors: { ...state.errors, dashboardError: action.payload.message },
      };

    case ActionTypes.SET_DASHBOARD_PARAMS:
      return {
        ...state,
        dashboardParams: new paramsTypes.OfficesBalanceParams({
          ...state.dashboardParams,
          ...action.payload,
        }),
        loading: { ...state.loading, getDashboard: true },
        errors: { ...state.errors, dashboardError: null },
      };

    case ActionTypes.SET_DASHBOARD_CHARTS_RANGE_PARAMS: {
      return {
        ...state,
        dashboardChartsRangeParams: new paramsTypes.OfficesBalanceParams({
          ...state.dashboardChartsRangeParams,
          ...action.payload,
        }),
        loading: { ...state.loading, getDashboardIncome: true, getDashboardExpense: true },
        errors: { ...state.errors, dashboardIncomeError: null, dashboardExpenseError: null },
      };
    }

    case ActionTypes.GET_EXPENSES_STRUCTURE_SETTING_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getExpensesStructure: true },
        errors: { ...state.errors, dashboardExpensesStructureError: null },
      };

    case ActionTypes.GET_EXPENSES_STRUCTURE_SETTING_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getExpensesStructure: false },
        dashboardExpensesStructureData: action.payload,
        errors: { ...state.errors, dashboardExpensesStructureError: null },
      };

    case (ActionTypes.GET_EXPENSES_STRUCTURE_SETTING_FAILURE, ActionTypes.PUT_EXPENSES_STRUCTURE_SETTING_FAILURE):
      return {
        ...state,
        loading: { ...state.loading, getExpensesStructure: false },
        errors: { ...state.errors, dashboardExpensesStructureError: action.payload.message },
      };

    case ActionTypes.SET_DASHBOARD_INCOME_GROUP:
      return {
        ...state,
        incomePositionNumber: action.payload.value,
      };

    case ActionTypes.SET_DASHBOARD_EXPENSE_GROUP:
      return {
        ...state,
        expensePositionNumber: action.payload.value,
      };

    case ActionTypes.GET_DASHBOARD_INCOME:
      return {
        ...state,
        loading: { ...state.loading, getDashboardIncome: true },
        errors: { ...state.errors, dashboardIncomeError: null },
      };

    case ActionTypes.GET_DASHBOARD_INCOME_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getDashboardIncome: false },
        dashboardIncomeData: action.payload,
        errors: { ...state.errors, dashboardIncomeError: null },
      };

    case ActionTypes.GET_DASHBOARD_INCOME_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getDashboardIncome: false },
        errors: { ...state.errors, dashboardIncomeError: action.payload.message },
      };

    case ActionTypes.GET_DASHBOARD_EXPENSE:
      return {
        ...state,
        loading: { ...state.loading, getDashboardExpense: true },
        errors: { ...state.errors, dashboardExpenseError: null },
      };

    case ActionTypes.GET_DASHBOARD_EXPENSE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getDashboardExpense: false },
        dashboardExpenseData: action.payload,
        errors: { ...state.errors, dashboardExpenseError: null },
      };

    case ActionTypes.GET_DASHBOARD_EXPENSE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getDashboardExpense: false },
        errors: { ...state.errors, dashboardExpenseError: action.payload.message },
      };

    case ActionTypes.GET_REVENUE_AND_EXPENSE_SETTING_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getRevenueAndExpense: true },
        errors: { ...state.errors, revenueAndExpenseError: null },
      };

    case ActionTypes.GET_REVENUE_AND_EXPENSE_SETTING_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getRevenueAndExpense: false },
        revenueAndExpenseData: action.payload,
        errors: { ...state.errors, revenueAndExpenseError: null },
      };

    case (ActionTypes.GET_REVENUE_AND_EXPENSE_SETTING_FAILURE, ActionTypes.PUT_REVENUE_AND_EXPENSE_SETTING_FAILURE):
      return {
        ...state,
        loading: { ...state.loading, getRevenueAndExpense: false },
        errors: { ...state.errors, revenueAndExpenseError: action.payload.message },
      };

    case ActionTypes.GET_DASHBOARD_PLANNED_EXPENSES_SETTING_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getDashboardPlannedExpenses: true },
        errors: { ...state.errors, dashboardPlannedExpensesError: null },
      };

    case ActionTypes.GET_DASHBOARD_PLANNED_EXPENSES_SETTING_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getDashboardPlannedExpenses: false },
        dashboardPlannedExpensesData: action.payload
          ? { ...state.dashboardPlannedExpensesData, [action.payload.blockType]: action.payload }
          : state.dashboardPlannedExpensesData,
        errors: { ...state.errors, dashboardPlannedExpensesError: null },
      };

    case (ActionTypes.GET_DASHBOARD_PLANNED_EXPENSES_SETTING_FAILURE,
    ActionTypes.PUT_DASHBOARD_PLANNED_EXPENSES_SETTING_FAILURE):
      return {
        ...state,
        loading: { ...state.loading, getDashboardPlannedExpenses: false },
        errors: { ...state.errors, dashboardPlannedExpensesError: action.payload.message },
      };

    case ActionTypes.GET_DASHBOARD_CLIENT_SETTING_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getDashboardClientSetting: true },
        errors: { ...state.errors, dashboardClientSettingError: null },
      };

    case ActionTypes.GET_DASHBOARD_CLIENT_SETTING_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getDashboardClientSetting: false },
        dashboardClientSettingData: action.payload
          ? { ...state.dashboardClientSettingData, [action.payload.blockType]: action.payload }
          : state.dashboardClientSettingData,
        errors: { ...state.errors, dashboardClientSettingError: null },
      };

    case (ActionTypes.GET_DASHBOARD_CLIENT_SETTING_FAILURE, ActionTypes.PUT_DASHBOARD_CLIENT_SETTING_FAILURE):
      return {
        ...state,
        loading: { ...state.loading, getDashboardClientSetting: false },
        errors: { ...state.errors, dashboardClientSettingError: action.payload.message },
      };

    case ActionTypes.GET_DASHBOARD_SETTING_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getDashboardSetting: true },
        errors: { ...state.errors, dashboardSettingError: null },
      };

    case ActionTypes.GET_DASHBOARD_SETTING_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getDashboardSetting: false },
        dashboardSettingData: action.payload,
        errors: { ...state.errors, dashboardSettingError: null },
      };

    case (ActionTypes.GET_DASHBOARD_SETTING_FAILURE, ActionTypes.PUT_DASHBOARD_SETTING_FAILURE):
      return {
        ...state,
        loading: { ...state.loading, getDashboardSetting: false },
        errors: { ...state.errors, dashboardSettingError: action.payload.message },
      };

    case ActionTypes.GET_DASHBOARD_TOP_SETTING_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getDashboardTopSetting: true },
        errors: { ...state.errors, dashboardTopSettingError: null },
      };

    case ActionTypes.GET_DASHBOARD_TOP_SETTING_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getDashboardTopSetting: false },
        dashboardTopSettingData: action.payload,
        errors: { ...state.errors, dashboardTopSettingError: null },
      };

    case (ActionTypes.GET_DASHBOARD_TOP_SETTING_FAILURE, ActionTypes.PUT_DASHBOARD_TOP_SETTING_FAILURE):
      return {
        ...state,
        loading: { ...state.loading, getDashboardTopSetting: false },
        errors: { ...state.errors, dashboardTopSettingError: action.payload.message },
      };

    case ActionTypes.GET_DASHBOARD_TOP_INCOME_SETTING_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getDashboardIncomeTopSetting: true },
        errors: { ...state.errors, dashboardIncomeTopSettingError: null },
      };

    case ActionTypes.GET_DASHBOARD_TOP_INCOME_SETTING_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getDashboardIncomeTopSetting: false },
        dashboardIncomeTopSettingData: action.payload,
        errors: { ...state.errors, dashboardIncomeTopSettingError: null },
      };

    case (ActionTypes.GET_DASHBOARD_TOP_INCOME_SETTING_FAILURE, ActionTypes.PUT_DASHBOARD_TOP_INCOME_SETTING_FAILURE):
      return {
        ...state,
        loading: { ...state.loading, getDashboardIncomeTopSetting: false },
        errors: { ...state.errors, dashboardIncomeTopSettingError: action.payload.message },
      };

    case ActionTypes.GET_DASHBOARD_TOP_EXPENSE_SETTING_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getDashboardExpenseTopSetting: true },
        errors: { ...state.errors, dashboardExpenseTopSettingError: null },
      };

    case ActionTypes.GET_DASHBOARD_TOP_EXPENSE_SETTING_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getDashboardExpenseTopSetting: false },
        dashboardExpenseTopSettingData: action.payload,
        errors: { ...state.errors, dashboardExpenseTopSettingError: null },
      };

    case (ActionTypes.GET_DASHBOARD_TOP_EXPENSE_SETTING_FAILURE, ActionTypes.PUT_DASHBOARD_TOP_EXPENSE_SETTING_FAILURE):
      return {
        ...state,
        loading: { ...state.loading, getDashboardExpenseTopSetting: false },
        errors: { ...state.errors, dashboardExpenseTopSettingError: action.payload.message },
      };

    case ActionTypes.GET_CASH_FLOW_REPORT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getCashFlowReport: false },
        cashFlowReportListData: action.payload,
        errors: { ...state.errors, cashFlowReportError: null },
      };

    case ActionTypes.GET_CASH_FLOW_REPORT_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getCashFlowReport: false },
        errors: { ...state.errors, cashFlowReportError: action.payload.message },
      };

    case ActionTypes.SET_CASH_FLOW_REPORT_PARAMS:
      return {
        ...state,
        cashFlowReportParams: new cashflowTypes.CashFlowReportParams({
          ...state.cashFlowReportParams,
          ...action.payload,
        }),
        loading: { ...state.loading, getCashFlowReport: true },
        errors: { ...state.errors, cashFlowReportError: null },
      };

    case ActionTypes.GET_CASH_FLOW_SETTING_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getCashFlowReportSetting: false },
        cashFlowReportSetting: action.payload,
        errors: { ...state.errors, cashFlowReportSettingError: null },
      };

    case ActionTypes.GET_CASH_FLOW_BALANCE_SETTING_SUCCESS:
      return {
        ...state,
        cashFlowReportBalanceSetting: action.payload.name,
      };

    case ActionTypes.GET_CASH_FLOW_TOTAL_SETTING_SUCCESS:
      return {
        ...state,
        cashFlowReportTotalSetting: action.payload.name,
      };

    case ActionTypes.GET_CASH_FLOW_BALANCE_SETTING_FAILURE:
      return {
        ...state,
        cashFlowReportBalanceSetting: null,
      };

    case ActionTypes.GET_CASH_FLOW_TOTAL_SETTING_FAILURE:
      return {
        ...state,
        cashFlowReportTotalSetting: null,
      };

    case ActionTypes.GET_CASH_FLOW_SETTING_FAILURE:
    case ActionTypes.PUT_CASH_FLOW_SETTING_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getCashFlowReportSetting: false },
        errors: { ...state.errors, cashFlowReportSettingError: action.payload.message },
      };

    case ActionTypes.GET_CASH_FLOW_EXPENSE_SETTING_REQUEST:
    case ActionTypes.GET_CASH_FLOW_INCOME_SETTING_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getCashFlowReportSetting: true },
        errors: { ...state.errors, cashFlowReportSettingError: null },
      };

    case ActionTypes.RESET_CASH_FLOW_SETTING:
      return {
        ...state,
        loading: { ...state.loading, getCashFlowReportSetting: false },
        cashFlowReportSetting: null,
        errors: { ...state.errors, cashFlowReportSettingError: null },
      };

    case ActionTypes.GET_OFFICES_BALANCE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getOfficesBalance: false },
        officesBalanceListData: action.payload,
        errors: { ...state.errors, officesBalanceError: null },
      };

    case ActionTypes.GET_OFFICES_BALANCE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getOfficesBalance: false },
        errors: { ...state.errors, officesBalanceError: action.payload.message },
      };

    case ActionTypes.SET_OFFICES_BALANCE_PARAMS:
      return {
        ...state,
        officesBalanceParams: new paramsTypes.OfficesBalanceParams({
          ...state.officesBalanceParams,
          ...action.payload,
        }),
        loading: { ...state.loading, getOfficesBalance: true },
        errors: { ...state.errors, officesBalanceError: null },
      };

    case ActionTypes.PUT_OFFICE_BALANCE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editOfficeBalance: true },
      };

    case ActionTypes.PUT_OFFICE_BALANCE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, editOfficeBalance: false },
        errors: { ...state.errors, officesBalanceError: null },
      };

    case ActionTypes.PUT_OFFICE_BALANCE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, editOfficeBalance: false },
        errors: {
          ...state.errors,
          officesBalanceError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.GET_TRANSACTIONS_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getTransactions: true },
        errors: { ...state.errors, transactionListError: null },
      };

    case ActionTypes.GET_TRANSACTIONS_LIST_SUCCESS:
      const { transactions, total } = action.payload;
      return {
        ...state,
        loading: { ...state.loading, getTransactions: false },
        transactionsListData: {
          ...transactions,
          content: transactions?.content?.map((transaction: any) => new Transaction(transaction)),
        },
        transactionsTotal: total,
        errors: { ...state.errors, transactionListError: null },
      };

    case ActionTypes.GET_TRANSACTIONS_LIST_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getTransactions: false },
        errors: { ...state.errors, transactionListError: action.payload.message },
      };

    case ActionTypes.POST_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createTransaction: true },
      };

    case ActionTypes.POST_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, createTransaction: false },
        errors: { ...state.errors, transactionListError: null },
      };

    case ActionTypes.POST_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, createTransaction: false },
        errors: {
          ...state.errors,
          transactionError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.IMPORT_TRANSACTIONS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, importTransactions: true },
      };

    case ActionTypes.IMPORT_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, importTransactions: false },
        errors: { ...state.errors, transactionListError: null },
      };

    case ActionTypes.IMPORT_TRANSACTIONS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, importTransactions: false },
        errors: {
          ...state.errors,
          importTransationsError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.PUT_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editTransaction: true },
      };

    case ActionTypes.PUT_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, editTransaction: false },
        errors: { ...state.errors, transactionError: null },
      };

    case ActionTypes.PUT_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, editTransaction: false },
        errors: {
          ...state.errors,
          transactionError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.DELETE_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteTransaction: true },
      };

    case ActionTypes.DELETE_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteTransaction: false },
        errors: { ...state.errors, transactionError: null },
      };

    case ActionTypes.DELETE_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, deleteTransaction: false },
        errors: {
          ...state.errors,
          transactionError: action.payload.message,
        },
      };

    case ActionTypes.SET_INVOICES_PARAMS:
      return {
        ...state,
        invoicesParams: new cashflowTypes.InvoicesParams({ ...state.invoicesParams, ...action.payload }),
        loading: { ...state.loading, getInvoices: true },
        errors: { ...state.errors, invoiceListError: null },
      };

    case ActionTypes.GET_INVOICES_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getInvoices: true },
        errors: { ...state.errors, invoiceListError: null },
      };

    case ActionTypes.GET_INVOICES_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getInvoices: false },
        invoicesListData: action.payload,
        errors: { ...state.errors, projectsError: null },
      };

    case ActionTypes.GET_INVOICES_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getInvoices: false },
        errors: { ...state.errors, invoiceError: action.payload.message },
      };

    case ActionTypes.CREATE_INVOICE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createInvoice: true },
      };

    case ActionTypes.CREATE_INVOICE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, createInvoice: false },
        errors: { ...state.errors, invoiceError: null },
      };

    case ActionTypes.CREATE_INVOICE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, createInvoice: false },
        errors: {
          ...state.errors,
          invoiceError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.PUT_INVOICE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editInvoice: true },
      };

    case ActionTypes.PUT_INVOICE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, editInvoice: false },
        errors: { ...state.errors, invoiceError: null },
      };

    case ActionTypes.PUT_INVOICE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, editInvoice: false },
        errors: {
          ...state.errors,
          invoiceError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.CLONE_INVOICE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, cloneInvoice: true },
      };

    case ActionTypes.CLONE_INVOICE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, cloneInvoice: false },
        errors: { ...state.errors, invoiceError: null },
      };

    case ActionTypes.CLONE_INVOICE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, cloneInvoice: false },
        errors: {
          ...state.errors,
          invoiceError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.UPLOAD_INVOICE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, uploadInvoice: true },
      };

    case ActionTypes.UPLOAD_INVOICE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, uploadInvoice: false },
        errors: { ...state.errors, invoiceError: null },
      };

    case ActionTypes.UPLOAD_INVOICE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, uploadInvoice: false },
        errors: {
          ...state.errors,
          invoiceError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.GENERATE_TASK_LIST_INVOICE_REQUEST:
      return {
        ...state,
        exportInfo: action.payload,
        isPendingRequests: true,
      };

    case ActionTypes.DOWNLOAD_INVOICE_REQUEST:
      return {
        ...state,
        exportInfo: action.payload,
        isPendingRequests: true,
      };

    case ActionTypes.DELETE_INVOICE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteInvoice: true },
      };

    case ActionTypes.DELETE_INVOICE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteInvoice: false },
        errors: { ...state.errors, invoiceError: null },
      };

    case ActionTypes.DELETE_INVOICE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, deleteInvoice: false },
        errors: {
          ...state.errors,
          invoiceError: action.payload.message,
        },
      };

    case ActionTypes.DELETE_INVOICE_FILE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deleteInvoiceFile: true },
      };

    case ActionTypes.DELETE_INVOICE_FILE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deleteInvoiceFile: false },
        errors: { ...state.errors, invoiceError: null },
      };

    case ActionTypes.DELETE_INVOICE_FILE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, deleteInvoiceFile: false },
        errors: {
          ...state.errors,
          invoiceError: action.payload.message,
        },
      };

    case ActionTypes.SET_TRANSACTIONS_PARAMS:
      return {
        ...state,
        transactionsParams: new paramsTypes.TransactionsParams({ ...state.transactionsParams, ...action.payload }),
        loading: { ...state.loading, getTransactions: true },
        errors: { ...state.errors, transactionListError: null },
      };

    case ActionTypes.GET_FINANCE_PLANS_LIST_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getPlansList: true },
        errors: { ...state.errors, planError: null },
      };

    case ActionTypes.GET_FINANCE_PLANS_LIST_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getPlansList: false },
        plansListData: {
          ...action.payload,
          content: action.payload.content.map((item: FinancePlan) => new FinancePlan(item)),
        },
        errors: { ...state.errors, planError: null },
      };

    case ActionTypes.GET_FINANCE_PLANS_LIST_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getPlansList: false },
        errors: { ...state.errors, planError: action.payload.message },
      };

    case ActionTypes.SET_FINANCE_PLANS_PARAMS:
      return {
        ...state,
        planParams: new paramsTypes.FinancePlanParams({ ...state.planParams, ...action.payload }),
      };

    case ActionTypes.GET_FINANCE_PLAN_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getPlan: true },
        errors: { ...state.errors, planError: null },
      };

    case ActionTypes.GET_FINANCE_PLAN_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getPlan: false },
        currentPlanData: new FinancePlan(action.payload),
        currenPlanMonth: action.payload.month,
        errors: { ...state.errors, planError: null },
      };

    case ActionTypes.GET_FINANCE_PLAN_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getPlan: false },
        errors: { ...state.errors, planError: action.payload.message },
      };

    case ActionTypes.SET_FINANCE_PLAN_MONTH:
      return {
        ...state,
        currenPlanMonth: action.payload.month,
      };

    case ActionTypes.POST_FINANCE_PLAN_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createPlan: true },
      };

    case ActionTypes.POST_FINANCE_PLAN_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, createPlan: false },
        errors: { ...state.errors, planError: null },
      };

    case ActionTypes.POST_FINANCE_PLAN_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, createPlan: false },
        errors: {
          ...state.errors,
          planError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.PUT_FINANCE_PLAN_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editPlan: true },
      };

    case ActionTypes.PUT_FINANCE_PLAN_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, editPlan: false },
        errors: { ...state.errors, planError: null },
      };

    case ActionTypes.PUT_FINANCE_PLAN_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, editPlan: false },
        errors: {
          ...state.errors,
          planError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.CLONE_FINANCE_PLAN_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, clonePlan: true },
      };

    case ActionTypes.CLONE_FINANCE_PLAN_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, clonePlan: false },
        errors: { ...state.errors, planError: null },
      };

    case ActionTypes.CLONE_FINANCE_PLAN_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, clonePlan: false },
        errors: {
          ...state.errors,
          planError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.SET_CLONE_FINANCE_PLAN_CHECKED_INFO:
      return {
        ...state,
        loading: { ...state.loading, clonePlan: false },
        clonePlanCheckedInfo: action.payload.info?.map(
          (info: ClonePlanCheckedInfoType) =>
            new ChekedInfo({
              user: info.user,
              newValue: info.hoursPercent,
              originalValue: action.payload.clonnedPlan.employees.find(
                (financeUserPlan: any) => financeUserPlan.userId === info.user.id,
              )?.hoursPercent,
            }),
        ),
      };

    case ActionTypes.RESET_CLONE_FINANCE_PLAN_CHECKED_INFO:
      return {
        ...state,
        clonePlanCheckedInfo: [],
      };

    case ActionTypes.DELETE_FINANCE_PLAN_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deletePlan: true },
      };

    case ActionTypes.DELETE_FINANCE_PLAN_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deletePlan: false },
        errors: { ...state.errors, planError: null },
      };

    case ActionTypes.DELETE_FINANCE_PLAN_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, deletePlan: false },
        errors: {
          ...state.errors,
          planError: action.payload.message,
        },
      };

    case ActionTypes.GET_FINANCE_PLAN_EMPLOYEES_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getPlanEmployeesList: true },
        errors: { ...state.errors, planEmployeesError: null },
      };

    case ActionTypes.GET_FINANCE_PLAN_EMPLOYEES_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getPlanEmployeesList: false },
        plansListData: {
          ...action.payload,
          content: action.payload.content.map((item: FinancePlanUser) => new FinancePlanUser(item)),
        },
        errors: { ...state.errors, planEmployeesError: null },
      };

    case ActionTypes.GET_FINANCE_PLAN_EMPLOYEES_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getPlanEmployeesList: false },
        errors: { ...state.errors, planEmployeesError: action.payload.message },
      };

    case ActionTypes.GET_EMPLOYEE_HOURS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getEmployeeHours: true },
        errors: { ...state.errors, employeeHoursError: null },
      };

    case ActionTypes.GET_EMPLOYEE_HOURS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getEmployeeHours: false },
        employeeHours: new EmployeeHours(action.payload),
        errors: { ...state.errors, employeeHoursError: null },
      };

    case ActionTypes.GET_EMPLOYEE_HOURS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getEmployeeHours: false },
        errors: { ...state.errors, employeeHoursError: action.payload.message },
      };

    case ActionTypes.RESET_EMPLOYEE_HOURS:
      return { ...state, employeeHours: new EmployeeHours() };

    case ActionTypes.SET_FINANCE_PLAN_EMPLOYEES_PARAMS:
      return {
        ...state,
        planEmployeesParams: new paramsTypes.FinancePlanEmployeesParams({
          ...state.planEmployeesParams,
          ...action.payload,
        }),
      };

    case ActionTypes.POST_FINANCE_PLAN_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, createPlanEmployee: true },
      };

    case ActionTypes.POST_FINANCE_PLAN_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, createPlanEmployee: false },
        errors: { ...state.errors, planEmployeesError: null },
      };

    case ActionTypes.POST_FINANCE_PLAN_EMPLOYEE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, createPlanEmployee: false },
        errors: {
          ...state.errors,
          planEmployeesError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.PUT_FINANCE_PLAN_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editPlanEmployee: true },
      };

    case ActionTypes.PUT_FINANCE_PLAN_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, editPlanEmployee: false },
        errors: { ...state.errors, planEmployeesError: null },
      };

    case ActionTypes.PUT_FINANCE_PLAN_EMPLOYEE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, editPlanEmployee: false },
        errors: {
          ...state.errors,
          planEmployeesError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.DELETE_FINANCE_PLAN_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deletePlanEmployee: true },
      };

    case ActionTypes.DELETE_FINANCE_PLAN_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deletePlanEmployee: false },
        errors: { ...state.errors, planEmployeesError: null },
      };

    case ActionTypes.DELETE_FINANCE_PLAN_EMPLOYEE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, deletePlanEmployee: false },
        errors: {
          ...state.errors,
          planEmployeesError: action.payload.message,
        },
      };

    case ActionTypes.IMPORT_RESOURCES_IN_FINANCE_PLAN_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, importResources: true },
      };

    case ActionTypes.IMPORT_RESOURCES_IN_FINANCE_PLAN_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, importResources: false },
        errors: { ...state.errors, importResourcesError: null },
      };

    case ActionTypes.IMPORT_RESOURCES_IN_FINANCE_PLAN_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, importResources: false },
        errors: {
          ...state.errors,
          importResourcesError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.SET_IMPORT_RESOURCES_IN_FINANCE_PLAN_CHECKED_INFO:
      return {
        ...state,
        loading: { ...state.loading, importResources: false },
        importResourcesCheckedInfo: action.payload.map(
          (info: ImportResourcesCheckedInfoType) =>
            new ChekedInfo({
              user: info.user,
              originalValue: info.resourceLoadPercent,
              newValue: info.resultLoadPercent,
            }),
        ),
      };

    case ActionTypes.SET_PLANNED_EXPENSE_PARAMS:
      return {
        ...state,
        plannedExpenseParams: new paramsTypes.PlannedExpenseParams({
          ...state.plannedExpenseParams,
          ...action.payload,
        }),
        loading: { ...state.loading, getPlannedExpense: true },
        errors: { ...state.errors, plannedExpenseError: null },
      };

    case ActionTypes.GET_PLANNED_EXPENSE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getPlannedExpense: false },
        plannedExpense: {
          ...action.payload,
          expenseReportParts: action.payload.expenseReportParts.map(
            (item: PlannedExpenseReportItem) => new PlannedExpenseReportItem(item),
          ),
        },
        errors: { ...state.errors, plannedExpenseError: null },
      };

    case ActionTypes.GET_PLANNED_EXPENSE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getPlannedExpense: false },
        errors: { ...state.errors, plannedExpenseError: action.payload.message },
      };

    case ActionTypes.PUT_PLANNED_EXPENSE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, editPlannedExpense: true },
      };

    case ActionTypes.PUT_PLANNED_EXPENSE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, editPlannedExpense: false },
        errors: { ...state.errors, plannedExpenseError: null },
      };

    case ActionTypes.PUT_PLANNED_EXPENSE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, editPlannedExpense: false },
        errors: {
          ...state.errors,
          plannedExpenseError: getReducerErrors(action.payload),
        },
      };

    case ActionTypes.DELETE_PLANNED_EXPENSE_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, deletePlannedExpense: true },
      };

    case ActionTypes.DELETE_PLANNED_EXPENSE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, deletePlannedExpense: false },
        errors: { ...state.errors, plannedExpenseError: null },
      };

    case ActionTypes.DELETE_PLANNED_EXPENSE_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, deletePlannedExpense: false },
        errors: {
          ...state.errors,
          plannedExpenseError: action.payload.message,
        },
      };

    case ActionTypes.RESET_IMPORT_RESOURCES_IN_FINANCE_PLAN_CHECKED_INFO:
      return {
        ...state,
        importResourcesCheckedInfo: [],
      };

    case ActionTypes.GET_DATES_OF_EXISTING_PLANS_REQUEST:
      return {
        ...state,
        loading: { ...state.loading, getDatesOfExistingPlans: true },
      };

    case ActionTypes.GET_DATES_OF_EXISTING_PLANS_SUCCESS:
      return {
        ...state,
        datesOfExistingPlans: action.payload,
        loading: { ...state.loading, getDatesOfExistingPlans: false },
        errors: { ...state.errors, datesOfExistingPlans: null },
      };

    case ActionTypes.GET_DATES_OF_EXISTING_PLANS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, getDatesOfExistingPlans: false },
        errors: {
          ...state.errors,
          datesOfExistingPlans: action.payload,
        },
      };

    case ActionTypes.RESET_DATES_OF_EXISTING_PLANS:
      return {
        ...state,
        datesOfExistingPlans: [],
      };

    default:
      return state;
  }
};

export default financeReducer;
