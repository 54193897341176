import { get } from 'lodash-es';
import * as yup from 'yup';
import { ExpenseIncomeTypesGroupEnum } from '../../pages/Transactions/utils';
import { getDateParams } from '../../utils/params.utils';
import { CurrencyFormatter } from '../finance/finance.enum';
import { SortParams } from '../params.enum';

export class IncomeTypesParams extends SortParams {
  size: number;
  constructor(params?: unknown) {
    super('name', params);
    this.size = get(params, 'size', 1000);
  }
}

export class CurrenciesParams extends SortParams {
  size: number;
  constructor(params?: unknown) {
    super('name', params);
    this.size = get(params, 'size', 1000);
  }
}

export class ClientsParams extends SortParams {
  size: number;
  constructor(params?: unknown) {
    super('name', params);
    this.size = get(params, 'size', 1000);
  }
}

export class SuppliersParams extends SortParams {
  size: number;
  constructor(params?: unknown) {
    super('name', params);
    this.size = get(params, 'size', 1000);
  }
}

export class ProjectTypesParams extends SortParams {
  size: number;
  constructor(params?: unknown) {
    super('name', params);
    this.size = get(params, 'size', 1000);
  }
}

export class ExpenseTypesParams extends SortParams {
  size: number;
  constructor(params?: unknown) {
    super('name', params);
    this.size = get(params, 'size', 1000);
  }
}

export class SalariesParams {
  size: number;
  dateFrom: string;
  dateTo: string;
  departments: string[];
  officeIds: string[];
  users: string[];

  constructor(params?: unknown) {
    this.size = get(params, 'size', 1000);
    this.dateFrom = get(params, 'dateFrom', getDateParams({ withTime: false, period: 'year' }));
    this.dateTo = get(params, 'dateTo', getDateParams({ withTime: false, getFromStart: false, period: 'year' }));
    this.departments = get(params, 'departments', []);
    this.officeIds = get(params, 'officeIds', []);
    this.users = get(params, 'users', []);
  }
}

export class FinanceProjectsParams extends SortParams {
  size: number;
  page: number;
  clientIds: string[];

  constructor(params?: unknown) {
    super('name', params);
    this.size = get(params, 'size', 10);
    this.page = get(params, 'page', 0);
    this.clientIds = get(params, 'clientIds', []);
  }
}

export class TransactionsParams extends SortParams {
  size: number;
  page: number;
  dateFrom: string;
  dateTo: string;
  officeIds: string[];
  expenseTypeIds: string[];
  subExpenseTypeIds: string[];
  incomeTypeIds: string[];
  clientPayerRecipientIds: string[];
  supplierPayerRecipientIds: string[];
  officePayerRecipientIds: string[];
  employeePayerRecipientIds: string[];
  currencyIds: string[];
  originalAmountFrom: number | null;
  originalAmountTo: number | null;
  unifiedAmountFrom: number | null;
  unifiedAmountTo: number | null;
  types: ExpenseIncomeTypesGroupEnum[];

  constructor(params?: unknown) {
    super('transactionDate', params);
    this.size = get(params, 'size', 10);
    this.page = get(params, 'page', 0);
    this.dateFrom = get(params, 'dateFrom', getDateParams({ withTime: false, period: 'month' }));
    this.dateTo = get(params, 'dateTo', getDateParams({ withTime: false, getFromStart: false, period: 'month' }));
    this.officeIds = get(params, 'officeIds', []);
    this.expenseTypeIds = get(params, 'expenseTypeIds', []);
    this.subExpenseTypeIds = get(params, 'subExpenseTypeIds', []);
    this.incomeTypeIds = get(params, 'incomeTypeIds', []);
    this.clientPayerRecipientIds = get(params, 'clientPayerRecipientIds', []);
    this.supplierPayerRecipientIds = get(params, 'supplierPayerRecipientIds', []);
    this.officePayerRecipientIds = get(params, 'officePayerRecipientIds', []);
    this.employeePayerRecipientIds = get(params, 'employeePayerRecipientIds', []);
    this.currencyIds = get(params, 'currencyIds', []);
    this.originalAmountFrom = get(params, 'originalAmountFrom', null);
    this.originalAmountTo = get(params, 'originalAmountTo', null);
    this.unifiedAmountFrom = get(params, 'unifiedAmountFrom', null);
    this.unifiedAmountTo = get(params, 'unifiedAmountTo', null);
    this.types = get(params, 'types', []);
  }
}

const amountToTest = (value: CurrencyFormatter | undefined, testContext: any) => {
  const originalAmountTo = value?.float;
  const originalAmountFrom: number | null = testContext.from[1].value.originalAmountFrom?.float;

  return !originalAmountFrom || !originalAmountTo || originalAmountTo >= originalAmountFrom;
};

export const TRANSACTIONS_ORIGINAL_AMOUNT_FILTER_SCHEMA = yup.object().shape({
  originalAmountFrom: yup.object({
    float: yup.mixed().nullable(),
    value: yup.mixed(),
    formatted: yup.mixed(),
  }),
  originalAmountTo: yup
    .object({
      float: yup.mixed().nullable(),
      value: yup.mixed(),
      formatted: yup.mixed(),
    })
    .test('originalAmountTo', 'Value must be more or equal', amountToTest),
});

export const TRANSACTIONS_UNIFIED_AMOUNT_FILTER_SCHEMA = yup.object().shape({
  unifiedAmountFrom: yup.object({
    float: yup.mixed().nullable(),
    value: yup.mixed(),
    formatted: yup.mixed(),
  }),
  unifiedAmountTo: yup
    .object({
      float: yup.mixed().nullable(),
      value: yup.mixed(),
      formatted: yup.mixed(),
    })
    .test('unifiedAmountTo', 'Value must be more or equal', amountToTest),
});

export class FinancePlanParams extends SortParams {
  size: number;
  page: number;
  projectIds: string[];
  clientIds: string[];
  month: string;

  constructor(params?: unknown) {
    super('client', params);
    this.size = get(params, 'size', 2000);
    this.page = get(params, 'page', 0);
    this.projectIds = get(params, 'projectIds', []);
    this.clientIds = get(params, 'clientIds', []);
    this.month = get(params, 'month', getDateParams({ withTime: false }));
  }
}

export class FinancePlanEmployeesParams extends SortParams {
  size: number;
  employeeIds: string[];

  constructor(params?: unknown) {
    super('user', params);
    this.size = get(params, 'size', 1000);
    this.employeeIds = get(params, 'employeeIds', []);
  }
}

export class OfficesBalanceParams {
  dateFrom: string;
  dateTo: string;
  officeIds: string[];

  constructor(params?: unknown) {
    this.dateFrom = get(params, 'dateFrom', getDateParams({ withTime: false, period: 'year' }));
    this.dateTo = get(params, 'dateTo', getDateParams({ withTime: false, getFromStart: false, period: 'year' }));
    this.officeIds = get(params, 'officeIds', []);
  }
}

export class IncomesExpensesReportParams {
  monthFrom: string;
  monthTo: string;
  incomeTypesIds: string[];
  officeIds: string[];
  expenseTypeIds: string[];
  clientIds: string[];
  userIds: string[];
  extension: string;
  isShowSalary: boolean;

  constructor(params?: unknown) {
    this.monthFrom = get(params, 'monthFrom', getDateParams({ withTime: false, period: 'year' }));
    this.monthTo = get(params, 'monthTo', getDateParams({ withTime: false, getFromStart: false, period: 'year' }));
    this.incomeTypesIds = get(params, 'incomeTypesIds', []);
    this.officeIds = get(params, 'officeIds', []);
    this.expenseTypeIds = get(params, 'expenseTypeIds', []);
    this.clientIds = get(params, 'clientIds', []);
    this.userIds = get(params, 'userIds', []);
    this.extension = get(params, 'extension', 'EXCEL');
    this.isShowSalary = get(params, 'isShowSalary', true);
  }
}

export class PlannedExpenseParams {
  startDate: string;
  endDate: string;
  officeIds: string[];
  expenseTypeIds: string[];

  constructor(params?: unknown) {
    this.startDate = get(params, 'startDate', getDateParams({ withTime: false, period: 'year' }));
    this.endDate = get(params, 'endDate', getDateParams({ withTime: false, getFromStart: false, period: 'year' }));
    this.officeIds = get(params, 'officeIds', []);
    this.expenseTypeIds = get(params, 'expenseTypeIds', []);
  }
}
