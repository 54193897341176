import React, { useCallback, useMemo, useState } from 'react';
import { OptionTypeBase } from 'react-select';
import Filter from '../../Filter';
import { useIntl } from 'react-intl';
import messages from '../messages';
import { useFiltersListValue } from '../../../utils/hooks.utils';
import { OfficeInfo } from '../../../enums/libraries.enum';
import { PlannedExpenseParams } from '../../../enums/params/finance.params';
import { ExpenseType } from '../../../types/finance';
import { FilterParamsName, FilterTypes } from '../../../constants/filters.constants';
import FilterClearButton from '../../FilterClearButton';
import FiltersControl from '../../FiltersControl';
import { SavedFilter, SavedFiltersDataType } from '../../../enums/filters.enum';
import { plannedExpenseUnsavedParams } from '../../../constants/finance.constants';
import RefreshButton from '../../RefreshButton';

type FiltersType = {
  offices: OfficeInfo[];
  expenseTypes: ExpenseType[];
};

type PlannedExpenseFiltersProps = {
  filters: FiltersType;
  values: PlannedExpenseParams;
  setPlannedExpenseParams: (data: Partial<PlannedExpenseParams>) => void;
  handleMultiParamsChange: (name: string) => (data: OptionTypeBase) => void;
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) => void;
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) => void;
  deleteSavedFilter: (data: { id: string; callback: () => void }) => void;
  savedFiltersData: SavedFiltersDataType;
  authUserId: string;
  handleFiltersControlChange: (value: SavedFilter) => void;
  handleClear: () => void;
  resetSavedFilterErrors: () => void;
  policyOfficiesIds: string[] | undefined;
  isOfficeSpecific: boolean;
  showClearButton: boolean;
};

function PlannedExpenseFilters({
  filters,
  handleMultiParamsChange,
  values,
  createNewSavedFilter,
  setPlannedExpenseParams,
  editSavedFilter,
  deleteSavedFilter,
  savedFiltersData,
  authUserId,
  handleFiltersControlChange,
  handleClear,
  resetSavedFilterErrors,
  policyOfficiesIds,
  isOfficeSpecific,
  showClearButton,
}: PlannedExpenseFiltersProps) {
  const intl = useIntl();

  const [resetCurrentFilter, setResetCurrentFilter] = useState(false);

  const officesOptions = useMemo(
    () =>
      filters.offices
        ?.filter(el => !isOfficeSpecific || policyOfficiesIds?.some(id => id === el.id))
        .map((el: Record<string, any>) => ({
          value: el.id,
          label: el.name,
        })),
    [filters.offices, policyOfficiesIds, isOfficeSpecific],
  );

  const expenseTypesOptions = useMemo(
    () =>
      filters.expenseTypes?.map((el: Record<string, any>) => ({
        label: el.name,
        value: el.id,
      })),
    [filters.expenseTypes],
  );

  const officeValues = useFiltersListValue(officesOptions, values.officeIds)?.filter(
    el => !isOfficeSpecific || policyOfficiesIds?.some(id => id === el.value),
  );

  const expenseTypesValues = useFiltersListValue(expenseTypesOptions, values.expenseTypeIds);

  const onClear = useCallback(() => {
    setResetCurrentFilter(true);
    handleClear();
  }, []);

  const setResettFilterFlag = useCallback(() => {
    setResetCurrentFilter(false);
  }, []);

  return (
    <>
      <Filter
        isMulti
        label={intl.formatMessage(messages.officesLabel)}
        options={officesOptions}
        value={officeValues}
        handleChange={e => handleMultiParamsChange(FilterParamsName.OFFICE_IDS)(e)}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        label={intl.formatMessage(messages.expenseTypesLabel)}
        options={expenseTypesOptions}
        value={expenseTypesValues}
        handleChange={e => handleMultiParamsChange('expenseTypeIds')(e)}
        externalClass="filters__select"
      />
      {showClearButton && <FilterClearButton onClear={onClear} />}
      <FiltersControl
        handleSaveFilter={createNewSavedFilter}
        handleUpdateFilter={editSavedFilter}
        handleDeleteFilter={deleteSavedFilter}
        savedFiltersData={savedFiltersData}
        authUserId={authUserId}
        filterType={FilterTypes.PLANNED_EXPENSES_FILTER}
        handleChange={handleFiltersControlChange}
        params={values}
        resetSavedFilterErrors={resetSavedFilterErrors}
        unsavedParams={plannedExpenseUnsavedParams}
        resetCurrentFilter={resetCurrentFilter}
        setResettFilterFlag={setResettFilterFlag}
      />
      <RefreshButton onRefresh={() => setPlannedExpenseParams(values)} />
    </>
  );
}

export default React.memo(PlannedExpenseFilters);
