import React, { useCallback, useEffect, useState, useMemo, useRef, useContext } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import EventFilter from '../../components/Schedule/Filter/EventFilter';
import cx from 'classnames';
import { ScheduleLayouts, scheduleUnsavedParams } from '../../constants/schedule.constants';
import * as scheduleActions from '../../actions/schedule.actions';
import * as librariesActions from '../../actions/libraries.actions';
import * as usersActions from '../../actions/users.actions';
import * as filtersActions from '../../actions/filters.actions';
import DurationPicker from '../../components/Dropdown';
import ModalNewEvent from '../../components/Schedule/Modals/ModalNewEvent';
import ModalEditEvent from '../../components/Schedule/Modals/ModalEditEvent';
import ModalDeleteEvent from '../../components/Schedule/Modals/ModalDeleteEvent';
import EventsListLayout from '../../components/Schedule/Layouts/EventsList';
import { EventInfo, EventPreviewInfo } from '../../enums/schedule.enum';
import { OptionTypeBase } from 'react-select';
import InlineDatePicker, { PeriodSelectOptionsType } from '../../components/InlineDatePicker';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import {
  useAbleToManageEventTypes,
  handleEventPopUpChangePosition,
  handleRenderDropdownListBtn,
} from '../../utils/schedule.utils';
import { EventTypeInfo } from '../../enums/calendar.enum';
import { NavLink } from 'react-router-dom';
import r from '../../constants/routes.constants';
import { EventsParams } from '../../enums/params/schedule.params';
import TableUserAvatar from '../../components/TableUserAvatar';
import AccessChecker from '../../components/AccessChecker';
import { UPDATE_EVENT, UPDATE_EVENT_EXTENDED, UPDATE_PUBLIC_EVENT } from '../../constants/policies.constants';
import DepartmentsListLayout from '../../components/Schedule/Layouts/DepartmentsList';
import { resetParamsChange, useFiltersValue, useLayout } from '../../utils/hooks.utils';
import CalendarLayout from '../../components/Schedule/Layouts/CalendarLayout';
import EventPopUpModal from '../../components/Schedule/Modals/EventPopUpModal';
import ModalHoliday from '../../components/Schedule/Modals/ModalHoliday';
import { PollParams } from '../../enums/params/questionnaires.params';
import { InlineDatePickerPeriods } from '../../components/InlineDatePicker/constants';
import { AssessmentsParams } from '../../enums/params/competencies.params';
import { UserInfo } from '../../enums/users.enum';
import moment from 'moment';
import PoliciesContext from '../../PoliciesContext';
import { allEventSizeOnPage, defaultEventSizeOnPage } from './utils';
import { isEmpty } from 'lodash-es';
import {
  checkParamsMatch,
  convertSavedFieldsToParams,
  getSavedFilterParams,
  getSavedFilters,
} from '../../utils/filters.utils';
import { SavedFilterParams } from '../../enums/params/filters.params';
import { SavedFilter } from '../../enums/filters.enum';
import { FilterParamsName, FilterTypes } from '../../constants/filters.constants';
import { DateSelectArg } from '@fullcalendar/react';

function Schedule({
  scheduleLayout,
  scheduleCalendarPeriod,
  eventType,
  eventError,
  loading,
  eventsParams,
  eventTableData,
  eventTypesFilter,
  officesFilter,
  usersFilter,
  userInfo,
  assessmentList,
  assessmentsLoading,
  departmentsFilter,
  userAbsencePeriods,
  userHrInfo,
  absencePeriodsLoading,
  usersLoading,
  holidaysData,
  daysOffInfo,
  officesWorkDays,
  pollList,
  eventData,
  getPollsFilter,
  setScheduleLayout,
  setScheduleCalendarPeriod,
  getEventList,
  createNewEvent,
  resetEventType,
  resetEventErrors,
  getEventTypesFilter,
  getEventType,
  setEventParams,
  getOfficesFilter,
  getDepartmentsFilter,
  getUsersFilter,
  resetErrors,
  resetState,
  getAssessmentsFilter,
  deleteEvent,
  editEvent,
  resetEvents,
  resetEvent,
  resetAssessmentsFilter,
  getProfessionalInfo,
  getUserAbsencePeriods,
  getHolidays,
  getOffDaysInfo,
  getOfficesWorkDays,
  getEvent,
  setSavedFiltersParams,
  createNewSavedFilter,
  savedFiltersData,
  editSavedFilter,
  deleteSavedFilter,
  resetSavedFilterErrors,
}: ConnectedProps<typeof connector>) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const userPolicies = useContext(PoliciesContext);
  const pickerRef = useRef<any>(null);
  const popUpRef = useRef<any>(null);
  const popupDropdownRef = useRef<any>(null);
  const [layout, handleChangeActiveLayout] = useLayout(ScheduleLayouts, scheduleLayout);
  const [modalNewEventIsOpen, setModalNewEventIsOpen] = useState(false);
  const [modalEditEventIsOpen, setModalEditEventIsOpen] = useState(false);
  const [modalDeleteEventIsOpen, setModalDeleteEventIsOpen] = useState(false);
  const [modalEventIsOpen, setModalEventIsOpen] = useState(false);
  const [eventPopUpModalClassName, setEventPopUpModalClassName] = useState<React.CSSProperties>({});
  const [updateNextModal, setUpdateNextModal] = useState(false);
  const [modalHolidayIsOpen, setModalHolidayIsOpen] = useState(false);
  const [calendarPeriod, setCalendarPeriod] = useState<InlineDatePickerPeriods>(scheduleCalendarPeriod);
  const [calendarClikedDate, setCalendarClikedDate] = useState<null | DateSelectArg>(null);

  const openUpdateNextModal = useCallback(() => {
    setUpdateNextModal(true);
  }, []);

  useEffect(() => {
    const action = (e: MouseEvent) => {
      if (e.target) {
        const elem = e.target as HTMLElement;
        let isEventClicked = false;
        const popup = popUpRef.current;
        const dropdown = popupDropdownRef.current;
        if ((popup && popup.contains(elem)) || (dropdown && dropdown.contains(elem))) {
          return;
        }

        const eventClasses = [
          'fc-daygrid-event',
          'fc-event-time',
          'fc-event-title',
          'fc-timegrid-event',
          'event-row',
          'event-row-text',
        ];

        elem.classList.forEach((elemClass: string) => {
          if (eventClasses.includes(elemClass)) {
            isEventClicked = true;
          }
        });
        !isEventClicked && closeEventModal();
      }
    };
    document.addEventListener('mousedown', action);
    return () => {
      document.removeEventListener('mousedown', action);
    };
  }, []);

  const closeUpdateNextModal = useCallback(() => {
    updateNextModal && resetEventErrors();
    setUpdateNextModal(false);
  }, [updateNextModal]);

  const handleSort = useCallback((sortBy, direction) => setEventParams({ sortBy, direction }), []);

  const handlePageChange = useCallback(({ selected }) => {
    setEventParams({ page: selected });
  }, []);

  const handleSizeChange = useCallback(data => {
    setEventParams({ size: data, page: 0 });
  }, []);

  const openNewEventModal = useCallback(() => {
    setModalNewEventIsOpen(true);
  }, []);

  const closeNewEventModal = useCallback(() => {
    modalNewEventIsOpen && resetEventType() && resetEventErrors() && resetAssessmentsFilter();
    setModalNewEventIsOpen(false);
    setCalendarClikedDate(null);
  }, [modalNewEventIsOpen]);

  const openEditEventModal = useCallback(() => {
    setModalEditEventIsOpen(true);
  }, []);

  const handleMoveToDayView = useCallback((date: string) => {
    const currentPicker = pickerRef.current;
    const format = {
      value: InlineDatePickerPeriods.DAY_PERIOD,
      label: InlineDatePickerPeriods.DAY_PERIOD,
    };
    if (currentPicker) {
      currentPicker.setPeriodFormat(format);
      currentPicker.handleChangeDate(date, date, format);
      setCalendarPeriod(InlineDatePickerPeriods.DAY_PERIOD);
    }
  }, []);

  const closeEditEventModal = useCallback(() => {
    !updateNextModal && modalEditEventIsOpen && resetEventErrors() && resetAssessmentsFilter() && resetEvent();
    !updateNextModal && setModalEditEventIsOpen(false);
    setModalEventIsOpen(false);
  }, [modalEditEventIsOpen, updateNextModal]);

  const openDeleteEventModal = useCallback(() => {
    setModalDeleteEventIsOpen(true);
  }, []);

  const closeDeleteEventModal = useCallback(() => {
    modalDeleteEventIsOpen && resetEventErrors() && resetErrors() && resetEvent();
    setModalDeleteEventIsOpen(false);
    setModalEventIsOpen(false);
  }, [modalDeleteEventIsOpen]);

  const openEventModal = useCallback(
    (event: EventPreviewInfo, e, view?: ScheduleLayouts) => {
      const handlePopUpPosition = () => handleEventPopUpChangePosition(e, setEventPopUpModalClassName, popUpRef, view);
      setModalEventIsOpen(true);
      getEvent(event.id, handlePopUpPosition);
      setTimeout(handlePopUpPosition, 0);
    },
    [popUpRef.current],
  );

  const closeEventModal = useCallback(() => {
    setModalEventIsOpen(false);
  }, []);

  const openHolidayModal = useCallback(() => {
    setModalHolidayIsOpen(true);
  }, []);

  const closeHolidayModal = useCallback(() => {
    setModalHolidayIsOpen(false);
  }, []);

  const handleEventMultiParamsChange = useCallback(
    name => (data: OptionTypeBase) => {
      const value = data.map((el: Record<string, any>) => el.value);
      setEventParams(
        { [name]: value, page: 0 },
        calendarPeriod !== InlineDatePickerPeriods.YEAR_PERIOD || layout !== ScheduleLayouts.CALENDAR,
      );
      useFiltersValue(dispatch, name, value);
    },
    [calendarPeriod, layout],
  );

  const handleUsersParamsChange = useCallback(
    name => {
      return (data: OptionTypeBase) => {
        const value = data.map(({ value }: { label: string; value: UserInfo }) => (value.id ? value.id : value));
        setEventParams(
          { [name]: value, page: 0 },
          calendarPeriod !== InlineDatePickerPeriods.YEAR_PERIOD || layout !== ScheduleLayouts.CALENDAR,
        );
        useFiltersValue(dispatch, name, value);
      };
    },
    [calendarPeriod, layout],
  );

  const ableToManageEventTypes = useAbleToManageEventTypes(eventTypesFilter.eventTypes, userInfo);

  const handleCalendarLayoutClick = useCallback(
    (prop: DateSelectArg) => {
      if (!isEmpty(ableToManageEventTypes)) {
        setCalendarClikedDate(prop);
        openNewEventModal();
        getEventType(ableToManageEventTypes[0].id);
      }
    },
    [ableToManageEventTypes],
  );

  const renderDropdownBtn = useCallback(
    (row: EventPreviewInfo | EventInfo, isUpdateBtn: boolean) =>
      handleRenderDropdownListBtn(
        row,
        ableToManageEventTypes,
        userInfo,
        userPolicies,
        isUpdateBtn,
        eventTypesFilter.eventTypes,
      ),
    [ableToManageEventTypes, userInfo, eventTypesFilter.eventTypes],
  );

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.typeLabel),
        modifier: (row: EventPreviewInfo) => (
          <NavLink className={'table__type-link'} to={r.eventInformation.replace(':id', `${row.id}`)}>
            <div className={'table__data-wrapper'}>
              <div className={'table__data-no-image'} style={{ backgroundColor: row.eventTypeColor }} />
              <span className={'table_button'}>{row.eventTypeName}</span>
            </div>
          </NavLink>
        ),
      },
      {
        name: intl.formatMessage(messages.titleLabel),
        modifier: (row: EventPreviewInfo) => row.title,
      },
      {
        name: intl.formatMessage(messages.datesLabel),
        modifier: (row: EventPreviewInfo) => row.datePeriod,
      },
      {
        name: intl.formatMessage(messages.targetMemberLabel),
        modifier: (row: EventPreviewInfo) =>
          row.targetEmployee.id ? <TableUserAvatar fileSize={48} users={[row.targetEmployee]} /> : null,
      },
      {
        name: intl.formatMessage(messages.participantsLabel),
        modifier: (row: EventPreviewInfo) => {
          const isEmptyParpicipants = isEmpty(row.participants);
          const isEmptyLocations = isEmpty(row.locations);
          return !isEmptyParpicipants || !isEmptyLocations ? (
            <div className={'table__participants'}>
              {!isEmptyParpicipants ? (
                <TableUserAvatar users={row.participants} fileSize={48} selectedTableUser />
              ) : (
                row.locations.map((el, index) => {
                  return (
                    <span className="page__event__town" key={index}>
                      {el.name}
                    </span>
                  );
                })
              )}
            </div>
          ) : null;
        },
      },
      {
        name: intl.formatMessage(messages.optionsLabel),
        modifier: (row: EventPreviewInfo) =>
          !isEmpty(row.eventOptions) && (
            <div className="page__event__option-wrapper">
              {row.eventOptions.map((option, index) => (
                <span className="page__event__option" key={index}>
                  {option}
                </span>
              ))}
            </div>
          ),
      },
    ],
    [],
  );

  const renderLayout = useCallback(
    (layout: string | undefined) => {
      switch (layout) {
        case ScheduleLayouts.EVENTS:
          return (
            <EventsListLayout
              isPaginated
              eventTableData={eventTableData}
              userInfo={userInfo}
              loading={loading.getEventList}
              error={eventError.eventListError}
              ableToManageEventTypes={ableToManageEventTypes}
              tableColumns={tableColumns}
              getEvent={getEvent}
              openEditEventModal={openEditEventModal}
              openDeleteEventModal={openDeleteEventModal}
              handlePageChange={handlePageChange}
              handleSizeChange={handleSizeChange}
              renderDropdownBtn={renderDropdownBtn}
            />
          );
        case ScheduleLayouts.DEPARTMENTS:
          return (
            <DepartmentsListLayout
              eventTableData={eventTableData?.content || []}
              isLoadingGetEventList={loading.getEventList}
              error={eventError.eventListError}
              dateFrom={eventsParams.dateTimeFrom}
              dateTo={eventsParams.dateTimeTo}
              userAbsencePeriods={userAbsencePeriods}
              userHrInfo={userHrInfo}
              absencePeriodsLoading={usersLoading.absencePeriods}
              officesWorkDays={officesWorkDays}
              daysOffInfo={daysOffInfo}
              openEventModal={openEventModal}
              getProfessionalInfo={getProfessionalInfo}
              getUserAbsencePeriods={getUserAbsencePeriods}
            />
          );

        case ScheduleLayouts.CALENDAR:
          return (
            <CalendarLayout
              eventTableData={eventTableData?.content || []}
              calendarPeriod={calendarPeriod}
              isLoadingGetEventList={loading.getEventList}
              dateFrom={eventsParams.dateTimeFrom}
              dateTo={eventsParams.dateTimeTo}
              officesWorkDays={officesWorkDays}
              daysOffInfo={daysOffInfo}
              popUpRef={popUpRef}
              openEventModal={openEventModal}
              handleMoveToDayView={handleMoveToDayView}
              getEventList={getEventList}
              openNewEventModal={handleCalendarLayoutClick}
            />
          );
        default:
          return (
            <CalendarLayout
              eventTableData={eventTableData?.content || []}
              calendarPeriod={calendarPeriod}
              dateFrom={eventsParams.dateTimeFrom}
              dateTo={eventsParams.dateTimeTo}
              officesWorkDays={officesWorkDays}
              isLoadingGetEventList={loading.getEventList}
              daysOffInfo={daysOffInfo}
              popUpRef={popUpRef}
              openEventModal={openEventModal}
              openNewEventModal={handleCalendarLayoutClick}
              handleMoveToDayView={handleMoveToDayView}
              getEventList={getEventList}
            />
          );
      }
    },
    [
      eventTableData,
      loading.getEventList,
      userAbsencePeriods,
      userHrInfo,
      usersLoading,
      daysOffInfo,
      officesWorkDays,
      absencePeriodsLoading,
      ableToManageEventTypes,
      calendarPeriod,
      eventsParams,
      userInfo,
      renderDropdownBtn,
    ],
  );

  useEffect(() => {
    resetEvents();
  }, [calendarPeriod]);

  const newEventAction = useMemo(
    () =>
      ableToManageEventTypes.map(({ id, name }: Pick<EventTypeInfo, 'id' | 'name'>) => ({
        id,
        label: name,
        handler: () => {
          openNewEventModal();
          getEventType(id);
        },
      })),
    [eventTypesFilter.eventTypes],
  );

  const handleDateChange = useCallback(
    (start: string, end: string) => {
      setEventParams({
        dateTimeFrom: moment(start).format(),
        dateTimeTo: moment(end).format(),
        size: layout === ScheduleLayouts.EVENTS ? defaultEventSizeOnPage : allEventSizeOnPage,
        page: 0,
      });
    },
    [layout],
  );

  const handleCalendarDateChange = useCallback((start: string, end: string, periodFormat: PeriodSelectOptionsType) => {
    const format = periodFormat.value;

    setCalendarPeriod(format);
    setScheduleCalendarPeriod(format);
    setEventParams(
      {
        dateTimeFrom: moment(start).format(),
        dateTimeTo: moment(end).format(),
        size: allEventSizeOnPage,
        page: 0,
      },
      periodFormat.value !== InlineDatePickerPeriods.YEAR_PERIOD,
    );
  }, []);

  const handleCalendarView = useCallback(() => {
    if (layout !== ScheduleLayouts.CALENDAR) {
      resetEvents();
      setEventParams({ page: 0, size: allEventSizeOnPage }, calendarPeriod !== InlineDatePickerPeriods.YEAR_PERIOD);
      const dateStart = moment(eventsParams.dateTimeFrom);
      const dateEnd = moment(eventsParams.dateTimeTo);
      const isOneMonthPeriod =
        dateStart.startOf('month').isSame(dateStart, 'day') && dateStart.endOf('month').isSame(dateEnd, 'day');
      const isOneDayPeriod = moment(eventsParams.dateTimeTo).isSame(moment(eventsParams.dateTimeFrom));
      const isOneWeekPeriod = moment(eventsParams.dateTimeTo).diff(moment(eventsParams.dateTimeFrom), 'd') === 6;
      const periodValue = isOneDayPeriod
        ? InlineDatePickerPeriods.DAY_PERIOD
        : isOneMonthPeriod
        ? InlineDatePickerPeriods.MONTH_PERIOD
        : isOneWeekPeriod
        ? InlineDatePickerPeriods.WEEK_PERIOD
        : calendarPeriod;
      periodValue !== calendarPeriod && setCalendarPeriod(periodValue);

      setTimeout(() =>
        pickerRef.current?.setPeriodFormat({
          value: periodValue,
          label: periodValue,
        }),
      );
    }
    handleChangeActiveLayout(ScheduleLayouts.CALENDAR);
    setScheduleLayout(ScheduleLayouts.CALENDAR);
  }, [layout, calendarPeriod, eventsParams.dateTimeFrom, eventsParams.dateTimeTo]);

  useEffect(() => {
    layout !== ScheduleLayouts.EVENTS && getOffDaysInfo();
  }, [eventsParams.dateTimeFrom, eventsParams.dateTimeTo, eventsParams.offices, layout]);

  useEffect(() => {
    layout !== ScheduleLayouts.EVENTS && getOfficesWorkDays({ offices: eventsParams.offices });
  }, [eventsParams.offices, layout]);

  useEffect(() => {
    closeEventModal();
  }, [eventsParams]);

  useEffect(() => {
    const currentSavedFilter = getSavedFilterParams(getSavedFilters(), FilterTypes.EVENT_FILTER);

    setEventParams(
      currentSavedFilter
        ? new EventsParams({
            ...currentSavedFilter,
            size: layout === ScheduleLayouts.EVENTS ? defaultEventSizeOnPage : allEventSizeOnPage,
            ...(layout === ScheduleLayouts.CALENDAR && {
              dateTimeFrom: moment(eventsParams.dateTimeFrom).startOf(calendarPeriod).format(),
              dateTimeTo: moment(eventsParams.dateTimeFrom).endOf(calendarPeriod).format(),
              isAllowPreviews: false,
              publicAllowed: true,
            }),
          })
        : {
            offices: officesFilter.value,
            departments: departmentsFilter.value,
            targetEmployees: usersFilter.value,
            isAllowPreviews: false,
            publicAllowed: true,
            eventTypes: eventTypesFilter.value,
            size: layout === ScheduleLayouts.EVENTS ? defaultEventSizeOnPage : allEventSizeOnPage,
            ...(layout === ScheduleLayouts.CALENDAR && {
              dateTimeFrom: moment(eventsParams.dateTimeFrom).startOf(calendarPeriod).format(),
              dateTimeTo: moment(eventsParams.dateTimeFrom).endOf(calendarPeriod).format(),
            }),
          },
      calendarPeriod !== InlineDatePickerPeriods.YEAR_PERIOD || layout !== ScheduleLayouts.CALENDAR,
    );

    getEventTypesFilter();
    getOfficesFilter();
    getUsersFilter();
    getDepartmentsFilter();
    getHolidays();
    setSavedFiltersParams({ filterType: FilterTypes.EVENT_FILTER });

    return () => {
      resetEvents();
      resetState();
    };
  }, []);

  const filters = useMemo(
    () => ({
      departments: departmentsFilter.departments,
      eventTypes: eventTypesFilter.eventTypes,
      offices: officesFilter.offices,
      users: usersFilter.users,
    }),
    [departmentsFilter.departments, eventTypesFilter.eventTypes, officesFilter.offices, usersFilter.users],
  );

  const handleFiltersControlChange = useCallback(
    value => {
      setEventParams(
        new EventsParams({
          ...convertSavedFieldsToParams(value.fields),
          dateTimeFrom: eventsParams.dateTimeFrom,
          dateTimeTo: eventsParams.dateTimeTo,
        }),
      );
    },
    [eventsParams],
  );

  const handleClear = useCallback(() => {
    setEventParams(new EventsParams({ dateTimeFrom: eventsParams.dateTimeFrom, dateTimeTo: eventsParams.dateTimeTo }));
    resetParamsChange(
      [
        FilterParamsName.EVENT_TYPES,
        FilterParamsName.TARGET_EMPLOYEES,
        FilterParamsName.OFFICES,
        FilterParamsName.DEPARTMENTS,
      ],
      dispatch,
    );
  }, [eventsParams]);

  const showClearButton = useMemo(
    () =>
      !checkParamsMatch({ ...eventsParams, dateTimeFrom: '2' }, { ...new EventsParams(), dateTimeFrom: '2' }, [
        ...scheduleUnsavedParams,
        'size',
      ]),
    [eventsParams],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed schedule-page">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
            <div className="page__panel-top__control">
              <div className="pagination page__panel-top__control__pagination">
                {layout === ScheduleLayouts.CALENDAR ? (
                  <InlineDatePicker
                    pickerRef={pickerRef}
                    onDateChange={handleCalendarDateChange}
                    defaultPeriod={calendarPeriod}
                    defaultPeriodStart={eventsParams.dateTimeFrom}
                    defaultPeriodEnd={eventsParams.dateTimeTo}
                    isCustomDatePicker={false}
                    key="calendar-date-picker"
                  />
                ) : (
                  <InlineDatePicker
                    onDateChange={handleDateChange}
                    defaultPeriodStart={eventsParams.dateTimeFrom}
                    defaultPeriodEnd={eventsParams.dateTimeTo}
                    key="event-departments-date-picker"
                  />
                )}
              </div>
              <div className="page__panel-view">
                <Button
                  externalClass={cx('page__panel-view__btn', { active: layout === ScheduleLayouts.CALENDAR })}
                  onClick={handleCalendarView}
                  color="gray"
                >
                  <Icon iconName="calendar" />
                </Button>
                <Button
                  externalClass={cx('page__panel-view__btn', { active: layout === ScheduleLayouts.DEPARTMENTS })}
                  onClick={() => {
                    if (layout !== ScheduleLayouts.DEPARTMENTS) {
                      resetEvents();
                      setEventParams({ page: 0, size: allEventSizeOnPage });
                    }
                    handleChangeActiveLayout(ScheduleLayouts.DEPARTMENTS);
                    setScheduleLayout(ScheduleLayouts.DEPARTMENTS);
                  }}
                  color="gray"
                >
                  <Icon iconName="stream" />
                </Button>
                <Button
                  externalClass={cx('page__panel-view__btn', { active: layout === ScheduleLayouts.EVENTS })}
                  onClick={() => {
                    if (layout !== ScheduleLayouts.EVENTS) {
                      resetEvents();
                      setEventParams({ page: 0, size: defaultEventSizeOnPage });
                    }
                    handleChangeActiveLayout(ScheduleLayouts.EVENTS);
                    setScheduleLayout(ScheduleLayouts.EVENTS);
                  }}
                  color="gray"
                >
                  <Icon iconName="table" />
                </Button>
              </div>
            </div>
          </div>
          <div className="page__panel-bottom">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[UPDATE_EVENT_EXTENDED, UPDATE_EVENT, UPDATE_PUBLIC_EVENT]}>
                  <DurationPicker
                    dropdownToggle={
                      <>
                        <Icon iconName={'plus'} externalClass={'button__icon'} />
                        <span className="button__text">
                          <FormattedMessage {...messages.newButton} />
                        </span>
                        <Icon iconName={'select-arrow'} externalClass={'button__icon select-arrow'} />
                      </>
                    }
                    dropdownList={newEventAction}
                    customBtn
                    dropdownToggleClass="btn-select"
                    placement="bottom-start"
                  />
                </AccessChecker>
                <EventFilter
                  filters={filters}
                  values={eventsParams}
                  handleEventMultiParamsChange={handleEventMultiParamsChange}
                  handleUsersParamsChange={handleUsersParamsChange}
                  handleSort={handleSort}
                  showSortSelect={layout === ScheduleLayouts.EVENTS}
                  createNewSavedFilter={createNewSavedFilter}
                  savedFiltersData={savedFiltersData}
                  authUserId={userInfo.id}
                  setEventParams={setEventParams}
                  deleteSavedFilter={deleteSavedFilter}
                  editSavedFilter={editSavedFilter}
                  handleFiltersControlChange={handleFiltersControlChange}
                  handleClear={handleClear}
                  resetSavedFilterErrors={resetSavedFilterErrors}
                  showClearButton={showClearButton}
                />
              </div>
              <div className="page__panel__filters--right">
                <div className="holiday-wrapper">
                  <Button onClick={openHolidayModal} color="#f5f5f5" circle externalClass="switch_back_arrow_button">
                    <Icon iconName="info" />
                  </Button>
                  {modalHolidayIsOpen && (
                    <ModalHoliday holidaysData={holidaysData} onRequestClose={closeHolidayModal} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">{renderLayout(layout)}</div>
      </div>
      {modalNewEventIsOpen && (
        <ModalNewEvent
          isOpen={modalNewEventIsOpen}
          calendarClikedDate={calendarClikedDate}
          getPollsFilter={getPollsFilter}
          polls={pollList}
          onCloseRequest={closeNewEventModal}
          eventError={eventError.eventError}
          isLoading={loading}
          offices={officesFilter.offices}
          users={usersFilter.users}
          createNewEvent={createNewEvent}
          getEventType={getEventType}
          eventType={eventType}
          eventTypesData={ableToManageEventTypes}
          allEventTypes={eventTypesFilter.eventTypes}
          assessmentsData={assessmentList}
          resetErrors={resetErrors}
          getAssessmentsFilter={getAssessmentsFilter}
          resetAssessmentsFilter={resetAssessmentsFilter}
          assessmentsLoading={assessmentsLoading}
          authUser={userInfo}
          userPolicies={userPolicies}
        />
      )}
      {modalEditEventIsOpen && (
        <ModalEditEvent
          isOpen={modalEditEventIsOpen}
          onCloseRequest={closeEditEventModal}
          eventError={eventError.eventError}
          isLoading={loading}
          offices={officesFilter.offices}
          users={usersFilter.users}
          getEventType={getEventType}
          eventTypesData={ableToManageEventTypes}
          allEventTypes={eventTypesFilter.eventTypes}
          eventData={eventData}
          eventType={eventType}
          isOpenUpdateNextModal={updateNextModal}
          polls={pollList}
          assessmentsLoading={assessmentsLoading}
          authUser={userInfo}
          userPolicies={userPolicies}
          assessmentsData={assessmentList}
          getPollsFilter={getPollsFilter}
          onEditRequest={(data: any, updateNext?: boolean) => {
            editEvent({
              ...data,
              isGetEventsList: layout === ScheduleLayouts.EVENTS || updateNext,
            });
          }}
          openUpdateNextModal={openUpdateNextModal}
          closeUpdateNextModal={closeUpdateNextModal}
          resetErrors={resetErrors}
          getAssessmentsFilter={getAssessmentsFilter}
        />
      )}
      {modalDeleteEventIsOpen && (
        <ModalDeleteEvent
          isOpen={modalDeleteEventIsOpen}
          authUserId={userInfo.id}
          onCloseRequest={closeDeleteEventModal}
          onDeleteRequest={(data: any, updateNext?: boolean) => {
            deleteEvent({
              ...data,
              isGetEventsList: layout === ScheduleLayouts.EVENTS || updateNext,
            });
          }}
          eventData={eventData}
          eventError={eventError.eventError}
          isLoading={loading}
          resetErrors={resetErrors}
        />
      )}
      {modalEventIsOpen && (
        <EventPopUpModal
          popUpRef={popUpRef}
          popupDropdownRef={popupDropdownRef}
          isOpen={modalEventIsOpen}
          eventPopUpModalClassName={eventPopUpModalClassName}
          event={eventData}
          isLoading={loading.getEvent}
          onRequestClose={closeEventModal}
          openEditEventModal={openEditEventModal}
          openDeleteEventModal={openDeleteEventModal}
          renderDropdownBtn={renderDropdownBtn}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ schedule, libraries, users, auth, filters }: RootState) => ({
  eventTypesFilter: filters.eventTypesFilter,
  eventData: schedule.eventData,
  eventTableData: schedule.eventTableData || [],
  eventType: schedule.eventType,
  holidaysData: schedule.holidaysData || [],
  officesFilter: filters.officesFilter,
  usersFilter: filters?.usersFilter,
  eventsParams: schedule.params,
  eventError: schedule.errors,
  loading: schedule.loading,
  userInfo: auth.currentUserInfo,
  departmentsFilter: filters.departmentsFilter,
  assessmentList: filters.assessmentsFilter.assessments,
  assessmentsLoading: filters.assessmentsFilter.loading,
  userAbsencePeriods: users.current.absencePeriods,
  userHrInfo: users.current.professional.hrInfo,
  absencePeriodsLoading: users.loading.absencePeriods,
  usersLoading: users.loading,
  daysOffInfo: schedule.daysOffInfo,
  officesWorkDays: libraries.officesWorkDays || [],
  pollList: filters.pollsFilter.polls,
  scheduleLayout: schedule.layout,
  scheduleCalendarPeriod: schedule.calendarPeriod,
  savedFiltersData: filters.savedFilters,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getOfficesFilter: () => dispatch(filtersActions.getOfficesFilter()),
  getEvent: (id: any, cb?: () => void) => dispatch(scheduleActions.getEvent(id, cb)),
  resetEvent: () => dispatch(scheduleActions.resetEvent()),
  getDepartmentsFilter: () => dispatch(filtersActions.getDepartmentsFilter()),
  getEventTypesFilter: () => dispatch(filtersActions.getEventTypesFilter()),
  getEventList: (data: { params: Partial<EventsParams>; cb?: () => void }) =>
    dispatch(scheduleActions.getEventsList(data)),
  getEventType: (id: string) => dispatch(scheduleActions.getEventType(id)),
  resetEventType: () => dispatch(scheduleActions.resetEventType()),
  getUsersFilter: () => dispatch(filtersActions.getUsersFilter()),
  resetEventErrors: () => dispatch(scheduleActions.resetEventErrors()),
  setEventParams: (data: Partial<EventsParams>, isGetEventsList?: boolean) =>
    dispatch(scheduleActions.setEventParams(data, isGetEventsList)),
  createNewEvent: (data: any) => dispatch(scheduleActions.createNewEvent(data)),
  deleteEvent: (data: any) => dispatch(scheduleActions.deleteEvent(data)),
  editEvent: (data: any) => dispatch(scheduleActions.editEventType(data)),
  getAssessmentsFilter: (data: Partial<AssessmentsParams>) => dispatch(filtersActions.getAssessmentsFilter(data)),
  resetEvents: () => dispatch(scheduleActions.resetEvents()),
  resetAssessmentsFilter: () => dispatch(filtersActions.resetAssessmentsFilter()),
  resetErrors: () => dispatch(scheduleActions.resetErrors()),
  resetState: () => dispatch(scheduleActions.resetState()),
  getProfessionalInfo: (uuid: string) => dispatch(usersActions.getUserProfessionalInfo(uuid)),
  getUserAbsencePeriods: (data: any) => dispatch(usersActions.getUserAbsencePeriods(data)),
  getHolidays: (data?: Partial<EventsParams>) => dispatch(scheduleActions.getHolidays(data)),
  getOffDaysInfo: () => dispatch(scheduleActions.getOffDaysInfo()),
  getOfficesWorkDays: (data?: any) => dispatch(librariesActions.getOfficesWorkDays(data)),
  getPollsFilter: (data: Partial<PollParams>) => dispatch(filtersActions.getPollsFilter(data)),
  setScheduleLayout: (data: ScheduleLayouts) => dispatch(scheduleActions.setScheduleLayout(data)),
  setScheduleCalendarPeriod: (data: InlineDatePickerPeriods) => dispatch(scheduleActions.setCalendarPeriod(data)),
  setSavedFiltersParams: (data: Partial<SavedFilterParams>) => dispatch(filtersActions.setSavedFiltersParams(data)),
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) =>
    dispatch(filtersActions.createNewSavedFilter(data)),
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) =>
    dispatch(filtersActions.editSavedFilter(data)),
  deleteSavedFilter: (data: { id: string; callback: () => void }) => dispatch(filtersActions.deleteSavedFilter(data)),
  resetSavedFilterErrors: () => dispatch(filtersActions.resetSavedFilterErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Schedule);
