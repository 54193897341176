import React, { memo, useCallback, useMemo, useState } from 'react';
import Filter from '../../Filter';
import { useIntl } from 'react-intl';
import messages from '../messages';
import { OptionTypeBase } from 'react-select';
import { FilterParamsName, FilterTypes } from '../../../constants/filters.constants';
import { OfficeInfo } from '../../../enums/libraries.enum';
import { UserInfo } from '../../../enums/users.enum';
import { useFiltersListValue } from '../../../utils/hooks.utils';
import { PollParams } from '../../../enums/params/questionnaires.params';
import { allOfficesItem, allRespondersItem } from '../utils';
import { PollTemplateCategoryInfoType } from '../../../types/questionnaires';
import FilterClearButton from '../../FilterClearButton';
import FiltersControl from '../../FiltersControl';
import { SavedFilter, SavedFiltersDataType } from '../../../enums/filters.enum';
import SortSelect from '../../SortSelect';
import { pollsUnsavedParams } from '../../../constants/questionnaires.constants';
import { SortParams } from '../../../enums/params.enum';
import RefreshButton from '../../RefreshButton';

type FiltersType = {
  offices: OfficeInfo[];
  users: UserInfo[];
  categories: PollTemplateCategoryInfoType[];
};

type PollsFiltersProps = {
  handleMultiParamsChange: (name: string) => (data: OptionTypeBase) => void;
  handleUsersParamsChange: (name: string) => (data: OptionTypeBase) => void;
  listStatus: Array<any>;
  filters: FiltersType;
  values: PollParams;
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) => void;
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) => void;
  setPollParams: (data: Partial<PollParams>) => void;
  deleteSavedFilter: (data: { id: string; callback: () => void }) => void;
  savedFiltersData: SavedFiltersDataType;
  authUserId: string;
  handleFiltersControlChange: (value: SavedFilter) => void;
  handleClear: () => void;
  resetSavedFilterErrors: () => void;
  handleSort: (sortBy: string, direction: string) => void;
  showClearButton: boolean;
};

const PollsFilters = ({
  handleMultiParamsChange,
  handleUsersParamsChange,
  filters,
  listStatus,
  values,
  createNewSavedFilter,
  setPollParams,
  editSavedFilter,
  deleteSavedFilter,
  savedFiltersData,
  authUserId,
  handleFiltersControlChange,
  handleClear,
  resetSavedFilterErrors,
  handleSort,
  showClearButton,
}: PollsFiltersProps) => {
  const intl = useIntl();

  const [resetCurrentFilter, setResetCurrentFilter] = useState(false);

  const { filterCategoriesLabel, filterLocationsLabel, respondersSelect, statusLabel, organizersSelect } = messages;

  const officesOptions = useMemo(
    () =>
      filters.offices?.map(({ id, name }: OfficeInfo) => ({
        value: id,
        label: name,
      })),
    [filters.offices],
  );

  const categoriesOptions = useMemo(
    () =>
      filters.categories?.map((item: PollTemplateCategoryInfoType) => ({
        value: item.id,
        label: item.name,
      })),
    [filters.categories],
  );

  const usersOptions = useMemo(
    () =>
      filters.users?.map((item: UserInfo) => ({
        ...item,
        value: item,
        label: item.fullName,
      })),
    [filters.users],
  );

  const locationsWithAll = useMemo(() => [allOfficesItem, ...officesOptions], [officesOptions]);

  const respondersWithAll = useMemo(() => [allRespondersItem, ...usersOptions], [usersOptions]);

  const officeValues = useFiltersListValue(locationsWithAll, values.officeIds);

  const categoriesValues = useFiltersListValue(categoriesOptions, values.templateCategoryIds);

  const organizersValues = useFiltersListValue(usersOptions, values.organizerIds);

  const respondersValues = useFiltersListValue(respondersWithAll, values.responderIds);

  const statusesValues = useFiltersListValue(listStatus, values.statuses);

  const onClear = useCallback(() => {
    setResetCurrentFilter(true);
    handleClear();
  }, [values]);

  const setResettFilterFlag = useCallback(() => {
    setResetCurrentFilter(false);
  }, []);

  return (
    <>
      <SortSelect
        sortOptions={[
          { label: intl.formatMessage(messages.nameColumn), value: 'name' },
          { label: intl.formatMessage(messages.relatedEventSelect), value: 'relatedEvent' },
          { label: intl.formatMessage(messages.deadlineLabel), value: 'deadline' },
          { label: intl.formatMessage(messages.statusLabel), value: 'status' },
        ]}
        params={new SortParams('', { sortBy: values.sortBy, direction: values.direction })}
        onSort={handleSort}
      />
      <Filter
        isMulti
        label={intl.formatMessage(filterCategoriesLabel)}
        options={categoriesOptions}
        value={categoriesValues}
        handleChange={e => handleMultiParamsChange('templateCategoryIds')(e)}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        label={intl.formatMessage(filterLocationsLabel)}
        options={locationsWithAll}
        handleChange={e => handleMultiParamsChange(FilterParamsName.OFFICE_IDS)(e)}
        externalClass="filters__select"
        value={officeValues}
      />
      <Filter
        isMulti
        isUsersFilter
        label={intl.formatMessage(respondersSelect)}
        options={respondersWithAll}
        value={respondersValues}
        handleChange={e => handleUsersParamsChange('responderIds')(e)}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        isUsersFilter
        label={intl.formatMessage(organizersSelect)}
        options={usersOptions}
        value={organizersValues}
        handleChange={e => handleUsersParamsChange('organizerIds')(e)}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        label={intl.formatMessage(statusLabel)}
        options={listStatus}
        value={statusesValues}
        handleChange={e => handleMultiParamsChange('statuses')(e)}
        externalClass="filters__select"
      />
      {showClearButton && <FilterClearButton onClear={onClear} />}
      <FiltersControl
        handleSaveFilter={createNewSavedFilter}
        handleUpdateFilter={editSavedFilter}
        handleDeleteFilter={deleteSavedFilter}
        savedFiltersData={savedFiltersData}
        authUserId={authUserId}
        filterType={FilterTypes.POLLS_FILTER}
        handleChange={handleFiltersControlChange}
        params={values}
        resetSavedFilterErrors={resetSavedFilterErrors}
        unsavedParams={pollsUnsavedParams}
        resetCurrentFilter={resetCurrentFilter}
        setResettFilterFlag={setResettFilterFlag}
      />
      <RefreshButton onRefresh={() => setPollParams(values)} />
    </>
  );
};

export default memo(PollsFilters);
