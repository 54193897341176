import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { formatValue } from 'react-currency-input-field';
import { FormattedMessage } from 'react-intl';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';

import Filter from '../../Filter';
import DashboardTopModal from '../Modal/DashboardTopModal/DashboardTopModal';
import Button from '../../Button';
import Icon from '../../Icon';
import { CurrencyType, DashboardTopBlock, DashboardTopData, EditDashboardTopSetting } from '../../../types/finance';
import messages from '../messages';
import moment from 'moment';
import { DATE_FORMAT } from '../../../constants/date.constants';
import CustomLoader from '../../Loader';
import { useDispatch } from 'react-redux';
import { setDashboardGroup } from '../../../actions/finance.actions';

export enum EDashboardTopSetting {
  INCOME = 'INCOME',
  EXPENSE = 'EXPENSE',
  NET_INCOME = 'NET_INCOME',
}

type DashboardProps = {
  blockType: EDashboardTopSetting;
  baseCurrency?: CurrencyType;
  months: string[];
  settingsData: any;
  settingsLoading: any;
  settingsError: any;
  getExpenseList: (data: { callback: (results: any) => void }) => void;
  getIncomeList: (data: { callback: (results: any) => void }) => void;
  editDashboardTopSetting: (data: { params: EditDashboardTopSetting[]; callback: () => void }) => void;
  onCloseSettings: () => void;
};

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const DashboardHeadBlock = ({
  blockType,
  baseCurrency,
  months,
  settingsData,
  settingsLoading,
  settingsError,
  getExpenseList,
  getIncomeList,
  onCloseSettings,
  editDashboardTopSetting,
}: DashboardProps) => {
  const dispatch = useDispatch();

  const [openSettingsModal, setOpenSettingModal] = useState(false);

  const blockData = useMemo(() => {
    if (
      settingsData &&
      settingsData[blockType === EDashboardTopSetting.INCOME ? 'dashboardIncomeData' : 'dashboardExpenseData']
    ) {
      if (blockType !== EDashboardTopSetting.NET_INCOME)
        return settingsData[blockType === EDashboardTopSetting.INCOME ? 'dashboardIncomeData' : 'dashboardExpenseData'];
      else if (settingsData.dashboardIncomeData && settingsData.dashboardExpenseData) {
        const income = settingsData.dashboardIncomeData,
          expense = settingsData.dashboardExpenseData;

        return months
          .map((month: string) => ({
            reportDate: month,
            amount:
              Number(
                income.find((data: DashboardTopData) => moment(data.reportDate).isSame(month, 'month'))?.amount || 0,
              ) -
              Number(
                expense.find((data: DashboardTopData) => moment(data.reportDate).isSame(month, 'month'))?.amount || 0,
              ),
          }))
          .filter(it => it.amount);
      }
    }

    return [];
  }, [settingsData.dashboardExpenseData, settingsData.dashboardIncomeData]);

  const dataSetting = useMemo(() => {
    return blockType !== EDashboardTopSetting.NET_INCOME
      ? settingsData[
          blockType === EDashboardTopSetting.INCOME ? 'dashboardIncomeTopSettingData' : 'dashboardExpenseTopSettingData'
        ]
      : { income: settingsData.dashboardIncomeTopSettingData, expense: settingsData.dashboardExpenseTopSettingData };
  }, [settingsData]);

  const options = useMemo(() => {
    if (dataSetting && dataSetting.length) {
      return dataSetting.map((set: DashboardTopBlock) => ({
        label: set.name,
        value: set.id || set.positionNumber,
        positionNumber: set.positionNumber,
      }));
    }

    return [];
  }, [dataSetting]);

  const textData = useMemo(() => {
    const sum = blockData.reduce((sum: number, current: DashboardTopData) => sum + Number(current.amount || 0), 0);

    return {
      total: Math.round(Number(sum)),
      midValue: Math.round(Number(sum / (blockData.length || 1))),
    };
  }, [blockData]);

  useEffect(() => {
    if (dataSetting && dataSetting.length === 1) {
      dispatch(
        setDashboardGroup({
          key: blockType,
          value: 0,
        }),
      );
    }
  }, [dataSetting]);

  const changeGroup = useCallback(
    data => {
      dispatch(
        setDashboardGroup({
          key: blockType,
          value: data.positionNumber,
        }),
      );
    },
    [settingsData.dashboardExpenseData, settingsData.dashboardIncomeData],
  );

  const chosenGroup = useMemo(() => {
    if (options.length)
      return options[
        settingsData[blockType === EDashboardTopSetting.INCOME ? 'incomePositionNumber' : 'expensePositionNumber']
      ];
    else return null;
  }, [settingsData.incomePositionNumber, settingsData.expensePositionNumber, options]);

  const chartData = useMemo(() => {
    const total =
      blockType === EDashboardTopSetting.NET_INCOME
        ? blockData.reduce((sum: number, current: DashboardTopData) => sum + Number(current.amount || 0), 0)
        : null;

    return {
      labels: months.map(month => moment(month).format(DATE_FORMAT.MMM_YYYY)),
      datasets: [
        {
          data: months.map((month: string, index: number) => {
            const value = blockData.find((data: DashboardTopData) => moment(data.reportDate).isSame(month, 'month'));

            return {
              index,
              percent: value ? value.percentage : 0,
              value: value ? Math.round(Number(value.amount || 0)) : 0,
            };
          }),
          fill: true,
          borderColor:
            (blockType === EDashboardTopSetting.NET_INCOME && total > 0) || blockType === EDashboardTopSetting.INCOME
              ? '#5aab18'
              : '#f0504b',
          backgroundColor: (ctx: any) => {
            const canvas = ctx.chart.ctx;
            const gradient = canvas.createLinearGradient(0, 0, 0, ctx.chart.height * 0.95);

            gradient.addColorStop(
              0,
              (blockType === EDashboardTopSetting.NET_INCOME && total > 0) || blockType === EDashboardTopSetting.INCOME
                ? '#5aab18'
                : '#f0504b',
            );
            gradient.addColorStop(1, '#fff');

            return gradient;
          },
        },
      ],
    };
  }, [blockData, blockType]);

  const openSetting = () => {
    setOpenSettingModal(true);
  };

  const closeSetting = () => {
    onCloseSettings();
    setOpenSettingModal(false);
  };

  return (
    <div className="dashboard-page-flex--container">
      {settingsLoading[blockType === EDashboardTopSetting.INCOME ? 'getDashboardIncome' : 'getDashboardExpense'] ? (
        <CustomLoader />
      ) : (
        <>
          <div className="dashboard-flex">
            <div className="filters__dashboard">
              {blockType === EDashboardTopSetting.NET_INCOME ? (
                <span className="filters__dashboard-text">
                  <FormattedMessage {...messages[EDashboardTopSetting.NET_INCOME]} />
                </span>
              ) : (
                <Filter
                  label=""
                  options={options}
                  value={chosenGroup}
                  handleChange={changeGroup}
                  externalClass="filters__dashboard-top"
                />
              )}
              {blockType !== EDashboardTopSetting.NET_INCOME ? (
                <Button externalClass="filters__dashboard-button" onClick={openSetting}>
                  <Icon iconName="pencil" externalClass="button__icon" />
                </Button>
              ) : null}
            </div>
            <div className="dashboard-head">
              <div className="dashboard-head__amount">
                {formatValue({
                  value: Number(textData.total).toFixed(2).toString(),
                })}
                &nbsp;
                {baseCurrency?.name}
              </div>
              <div className="dashboard-head__amount dashboard-head__amount-mid">
                (Avg.&nbsp;
                {formatValue({
                  value: Number(textData.midValue).toFixed(2).toString(),
                })}
                &nbsp;
                {baseCurrency?.name})
              </div>
            </div>
          </div>
          <div className="dashboard-flex-small">
            {chartData ? (
              <Line
                height="100%"
                options={{
                  maintainAspectRatio: false,
                  layout: {
                    autoPadding: false,
                    padding: {
                      left: 5,
                      right: 5,
                      top: 5,
                      bottom: 5,
                    },
                  },
                  parsing: {
                    xAxisKey: 'index',
                    yAxisKey: 'value',
                  },
                  plugins: {
                    legend: {
                      display: false,
                    },
                    tooltip: {
                      callbacks: {
                        label: function (context) {
                          let label = context.dataset.label || '';

                          if (label) {
                            label += ': ';
                          }

                          if (context.parsed.y !== null) {
                            //@ts-ignore
                            label += context.parsed.y
                              ? formatValue({ value: Number(context.parsed.y).toString() }) +
                                ` ${baseCurrency?.name}` +
                                (blockType !== EDashboardTopSetting.NET_INCOME
                                  ? //@ts-ignore
                                    ` (${context.raw.percent > 0 ? '+' : ''}${context.raw.percent} %)`
                                  : '')
                              : 'No data';
                          }

                          return label;
                        },
                      },
                    },
                  },
                  elements: {
                    point: {
                      radius: 1.5,
                    },
                  },
                  scales: {
                    y: {
                      display: false,
                      grid: {
                        drawBorder: false,
                        drawTicks: false,
                      },
                    },
                    x: {
                      display: false,
                      grid: {
                        drawBorder: false,
                        drawTicks: false,
                      },
                    },
                  },
                }}
                data={chartData}
              />
            ) : null}
          </div>
        </>
      )}
      {openSettingsModal && (
        <DashboardTopModal
          data={
            settingsData[
              blockType === EDashboardTopSetting.INCOME
                ? 'dashboardIncomeTopSettingData'
                : 'dashboardExpenseTopSettingData'
            ]
          }
          loading={
            settingsLoading[
              blockType === EDashboardTopSetting.INCOME
                ? 'getDashboardIncomeTopSetting'
                : 'getDashboardExpenseTopSetting'
            ]
          }
          editDashboardTopSetting={editDashboardTopSetting}
          isOpen={openSettingsModal}
          blockType={blockType}
          getExpenseList={getExpenseList}
          getIncomeList={getIncomeList}
          onCloseRequest={closeSetting}
          resetSettings={onCloseSettings}
          error={
            settingsError[
              blockType === EDashboardTopSetting.INCOME
                ? 'dashboardIncomeTopSettingError'
                : 'dashboardExpenseTopSettingError'
            ]
          }
        />
      )}
    </div>
  );
};

export default DashboardHeadBlock;
