import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import * as recognitionActions from '../../actions/recognitions.actions';
import * as filtersActions from '../../actions/filters.actions';
import { Recognition } from '../../enums/recognitions.enum';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import ModalNewRecognition from '../../components/Recognitions/Modals/ModalNewRecognition';
import ModalEditRecognition from '../../components/Recognitions/Modals/ModalEditRecognition';
import ModalDeleteRecognition from '../../components/Recognitions/Modals/ModalDeleteRecognition';
import RecognitionIcon from '../../components/RecognitionIcon';
import InlineDatePicker from '../../components/InlineDatePicker';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { UserInfo } from '../../enums/users.enum';
import TableUserAvatar from '../../components/TableUserAvatar';
import { RecognitionsParams } from '../../enums/params/recognitions.params';
import AccessChecker from '../../components/AccessChecker';
import { DELETE_RECOGNITION_EXTENDED, UPDATE_RECOGNITION_EXTENDED } from '../../constants/policies.constants';
import { resetParamsChange, useParamsChange, useUsersParamsChange } from '../../utils/hooks.utils';
import { FilterParamsName, FilterTypes } from '../../constants/filters.constants';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import RecognitionsFilter from '../../components/Recognitions/Filter/RecognitionsFilter';
import { SavedFilterParams } from '../../enums/params/filters.params';
import { SavedFilter } from '../../enums/filters.enum';
import {
  checkParamsMatch,
  convertSavedFieldsToParams,
  getSavedFilterParams,
  getSavedFilters,
} from '../../utils/filters.utils';
import { recognitionsUnsavedParams } from '../../constants/recognitions.constants';

function Recognitions({
  createUserRecognition,
  editRecognition,
  deleteRecognition,
  recognitionError,
  getRecognitionTypesFilter,
  getUsersFilter,
  usersFilter,
  recognitionTypeList,
  setRecognitionParams,
  isLoading,
  tableData,
  params,
  resetErrors,
  resetState,
  setSavedFiltersParams,
  createNewSavedFilter,
  savedFiltersData,
  editSavedFilter,
  deleteSavedFilter,
  resetSavedFilterErrors,
  authUserId,
}: ConnectedProps<typeof connector>) {
  const dispatch = useDispatch();
  const [modalNewRecognitionIsOpen, setModalNewRecognitionIsOpen] = useState(false);
  const [modalEditRecognitionIsOpen, setModalEditRecognitionIsOpen] = useState(false);
  const [modalDeleteRecognitionIsOpen, setModalDeleteRecognitionIsOpen] = useState(false);
  const [recognitionClicked, setRecognitionClicked] = useState<Recognition>(new Recognition());

  const intl = useIntl();

  useEffect(() => {
    const currentSavedFilter = getSavedFilterParams(getSavedFilters(), FilterTypes.RECOGNITIONS_FILTER);

    setRecognitionParams(
      currentSavedFilter ? new RecognitionsParams(currentSavedFilter) : { userIds: usersFilter.value },
    );
    getUsersFilter();
    getRecognitionTypesFilter();
    setSavedFiltersParams({ filterType: FilterTypes.RECOGNITIONS_FILTER });

    return () => {
      resetState();
    };
  }, []);

  const handleMultiParamsChange = useParamsChange(setRecognitionParams, dispatch);

  const handleUsersParamsChange = useUsersParamsChange(setRecognitionParams, dispatch);

  const openNewRecognitionModal = useCallback(() => {
    setModalNewRecognitionIsOpen(true);
  }, []);

  const closeNewRecognitionModal = useCallback(() => {
    setModalNewRecognitionIsOpen(false);
  }, []);

  const openEditRecognitionModal = useCallback(() => {
    setModalEditRecognitionIsOpen(true);
  }, []);

  const closeEditRecognitionModal = useCallback(() => {
    setModalEditRecognitionIsOpen(false);
  }, []);

  const openDeleteRecognitionModal = useCallback(() => {
    setModalDeleteRecognitionIsOpen(true);
  }, []);

  const closeDeleteRecognitionModal = useCallback(() => {
    setModalDeleteRecognitionIsOpen(false);
  }, []);

  const setRecognitionCallback = useCallback(() => {
    setRecognitionClicked(new Recognition());
  }, []);

  const filters = useMemo(
    () => ({
      users: usersFilter.users,
      recognitionTypeList,
    }),
    [usersFilter.users, recognitionTypeList],
  );

  const tableActions: any = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: Recognition) => {
          setRecognitionClicked(row);
          openEditRecognitionModal();
        },
        verifiablePolicies: [UPDATE_RECOGNITION_EXTENDED],
      },
      ,
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: Recognition) => {
          setRecognitionClicked(row);
          openDeleteRecognitionModal();
        },
        verifiablePolicies: [DELETE_RECOGNITION_EXTENDED],
      },
      ,
    ],
    [],
  );

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.memberLabel),
        modifier: (row: Recognition) => (
          <TableUserAvatar users={[row.targetUser]} fileSize={48} externalClassName="bold" />
        ),
      },
      {
        name: intl.formatMessage(messages.recognitionTypeColumn),
        modifier: (row: Recognition) => {
          const color = `#${row.recognitionType.backgroundColor}`;
          return (
            <div className={'table__data-wrapper'}>
              <RecognitionIcon icon={row.recognitionType.icon} color={color} />
              <span style={{ color }}>{row.recognitionType.name}</span>
            </div>
          );
        },
      },
      {
        name: intl.formatMessage(messages.messageTitle),
        modifier: (row: Recognition) => row.text,
      },
      {
        name: intl.formatMessage(messages.authorColumn),
        modifier: (row: Recognition) => <TableUserAvatar users={[new UserInfo(row.author)]} fileSize={48} />,
      },
      {
        name: intl.formatMessage(messages.createdColumn),
        modifier: (row: Recognition) => <span>{Recognition.getRecognitionDate(row.createdDate)}</span>,
      },
      {
        name: intl.formatMessage(messages.editorColumn),
        modifier: (row: Recognition) => <TableUserAvatar users={[new UserInfo(row.lastEditor)]} fileSize={48} />,
      },
      {
        name: intl.formatMessage(messages.updatedColumn),
        modifier: (row: Recognition) => <span>{Recognition.getRecognitionDate(row.lastModifiedDate)}</span>,
      },
    ],
    [],
  );

  const onDateChange = (start: string, end: string) => {
    setRecognitionParams({ dateFrom: start, dateTo: end, page: 0 });
  };

  const handleSort = useCallback((sortBy, direction) => setRecognitionParams({ sortBy, direction }), []);

  const handlePageChange = useCallback(({ selected }) => {
    setRecognitionParams({ page: selected });
  }, []);

  const handleSizeChange = useCallback(data => {
    setRecognitionParams({ size: data, page: 0 });
  }, []);

  const handleFiltersControlChange = useCallback(
    value => {
      setRecognitionParams(
        new RecognitionsParams({
          ...convertSavedFieldsToParams(value.fields),
          dateFrom: params.dateFrom,
          dateTo: params.dateTo,
        }),
      );
    },
    [params],
  );

  const handleClear = useCallback(() => {
    setRecognitionParams(new RecognitionsParams({ dateFrom: params.dateFrom, dateTo: params.dateTo }));
    resetParamsChange([FilterParamsName.USER_IDS], dispatch);
  }, [params]);

  const showClearButton = useMemo(
    () => !checkParamsMatch(params, new RecognitionsParams(), recognitionsUnsavedParams),
    [params],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
            <InlineDatePicker
              onDateChange={onDateChange}
              defaultPeriodStart={params.dateFrom}
              defaultPeriodEnd={params.dateTo}
            />
          </div>
          <div className="page__panel-bottom left">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[UPDATE_RECOGNITION_EXTENDED]}>
                  <Button externalClass={'button--with-icon'} onClick={openNewRecognitionModal}>
                    <Icon iconName={'plus'} externalClass={'button__icon'} />
                    <span className="button__text">
                      <FormattedMessage {...messages.newButton} />
                    </span>
                  </Button>
                </AccessChecker>
                <RecognitionsFilter
                  filters={filters}
                  values={params}
                  handleUsersParamsChange={handleUsersParamsChange}
                  createNewSavedFilter={createNewSavedFilter}
                  savedFiltersData={savedFiltersData}
                  authUserId={authUserId}
                  deleteSavedFilter={deleteSavedFilter}
                  editSavedFilter={editSavedFilter}
                  handleFiltersControlChange={handleFiltersControlChange}
                  handleClear={handleClear}
                  resetSavedFilterErrors={resetSavedFilterErrors}
                  setRecognitionParams={setRecognitionParams}
                  handleSort={handleSort}
                  handleMultiParamsChange={handleMultiParamsChange}
                  showClearButton={showClearButton}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div className="page__wrapper">
          <Table
            externalClass={'table--recognitions'}
            tableColumns={tableColumns}
            tableData={tableData?.content || []}
            tableActions={tableActions}
            error={recognitionError}
            loading={isLoading.getRecognition}
          />
          <Pagination
            pageable={{
              ...tableData?.pageable,
              ...tableData?.sort,
              totalElements: tableData?.totalElements,
              numberOfElements: tableData?.numberOfElements,
              totalPages: tableData?.totalPages,
            }}
            onPageChange={(data: any) => handlePageChange(data)}
            onPageSizeChange={(data: any) => handleSizeChange(data)}
          />
        </div>
      </div>
      {modalNewRecognitionIsOpen && (
        <ModalNewRecognition
          isOpen
          onCloseRequest={closeNewRecognitionModal}
          createNewRecognition={createUserRecognition}
          recognitionError={recognitionError}
          recognitionTypes={recognitionTypeList}
          users={usersFilter.users}
          isLoading={isLoading.createRecognition}
          resetErrors={resetErrors}
        />
      )}
      {modalEditRecognitionIsOpen && (
        <ModalEditRecognition
          isOpen
          onCloseRequest={closeEditRecognitionModal}
          editRecognition={editRecognition}
          recognitionData={recognitionClicked}
          recognitionError={recognitionError}
          isLoading={isLoading.editRecognition}
          recognitionTypes={recognitionTypeList}
          users={usersFilter.users}
          resetErrors={resetErrors}
        />
      )}
      {modalDeleteRecognitionIsOpen && (
        <ModalDeleteRecognition
          isOpen
          onCloseRequest={closeDeleteRecognitionModal}
          onDeleteRequest={(data: Record<string, unknown>) => {
            deleteRecognition({
              ...data,
              setRecognitionCallback,
            });
          }}
          isLoading={isLoading.deleteRecognition}
          recognitionError={recognitionError}
          recognitionData={recognitionClicked}
          resetErrors={resetErrors}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ recognitions, filters, auth }: RootState) => ({
  recognitionTypeList: filters.recognitionTypesFilter.recognitionTypes,
  tableData: recognitions.recognitionTableData,
  recognitionError: recognitions.errors.recognitionError,
  isLoading: recognitions.loading,
  usersFilter: filters.usersFilter,
  params: recognitions.recognitionsParams,
  savedFiltersData: filters.savedFilters,
  authUserId: auth.currentUserInfo.id,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getRecognitionTypesFilter: () => dispatch(filtersActions.getRecognitionTypesFilter()),
  getUsersFilter: () => dispatch(filtersActions.getUsersFilter()),
  deleteRecognition: (data: Record<string, unknown>) => dispatch(recognitionActions.deleteRecognition(data)),
  createUserRecognition: (data: Record<string, unknown>) => dispatch(recognitionActions.createUserRecognition(data)),
  editRecognition: (id: string, data: Record<string, unknown>) =>
    dispatch(recognitionActions.editRecognition({ ...data, id })),
  setRecognitionParams: (data: Partial<RecognitionsParams>) => dispatch(recognitionActions.setRecognitionParams(data)),
  resetErrors: () => dispatch(recognitionActions.resetErrors()),
  resetState: () => dispatch(recognitionActions.resetState()),
  setSavedFiltersParams: (data: Partial<SavedFilterParams>) => dispatch(filtersActions.setSavedFiltersParams(data)),
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) =>
    dispatch(filtersActions.createNewSavedFilter(data)),
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) =>
    dispatch(filtersActions.editSavedFilter(data)),
  deleteSavedFilter: (data: { id: string; callback: () => void }) => dispatch(filtersActions.deleteSavedFilter(data)),
  resetSavedFilterErrors: () => dispatch(filtersActions.resetSavedFilterErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Recognitions);
