import React, { useCallback, useMemo, useState } from 'react';
import { OptionTypeBase } from 'react-select';
import { useIntl } from 'react-intl';
import messages from '../messages';
import Filter from '../../Filter';
import { FilterParamsName, FilterTypes } from '../../../constants/filters.constants';
import { DaysUsedLimitParams } from '../../../enums/params/schedule.params';
import { UserInfo } from '../../../enums/users.enum';
import { OfficeInfo } from '../../../enums/libraries.enum';
import { EventTypeInfo } from '../../../enums/calendar.enum';
import { DepartmentsInfoType } from '../../../types/libraries';
import { useFiltersListValue } from '../../../utils/hooks.utils';
import FilterClearButton from '../../FilterClearButton';
import FiltersControl from '../../FiltersControl';
import { SavedFilter, SavedFiltersDataType } from '../../../enums/filters.enum';
import { daysLimitUnsavedParams } from '../../../constants/schedule.constants';
import RefreshButton from '../../RefreshButton';

type FiltersType = {
  users: UserInfo[];
  departments: DepartmentsInfoType[];
  offices: OfficeInfo[];
  eventTypes: EventTypeInfo[];
};

type DaysUsedLimitFilterProps = {
  filters: FiltersType;
  values: DaysUsedLimitParams;
  handleMultiParamsChange: (name: string) => (data: OptionTypeBase) => void;
  createNewSavedFilter: (data: { data: SavedFilter; callback: () => void }) => void;
  editSavedFilter: (data: { data: SavedFilter; callback?: () => void }) => void;
  deleteSavedFilter: (data: { id: string; callback: () => void }) => void;
  setParams: (data: Partial<DaysUsedLimitParams>) => void;
  savedFiltersData: SavedFiltersDataType;
  authUserId: string;
  handleFiltersControlChange: (value: SavedFilter) => void;
  handleClear: () => void;
  resetSavedFilterErrors: () => void;
  showClearButton: boolean;
};

const DaysUsedLimitFilter = ({
  filters,
  values,
  setParams,
  handleMultiParamsChange,
  createNewSavedFilter,
  editSavedFilter,
  deleteSavedFilter,
  savedFiltersData,
  authUserId,
  handleFiltersControlChange,
  handleClear,
  resetSavedFilterErrors,
  showClearButton,
}: DaysUsedLimitFilterProps) => {
  const intl = useIntl();

  const [resetCurrentFilter, setResetCurrentFilter] = useState(false);

  const officesOptions = useMemo(
    () =>
      filters.offices?.map(office => ({
        label: office.name,
        value: office.id,
      })),
    [filters.offices],
  );

  const departmentsOptions = useMemo(
    () =>
      filters.departments?.map(department => ({
        label: department.displayName,
        value: department.id,
      })),
    [filters.departments],
  );

  const usersOptions = useMemo(
    () =>
      filters.users?.map(user => ({
        label: user.fullName,
        value: user.id,
      })),
    [filters.users],
  );

  const eventTypesOptions = useMemo(
    () =>
      filters.eventTypes?.map(office => ({
        label: office.name,
        value: office.id,
      })),
    [filters.eventTypes],
  );

  const employeesValues = useFiltersListValue(usersOptions, values.users);

  const officeValues = useFiltersListValue(officesOptions, values.offices);

  const departmentValues = useFiltersListValue(departmentsOptions, values.departments);

  const eventTypeValues = useFiltersListValue(eventTypesOptions, values.eventTypes);

  const onClear = useCallback(() => {
    setResetCurrentFilter(true);
    handleClear();
  }, [values]);

  const setResettFilterFlag = useCallback(() => {
    setResetCurrentFilter(false);
  }, []);

  return (
    <>
      <Filter
        isMulti
        label={intl.formatMessage(messages.officesLabel)}
        options={officesOptions}
        value={officeValues}
        handleChange={handleMultiParamsChange(FilterParamsName.OFFICES)}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        label={intl.formatMessage(messages.departmentsLabel)}
        options={departmentsOptions}
        value={departmentValues}
        handleChange={handleMultiParamsChange(FilterParamsName.DEPARTMENTS)}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        label={intl.formatMessage(messages.employeesLabel)}
        options={usersOptions}
        value={employeesValues}
        handleChange={handleMultiParamsChange(FilterParamsName.USERS)}
        externalClass="filters__select"
      />
      <Filter
        isMulti
        label={intl.formatMessage(messages.eventTypesLabel)}
        options={eventTypesOptions}
        value={eventTypeValues}
        handleChange={handleMultiParamsChange(FilterParamsName.EVENT_TYPES)}
        externalClass="filters__select"
      />
      {showClearButton && <FilterClearButton onClear={onClear} />}
      <FiltersControl
        handleSaveFilter={createNewSavedFilter}
        handleUpdateFilter={editSavedFilter}
        handleDeleteFilter={deleteSavedFilter}
        savedFiltersData={savedFiltersData}
        authUserId={authUserId}
        filterType={FilterTypes.DAYS_USED_LIMIT_FILTER}
        handleChange={handleFiltersControlChange}
        params={values}
        resetSavedFilterErrors={resetSavedFilterErrors}
        unsavedParams={daysLimitUnsavedParams}
        resetCurrentFilter={resetCurrentFilter}
        setResettFilterFlag={setResettFilterFlag}
      />
      <RefreshButton onRefresh={() => setParams(values)} />
    </>
  );
};

export default React.memo(DaysUsedLimitFilter);
