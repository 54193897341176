import { defineMessages } from 'react-intl';
import { applyButton, allLabel } from '../../i18n/defaultMessage';

export default defineMessages({
  applyButton,
  allLabel,
  todayBtn: {
    id: 'datepicker.today.btn.label',
    defaultMessage: 'Today',
  },
  thisWeekBtn: {
    id: 'datepicker.this.week.btn.label',
    defaultMessage: 'This week',
  },
  previousMonthBtn: {
    id: 'datepicker.previous.month.btn.label',
    defaultMessage: 'Previous month',
  },
  thisYearBtn: {
    id: 'datepicker.this.year.btn.label',
    defaultMessage: 'This year',
  },
  thisMonthBtn: {
    id: 'datepicker.this.month.btn.label',
    defaultMessage: 'This month',
  },
});
