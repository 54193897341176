import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as candidatesActions from '../../actions/candidates.actions';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Table from '../../components/Table';
import { UPDATE_CANDIDATE_TECHNOLOGY, DELETE_CANDIDATE_TECHNOLOGY } from '../../constants/policies.constants';
import AccessChecker from '../../components/AccessChecker';
import { SortParams } from '../../enums/params.enum';
import { CandidateTechnologyParams } from '../../enums/params/candidates.params';
import { CandidateTechnologyType } from '../../types/candidates';
import ModalNewCandidateTechnology from '../../components/CandidatesTechnologies/Modals/ModalNewCandidateTechnology';
import ModalEditCandidateTechnology from '../../components/CandidatesTechnologies/Modals/ModalEditCandidateTechnology';
import ModalDeleteCandidateTechnology from '../../components/CandidatesTechnologies/Modals/ModalDeleteCandidateTechnology';
import Pagination from '../../components/Pagination';
import RefreshButton from '../../components/RefreshButton';

function CandidateTechnologys({
  tableData,
  isLoading,
  candidateTechnologiesListError,
  candidateTechnologyError,
  params,
  getCandidateTechnologiesList,
  setCandidateTechnologiesParams,
  createCandidateTechnology,
  editCandidateTechnology,
  deleteCandidateTechnology,
  resetState,
  resetErrors,
}: ConnectedProps<typeof connector>) {
  const intl = useIntl();

  const [modalNewCandidateTechnologyIsOpen, setModalNewCandidateTechnologyIsOpen] = useState(false);
  const [modalEditCandidateTechnologyIsOpen, setModalEditCandidateTechnologyIsOpen] = useState(false);
  const [modalDeleteCandidateTechnologyIsOpen, setModalDeleteCandidateTechnologyIsOpen] = useState(false);
  const [candidateTechnologyClicked, setCandidateTechnologyClicked] = useState<CandidateTechnologyType>({
    id: '',
    name: '',
  });

  useEffect(() => {
    getCandidateTechnologiesList();
    return () => {
      resetState();
    };
  }, []);

  const openNewCandidateTechnologyModal = useCallback(() => {
    setModalNewCandidateTechnologyIsOpen(true);
  }, []);

  const closeNewCandidateTechnologyModal = useCallback(() => {
    setModalNewCandidateTechnologyIsOpen(false);
    resetErrors();
  }, []);

  const openEditCandidateTechnologyModal = useCallback(() => {
    setModalEditCandidateTechnologyIsOpen(true);
  }, []);

  const closeEditCandidateTechnologyModal = useCallback(() => {
    setModalEditCandidateTechnologyIsOpen(false);
    resetErrors();
  }, []);

  const openDeleteCandidateTechnologyModal = useCallback(() => {
    setModalDeleteCandidateTechnologyIsOpen(true);
  }, []);

  const closeDeleteCandidateTechnologyModal = useCallback(() => {
    setModalDeleteCandidateTechnologyIsOpen(false);
    resetErrors();
  }, []);

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.nameColumn),
        modifier: (row: CandidateTechnologyType) => <span>{row.name}</span>,
        sortName: 'name',
      },
    ],
    [],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: CandidateTechnologyType) => {
          setCandidateTechnologyClicked(row);
          openEditCandidateTechnologyModal();
        },
        verifiablePolicies: [UPDATE_CANDIDATE_TECHNOLOGY],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: CandidateTechnologyType) => {
          setCandidateTechnologyClicked(row);
          openDeleteCandidateTechnologyModal();
        },
        verifiablePolicies: [DELETE_CANDIDATE_TECHNOLOGY],
      },
    ],
    [],
  );

  const sortParams = useMemo(() => new SortParams('name', params), [params]);

  const pageable = useMemo(
    () => ({
      ...tableData?.pageable,
      ...tableData?.sort,
      totalElements: tableData?.totalElements,
      numberOfElements: tableData?.numberOfElements,
      totalPages: tableData?.totalPages,
    }),
    [tableData],
  );

  const handlePageChange = useCallback(({ selected }) => {
    setCandidateTechnologiesParams({ page: selected });
  }, []);

  const handleSizeChange = useCallback(data => {
    setCandidateTechnologiesParams({ size: data, page: 0 });
  }, []);

  const handleSort = useCallback((sortBy, direction) => setCandidateTechnologiesParams({ sortBy, direction }), []);

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom no-border">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[UPDATE_CANDIDATE_TECHNOLOGY]}>
                  <Button externalClass={'button--with-icon'} onClick={openNewCandidateTechnologyModal}>
                    <Icon iconName={'plus'} externalClass={'button__icon'} />
                    <span className="button__text">
                      <FormattedMessage {...messages.newButton} />
                    </span>
                  </Button>
                </AccessChecker>
                <RefreshButton onRefresh={() => setCandidateTechnologiesParams(params)} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content half-content">
        <div className="page__wrapper">
          <Table
            tableColumns={tableColumns}
            tableData={tableData?.content || []}
            loading={isLoading.getCandidateTechnologiesList}
            error={candidateTechnologiesListError}
            tableActions={tableActions}
            params={sortParams}
            onSort={handleSort}
          />
          <Pagination pageable={pageable} onPageChange={handlePageChange} onPageSizeChange={handleSizeChange} />
        </div>
      </div>
      {modalNewCandidateTechnologyIsOpen && (
        <ModalNewCandidateTechnology
          isOpen
          error={candidateTechnologyError}
          isLoading={isLoading.createCandidateTechnology}
          onCloseRequest={closeNewCandidateTechnologyModal}
          createCandidateTechnology={createCandidateTechnology}
        />
      )}
      {modalEditCandidateTechnologyIsOpen && (
        <ModalEditCandidateTechnology
          candidateTechnology={candidateTechnologyClicked}
          isOpen
          error={candidateTechnologyError}
          isLoading={isLoading.editCandidateTechnology}
          onCloseRequest={closeEditCandidateTechnologyModal}
          editCandidateTechnology={editCandidateTechnology}
        />
      )}
      {modalDeleteCandidateTechnologyIsOpen && (
        <ModalDeleteCandidateTechnology
          candidateTechnology={candidateTechnologyClicked}
          isOpen
          isLoading={isLoading.deleteCandidateTechnology}
          error={candidateTechnologyError}
          onCloseRequest={closeDeleteCandidateTechnologyModal}
          onDeleteRequest={deleteCandidateTechnology}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ candidates }: RootState) => ({
  tableData: candidates.candidateTechnologiesList,
  isLoading: candidates.loading,
  candidateTechnologiesListError: candidates.errors.candidateTechnologiesListError,
  candidateTechnologyError: candidates.errors.candidateTechnologyError,
  params: candidates.candidateTechnologiesParams,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getCandidateTechnologiesList: () => dispatch(candidatesActions.getCandidateTechnologiesList()),
  setCandidateTechnologiesParams: (params: Partial<CandidateTechnologyParams>) =>
    dispatch(candidatesActions.setCandidateTechnologiesParams(params)),
  createCandidateTechnology: (data: { data: CandidateTechnologyType; callback: () => void }) =>
    dispatch(candidatesActions.createCandidateTechnology(data)),
  editCandidateTechnology: (data: { data: CandidateTechnologyType; callback: () => void }) =>
    dispatch(candidatesActions.editCandidateTechnology(data)),
  deleteCandidateTechnology: (data: { data: string; callback: () => void }) =>
    dispatch(candidatesActions.deleteCandidateTechnology(data)),
  resetState: () => dispatch(candidatesActions.resetState()),
  resetErrors: () => dispatch(candidatesActions.resetErrors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(CandidateTechnologys);
