import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { connect, ConnectedProps } from 'react-redux';
import * as bonusesActions from '../../actions/bonuses.actions';
import { OfficeInfo } from '../../enums/libraries.enum';
import Filter from '../../components/Filter';
import * as filtersActions from '../../actions/filters.actions';
import Icon from '../../components/Icon';
import { BonusCategoryInfo, BonusExperiencesInfo } from '../../enums/bonuses.enums';
import ModalEditBonusByGrades from '../../components/BonusesByGrades/Modals/EditBonusesByGrades';
import { BonusesParams } from '../../enums/params/bonuses.params';
import {
  DELETE_BONUS_BY_GRADES,
  UPDATE_BONUS_BY_GRADES,
  VIEW_BONUS_SETTINGS,
} from '../../constants/policies.constants';
import HierarchicalTable from '../../components/HierarchicalTable';
import { useTableData } from '../../utils/hooks.utils';
import Dropdown from '../../components/Dropdown';
import { checkPolicies } from '../../utils/policies.utils';
import PoliciesContext from '../../PoliciesContext';
import { isEmpty } from 'lodash-es';
import ModalNewBonus from '../../components/Bonuses/Modals/ModalNewBonus';
import ModalEditBonus from '../../components/Bonuses/Modals/ModalEditBonus';
import ModalDeleteBonus from '../../components/Bonuses/Modals/ModalDeleteBonus';
import Button from '../../components/Button';
import AccessChecker from '../../components/AccessChecker';
import ModalSettings, { BonusesSettingsType } from '../../components/Bonuses/Modals/ModaSettings';
import RefreshButton from '../../components/RefreshButton';

export type BonusExpType = {
  bonusExp: BonusExperiencesInfo;
  bonusExpIndex: number;
};

function BonusesByGrades({
  bonusesList,
  officeList,
  gradeList,
  isLoading,
  bonusesErrors,
  params,
  bonusesSettings,
  getBonusesSettings,
  editBonusesSettings,
  setBonusesParams,
  getOfficesFilter,
  getGradesFilters,
  createNewBonus,
  editBonus,
  deleteBonus,
  resetErrors,
  resetBonusesState,
}: ConnectedProps<typeof connector>) {
  const policies = useContext(PoliciesContext);

  const intl = useIntl();
  const [clickedBonus, setBonusClicked] = useState(new BonusCategoryInfo());
  const [modalNewBonusIsOpen, setMoldaNewBonusIsOpen] = useState(false);
  const [modalEditBonusIsOpen, setMoldaEditBonusIsOpen] = useState(false);
  const [modalDeleteBonusIsOpen, setMoldaDeleteBonusIsOpen] = useState(false);
  const [clickedBonusExp, setBonusExpClicked] = useState<BonusExpType>({
    bonusExp: new BonusExperiencesInfo(),
    bonusExpIndex: 0,
  });
  const [modalEditBonusByGradesIsOpen, setModalEditBonusByGradesIsOpen] = useState(false);
  const [modalSettingsIsOpen, setModalSettingsIsOpen] = useState(false);

  useEffect(() => {
    getOfficesFilter();
    getGradesFilters();
    getBonusesSettings();
    return () => {
      resetBonusesState();
    };
  }, []);

  const openNewBonusModal = useCallback(() => {
    setMoldaNewBonusIsOpen(true);
  }, []);

  const closeNewBonusModal = useCallback(() => {
    setMoldaNewBonusIsOpen(false);
  }, []);

  const openEditBonusModal = useCallback(() => {
    setMoldaEditBonusIsOpen(true);
  }, []);

  const closeEditBonusModal = useCallback(() => {
    modalEditBonusIsOpen && setBonusClicked(new BonusCategoryInfo());
    setMoldaEditBonusIsOpen(false);
  }, [modalEditBonusIsOpen]);

  const openDeleteBonusModal = useCallback(() => {
    setMoldaDeleteBonusIsOpen(true);
  }, []);

  const closeDeleteBonusModal = useCallback(() => {
    setMoldaDeleteBonusIsOpen(false);
  }, []);

  const openEditBonusByGradesModal = useCallback(() => {
    setModalEditBonusByGradesIsOpen(true);
  }, []);

  const closeEditBonusByGradesModal = useCallback(() => {
    modalEditBonusByGradesIsOpen && setBonusClicked(new BonusCategoryInfo());
    setModalEditBonusByGradesIsOpen(false);
  }, [modalEditBonusByGradesIsOpen]);

  const openSettingsModal = useCallback(() => {
    setModalSettingsIsOpen(true);
  }, []);

  const closeSettingsModal = useCallback(() => {
    setModalSettingsIsOpen(false);
  }, []);

  const handleBonusesOfficeChange = useCallback(
    name => (data: any) => {
      setBonusesParams({ [name]: [data.value] });
    },
    [],
  );

  useEffect(() => {
    !isEmpty(params.officeIds) && setBonusesParams({ officeIds: params.officeIds });
  }, []);

  useEffect(() => {
    !isEmpty(officeList) &&
      isEmpty(params.officeIds) &&
      handleBonusesOfficeChange('officeIds')({ value: officeList[0]?.id });
  }, [officeList]);

  const tableSubRowActions: any = useMemo(
    () => [
      ...(checkPolicies([UPDATE_BONUS_BY_GRADES], policies)
        ? [
            {
              label: (
                <>
                  <Icon iconName="pencil" externalClass="dropdown__list-item__icon" />
                  <FormattedMessage {...messages.editButton} />
                </>
              ),
              itemClassName: 'dropdown__list-item__button',
              handler: (row: BonusExperiencesInfo, bonusExpIndex: number) => {
                handleBonusClicked(row, bonusExpIndex);
                openEditBonusByGradesModal();
              },
            },
          ]
        : []),
    ],
    [bonusesList, gradeList, policies],
  );

  const tableHeaderItems = [
    {
      name: '',
      className: 'table__head-social',
      sortName: '',
    },
    {
      name: intl.formatMessage(messages.gradesLabel),
      className: 'table__head-column table__head-column--center bonus-grades-title',
      colspan: gradeList?.length + (tableSubRowActions.length && 1),
      sortName: '',
    },
  ];

  const tableColumnsStart = useMemo(
    () => [
      {
        Header: intl.formatMessage(messages.socialPaymentLabel),
        accessor: 'name',
        headClassName: 'table__head-column--no-border table__head-social',
      },
    ],
    [gradeList],
  );

  if (bonusesList) {
    bonusesList.forEach((el: any) => {
      el.experiences.forEach((el: any) => {
        el.experienceGrades.sort((a: any, b: any) => {
          return a.gradeFrom.level - b.gradeFrom.level;
        });
      });
    });
  }

  const tableColumns = useMemo(
    () =>
      gradeList?.map((grade: any) => ({
        id: grade.id,
        Header: grade.name,
        headClassName: 'table__head-column--center table__grade',
      })),
    [gradeList],
  );

  const gradeColumns = tableColumnsStart.concat(tableColumns || []);

  const handleBonusClicked = (row: BonusExperiencesInfo, bonusExpIndex: number) => {
    bonusesList.forEach((bonus: BonusCategoryInfo) => {
      if (bonus.experiences.find((bonusExp: BonusExperiencesInfo) => row.id === bonusExp.id)) {
        setBonusClicked(bonus);
      }
    });
    setBonusExpClicked({
      bonusExp: row,
      bonusExpIndex,
    });
  };

  const officesOptions = useMemo(
    () =>
      officeList.map((office: OfficeInfo) => ({
        label: office.name,
        value: office.id,
      })),
    [officeList],
  );

  const officesValues = useMemo(
    () => officesOptions.find((el: { label: string; value: string }) => el.value === params.officeIds[0]),
    [params.officeIds],
  );

  const tableActions: any = useMemo(
    () => [
      ...(checkPolicies([UPDATE_BONUS_BY_GRADES], policies)
        ? [
            {
              label: (
                <>
                  <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
                  {intl.formatMessage(messages.editButton)}
                </>
              ),
              handler: (row: BonusCategoryInfo) => {
                setBonusClicked(row);
                openEditBonusModal();
              },
            },
          ]
        : []),
      ...(checkPolicies([DELETE_BONUS_BY_GRADES], policies)
        ? [
            {
              label: (
                <>
                  <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
                  {intl.formatMessage(messages.deleteButton)}
                </>
              ),
              handler: (row: BonusCategoryInfo) => {
                setBonusClicked(row);
                openDeleteBonusModal();
              },
            },
          ]
        : []),
    ],
    [policies],
  );

  const customColumn = useCallback(
    (row: any) => {
      if (row.depth === 0) {
        const originalRow: BonusCategoryInfo = row.original;
        return (
          <>
            <td
              className="table__data first-column"
              colSpan={gradeList?.length + (tableSubRowActions.length && 1)}
              //@ts-ignore
              style={{ '--column-padding-left': '0px' }}
            >
              <div className="table__row-title">
                {!isEmpty(originalRow.experiences) && (
                  <Icon iconName="arrow-open" externalClass="icon table__open-icon" />
                )}
                <div className="table__data-wrapper">{originalRow.name}</div>
              </div>
            </td>
            <td className="table__data first-column">
              <div className="bonus__category-action-wrapper">
                {originalRow.active ? (
                  <span className="bonus-category-active">
                    <FormattedMessage {...messages.activeLabel} />
                  </span>
                ) : (
                  <span className="bonus-category-inactive">
                    <FormattedMessage {...messages.inactiveLabel} />
                  </span>
                )}
                {!isEmpty(tableActions) && (
                  <Dropdown
                    dropdownClass="dropdown--no-bg"
                    dropdownToggle={<Icon iconName="dots" externalClass="dropdown__button-main-icon" />}
                    dropdownList={tableActions}
                    dropdownInfo={originalRow}
                    dropdownIndex={row.id.split('.')[1]}
                    stopPropagation
                  />
                )}
              </div>
            </td>
          </>
        );
      } else if (row.depth === 1) {
        const originalRow: BonusExperiencesInfo = row.original;
        return (
          <>
            <td
              //@ts-ignore
              style={{ '--column-padding-left': '32px' }}
              key={originalRow.id}
              className="table__data sub_row_table__data first-column"
            >
              <div className="table__row-title">
                <div className="table__data-wrapper">
                  <span className="bonus-title"> {originalRow.name}</span>
                </div>
              </div>
            </td>
            {originalRow.experienceGrades.map((el: any) => (
              <td key={el.id} className="table__data bonus-value" colSpan={el.gradeTo.level - el.gradeFrom.level + 1}>
                <div>{el.value}</div>
              </td>
            ))}
            <td className="table__data sub_row_table__data" colSpan={gradeList.length + 1}>
              {!!tableSubRowActions.length && (
                <div className="bonus__category-action-wrapper">
                  <Dropdown
                    dropdownClass="dropdown--no-bg"
                    dropdownToggle={<Icon iconName="dots" externalClass="dropdown__button-main-icon" />}
                    dropdownList={tableSubRowActions}
                    dropdownIndex={row.id.split('.')[1]}
                    dropdownInfo={originalRow}
                    stopPropagation
                  />
                </div>
              )}
            </td>
          </>
        );
      }
      return null;
    },
    [gradeList, tableSubRowActions],
  );

  return (
    <>
      <div className="page__panel bonuses-by-grades">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
            <div className="page__panel-top__control">
              <AccessChecker verifiablePolicies={[VIEW_BONUS_SETTINGS]}>
                <div className="bonuses-settings" onClick={openSettingsModal}>
                  <Icon iconName="settings" />
                </div>
              </AccessChecker>
            </div>
          </div>
          <div className="page__panel-bottom">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[UPDATE_BONUS_BY_GRADES]}>
                  <Button externalClass={'button--with-icon'} onClick={openNewBonusModal}>
                    <Icon iconName={'plus'} externalClass={'button__icon'} />
                    <span className="button__text">
                      <FormattedMessage {...messages.newButton} />
                    </span>
                  </Button>
                </AccessChecker>
                <Filter
                  options={officesOptions}
                  value={officesValues}
                  label={intl.formatMessage(messages.officeLabel)}
                  handleChange={handleBonusesOfficeChange('officeIds')}
                  externalClass="filters__select"
                />
                <RefreshButton onRefresh={() => setBonusesParams(params)} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content bonuses-by-grades">
        <div className="page__wrapper">
          <HierarchicalTable
            tableData={useTableData(bonusesList, ['experiences'])}
            tableHeaderItems={tableHeaderItems}
            tableColumns={gradeColumns}
            loading={isLoading.getBonusesList}
            error={bonusesErrors.bonusesListError}
            externalClass="table--striped table--bonuses-by-grades fixed"
            customColumn={customColumn}
          />
        </div>
      </div>
      {modalEditBonusByGradesIsOpen && (
        <ModalEditBonusByGrades
          isOpen
          editBonus={editBonus}
          onCloseRequest={closeEditBonusByGradesModal}
          resetErrors={resetErrors}
          isLoading={isLoading.editBonus}
          bonusError={bonusesErrors.bonusError}
          bonusData={clickedBonus}
          bonusExpData={clickedBonusExp}
          gradesList={gradeList}
        />
      )}
      {modalNewBonusIsOpen && (
        <ModalNewBonus
          isOpen
          onCloseRequest={closeNewBonusModal}
          createNewBonus={createNewBonus}
          resetErrors={resetErrors}
          isLoading={isLoading.createBonus}
          bonusError={bonusesErrors.bonusError}
          officesOptions={officesOptions}
        />
      )}
      {modalEditBonusIsOpen && bonusesSettings && (
        <ModalEditBonus
          isOpen
          onCloseRequest={closeEditBonusModal}
          editBonus={editBonus}
          resetErrors={resetErrors}
          isLoading={isLoading.editBonus}
          bonusError={bonusesErrors.bonusError}
          officesOptions={officesOptions}
          bonusData={clickedBonus}
        />
      )}
      {modalDeleteBonusIsOpen && (
        <ModalDeleteBonus
          isOpen
          onCloseRequest={closeDeleteBonusModal}
          onDeleteRequest={deleteBonus}
          resetErrors={resetErrors}
          isLoading={isLoading.deleteBonus}
          bonuseError={bonusesErrors.bonusError}
          bonusData={clickedBonus}
        />
      )}
      {modalSettingsIsOpen && (
        <ModalSettings
          isOpen
          onCloseRequest={closeSettingsModal}
          editBonusesSettings={editBonusesSettings}
          resetErrors={resetErrors}
          isLoading={isLoading.editBonusesSettings}
          bonusesErrors={bonusesErrors.bonusesSettingsError}
          bonusesSettings={bonusesSettings}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ bonuses, filters }: RootState) => ({
  bonusesList: bonuses.bonusesTableData?.content || [],
  gradeList: filters.gradesFilter.grades,
  isLoading: bonuses.loading,
  bonusesErrors: bonuses.errors,
  officeList: filters.officesFilter.offices,
  params: bonuses.params,
  bonusesSettings: bonuses.bonusesSettings,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setBonusesParams: (data: Partial<BonusesParams>) => dispatch(bonusesActions.setBonusesParams(data)),
  getBonusesSettings: () => dispatch(bonusesActions.getBonusesSettings()),
  editBonusesSettings: (data: BonusesSettingsType) => dispatch(bonusesActions.editBonusesSettings(data)),
  createNewBonus: (data: { data: BonusCategoryInfo; callback: () => void }) =>
    dispatch(bonusesActions.createNewBonus(data)),
  editBonus: (data: { id: string; data: BonusCategoryInfo; callback: () => void }) =>
    dispatch(bonusesActions.editBonus(data)),
  deleteBonus: (data: { data: string; callback: () => void }) => dispatch(bonusesActions.deleteBonus(data)),
  resetErrors: () => dispatch(bonusesActions.resetBonusesErrors()),
  getOfficesFilter: () => dispatch(filtersActions.getOfficesFilter()),
  getGradesFilters: () => dispatch(filtersActions.getGradesFilter()),
  resetBonusesState: () => dispatch(bonusesActions.resetBonusesState()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(BonusesByGrades);
