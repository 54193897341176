import { defineMessages } from 'react-intl';
import { deleteButton, editButton, clientLabel, newButton, totalRow } from '../../i18n/defaultMessage';

export default defineMessages({
  editButton,
  deleteButton,
  newButton,
  totalRow,
  clientLabel,
  pageTitle: {
    id: 'finance.planned.expense.title.dashboard',
    defaultMessage: 'Dashboard',
  },
  incomeTypeLabel: {
    id: 'finance.profit.loss.modal.label.income',
    defaultMessage: 'Income Type',
  },
  dashboardTopEdit: {
    id: 'finance.profit.loss.modal.label.dashboard.top',
    defaultMessage: 'Edit Dashboard Top Block',
  },
  dashboardEdit: {
    id: 'finance.profit.loss.modal.label.dashboard.block',
    defaultMessage: 'Edit Dashboard Block',
  },
});
