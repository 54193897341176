import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as financeActions from '../../actions/finance.actions';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Table from '../../components/Table';
import ModalNewProjectType from '../../components/ProjectTypes/Modals/ModalNewProjectType';
import ModalEditProjectType from '../../components/ProjectTypes/Modals/ModalEditProjectType';
import ModalDeleteProjectType from '../../components/ProjectTypes/Modals/ModalDeleteProjectType';
import { ProjectTypesParams } from '../../enums/params/finance.params';
import AccessChecker from '../../components/AccessChecker';
import { DELETE_PROJECT_TYPE, UPDATE_PROJECT_TYPE } from '../../constants/policies.constants';
import { ProjectType } from '../../types/finance';
import RefreshButton from '../../components/RefreshButton';

function ProjectTypes({
  tableData,
  isLoading,
  errors,
  getProjectTypesList,
  createProjectType,
  editProjectType,
  deleteProjectType,
  resetState,
  resetErrors,
  setProjectTypesParams,
  sortParams,
}: ConnectedProps<typeof connector>) {
  const intl = useIntl();

  const [modalNewProjectTypeIsOpen, setModalNewProjectTypeIsOpen] = useState(false);
  const [modalEditProjectTypeIsOpen, setModalEditProjectTypeIsOpen] = useState(false);
  const [modalDeleteProjectTypeIsOpen, setModalDeleteProjectTypeIsOpen] = useState(false);
  const [projectTypeClicked, setProjectTypeClicked] = useState<ProjectType>({
    name: '',
    id: '',
  });

  const handleSort = useCallback((sortBy, direction) => setProjectTypesParams({ sortBy, direction }), []);

  useEffect(() => {
    getProjectTypesList();
    return () => {
      resetState();
    };
  }, []);

  const openNewProjectTypeModal = useCallback(() => {
    setModalNewProjectTypeIsOpen(true);
  }, []);

  const closeNewProjectTypeeModal = useCallback(() => {
    setModalNewProjectTypeIsOpen(false);
    resetErrors();
  }, []);

  const closeEditProjectTypeModal = useCallback(() => {
    setModalEditProjectTypeIsOpen(false);
    resetErrors();
  }, []);

  const closeDeleteProjectTypeModal = useCallback(() => {
    setModalDeleteProjectTypeIsOpen(false);
    resetErrors();
  }, []);

  const tableColumns = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.nameColumn),
        modifier: (row: ProjectType) => row.name,
        sortName: 'name',
      },
    ],
    [],
  );

  const tableActions = useMemo(
    () => [
      {
        label: (
          <>
            <Icon iconName={'pencil'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.editButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: ProjectType) => {
          setProjectTypeClicked(row);
          setModalEditProjectTypeIsOpen(true);
        },
        verifiablePolicies: [UPDATE_PROJECT_TYPE],
      },
      {
        label: (
          <>
            <Icon iconName={'trash'} externalClass={'dropdown__list-item__icon'} />
            <FormattedMessage {...messages.deleteButton} />
          </>
        ),
        itemClassName: 'dropdown__list-item__button',
        handler: (row: ProjectType) => {
          setProjectTypeClicked(row);
          setModalDeleteProjectTypeIsOpen(true);
        },
        verifiablePolicies: [DELETE_PROJECT_TYPE],
      },
    ],
    [],
  );

  return (
    <>
      <div className="page__panel page__panel--fixed">
        <div className="page__wrapper">
          <div className="page__panel-top">
            <h1 className="page__title">
              <FormattedMessage {...messages.pageTitle} />
            </h1>
          </div>
          <div className="page__panel-bottom no-border">
            <div className="page__panel-bottom__wrapper--people">
              <div className="page__panel-bottom__wrapper--left">
                <AccessChecker verifiablePolicies={[UPDATE_PROJECT_TYPE]}>
                  <Button externalClass={'button--with-icon'} onClick={openNewProjectTypeModal}>
                    <Icon iconName={'plus'} externalClass={'button__icon'} />
                    <span className="button__text">
                      <FormattedMessage {...messages.newButton} />
                    </span>
                  </Button>
                </AccessChecker>
                <RefreshButton onRefresh={() => setProjectTypesParams(sortParams)} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page__content active_employees_page">
        <div className="page__wrapper">
          <Table
            externalClass={'table table--half'}
            tableColumns={tableColumns}
            tableData={tableData || []}
            loading={isLoading.getProjectTypesList}
            error={errors.projectTypesError}
            tableActions={tableActions}
            onSort={handleSort}
            params={sortParams}
          />
        </div>
      </div>
      {modalNewProjectTypeIsOpen && (
        <ModalNewProjectType
          isOpen
          onCloseRequest={closeNewProjectTypeeModal}
          createProjectType={createProjectType}
          error={errors.projectTypesError}
          isLoading={isLoading.createProjectType}
        />
      )}
      {modalEditProjectTypeIsOpen && (
        <ModalEditProjectType
          isOpen
          onCloseRequest={closeEditProjectTypeModal}
          editProjectType={editProjectType}
          isLoading={isLoading.editProjectType}
          error={errors.projectTypesError}
          projectType={projectTypeClicked}
        />
      )}
      {modalDeleteProjectTypeIsOpen && (
        <ModalDeleteProjectType
          isOpen
          onCloseRequest={closeDeleteProjectTypeModal}
          onDeleteRequest={deleteProjectType}
          isLoading={isLoading.deleteProjectType}
          error={errors.projectTypesError}
          projectType={projectTypeClicked}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ finance }: RootState) => ({
  errors: finance.errors,
  isLoading: finance.loading,
  tableData: finance.projectTypesListData?.content,
  sortParams: finance.projectTypesParams,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getProjectTypesList: () => dispatch(financeActions.getProjectTypesList()),
  createProjectType: (data: { data: ProjectType; callback: () => void }) =>
    dispatch(financeActions.createProjectType(data)),
  editProjectType: (data: { data: ProjectType; callback: () => void }) =>
    dispatch(financeActions.editProjectType(data)),
  deleteProjectType: (data: { id: string; callback: () => void }) => dispatch(financeActions.deleteProjectType(data)),
  resetState: () => dispatch(financeActions.resetState()),
  resetErrors: () => dispatch(financeActions.resetErrors()),
  setProjectTypesParams: (params: Partial<ProjectTypesParams>) =>
    dispatch(financeActions.setProjectTypesParams(params)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ProjectTypes);
