import { get } from 'lodash-es';
import { getDateParams } from '../../utils/params.utils';

export class DashboardParams {
  dateFrom: string;
  dateTo: string;
  officeIds: string[];

  constructor(params?: unknown) {
    this.dateFrom = get(params, 'dateFrom', getDateParams({ withTime: false, period: 'month' }));
    this.dateTo = get(params, 'dateTo', getDateParams({ withTime: false, getFromStart: false, period: 'month' }));
    this.officeIds = get(params, 'officeIds', []);
  }
}

export class DashboardChartsRangeParams {
  dateFrom: string;
  dateTo: string;

  constructor(params?: unknown) {
    this.dateFrom = get(params, 'dateFrom', getDateParams({ withTime: false, period: 'year' }));
    this.dateTo = get(params, 'dateTo', getDateParams({ withTime: false, getFromStart: false, period: 'year' }));
  }
}
